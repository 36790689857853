import React from 'react'
import PropTypes from 'prop-types'
// Local deps
import './style.css'

class Zoom extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      backgroundImage: `url('${props.src || ''}')`,
      backgroundPosition: '0% 0%',
      show: false,
      startTrack: false,
    }
  }
  handleClick = e => {
    const { left, top, width, height } = e.target.getBoundingClientRect()
    const x = (e.clientX - left) / width * 100
    const y = (e.clientY - top) / height * 100
    this.setState(prevState => ({
      backgroundPosition: `${x}% ${y}%`,
      show: !prevState.show,
      startTrack: !prevState.startTrack,
    }))
  }
  handleMouseMove = e => {
    if (!this.state.startTrack) {
      return
    }
    const { left, top, width, height } = e.target.getBoundingClientRect()
    const x = (e.clientX - left) / width * 100
    const y = (e.clientY - top) / height * 100
    this.setState({
      backgroundPosition: `${x}% ${y}%`,
      zIndex: 100,
      position: 'relative',
      show: true,
    })
  }
  handleMouseLeave = e => {
    this.setState({ show: false, startTrack: false })
  }

  render = () =>
    <figure
      className='img-container'
      onClick={this.handleClick}
      onMouseMove={this.handleMouseMove}
      onMouseLeave={this.handleMouseLeave}
      style={{
        maxWidth: 1080,
        height: '100%',
        width: '100%',
        position: 'relative',
        zIndex: 100,
        ...(this.state.show && this.state),
      }}
    >
      <div style={{
        backgroundImage: `url('${this.props.src || ''}')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '100%',
        height: '100%',
        ...(this.state.show && { opacity: 0 }),
      }}>
      </div>
    </figure>
}

Zoom.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
}

export default Zoom
