import { call, put, takeLatest, fork } from 'redux-saga/effects'
import config from 'config'
import axios from 'utils/axios'
import moment from 'utils/moment'
import { getErrorMessage } from 'utils/api'
// Local deps
import StatisticsActions, { StatisticsTypes } from './actions'
import { downloadFile } from 'utils/invoices'
import { getUTCDate } from 'utils/dateTime'

// Sagas
function * getCustomersStatistic () {
  yield put(StatisticsActions.getCustomersStatisticLoading())
  try {
    const { data: { data: customersStatistic } } = yield call(axios.get, `/customers_statistics`)
    yield put(StatisticsActions.getCustomersStatisticSuccess(customersStatistic))
  } catch (e) {
    yield put(StatisticsActions.getCustomersStatisticFailure(getErrorMessage(e)))
  }
}

function * getSubscriptionsStatistic ({ startDate, endDate }) {
  yield put(StatisticsActions.getSubscriptionsStatisticLoading())
  try {
    const url = `/subscriptions_statistic?start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}`
    const { data: { data: subscriptionsStatistic } } = yield call(axios.get, url)
    yield put(StatisticsActions.getSubscriptionsStatisticSuccess(
      subscriptionsStatistic.active,
      subscriptionsStatistic.canceled,
    ))
  } catch (e) {
    yield put(StatisticsActions.getSubscriptionsStatisticFailure(getErrorMessage(e)))
  }
}

function * getAllSubscriptions ({ startDate, endDate }) {
  yield put(StatisticsActions.getAllSubscriptionsLoading())
  try {
    // activated_date_gt, activated_date_lt, canceled_date_gt, canceled_date_lt
    // ?start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}
    const { data: { data: subscriptions } } = yield call(axios.get, `/subscriptions`)
    const newSubs = subscriptions.map(sub => ({
      ...sub,
      companyId: sub.company.id,
      companyName: sub.company.name,
      canceled_by: sub.canceled_by ? sub.canceled_by.email : null,
      activated_by: sub.activated_by ? sub.activated_by.email : null,
      activated_date: sub.activated_date ? getUTCDate(sub.activated_date).format(config.DATETIME_FORMAT) : sub.activated_date,
      canceled_date: sub.canceled_date ? getUTCDate(sub.canceled_date).format(config.DATETIME_FORMAT) : sub.canceled_date,
      end_date: sub.end_date ? getUTCDate(sub.end_date).format(config.DATETIME_FORMAT) : sub.end_date,
      last_payment_date: sub.last_payment_date ? getUTCDate(sub.last_payment_date).format(config.DATETIME_FORMAT) : sub.last_payment_date,
      next_payment_date: sub.next_payment_date ? getUTCDate(sub.next_payment_date).format(config.DATETIME_FORMAT) : sub.next_payment_date,
    }))
    const isCanceledSub = sub => Boolean(sub.cancellation_reason) || sub.canceled_date
    const momentStartDate = getUTCDate(startDate)
    const momentEndDate = getUTCDate(endDate)

    const filteredDataByDate = newSubs.filter(sub =>
      moment(sub.activated_date).isBetween(momentStartDate, momentEndDate) ||
      moment(sub.canceled_date).isBetween(momentStartDate, momentEndDate) ||
      moment(sub.end_date).isBetween(momentStartDate, momentEndDate) ||
      moment(sub.last_payment_date).isBetween(momentStartDate, momentEndDate) ||
      moment(sub.next_payment_date).isBetween(momentStartDate, momentEndDate),
    )
    const active = filteredDataByDate.filter(sub => !isCanceledSub(sub))
    const canceled = filteredDataByDate.filter(sub => isCanceledSub(sub))

    yield put(StatisticsActions.getAllSubscriptionsSuccess(
      newSubs,
      active,
      canceled,
    ))
  } catch (e) {
    yield put(StatisticsActions.getAllSubscriptionsFailure(getErrorMessage(e)))
  }
}

function * getRefunds ({ startDate, endDate }) {
  yield put(StatisticsActions.getRefundsLoading())
  try {
    const url = `/refunds?start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}`
    const { data: { data: refunds } } = yield call(axios.get, url)
    yield put(StatisticsActions.getRefundsSuccess(refunds))
  } catch (e) {
    yield put(StatisticsActions.getRefundsFailure(getErrorMessage(e)))
  }
}

function * downloadCSVSubscriptions () {
  yield put(StatisticsActions.downloadCSVSubscriptionsLoading())
  try {
    const url = `/subscriptions/download_statistics`
    const { data: subscriptions } = yield call(axios.get, url)
    downloadFile('subscriptions', subscriptions, { type: 'text', extension: 'csv' })
    yield put(StatisticsActions.downloadCSVSubscriptionsSuccess())
  } catch (e) {
    yield put(StatisticsActions.downloadCSVSubscriptionsFailure())
  }
}

function * getSessionsForProduct ({ productName, startDate, endDate }) {
  yield put(StatisticsActions.getSessionsForProductLoading(productName))
  try {
    const url = `/statistics/${productName}/sessions?order_by=created&order=asc&created_gt=${startDate}&created_lt=${endDate}&phases=startup&os=windows&exclude_pls=true`
    const { data: { data: sessions } } = yield call(axios.get, url)
    yield put(StatisticsActions.getSessionsForProductSuccess(productName, sessions, startDate, endDate))
  } catch (e) {
    yield put(StatisticsActions.getSessionsForProductFailure(productName, getErrorMessage(e)))
  }
}

// Watchers
function * getCustomersStatisticWatcher () {
  yield takeLatest(StatisticsTypes.GET_CUSTOMERS_STATISTIC, getCustomersStatistic)
}

function * getSubscriptionsStatisticWatcher () {
  yield takeLatest(StatisticsTypes.GET_SUBSCRIPTIONS_STATISTIC, getSubscriptionsStatistic)
}

function * getAllSubscriptionsWatcher () {
  yield takeLatest(StatisticsTypes.GET_ALL_SUBSCRIPTIONS, getAllSubscriptions)
}

function * getRefundsWatcher () {
  yield takeLatest(StatisticsTypes.GET_REFUNDS, getRefunds)
}

function * downloadCSVSubscriptionsWatcher () {
  yield takeLatest(StatisticsTypes.DOWNLOAD_CSV_SUBSCRIPTIONS, downloadCSVSubscriptions)
}

function * getSessionsForProductWatcher () {
  yield takeLatest(StatisticsTypes.GET_SESSIONS_FOR_PRODUCT, getSessionsForProduct)
}
export default function * root () {
  yield fork(getCustomersStatisticWatcher)
  yield fork(getSubscriptionsStatisticWatcher)
  yield fork(getAllSubscriptionsWatcher)
  yield fork(getRefundsWatcher)
  yield fork(downloadCSVSubscriptionsWatcher)
  yield fork(getSessionsForProductWatcher)
}
