import { createReducer } from 'reduxsauce'
import { INITIAL_STATE } from './initialState'
import { AppTypes } from './actions'
import { storageId } from 'config'
import { convertRawLog } from 'types/logs'
import { convertRawRoverEvent, RoverEventType } from 'types/roverEvents'
import { makeUnique, mapById } from 'utils/list'
import { RoverTypes } from 'modules/rovers/actions'

export const getConstantsSuccess = (state, {
  countries,
  industries,
  timeZones,
}) => {
  return state.merge({
    constants: {
      ...state.get('constants'),
      countries: countries.map(country => country.countryName).sort(),
      countryToCode: countries.reduce((all, country) => {
        all[country.countryName] = country.countryShortCode
        return all
      }, {}),
      regions: countries.reduce((all, country) => {
        all[country.countryName] = country.regions.map(region => region.name)
        return all
      }, {}),
      timeZones,
      industries,
    },
    constantsLoaded: true,
    getConstantsIsLoading: false,
  })
}

export const getConstantsLoading = state => {
  return state.merge({
    getConstantsIsLoading: true,
  })
}

export const getConstantsFailure = state => {
  return state.merge({
    constantsLoaded: false,
    getConstantsIsLoading: false,
  })
}

export const getReleasesSuccess = (state, {
  releases,
}) => {
  return state.merge({
    constants: {
      ...state.get('constants'),
      releases: releases.map(release => ({ ...release, id: (release.version || release.id) })),
    },
    constantsLoaded: true,
    getConstantsIsLoading: false,
  })
}

export const getReleasesLoading = state => {
  return state.merge({
    getConstantsIsLoading: true,
  })
}

export const getReleasesFailure = state => {
  return state.merge({
    constantsLoaded: false,
    getConstantsIsLoading: false,
  })
}

export const getProductReleasesSuccess = (state, {
  productName,
  releases,
  shouldShowPublic,
}) => {
  return state.merge({
    releases: {
      ...state.get('releases'),
      [productName]: releases.map(release => ({
        ...release,
        originalId: release.id,
        id: release.version || release.id,
        changes: (release.filtered_changes || [])
          .map(change => (shouldShowPublic ? !change.public ? '<span style="color: red;">(Non public)</span> ' : '' : '') + [change.issue_number ? `<b>Issue: ${change.issue_number}</b>` : '', change.description].filter(Boolean).join('. '))
          .filter(Boolean)
          .join('\r\n'),
      })),
    },
    isLoadingReleases: {
      ...state.get('isLoadingReleases'),
      [productName]: false,
    },
  })
}

export const getProductReleasesLoading = (state, { productName }) => {
  return state.merge({
    isLoadingReleases: {
      ...state.get('isLoadingReleases'),
      [productName]: true,
    },
  })
}

export const getProductReleasesFailure = (state, { productName }) => {
  return state.merge({
    isLoadingReleases: {
      ...state.get('isLoadingReleases'),
      [productName]: false,
    },
  })
}

export const getTagsSuccess = (state, { tags }) => {
  return state.merge({
    constants: {
      ...state.get('constants'),
      tags: tags.map(tag => tag.name),
    },
  })
}

export const getTagsLoading = state => {
  return state.merge({})
}

export const getTagsFailure = state => {
  return state.merge({})
}

export const getAllSystemTypesLoading = state => {
  return state.merge({
    getAllSystemTypesIsLoading: true,
  })
}

export const getAllSystemTypesSuccess = (state, { systemTypes }) => {
  return state.merge({
    systemTypes,
    getAllSystemTypesIsLoading: false,
  })
}

export const getAllSystemTypesFailure = state => {
  return state.merge({
    getAllSystemTypesIsLoading: false,
  })
}

export const getAllCustomerTypesLoading = state => {
  return state.merge({
    getAllCustomerTypesIsLoading: true,
  })
}

export const getAllCustomerTypesSuccess = (state, { customerTypes }) => {
  return state.merge({
    customerTypes,
    getAllCustomerTypesIsLoading: false,
  })
}

export const getAllCustomerTypesFailure = state => {
  return state.merge({
    getAllCustomerTypesIsLoading: false,
  })
}

export const changeUserSettingsProjects = (state, { settings }) => {
  const userSettings = state.get('userSettings')
  const newUserSettings = {
    ...userSettings,
    projects: {
      ...userSettings.projects,
      ...settings,
    },
  }
  localStorage.setItem(storageId.settings, JSON.stringify(newUserSettings))
  return state.merge({
    userSettings: newUserSettings,
  })
}

// Logs
export const getLogsLoading = state => state.merge({
  getLogsIsLoading: true,
})

export const getLogsSuccess = (state, { logs, pagination }) => {
  return state.merge({
    getLogsIsLoading: false,
    logs: logs
      .map(convertRawLog),
    // .sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())
    logsPagination: pagination,
    // logsPagination: pagination
  })
}
export const getLogsFailure = (state, { logs }) => {
  return state.merge({
    getLogsIsLoading: false,
  })
}

// DA events
export const getDAEventsLoading = state => state.merge({
  getDAEventsIsLoading: true,
})

export const getDAEventsSuccess = (state, { daEvents, pagination }) => {
  return state.merge({
    getDAEventsIsLoading: false,
    daEvents: daEvents
      .map(item => convertRawRoverEvent(item, RoverEventType.DATA_ACQUISITION)),
    daEventsPagination: pagination,
  })
}
export const getDAEventsFailure = state => {
  return state.merge({
    getDAEventsIsLoading: false,
  })
}
// All da events
export const getAllDAEventsLoading = state => state.merge({
  getDAEventsIsLoading: true,
})

export const getAllDAEventsSuccess = (state, { daEvents }) => {
  return state.merge({
    getDAEventsIsLoading: false,
    allDaEvents: daEvents.map(item => convertRawRoverEvent(item, RoverEventType.DATA_ACQUISITION)),
  })
}
export const getAllDAEventsFailure = state => {
  return state.merge({
    getDAEventsIsLoading: false,
  })
}

// DA request events
export const getDARequestEventsLoading = state => state.merge({
  getDARequestEventsIsLoading: true,
})

export const getDARequestEventsSuccess = (state, { daEvents, pagination }) => {
  return state.merge({
    getDARequestEventsIsLoading: false,
    daRequestEvents: daEvents
      .map(item => convertRawRoverEvent(item, RoverEventType.DATA_ACQUISITION_REQUEST)),
    daRequestEventsPagination: pagination,
  })
}
export const getDARequestEventsFailure = state => {
  return state.merge({
    getDARequestEventsIsLoading: false,
  })
}

// All DA request events
export const getAllDARequestEventsLoading = state => state.merge({
  getDARequestEventsIsLoading: true,
})

export const getAllDARequestEventsSuccess = (state, { darEvents }) => {
  return state.merge({
    getDARequestEventsIsLoading: false,
    allDaRequestEvents: darEvents.map(item => convertRawRoverEvent(item, RoverEventType.DATA_ACQUISITION_REQUEST)),
  })
}
export const getAllDARequestEventsFailure = state => {
  return state.merge({
    getDARequestEventsIsLoading: false,
  })
}

// Rent events
export const getRentEventsLoading = state => state.merge({
  getRentEventsIsLoading: true,
})

export const getRentEventsSuccess = (state, { rentEvents, pagination }) => {
  return state.merge({
    getRentEventsIsLoading: false,
    rentEvents: rentEvents.map(item => convertRawRoverEvent(item, RoverEventType.RENT)),
    rentEventsPagination: pagination,
  })
}
export const getRentEventsFailure = state => {
  return state.merge({
    getRentEventsIsLoading: false,
  })
}

// All Rent events
export const getAllRentEventsLoading = state => state.merge({
  getRentEventsIsLoading: true,
})

export const getAllRentEventsSuccess = (state, { rentEvents }) => {
  return state.merge({
    getRentEventsIsLoading: false,
    allRentEvents: rentEvents.map(item => convertRawRoverEvent(item, RoverEventType.RENT)),
  })
}
export const getAllRentEventsFailure = state => {
  return state.merge({
    getRentEventsIsLoading: false,
  })
}

export const getComponentTypesLoading = state => state.merge({})
export const getComponentTypesSuccess = (state, { componentTypes }) => state.merge({ componentTypes })
export const getComponentTypesFailure = state => state.merge({})

export const getPropertyTypesLoading = state => state.merge({})
export const getPropertyTypesSuccess = (state, { propertyTypes }) => state.merge({ propertyTypes })
export const getPropertyTypesFailure = state => state.merge({})

export const getULDataSuccess = (state, { sales_invoices, sales_orders, purchase_orders, credit_notes }) => {
  return state.merge({
    sales_invoices: sales_invoices,
    sales_orders: sales_orders,
    purchase_orders: purchase_orders,
    credit_notes: credit_notes,
    isLoadingULData: false,
  })
}

export const getULDataLoading = state => {
  return state.merge({
    isLoadingULData: true,
  })
}

export const getULDataFailure = state => {
  return state.merge({
    isLoadingULData: false,
  })
}

export const getAllProjectsSuccess = (state, { projects }) => {
  return state.merge({
    projects,
  })
}

// Notifications
export const getNotificationsLoading = state => state.merge({
  getNotificationsIsLoading: true,
})

export const getNotificationsSuccess = (state, { notifications, pagination }) => {
  return state.merge({
    getNotificationsIsLoading: false,
    notifications,
    notificationsPagination: pagination,
  })
}
export const getNotificationsFailure = state => {
  return state.merge({
    getNotificationsIsLoading: false,
  })
}

export const getDAREventTypesLoading = state => state.merge({
  daEventTypes: [],
})

export const getDAREventTypesSuccess = (state, { darEventTypes }) => {
  return state.merge({
    daEventTypes: darEventTypes.map(eventType => ({
      label: eventType.name,
      labelDescriptioned: `${eventType.name}${eventType.description ? ` (${eventType.description})` : ''}`,
      value: eventType.value,
      id: eventType.value,
    })),
  })
}
export const getDAREventTypesFailure = state => {
  return state.merge({
    daEventTypes: [],
  })
}

export const getDAEventIssueTypesLoading = state => state.merge({
  daIssueTypes: [],
})

export const getDAEventIssueTypesSuccess = (state, { daIssueTypes }) => {
  return state.merge({
    daIssueTypes: daIssueTypes.map(eventType => ({
      label: eventType.name,
      labelDescriptioned: `${eventType.name}${eventType.description ? ` (${eventType.description})` : ''}`,
      value: eventType.value,
      id: eventType.value,
    })),
  })
}
export const getDAEventIssueTypesFailure = state => {
  return state.merge({
    daIssueTypes: [],
  })
}

export const getCheckListStepsLoading = state => state.merge({
  checkListSteps: [],
})

export const getCheckListStepsSuccess = (state, { checkListSteps }) => {
  return state.merge({
    checkListSteps,
  })
}
export const getCheckListStepsFailure = state => {
  return state.merge({
    checkListSteps: [],
  })
}

export const getRMAStatusesLoading = state => state.merge({
  rmaStatuses: [],
  getRMAStatusesIsLoading: true,
})

export const getRMAStatusesSuccess = (state, { rmaStatuses }) => {
  return state.merge({
    getRMAStatusesIsLoading: false,
    rmaStatuses: rmaStatuses.map(rmaStatus => ({
      label: rmaStatus.name,
      labelDescriptioned: `${rmaStatus.name}${rmaStatus.description ? ` (${rmaStatus.description})` : ''}`,
      description: rmaStatus.description,
      value: rmaStatus.value,
      id: rmaStatus.value,
      isDeprecated: rmaStatus.is_deprecated,
      notify: rmaStatus.notify_customer,
    })),
  })
}
export const getRMAStatusesFailure = state => {
  return state.merge({
    getRMAStatusesIsLoading: false,
    rmaStatuses: [],
  })
}

export const getSystemTypeStatusesLoading = state => state.merge({
  systemTypeStatuses: [],
  getSystemTypeStatusesIsLoading: true,
})

export const getSystemTypeStatusesSuccess = (state, { systemTypeStatuses }) => {
  return state.merge({
    getSystemTypeStatusesIsLoading: false,
    systemTypeStatuses: systemTypeStatuses.map(systemTypeStatus => ({
      label: systemTypeStatus.name,
      labelDescriptioned: `${systemTypeStatus.name}${systemTypeStatus.description ? ` (${systemTypeStatus.description})` : ''}`,
      description: systemTypeStatus.description,
      value: systemTypeStatus.value,
      id: systemTypeStatus.value,
    })),
  })
}
export const getSystemTypeStatusesFailure = state => {
  return state.merge({
    getSystemTypeStatusesIsLoading: false,
    systemTypeStatuses: [],
  })
}

export const setHeaderDimensions = (state, { width, height }) => {
  return state.merge({
    headerHeight: height,
    headerWidth: width,
  })
}

export const getProductsLoading = state => state.merge({ products: [] })
export const getProductsSuccess = (state, { products }) => state.merge({ products })
export const getProductsFailure = state => state.merge({ products: [] })

export const getWorkListsLoading = state => state.merge({ workLists: [], getWorkListsIsLoading: true })
export const getWorkListsSuccess = (state, { workLists }) => state.merge({ workLists, getWorkListsIsLoading: false })
export const getWorkListsFailure = state => state.merge({ workLists: [], getWorkListsIsLoading: false })

export const getAntennasSuccess = (state, { antennas }) => {
  return state.merge({
    getAntennasIsLoading: false,
    constants: {
      ...state.get('constants'),
      antennas: makeUnique(antennas.map(antenna => antenna.antenna_type)),
      antennasOptions: antennas.map(antenna => {
        return {
          antenna_type: antenna.antenna_type,
          phaseCenterOffset: antenna.phaseCenterOffset,
          radome: antenna.radome,
        }
      }),
    },
  })
}

export const getAntennasLoading = state => {
  return state.merge({ getAntennasIsLoading: true })
}

export const getAntennasFailure = state => {
  return state.merge({ getAntennasIsLoading: false })
}

export const editEventSuccess = (state, { serial, eventId, eventType, event, eventAttachments = [] }) => {
  if (eventType === RoverEventType.DATA_ACQUISITION_REQUEST) {
    return state.merge({
      daRequestEvents: mapById(eventId, state.get('daRequestEvents'), () => convertRawRoverEvent(event, eventType)),
    })
  } else {
    return state.merge({})
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [AppTypes.GET_CONSTANTS_LOADING]: getConstantsLoading,
  [AppTypes.GET_CONSTANTS_SUCCESS]: getConstantsSuccess,
  [AppTypes.GET_CONSTANTS_FAILURE]: getConstantsFailure,

  [AppTypes.GET_ANTENNAS_LOADING]: getAntennasLoading,
  [AppTypes.GET_ANTENNAS_SUCCESS]: getAntennasSuccess,
  [AppTypes.GET_ANTENNAS_FAILURE]: getAntennasFailure,

  [AppTypes.GET_PRODUCT_RELEASES_LOADING]: getProductReleasesLoading,
  [AppTypes.GET_PRODUCT_RELEASES_SUCCESS]: getProductReleasesSuccess,
  [AppTypes.GET_PRODUCT_RELEASES_FAILURE]: getProductReleasesFailure,

  [AppTypes.GET_TAGS_LOADING]: getTagsLoading,
  [AppTypes.GET_TAGS_SUCCESS]: getTagsSuccess,
  [AppTypes.GET_TAGS_FAILURE]: getTagsFailure,

  [AppTypes.GET_ALL_SYSTEM_TYPES_LOADING]: getAllSystemTypesLoading,
  [AppTypes.GET_ALL_SYSTEM_TYPES_SUCCESS]: getAllSystemTypesSuccess,
  [AppTypes.GET_ALL_SYSTEM_TYPES_FAILURE]: getAllSystemTypesFailure,

  [AppTypes.GET_ALL_CUSTOMER_TYPES_LOADING]: getAllCustomerTypesLoading,
  [AppTypes.GET_ALL_CUSTOMER_TYPES_SUCCESS]: getAllCustomerTypesSuccess,
  [AppTypes.GET_ALL_CUSTOMER_TYPES_FAILURE]: getAllCustomerTypesFailure,

  [AppTypes.CHANGE_USER_SETTINGS_PROJECTS]: changeUserSettingsProjects,

  [AppTypes.GET_LOGS_LOADING]: getLogsLoading,
  [AppTypes.GET_LOGS_SUCCESS]: getLogsSuccess,
  [AppTypes.GET_LOGS_FAILURE]: getLogsFailure,

  [AppTypes.GET_DA_EVENTS_LOADING]: getDAEventsLoading,
  [AppTypes.GET_DA_EVENTS_SUCCESS]: getDAEventsSuccess,
  [AppTypes.GET_DA_EVENTS_FAILURE]: getDAEventsFailure,

  [AppTypes.GET_ALL_DA_EVENTS_LOADING]: getAllDAEventsLoading,
  [AppTypes.GET_ALL_DA_EVENTS_SUCCESS]: getAllDAEventsSuccess,
  [AppTypes.GET_ALL_DA_EVENTS_FAILURE]: getAllDAEventsFailure,

  [AppTypes.GET_DA_REQUEST_EVENTS_LOADING]: getDARequestEventsLoading,
  [AppTypes.GET_DA_REQUEST_EVENTS_SUCCESS]: getDARequestEventsSuccess,
  [AppTypes.GET_DA_REQUEST_EVENTS_FAILURE]: getDARequestEventsFailure,

  [AppTypes.GET_ALL_DA_REQUEST_EVENTS_LOADING]: getAllDARequestEventsLoading,
  [AppTypes.GET_ALL_DA_REQUEST_EVENTS_SUCCESS]: getAllDARequestEventsSuccess,
  [AppTypes.GET_ALL_DA_REQUEST_EVENTS_FAILURE]: getAllDARequestEventsFailure,

  [AppTypes.GET_RENT_EVENTS_LOADING]: getRentEventsLoading,
  [AppTypes.GET_RENT_EVENTS_SUCCESS]: getRentEventsSuccess,
  [AppTypes.GET_RENT_EVENTS_FAILURE]: getRentEventsFailure,

  [AppTypes.GET_ALL_RENT_EVENTS_LOADING]: getAllRentEventsLoading,
  [AppTypes.GET_ALL_RENT_EVENTS_SUCCESS]: getAllRentEventsSuccess,
  [AppTypes.GET_ALL_RENT_EVENTS_FAILURE]: getAllRentEventsFailure,

  [AppTypes.GET_NOTIFICATIONS_LOADING]: getNotificationsLoading,
  [AppTypes.GET_NOTIFICATIONS_SUCCESS]: getNotificationsSuccess,
  [AppTypes.GET_NOTIFICATIONS_FAILURE]: getNotificationsFailure,

  [AppTypes.GET_COMPONENT_TYPES_LOADING]: getComponentTypesLoading,
  [AppTypes.GET_COMPONENT_TYPES_SUCCESS]: getComponentTypesSuccess,
  [AppTypes.GET_COMPONENT_TYPES_FAILURE]: getComponentTypesFailure,

  [AppTypes.GET_PROPERTY_TYPES_LOADING]: getPropertyTypesLoading,
  [AppTypes.GET_PROPERTY_TYPES_SUCCESS]: getPropertyTypesSuccess,
  [AppTypes.GET_PROPERTY_TYPES_FAILURE]: getPropertyTypesFailure,

  [AppTypes.GET_UL_DATA_LOADING]: getULDataLoading,
  [AppTypes.GET_UL_DATA_SUCCESS]: getULDataSuccess,
  [AppTypes.GET_UL_DATA_FAILURE]: getULDataFailure,

  [AppTypes.GET_DAR_EVENT_TYPES_LOADING]: getDAREventTypesLoading,
  [AppTypes.GET_DAR_EVENT_TYPES_SUCCESS]: getDAREventTypesSuccess,
  [AppTypes.GET_DAR_EVENT_TYPES_FAILURE]: getDAREventTypesFailure,

  [AppTypes.GET_DA_EVENT_ISSUE_TYPES_LOADING]: getDAEventIssueTypesLoading,
  [AppTypes.GET_DA_EVENT_ISSUE_TYPES_SUCCESS]: getDAEventIssueTypesSuccess,
  [AppTypes.GET_DA_EVENT_ISSUE_TYPES_FAILURE]: getDAEventIssueTypesFailure,

  [AppTypes.GET_CHECK_LIST_STEPS_LOADING]: getCheckListStepsLoading,
  [AppTypes.GET_CHECK_LIST_STEPS_SUCCESS]: getCheckListStepsSuccess,
  [AppTypes.GET_CHECK_LIST_STEPS_FAILURE]: getCheckListStepsFailure,

  [AppTypes.GET_RMA_STATUSES_LOADING]: getRMAStatusesLoading,
  [AppTypes.GET_RMA_STATUSES_SUCCESS]: getRMAStatusesSuccess,
  [AppTypes.GET_RMA_STATUSES_FAILURE]: getRMAStatusesFailure,

  [AppTypes.GET_SYSTEM_TYPE_STATUSES_LOADING]: getSystemTypeStatusesLoading,
  [AppTypes.GET_SYSTEM_TYPE_STATUSES_SUCCESS]: getSystemTypeStatusesSuccess,
  [AppTypes.GET_SYSTEM_TYPE_STATUSES_FAILURE]: getSystemTypeStatusesFailure,

  [AppTypes.GET_PRODUCTS_LOADING]: getProductsLoading,
  [AppTypes.GET_PRODUCTS_SUCCESS]: getProductsSuccess,
  [AppTypes.GET_PRODUCTS_FAILURE]: getProductsFailure,

  [AppTypes.GET_WORK_LISTS_LOADING]: getWorkListsLoading,
  [AppTypes.GET_WORK_LISTS_SUCCESS]: getWorkListsSuccess,
  [AppTypes.GET_WORK_LISTS_FAILURE]: getWorkListsFailure,

  [AppTypes.GET_ALL_PROJECTS_SUCCESS]: getAllProjectsSuccess,
  [AppTypes.SET_HEADER_DIMENSIONS]: setHeaderDimensions,

  [RoverTypes.EDIT_EVENT_SUCCESS]: editEventSuccess,
})
