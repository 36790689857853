import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Select, MenuItem, FormControl, InputLabel, DialogActions, Button, DialogContent, Dialog, FormHelperText } from '@material-ui/core'

const AddedFilesTypesDialog = props => {
  const { documentTypes, open, onSubmit, eventType } = props
  const [type, setType] = useState(null) // documentTypes[0].value
  const onChange = e => {
    const { value } = e.target
    setType(value)
  }
  const error = !type
  const isLicenseKeyEvent = eventType === 'licenseKey'
  const allowNoneDocumentType = !isLicenseKeyEvent
  const label = isLicenseKeyEvent ? 'license type' : 'document type'
  return (
    <Dialog open={open} maxWidth='sm' fullWidth>
      <DialogContent style={{ padding: 36 }}>
        <FormControl fullWidth style={{ marginTop: 4 }} variant='outlined' error={error}>
          <InputLabel id='demo-simple-select-label'>Predefine added files {label} as</InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={type}
            label={`Predefine added files ${label} as`}
            onChange={onChange}
          >
            {allowNoneDocumentType && <MenuItem value='none'><i>None</i></MenuItem>}
            {documentTypes.map(({ label, value }) => (
              <MenuItem value={value} key={value}>{label}</MenuItem>
            ))}
          </Select>
          {error && <FormHelperText error={error}>
            You should select {label} for the files
          </FormHelperText>
          }
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button disabled={!type} onClick={() => onSubmit(type)}>Ok</Button>
      </DialogActions>
    </Dialog>
  )
}

AddedFilesTypesDialog.propTypes = {
  open: PropTypes.bool,
  eventType: PropTypes.string,
  documentTypes: PropTypes.array,
  onSubmit: PropTypes.func,
}

export default AddedFilesTypesDialog
