import { call, put, takeEvery, fork } from 'redux-saga/effects'
import { showErrorMessage } from 'utils/api'
import axios from 'utils/axios'
// Local deps
import ReleasesActions, { ReleasesTypes } from './actions'

function * getReleaseChanges ({ releaseId }) {
  yield put(ReleasesActions.getReleaseChangesLoading(releaseId))
  try {
    const { data: { data: changes } } = yield call(axios.get, `/releases/${releaseId}/changes`)
    yield put(ReleasesActions.getReleaseChangesSuccess(releaseId, changes))
  } catch (e) {
    showErrorMessage(e)
    yield put(ReleasesActions.getReleaseChangesFailure(releaseId, e.message || e))
  }
}

// Watchers
function * getCompanyProductUpdatesWatcher () {
  yield takeEvery(ReleasesTypes.GET_RELEASE_CHANGES, getReleaseChanges)
}
export default function * root () {
  yield fork(getCompanyProductUpdatesWatcher)
}
