import React from 'react'
import PropTypes from 'prop-types'
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import { getErrors, getLabels } from './utils'

const FileFields = props => {
  const {
    file,
    eventType,
    allowEventType,
    documentTypes,
    files,
    onChange,
  } = props
  const errors = getErrors(file, files, eventType)
  const labels = getLabels(file, files, eventType)
  return (
    <div style={{ width: '100%' }}>
      <TextField
        label={labels.name}
        fullWidth
        value={file.name}
        name='name'
        onChange={onChange(file.id)}
        error={errors.name}
      />
      {allowEventType && <FormControl fullWidth style={{ marginTop: 4 }}>
        <InputLabel id="demo-simple-select-label">Document type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={file.type}
          name='type'
          label='Document type'
          onChange={onChange(file.id)}
        >
          <MenuItem value='none'><i>None</i></MenuItem>
          {documentTypes.map(({ label, value }) => (
            <MenuItem value={value} key={value}>{label}</MenuItem>
          ))}
        </Select>
      </FormControl>
      }
    </div>
  )
}

FileFields.propTypes = {
  file: PropTypes.object,
  eventType: PropTypes.string,
  documentTypes: PropTypes.array,
  files: PropTypes.array,
  onChange: PropTypes.func,
  allowEventType: PropTypes.bool,
  editFileName: PropTypes.bool,
}

export default FileFields
