import { isReconRover } from './rovers'

export const getAllowedSoftwareUpdates = (releases, rovers) => {
  const allowedReleases = releases.filter(release => {
    const releaseName = release.name.toLowerCase()
    if (releaseName === 'reconpostprocessing' || releaseName === 'reconfirmware') {
      return rovers.find(rover => isReconRover(rover.serial))
    } else if (releaseName === 'spatialrover') {
      return rovers.find(rover => !isReconRover(rover.serial))
    } else {
      return true
    }
  })
  return allowedReleases
}
