import React from 'react'
import PropTypes from 'prop-types'
// Material UI
import { TableRow, TableCell } from '@material-ui/core'
// Project deps

const TotalRow = props => {
  const { columns, display, total } = props
  return (
    <TableRow>
      {
        columns.map((column, index) => {
          const { id, options = {} } = column
          const { customBodyRender } = options
          const totalCellValue = total[id]
          return (
            display[index] &&
              <TableCell key={`total-${id}`}>
                {
                  typeof customBodyRender === 'function'
                    ? customBodyRender(totalCellValue, total, true)
                    : totalCellValue || ''
                }
              </TableCell>
          )
        })
      }
    </TableRow>
  )
}

TotalRow.propTypes = {
  columns: PropTypes.array,
  display: PropTypes.array,
  total: PropTypes.object,
}

export default TotalRow
