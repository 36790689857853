import { isLocalEnvironment } from 'config'
import Cookies from 'js-cookie'
import { path } from 'ramda'
import { setToken } from 'utils/axios'
import { cookieName } from 'utils/cookies'

const persistMiddleware = store => next => action => {
  if (action.type === 'persist/REHYDRATE') {
    const newAction = {
      ...action,
      token: null,
    }
    if (isLocalEnvironment()) {
      const users = path(['payload', 'users'], action)
      if (users) {
        const token = users.get('token')
        setToken(token)
        newAction.token = token
      }
    } else {
      const token = Cookies.get(cookieName)
      if (token) {
        setToken(token)
        newAction.token = token
      }
    }
    next(newAction)
  } else {
    next(action)
  }
}

export default persistMiddleware
