import React from 'react'
import i18n from 'i18n'
import { path } from 'ramda'
// Material UI
import Chip from 'components/reusable/Chip'
import { MapBackendToFrontendLicenseType } from 'types/licenses'
import { isBundleSalesProduct, isDemoDuration, isMaintenanceSalesProduct, isPerpetualDuration, isPlanFlightPlanner } from './billingAgreement'
import { findById, makeUniqueById } from './list'
import { orderBy } from 'lodash'
import SubscriptionDeactivationTreeNode from 'components/reusable/SubscriptionDeactivationTreeNode'

// Available subscription type
export const SubscriptionType = {
  STRIPE: 'stripe',
  OFFLINE: 'offline',
  PAYPAL: 'paypal',
}

// Subscription type to frontend name
export const SubscriptionTypes = {
  [SubscriptionType.STRIPE]: i18n.t('subscriptions.type.stripe'),
  [SubscriptionType.OFFLINE]: i18n.t('subscriptions.type.offline'),
  [SubscriptionType.PAYPAL]: i18n.t('subscriptions.type.paypal'),
}

// Available subscription status
export const SubscriptionStatus = {
  'active': i18n.t('subscriptions.status.active'),
  'expired': i18n.t('subscriptions.status.expired'),
  'incomplete': i18n.t('subscriptions.status.incomplete'),
  'incomplete_expired': i18n.t('subscriptions.status.incomplete_expired'),
  'trialing': i18n.t('subscriptions.status.trialing'),
  'past_due': i18n.t('subscriptions.status.past_due'),
  'canceled': i18n.t('subscriptions.status.canceled'),
  'unpaid': i18n.t('subscriptions.status.unpaid'),
}

export const isOfflineSubscription = subType => {
  const lowerCasedStatus = (subType || '').toLowerCase()
  return Boolean(lowerCasedStatus === SubscriptionType.OFFLINE)
}

export const isStripeSubscription = subType => {
  const lowerCasedStatus = (subType || '').toLowerCase()
  return Boolean(lowerCasedStatus === SubscriptionType.STRIPE)
}

export const isActiveSubscription = subStatus => {
  const lowerCasedStatus = (subStatus || '').toLowerCase()
  return Boolean(lowerCasedStatus === 'active' || lowerCasedStatus === 'feature')
}

export const isSubscriptionCancelable = subStatus => {
  const lowerCasedStatus = (subStatus || '').toLowerCase()
  return isActiveSubscription(subStatus) || lowerCasedStatus === 'past_due'
}

export const isSubscriptionCancelled = subStatus => {
  const lowerCasedStatus = (subStatus || '').toLowerCase()
  return lowerCasedStatus === 'cancelled' || lowerCasedStatus === 'canceled'
}

export const isShowArchiveButton = subStatus => {
  const lowerCasedStatus = (subStatus || '').toLowerCase()
  return Boolean(isSubscriptionCancelled(subStatus) || lowerCasedStatus === 'expired')
}

const SubscriptionTypeColor = {
  [SubscriptionType.STRIPE]: '#2f334d',
  [SubscriptionType.OFFLINE]: '#b06b59',
}

export function getSubscriptionTypeIcon (type) {
  const lowerCasedStatus = type && type.toLowerCase()
  const style = {
    backgroundColor: SubscriptionTypeColor[lowerCasedStatus] || 'grey',
    color: '#fff',
  }
  return <Chip
    style={style}
    label={
      <React.Fragment>
        {SubscriptionTypes[type]}
      </React.Fragment>
    }
    size='small'
  />
}
/**
 * Returns duration label displayed in the subscriptions list (legacy license_type is supported)
 * @param {Subscription} subscription
 * @returns {String}
 */
export function getSubscriptionDurationLabel (subscription) {
  return MapBackendToFrontendLicenseType[subscription.license_type] || path(['product_duration', 'label'], subscription) || ''
}

/**
 * Returns all `active` subscriptions which are not a flightplanner subscriptions
 * @param {Array.<Subscription>} subscriptions
 * @returns {Array.<Subscription>}
 */
export const getActiveNonFlightPlannerSubscriptions = subscriptions => {
  return subscriptions.filter(subscription => (
    isActiveSubscription(subscription.subscription_state) &&
    !isPlanFlightPlanner(subscription.plan)
  ))
}

/**
 * Returns dependecies of current duration.
 * In case we extending subscription we should use a duration itself as a dependency
 * @param {Boolean} isExtending
 * @param {ProductDuration} duration
 * @returns {Array.<DurationDependency>}
 */
export const getDependencies = (isExtending, duration) => {
  // in case of extending the subscription dependencies should be a duration itself
  return isExtending ? [duration] : duration.dependencies || []
}

/**
 * Checks if we can extend non-recurring subscription
 * @param {Subscription} subscription
 * @returns {Boolean}
 */
export const canExtendSubscription = subscription => {
  if (!subscription) {
    return false
  }
  const duration = subscription.product_duration
  if (
    isSubscriptionCancelled(subscription.subscription_state) ||
    isStripeSubscription(subscription.subscription_type) ||
    !subscription.end_date ||
    !duration ||
    isDemoDuration(duration) ||
    isPerpetualDuration(duration)
  ) {
    return false
  }
  return true
}

export const isOnlyExtendViaStripe = salesProduct => {
  const isBundle = isBundleSalesProduct(salesProduct)
  return isBundle
    ? salesProduct.durations.every(duration => isMaintenanceSalesProduct(duration.sales_product))
    : isMaintenanceSalesProduct(salesProduct)
}

export const isChildSubscription = subscription => {
  if (!subscription) {
    return false
  }
  return subscription.subscription_id
}

export const isChildMaintenanceSubscription = subscription => {
  if (!subscription) {
    return false
  }
  return isChildSubscription(subscription) && isMaintenanceSalesProduct(subscription.plan)
}

export const getAllCancellableSubscriptions = (subscription, subscriptions) => {
  const transformLabel = (subscription, label) => {
    return {
      ...subscription,
      label: <SubscriptionDeactivationTreeNode subscription={subscription} label={label}/>,
    }
  }
  const transformedSubscriptions = subscriptions
    .filter(subscription => isSubscriptionCancelable(subscription.subscription_state))
    .map(subscription => ({ ...subscription, childs: [] }))
  let subscriptionsToCancel = []
  const allChildSubscriptions = transformedSubscriptions.filter(isChildSubscription)
  const allBundleSubscriptions = transformedSubscriptions.filter(subscription => subscription.sales_bundle_id)

  const getSubs = (subscription, level = 0) => {
    if (level === 0) {
      subscription = transformLabel(subscription, '')
      subscriptionsToCancel.push({ id: subscription.id, subscription: subscription, priority: level })
    }
    const subscriptionId = subscription.id
    const bundleId = subscription.sales_bundle_id
    const rovers = subscription.rovers
    const childSubscriptions = allChildSubscriptions
      .filter(subscription => subscription.subscription_id === subscriptionId)
    const sameBundleSubscriptions = allBundleSubscriptions
      .filter(subscription => bundleId && subscription.sales_bundle_id === bundleId)
      .filter(subscription => subscription.rovers.length === rovers.length && rovers.every(rover => subscription.rovers.find(subRover => subRover.serial === rover.serial)))
    subscription.childs = makeUniqueById([
      ...childSubscriptions.map(childSub => transformLabel(childSub, isMaintenanceSalesProduct(childSub.plan) ? 'Maintenance' : 'Plugin')),
      ...sameBundleSubscriptions.map(childSub => transformLabel(childSub, 'Bundle')),
    ]).filter(subscription => !findById(subscription.id, subscriptionsToCancel))
    subscription.childs.forEach(child => {
      subscriptionsToCancel.push({ id: child.id, subscription: child, priority: level + 1 })
    })
    subscription.childs.forEach(child => {
      getSubs(child, level + 1)
    })
    return subscription
  }
  const tree = !subscription ? null : [getSubs(subscription)]
  subscriptionsToCancel = makeUniqueById(subscriptionsToCancel)
  subscriptionsToCancel = orderBy(subscriptionsToCancel, ['priority'], ['desc'])

  return {
    tree,
    cancellableSubscriptions: subscriptionsToCancel,
  }
}
