import { createReducer } from 'reduxsauce'
import { INITIAL_STATE } from './initialState'
import { RMATypes } from './actions'
import { convertRawRoverEvent, RoverEventType } from 'types/roverEvents'

// Get recalls
export const getRMALoading = state => {
  return state.merge({
    getRMAIsLoading: true,
  })
}
export const getRMASuccess = (state, { rmas, pagination }) => {
  return state.merge({
    getRMAIsLoading: false,
    rma: rmas.map(raw => convertRawRoverEvent(raw, RoverEventType.RMA)),
    pagination,
  })
}
export const getRMAFailure = state => {
  return state.merge({
    getRMAIsLoading: true,
  })
}

export const getAllRMALoading = state => {
  return state.merge({
    getRMAIsLoading: true,
  })
}
export const getAllRMASuccess = (state, { rmas }) => {
  return state.merge({
    getRMAIsLoading: false,
    allRma: rmas.map(raw => convertRawRoverEvent(raw, RoverEventType.RMA)),
  })
}
export const getAllRMAFailure = state => {
  return state.merge({
    getRMAIsLoading: true,
  })
}

export const reducer = createReducer(INITIAL_STATE, {
  [RMATypes.GET_RMA_LOADING]: getRMALoading,
  [RMATypes.GET_RMA_SUCCESS]: getRMASuccess,
  [RMATypes.GET_RMA_FAILURE]: getRMAFailure,

  [RMATypes.GET_ALL_RMA_LOADING]: getAllRMALoading,
  [RMATypes.GET_ALL_RMA_SUCCESS]: getAllRMASuccess,
  [RMATypes.GET_ALL_RMA_FAILURE]: getAllRMAFailure,
})
