import { omit } from 'ramda'
import { DataType } from 'types/form'

/**
 * Checks if a value is not empty.
 * @param value The value for the job option.
 * @return Whether the value is empty or not.
 */
export function isValueSet (value, dataType) {
  if (typeof value === 'string') {
    return value !== ''
  }
  if (typeof value === 'boolean') {
    return true
  }
  if (typeof value === 'number') {
    return true
  }
  if (dataType === DataType.SELECTION && typeof value === 'object') {
    return true
  }
  if (
    dataType === DataType.MULTIPLE_SELECTION ||
    dataType === DataType.SERIAL_NUMBERS
  ) {
    return value.length > 0
  }
  if (
    dataType === DataType.MULTI_STRING
  ) {
    return false
  }
  if (Array.isArray(value) && typeof value[0] === 'string') {
    return value.every(elem => elem !== '')
  }
  if (Array.isArray(value) && typeof value[0] === 'number') {
    return true
  }
  if ((dataType === DataType.AUTOCOMPLETE || dataType === DataType.GEOID_AUTOCOMPLETE) &&
    typeof value === 'object' && value !== null
  ) {
    return true
  }
  if ((
    dataType === DataType.DATETIME ||
    dataType === DataType.TIME_OF_WEEK ||
    dataType === DataType.DATE
  ) && typeof value === 'object' && value !== null) {
    return true
  }
  if (dataType === DataType.CRS && typeof value === 'object' && Boolean(value)) {
    if (Object.values(omit(['uniqueKey', 'name', 'id', 'project'], value)).some(Boolean)) {
      return true
    }
  }
  return false
}

/**
 * Returns the actual value that will be used in the rendered option
 * Here we can write specific handlers for each DataType
 * @param {*} props
 */
export const getValue = props => {
  const { values, name, option, state, value, extra, extraProps = {} } = props
  const { getValue, dataType } = option
  let formValue = value || values[name] || (dataType === DataType.DATE ? null : '')
  switch (dataType) {
    case DataType.BOOLEAN:
      formValue = Boolean(formValue)
      break
    case DataType.GPS_WEEK:
      formValue = formValue.toString()
      break
    default:
      break
  }
  return typeof getValue === 'function'
    ? getValue(state, formValue, values, extra, extraProps)
    : typeof getValue !== 'undefined'
      ? getValue
      : formValue
}

export const isDisabled = ({ option, state, values, extra, formTemplate, name, extraProps }) => {
  let disabled
  // If `option.disabled` is a function it needs to be evaluated...
  if (typeof option.disabled === 'function') {
    disabled = option.disabled(state, values, extra, formTemplate, { option, state, values, extra, formTemplate, name, extraProps })
  } else {
    // ...otherwise the raw value can be used.
    disabled = option.disabled
  }
  return disabled
}
