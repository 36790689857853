// import { createSelector } from 'reselect'
// import { getCoordinateSystemLabelWithType } from 'utils/coordinateSystem';

export const getConstants = state => {
  return state.app.get('constants')
}

export const getProductReleases = (state, productName) => {
  return state.app.get('releases')[productName] || []
}

export const isGetReleasesLoading = (state, productName) => {
  return !!state.app.get('isLoadingReleases')[productName]
}

export const getLidarmillReleases = state => {
  return state.app.get('constants').lidarmillReleases
}

export const getAllSystemTypes = state => {
  return state.app.get('systemTypes')
}

export const getAllCustomerTypes = state => {
  return state.app.get('customerTypes')
}

export const isGetAllSystemTypesLoading = state => state.app.get('getAllSystemTypesIsLoading')

export const getIndustries = state => {
  return state.app.get('constants').industries
}

export const getTags = state => state.app.get('constants').tags

export const getCountries = state => {
  return state.app.get('constants').countries
}

export const getRegions = (state, countryName) => {
  return state.app.get('constants').regions[countryName] || []
}

export const getAllRegions = (state, countryName) => {
  return state.app.get('constants').regions
}

export const getTimezones = state => {
  return state.app.get('constants').timeZones
}

export const getDAEventTypes = state => {
  return state.app.get('daEventTypes')
}

export const getDAEventIssueTypes = state => {
  return state.app.get('daIssueTypes')
}

export const getCheckListSteps = state => {
  return state.app.get('checkListSteps')
}

export const getRMAStatuses = state => {
  return state.app.get('rmaStatuses')
}
export const isGetRMAStatusesLoading = state => {
  return state.app.get('getRMAStatusesIsLoading')
}

export const getSystemTypeStatuses = state => {
  return state.app.get('systemTypeStatuses')
}
export const isGetSystemTypeStatusesLoading = state => {
  return state.app.get('getSystemTypeStatusesIsLoading')
}

export const getProducts = state => {
  return state.app.get('products')
}

export const getAllCountryToCodes = state => {
  return state.app.get('constants').countryToCode
}

export const getCountryCode = (state, countryName) => {
  return getAllCountryToCodes(state)[countryName]
}

export const getWorkLists = state => {
  return state.app.get('workLists')
}
export const isGetWorkListsLoading = state => {
  return state.app.get('getWorkListsIsLoading')
}

export const isConstantsLoaded = state => {
  return state.app.get('constantsLoaded')
}

export const getProjectsUserSettings = state => {
  return state.app.get('userSettings').projects
}
export const getSalesInvoices = state => state.app.get('sales_invoices') || []
export const getSalesOrders = state => state.app.get('sales_orders') || []
export const getPurchaseOrders = state => state.app.get('purchase_orders') || []
export const getCreditNotes = state => state.app.get('credit_notes') || []
export const isLoadingULData = state => !!state.app.get('isLoadingULData')

export const getLogs = state => state.app.get('logs')
export const getLogsPagination = state => state.app.get('logsPagination')
export const isLogsLoading = state => state.app.get('getLogsIsLoading')
export const getComments = state => state.app.get('comments')

export const getDAEvents = state => state.app.get('daEvents')
export const getAllDAEvents = state => state.app.get('allDaEvents')
export const getDAEventsPagination = state => state.app.get('daEventsPagination')
export const isDAEventsLoading = state => state.app.get('getDAEventsIsLoading')

export const getDARequestEvents = state => state.app.get('daRequestEvents')
export const getAllDARequestEvents = state => state.app.get('allDaRequestEvents')
export const getDARequestEventsPagination = state => state.app.get('daRequestEventsPagination')
export const isDARequestEventsLoading = state => state.app.get('getDARequestEventsIsLoading')

export const getRentEvents = state => state.app.get('rentEvents')
export const getAllRentEvents = state => state.app.get('allRentEvents')
export const getRentEventsPagination = state => state.app.get('rentEventsPagination')
export const isRentEventsLoading = state => state.app.get('getRentEventsIsLoading')

export const getNotifications = state => state.app.get('notifications')
export const getNotificationsPagination = state => state.app.get('notificationsPagination')
export const isNotificationsLoading = state => state.app.get('getNotificationsIsLoading')

export const getAllProjects = state => state.app.get('projects')

export const getHeaderHeight = state => state.app.get('headerHeight')
export const getHeaderWidth = state => state.app.get('headerWidth')

export const getAntennas = state => {
  return state.app.get('constants').antennas
}
export const getAntennasOptions = state => {
  return state.app.get('constants').antennasOptions
}
export const isLoadingAntennas = state => {
  return state.app.get('getAntennasIsLoading')
}
