import i18n from 'i18n'

export const LicenseType = {
  ANNUAL: 'annual',
  ENTERPRISE: 'enterprise',
  MONTHLY: 'monthly',
  DEMO: 'demo',
  PERMANENT: 'permanent',
  UNLIMITED: 'unlimited',
}
// Map between backend and frontend license type names
export const MapBackendToFrontendLicenseType = Object.keys(LicenseType).reduce((all, key) => ({
  ...all,
  [LicenseType[key]]: i18n.t(`licenses.type.${LicenseType[key]}`),
}), {})

// Available non-recurring subscription license types
export const LicensesTypes = [
  { type: LicenseType.ANNUAL, label: MapBackendToFrontendLicenseType[LicenseType.ANNUAL] },
  { type: LicenseType.ENTERPRISE, label: MapBackendToFrontendLicenseType[LicenseType.ENTERPRISE] },
  { type: LicenseType.MONTHLY, label: MapBackendToFrontendLicenseType[LicenseType.MONTHLY] },
  { type: LicenseType.DEMO, label: MapBackendToFrontendLicenseType[LicenseType.DEMO] },
  { type: LicenseType.PERMANENT, label: MapBackendToFrontendLicenseType[LicenseType.PERMANENT] },
]
