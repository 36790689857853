import DialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/styles'

const StyledDialogTitle = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.secondary.alternative,
    color: '#fff',
  },
}))(DialogTitle)

export default StyledDialogTitle
