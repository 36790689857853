import { put, takeLatest, call } from 'redux-saga/effects'
import SystemTypeActions, { SystemTypeTypes } from './actions'
import { toast } from 'react-toastify'
// import axios from 'axios'
import { makeRequest } from 'utils/request'
import axios from 'utils/axios'
import { SystemTypeAction } from 'types/systemTypes'
import { addAttachments } from 'modules/utils'
import { RoverEventType } from 'types/roverEvents'

function * getSystemTypeConfigurations ({ systemTypeId }) {
  yield put(SystemTypeActions.getSystemTypeConfigurationsLoading(systemTypeId))
  try {
    const { data: { data: configurations } } = yield call(axios.get, `/rovers_system_types/${systemTypeId}/configurations`)
    yield put(SystemTypeActions.getSystemTypeConfigurationsSuccess(systemTypeId, configurations))
  } catch (e) {
    yield put(SystemTypeActions.getSystemTypeConfigurationsFailure(systemTypeId, e.message || 'Error ocurred'))
  }
}

function * addConfigurationToSystemType ({ formData, onSuccess }) {
  yield put(SystemTypeActions.addConfigurationToSystemTypeLoading())
  try {
    let systemTypeId = formData.system_type
    let configurationComment = formData.comment
    if (!systemTypeId) {
      const { data: { data: systemType } } = yield call(makeRequest,
        '/rovers_system_types',
        'POST',
        SystemTypeAction.ADD_SYSTEM_TYPE,
        {
          name: formData.name,
          comment: configurationComment,
          configurations: [],
        },
      )
      systemTypeId = systemType.id.toString()
      configurationComment = 'Initial configuration'
      yield put(SystemTypeActions.addSystemTypeSuccess(systemType))
    }
    const { data: { data: configuration } } = yield call(makeRequest,
      `/rovers_system_types/${systemTypeId}/configurations`,
      'POST',
      SystemTypeAction.ADD_CONFIGURATION,
      {
        configuration: formData.configuration,
        transform: formData.transform,
        comment: configurationComment,
      },
    )
    if (formData.attachments && formData.attachments.length > 0) {
      const eventAttachments = yield call(
        addAttachments,
        configuration.id,
        RoverEventType.ROVER_SYSTEM_TYPE_CONFIGURATION,
        formData.attachments,
      )
      yield put(SystemTypeActions.addConfigurationToSystemTypeSuccess(systemTypeId, { ...configuration, attachments: eventAttachments }))
      yield put(SystemTypeActions.addSystemTypeAttachments(systemTypeId, eventAttachments))
    } else {
      yield put(SystemTypeActions.addConfigurationToSystemTypeSuccess(systemTypeId, configuration))
    }
    if (typeof onSuccess === 'function') {
      onSuccess()
    }
    yield put(SystemTypeActions.setExpandedConfiguration(configuration.id))
  } catch (e) {
    yield put(SystemTypeActions.addConfigurationToSystemTypeFailure(e.message || 'Error ocurred'))
  }
}

function * updateConfiguration ({ systemTypeId, configurationId, formData, oldConfiguration, onSuccess }) {
  yield put(SystemTypeActions.updateConfigurationLoading())
  try {
    if (formData.attachments && formData.attachments.length > 0) {
      const eventAttachments = yield call(
        addAttachments,
        configurationId,
        RoverEventType.ROVER_SYSTEM_TYPE_CONFIGURATION,
        formData.attachments,
      )
      yield put(SystemTypeActions.addSystemTypeAttachments(systemTypeId, eventAttachments))
      yield put(SystemTypeActions.updateConfigurationSuccess(
        systemTypeId,
        { ...oldConfiguration, attachments: [ ...eventAttachments, ...(oldConfiguration.attachments || []) ] },
      ))
      toast.success('Attachments were successfully added')
    } else {
      yield put(SystemTypeActions.updateConfigurationSuccess(systemTypeId, oldConfiguration))
    }
    if (typeof onSuccess === 'function') {
      onSuccess()
    }
  } catch (e) {
    yield put(SystemTypeActions.updateConfigurationFailure(e.message || 'Error ocurred'))
  }
}

export function * getSystemTypeConfigurationsWatcher () {
  yield takeLatest(SystemTypeTypes.GET_SYSTEM_TYPE_CONFIGURATIONS, getSystemTypeConfigurations)
}

export function * addConfigurationToSystemTypeWatcher () {
  yield takeLatest(SystemTypeTypes.ADD_CONFIGURATION_TO_SYSTEM_TYPE, addConfigurationToSystemType)
}

export function * updateConfigurationWatcher () {
  yield takeLatest(SystemTypeTypes.UPDATE_CONFIGURATION, updateConfiguration)
}
