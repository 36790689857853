import { call, put, takeLatest, fork, select, takeEvery } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import i18next from 'i18n'
import { getActiveUser } from 'modules/users/selectors'
import { grantToRaw, convertRawGrant, copyGrant } from 'types/grants'
import axios from 'utils/axios'
import { getErrorMessage, joinAll, showErrorMessage } from 'utils/api'
// Local deps
import GrantsActions, { GrantsTypes } from './actions'
import BillingAgreementActions from 'modules/billingAgreement/actions'

// Sagas
function * checkGrants ({ grants, key, field }) {
  yield put(GrantsActions.checkGrantsLoading(key))
  try {
    const tasks = []
    for (const grant of grants) {
      const { userId, grantType, context } = grant
      const body = { type_grant: { id: grantType }, context }
      const url = `/grant/check/${userId}`
      tasks.push(yield fork(axios.post, url, body))
    }
    const joinedResults = yield joinAll(tasks)
    const resultGrants = joinedResults.reduce((allResults, { data: { data } }, index) => ([
      ...allResults,
      {
        grant: grants[index],
        allowed: data.allowed,
      },
    ]), [])
    yield put(GrantsActions.checkGrantsSuccess(resultGrants, key))
  } catch (e) {
    yield put(GrantsActions.checkGrantsFailure(key, getErrorMessage(e)))
  }
}

function * checkGrant ({ grant, key, field, resultField }) {
  yield put(GrantsActions.checkGrantLoading(grant))
  try {
    const { userId, grantType, context } = grant
    const body = { type_grant: { id: grantType }, context }
    const url = `/grant/check/${userId}`

    const { data: { data } } = yield call(axios.post, url, body)
    if (field) {
      yield put(GrantsActions.checkGrantsLoading(key))
      const { [field]: allowed } = data
      const grants = Object.keys(allowed).map(key => ({
        grant: {
          userId,
          grantType,
          context: {
            [resultField]: key,
          },
        },
        allowed: allowed[key],
      }))
      yield put(GrantsActions.checkGrantsSuccess(grants, key))
      yield put(GrantsActions.checkGrantSuccess(grant, true))
    }
    if (!field) yield put(GrantsActions.checkGrantSuccess(grant, data.allowed))
  } catch (e) {
    yield put(GrantsActions.checkGrantFailure(getErrorMessage(e)))
  }
}
// Retrieve a list of grants for user
function * getGrants ({ userId }) {
  yield put(GrantsActions.getGrantsLoading())
  try {
    const url = `/grant/${userId}`
    const { data: { data } } = yield call(axios.get, url)
    const grants = data.grants.map(convertRawGrant)
    const navlabUsed = data.navlab_used
    const navlabMax = data.navlab_max
    yield put(GrantsActions.getGrantsSuccess(grants, navlabUsed, navlabMax, userId))
  } catch (e) {
    yield put(GrantsActions.getGrantsFailure(getErrorMessage(e)))
  }
}
// Retrieve a list of grants for active user (user page)
function * getGrantsForActiveUser () {
  yield put(GrantsActions.getGrantsForActiveUserLoading())
  try {
    const activeUser = yield select(state => getActiveUser(state))
    const { data: { data } } = yield call(axios.get, `/grant/${activeUser.id}`)
    const { navlab_max: navlabMax, navlab_used: navlabUsed } = data
    const grants = data.grants.map(convertRawGrant)
    // yield put(UsersActions.setActiveUserGrants(data.grants.map(convertRawGrant)))
    yield put(GrantsActions.getGrantsForActiveUserSuccess())
    yield put(GrantsActions.getGrantsSuccess(grants, navlabUsed, navlabMax, activeUser.id))
  } catch (e) {
    yield put(GrantsActions.getGrantsForActiveUserFailure(getErrorMessage(e)))
  }
}
// Create a grant for user
function * createGrant ({ subscriptionId, grant }) {
  yield put(GrantsActions.createGrantLoading())
  try {
    const body = grantToRaw(grant)
    const url = `/subscriptions/${subscriptionId}/grants`
    const { data: { data: newGrant } } = yield call(axios.post, url, body)
    // const returnedGrant = convertRawGrant(result.response.data)
    yield put(GrantsActions.createGrantSuccess(newGrant, newGrant.subscription_id)) // allowed
    yield put(BillingAgreementActions.getSubscriptionGrants(subscriptionId))
    toast.success(i18next.t('toast.grant.createSuccess'))
  } catch (e) {
    showErrorMessage(e)
    yield put(GrantsActions.createGrantFailure(getErrorMessage(e)))
  }
}
// Deletes grant from subscription
function * deleteGrant ({ subscriptionId, grant, onSuccess }) {
  yield put(GrantsActions.deleteGrantLoading())
  try {
    // we deleting grant by creating a copy of existing grant with property 'is_deleted' set to true
    const body = copyGrant(grant)
    body.is_deleted = true
    const url = `/subscriptions/${subscriptionId}/grants`
    const { data: { data: newGrant } } = yield call(axios.post, url, body)
    yield put(GrantsActions.deleteGrantSuccess(newGrant, newGrant.subscription_id)) // allowed
    yield put(BillingAgreementActions.getSubscriptionGrants(subscriptionId))
    toast.success(i18next.t('toast.grant.deleteSuccess'))
    if (typeof onSuccess === 'function') {
      onSuccess()
    }
  } catch (e) {
    showErrorMessage(e)
    yield put(GrantsActions.deleteGrantFailure(getErrorMessage(e)))
  }
}

// Watchers
function * checkGrantWatcher () {
  yield takeEvery(GrantsTypes.CHECK_GRANT, checkGrant)
}

function * checkGrantsWatcher () {
  yield takeEvery(GrantsTypes.CHECK_GRANTS, checkGrants)
}

function * getGrantsWatcher () {
  yield takeEvery(GrantsTypes.GET_GRANTS, getGrants)
}

function * getGrantsForActiveUserWatcher () {
  yield takeLatest(GrantsTypes.GET_GRANTS_FOR_ACTIVE_USER, getGrantsForActiveUser)
}

function * createGrantWatcher () {
  yield takeLatest(GrantsTypes.CREATE_GRANT, createGrant)
}

function * deleteGrantWatcher () {
  yield takeLatest(GrantsTypes.DELETE_GRANT, deleteGrant)
}

export default function * root () {
  yield fork(checkGrantWatcher)
  yield fork(checkGrantsWatcher)
  yield fork(getGrantsWatcher)
  yield fork(getGrantsForActiveUserWatcher)
  yield fork(createGrantWatcher)
  yield fork(deleteGrantWatcher)
}
