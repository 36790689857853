import moment from 'moment'
import config from 'config'
import { sortBy } from 'lodash'

export const RoverAction = {
  'ADD_ROVER': 'ADD_ROVER',
  'ENABLE_DOWNLOAD': 'ENABLE_DOWNLOAD',
  'EDIT_ROVER': 'EDIT_ROVER',
  'EDIT_CALIBRATION': 'EDIT_CALIBRATION',
  'DELETE_CALIBRATION': 'DELETE_CALIBRATION',
  'ADD_SETTING': 'ADD_SETTING',
  'EDIT_SETTING': 'EDIT_SETTING',
}

export const convertRawRover = raw => {
  const {
    created_at,
    updated_at,
    rover_tags,
  } = raw
  return {
    ...raw,
    created_at: moment(created_at).format(config.DATETIME_FORMAT),
    updated_at: moment(updated_at).format(config.DATETIME_FORMAT),
    rover_tags: sortBy(rover_tags, ['tag_name']).map(roverTag => roverTag.tag_name),
  }
}
