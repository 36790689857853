import { put, takeLatest, call } from 'redux-saga/effects'
import { toast } from 'react-toastify'
// import axios from 'axios'
// Project deps
import axios from 'utils/axios'
// Local deps
import RoverActions, { RoverTypes } from './actions'

function * getRoverCustomSettings ({ serial }) {
  yield put(RoverActions.getRoverCustomSettingsLoading(serial))
  try {
    const { data: { data: customSettings } } = yield call(axios.get, `/rovers/${serial}/custom_settings`)
    yield put(RoverActions.getRoverCustomSettingsSuccess(serial, customSettings))
  } catch (e) {
    yield put(RoverActions.getRoverCustomSettingsFailure(serial, e.message || 'Error ocurred'))
  }
}

function * addRoverCustomSetting ({ serial, setting, comment, onSuccess }) {
  yield put(RoverActions.addRoverCustomSettingLoading(serial))
  try {
    const { data: { data: customSetting } } = yield call(axios.post, `/rovers/${serial}/custom_settings`, {
      setting,
      comment,
    })
    if (typeof onSuccess === 'function') {
      onSuccess()
    }
    toast.success('Custom setting has been created!')
    yield put(RoverActions.addRoverCustomSettingSuccess(serial, customSetting))
    yield put(RoverActions.setExpandedCustomSetting(customSetting.id))
  } catch (e) {
    yield put(RoverActions.addRoverCustomSettingFailure(serial, e.message || 'Error ocurred'))
  }
}

/*
function * deleteRoverCustomSetting ({ serial, setting, comment }) {
  yield put(RoverActions.deleteRoverCustomSettingLoading(serial))
  try {
    const { data } = yield call(axios.delete, `/rover_custom_settings/`, {
      rover: { serial },
      setting,
      comment
    })
    yield put(RoverActions.deleteRoverCustomSettingSuccess(serial, data))
  } catch (e) {
    yield put(RoverActions.deleteRoverCustomSettingFailure(serial, e.message || 'Error ocurred'))
  }
}
*/

export function * getRoverCustomSettingsWatcher () {
  yield takeLatest(RoverTypes.GET_ROVER_CUSTOM_SETTINGS, getRoverCustomSettings)
}
export function * addRoverCustomSettingWatcher () {
  yield takeLatest(RoverTypes.ADD_ROVER_CUSTOM_SETTING, addRoverCustomSetting)
}
