import React from 'react'
import PropTypes from 'prop-types'
// import { DatePicker } from 'material-ui-pickers'
// Material UI
import { withStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import { Typography } from '@material-ui/core'
// Icons
// Project deps
import MenuItem from 'components/reusable/MenuItem'
import { getValue } from 'utils/templatedForm'
import { mapById, findById } from 'utils/list'

const styles = theme => ({
  select: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: 2,
    marginBottom: 2,
  },
})

/**
 * Renders a selection input field.
 * @param name The name of the field.
 * @param option The field definition.
 * @return A component for selecting an option.
 */
export const FileListSelectorOption = propOptions => {
  const {
    state,
    extra,
    name,
    option,
    disabled,
    values: formValues,
    setValue,
    classes,
  } = propOptions
  const { onChange: onChangeHandle = () => {}, selectorOptions = [] } = option
  const value = getValue(propOptions)
  const val = value || []
  const onChange = (event, index) => {
    const { value, name: optionId } = event.target
    const newValue = mapById(optionId, val, item => ({
      id: item.id,
      list: value,
    }))
    if (typeof onChangeHandle === 'function') onChangeHandle(name, newValue, propOptions)
    setValue(name, newValue, option)
  }
  // const errorMessage = getFormFieldErrorMessage(value, option, formValues, state, extra, formTemplate)
  const { options } = option
  // const onFocus = () => onSetContext(name)
  let createdOptions
  // It is possible to specify the options for the select field as an array of strings as well as
  // a function returning the array of strings.
  // If the `options` property was a function, it needs to be evaluated...
  if (typeof options === 'function') {
    createdOptions = options(state, formValues, extra)
  } else {
    // ... otherwise the property can be used directly.
    createdOptions = options
  }
  return (
    <div>
      {
        createdOptions.map(option => {
          const selectedValue = findById(option.id, val)
          const list = selectedValue && selectedValue.list
          return (
            <div key={option.id} className={classes.item}>
              <Typography noWrap>Use {option.label || option.name} for</Typography>
              <Select
                // open={open === option.id}
                // onClose={handleClose(option.id)}
                // onOpen={handleOpen(option.id)}
                value={list || ''}
                // error={Boolean(errorMessage)}
                onChange={onChange}
                name={option.id}
                disabled={disabled}
                style={{
                  marginRight: 8,
                  width: 300,
                }}
                classes={{
                  select: classes.select,
                }}
              >
                {
                  // Here, `createOptions` contains an array of strings which can be used to render the menu items.
                  selectorOptions.map(({ id, label }, index) => (
                    <MenuItem key={id} value={id}>
                      {label}
                    </MenuItem>
                  ))
                }
              </Select>
            </div>
          )
        })
      }
    </div>
  )
}

FileListSelectorOption.propTypes = {
  state: PropTypes.object,
  extra: PropTypes.any,
  name: PropTypes.string,
  option: PropTypes.object,
  disabled: PropTypes.bool,
  classes: PropTypes.object,
  values: PropTypes.object,
  setValue: PropTypes.func,
  formTemplate: PropTypes.object,
}

/*
    <StyledTooltip placement='top-start' {...tooltipProps} title={tooltip || ''}>
      {!editable
        ? <TextField value={value || ''} fullWidth label={option.name}/>
        : <FormControl style={{ width: '100%' }}>
          <InputLabel htmlFor={option.name}>{option.name}</InputLabel>
          <Select
            value={value || ''}
            error={Boolean(errorMessage)}
            onChange={editable ? onChange : () => {}}
            disabled={disabled}
            inputProps={{ id: option.name }}
            fullWidth
          >
            {
            // Here, `createOptions` contains an array of strings which can be used to render the menu items.
              createdOptions.map((selectOption, index) => (
                <MenuItem key={selectOption + index} value={selectOption}>
                  {selectOption}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      }
    </StyledTooltip>

*/

export default withStyles(styles)(FileListSelectorOption)
