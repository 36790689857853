
/**
 * Delete cookies by setting the expire date to the past
 */
export const deleteCookies = () => {
  const cookies = document.cookie.split(';')
  cookies.forEach(cookie => {
    const eqPos = cookie.indexOf('=')
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
  })
}

/**
 * Renew the version of accounts by removing any data in local storage
 * and setting a new version of accounts
 */
export const renewVersion = () => {
  deleteCookies()
  // localStorage.removeItem('persist:root')
  localStorage.setItem('version', process.env.REACT_APP_ACCOUNTS_VERSION)
}

/**
 * Checks the version of the accounts frontend
 * @param {func} callback Some callback function
 */
export const checkVersion = callback => {
  const version = localStorage.getItem('version')
  if (process.env.REACT_APP_ACCOUNTS_VERSION !== version) {
    renewVersion()
  }
  if (typeof callback === 'function') callback()
}
