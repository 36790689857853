import { createReducer } from 'reduxsauce'
import { convertRawRefund } from 'utils/refunds'
// Local deps
import { INITIAL_STATE } from './initialState'
import { StatisticsTypes } from './actions'
import { getUTCDate } from 'utils/dateTime'

export const getCustomersStatisticLoading = state => state.merge({ customersStatisticIsLoading: true })
export const getCustomersStatisticSuccess = (state, { customers }) =>
  state.merge({
    allCustomers: customers,
    customersStatisticIsLoading: false,
  })
export const getCustomersStatisticFailure = state => state.merge({ customersStatisticIsLoading: false })

export const getSubscriptionsStatisticLoading = state =>
  state.merge({
    subscriptionsStatisticIsLoading: true,
  })

export const getSubscriptionsStatisticSuccess = (state, { activeCustomers, canceledCustomers }) =>
  state.merge({
    activeCustomers,
    canceledCustomers,
    subscriptionsStatisticIsLoading: false,
  })
export const getSubscriptionsStatisticFailure = state => state.merge({})

export const getAllSubscriptionsLoading = state => state.merge({ subscriptionsStatisticIsLoading: true })
export const getAllSubscriptionsSuccess = (state, { allSubscriptions, activeSubscriptions, canceledSubscriptions }) => {
  return state.merge({
    allCustomers: allSubscriptions,
    activeCustomers: activeSubscriptions,
    canceledCustomers: canceledSubscriptions,
    subscriptionsStatisticIsLoading: false,
  })
}
export const getAllSubscriptionsFailure = state => state.merge({ subscriptionsStatisticIsLoading: false })

export const getRefundsLoading = state => state.merge({ getRefundsIsLoading: true })
export const getRefundsSuccess = (state, { refunds }) => state.merge({
  refunds: refunds.map(convertRawRefund),
  getRefundsIsLoading: false,
})
export const getRefundsFailure = state => state.merge({ getRefundsIsLoading: false })

export const downloadCSVSubscriptionsLoading = state => state.merge({ downloadCSVSubscriptionsIsLoading: true })
export const downloadCSVSubscriptionsSuccess = state => state.merge({ downloadCSVSubscriptionsIsLoading: false })
export const downloadCSVSubscriptionsFailure = state => state.merge({ downloadCSVSubscriptionsIsLoading: false })

export const getSessionsForProductLoading = (state, { productName }) => state.merge({
  sessionsIsLoading: {
    ...state.get('sessionsIsLoading'),
    [productName]: true,
  },
})

export const calculate = function (sessions, startDate, endDate, { ignoreTime, countUnknown, splitBy }) {
  // const firstSession = sessions[0]
  // const lastSession = sessions[sessions.length - 1]
  // const startDate = firstSession && firstSession.created
  // const endDate = lastSession && lastSession.created
  const numberOfWeeks = getUTCDate(endDate).diff(getUTCDate(startDate), splitBy)
  const sessionsByWeek = Array.apply(null, Array(numberOfWeeks)).map(() => ({}))
  const versions = []
  const statisticsByVersion = {}
  const getDefaultShutdownTypeCounter = () => {
    return { crash: 0, unknown: 0, normal: 0 }
  }
  for (let i = 0; i < sessions.length; i++) {
    const session = sessions[i]
    if (session.shutdown_type === 'crash') {
      if ((session.processing_time || 0) <= ignoreTime) {
        continue
      }
    }
    let week = getUTCDate(session.created).diff(getUTCDate(startDate), splitBy)
    week = week === numberOfWeeks ? numberOfWeeks - 1 : week
    // sessionsByWeek[week].push(session)
    // const startUpToken = session.startup_token || 'unknown'
    // if (startUpToken in sessionsByWeek[week]) {
    //   sessionsByWeek[week][startUpToken].push(session)
    // } else {
    //   sessionsByWeek[week][startUpToken] = [session]
    // }
    const productVersion = session.version || 'unknown'
    const startUpToken = session.startup_token || 'unknown'
    if (!versions.includes(productVersion)) {
      versions.push(productVersion)
    }
    if (!(productVersion in statisticsByVersion)) {
      statisticsByVersion[productVersion] = getDefaultShutdownTypeCounter()
    }
    statisticsByVersion[productVersion][session.shutdown_type] += 1

    if (productVersion in sessionsByWeek[week]) {
      if (startUpToken in sessionsByWeek[week][productVersion]) {
        sessionsByWeek[week][productVersion][startUpToken].push(session)
      } else {
        sessionsByWeek[week][productVersion][startUpToken] = [session]
      }
    } else {
      sessionsByWeek[week][productVersion] = {
        [startUpToken]: [session],
      }
    }
  }
  versions.sort()

  sessionsByWeek.forEach(sessionsByVersion => {
    Object.keys(sessionsByVersion).forEach(version => {
      const sessionsForVersionByStartupToken = sessionsByVersion[version]
      Object.keys(sessionsForVersionByStartupToken).forEach(startUpToken => {
        const sessionsForStartupToken = sessionsForVersionByStartupToken[startUpToken]
        const sessionsByPhase = getDefaultShutdownTypeCounter()
        for (let i = 0; i < sessionsForStartupToken.length; i++) {
          const session = sessionsForStartupToken[i]
          sessionsByPhase[session.shutdown_type]++
        }
        return Object.keys(sessionsByPhase).forEach(phase => {
          const statisticsByPhase = sessionsByPhase[phase]
          sessionsForVersionByStartupToken[phase] = (sessionsForVersionByStartupToken[phase] || 0) + statisticsByPhase
          sessionsByVersion[phase] = (sessionsByVersion[phase] || 0) + statisticsByPhase
        })
      }, getDefaultShutdownTypeCounter())
    })
  })
  return {
    versions: versions,
    weeks: sessionsByWeek,
    statisticsByVersion,
    sessions: sessions,
    startDate,
    endDate,
  }
}

export const getSessionsForProductSuccess = (state, { productName, sessions, startDate, endDate }) => {
  const sessionsStore = state.get('sessions')
  sessionsStore[productName] = calculate(sessions, startDate, endDate, state.get('sessionsPreferences'))
  return state.merge({
    sessions: sessionsStore,
    sessionsIsLoading: {
      ...state.get('sessionsIsLoading'),
      [productName]: false,
    },
  })
}
export const getSessionsForProductFailure = (state, { productName }) => state.merge({
  sessionsIsLoading: {
    ...state.get('sessionsIsLoading'),
    [productName]: false,
  },
})

export const changeSessionsPreferences = (state, { productName, data }) => {
  const sessionsStore = state.get('sessions')
  const sessionsForProduct = sessionsStore[productName] || {}
  const newPreferences = {
    ...state.get('sessionsPreferences'),
    ...data,
  }
  sessionsStore[productName] = calculate(sessionsForProduct.sessions || [], sessionsForProduct.startDate, sessionsForProduct.endDate, newPreferences)
  return state.merge({
    sessions: sessionsStore,
    sessionsPreferences: newPreferences,
  })
}

export const reducer = createReducer(INITIAL_STATE, {
  [StatisticsTypes.GET_CUSTOMERS_STATISTIC_SUCCESS]: getCustomersStatisticSuccess,
  [StatisticsTypes.GET_CUSTOMERS_STATISTIC_LOADING]: getCustomersStatisticLoading,
  [StatisticsTypes.GET_CUSTOMERS_STATISTIC_FAILURE]: getCustomersStatisticFailure,

  [StatisticsTypes.GET_SUBSCRIPTIONS_STATISTIC_SUCCESS]: getSubscriptionsStatisticSuccess,
  [StatisticsTypes.GET_SUBSCRIPTIONS_STATISTIC_LOADING]: getSubscriptionsStatisticLoading,
  [StatisticsTypes.GET_SUBSCRIPTIONS_STATISTIC_FAILURE]: getSubscriptionsStatisticFailure,

  [StatisticsTypes.GET_ALL_SUBSCRIPTIONS_SUCCESS]: getAllSubscriptionsSuccess,
  [StatisticsTypes.GET_ALL_SUBSCRIPTIONS_LOADING]: getAllSubscriptionsLoading,
  [StatisticsTypes.GET_ALL_SUBSCRIPTIONS_FAILURE]: getAllSubscriptionsFailure,

  [StatisticsTypes.GET_REFUNDS_SUCCESS]: getRefundsSuccess,
  [StatisticsTypes.GET_REFUNDS_LOADING]: getRefundsLoading,
  [StatisticsTypes.GET_REFUNDS_FAILURE]: getRefundsFailure,

  [StatisticsTypes.GET_SESSIONS_FOR_PRODUCT_SUCCESS]: getSessionsForProductSuccess,
  [StatisticsTypes.GET_SESSIONS_FOR_PRODUCT_LOADING]: getSessionsForProductLoading,
  [StatisticsTypes.GET_SESSIONS_FOR_PRODUCT_FAILURE]: getSessionsForProductFailure,

  [StatisticsTypes.CHANGE_SESSIONS_PREFERENCES]: changeSessionsPreferences,

  [StatisticsTypes.DOWNLOAD_CSV_SUBSCRIPTIONS_SUCCESS]: downloadCSVSubscriptionsSuccess,
  [StatisticsTypes.DOWNLOAD_CSV_SUBSCRIPTIONS_LOADING]: downloadCSVSubscriptionsLoading,
  [StatisticsTypes.DOWNLOAD_CSV_SUBSCRIPTIONS_FAILURE]: downloadCSVSubscriptionsFailure,
})
