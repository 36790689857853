import React from 'react'
import PropTypes from 'prop-types'
// Material UI
import { IconButton, Typography } from '@material-ui/core'
// Icons
import CopyIcon from '@material-ui/icons/Assignment'
// Project deps
import DialogTitle from 'components/reusable/StyledDialogTitle'
import CopyToClipboardButton from 'components/reusable/CopyToClipboardButton'
import StyledTooltip from '../StyledTooltip'

const DialogTitleRoverEvent = props => {
  const { text, title, id = 'draggable-dialog-title' } = props
  return (
    <DialogTitle style={{ padding: 0 }} disableTypography>
      <Typography variant='h6' style={{ cursor: 'move', padding: 16, width: '100%', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }} id={id}>
        {title}
        <span
          draggable={false}
          style={{ cursor: 'text', color: '#b0b0b0', display: 'flex', alignItems: 'center', marginLeft: 4 }}
          onDragStart={e => { e.stopPropagation(); e.preventDefault() }}
          onDrag={e => { e.stopPropagation(); e.preventDefault() }}
          // onDragCapture={e => { e.stopPropagation(); e.preventDefault() }}
          onDragEnd={e => { e.stopPropagation(); e.preventDefault() }}
          // onDragEndCapture={e => { e.stopPropagation(); e.preventDefault() }}
          onMouseDown={e => { e.stopPropagation() }}
          onMouseUp={e => { e.stopPropagation() }}
        >
          {text}
          <StyledTooltip placement='top' title='Copy event identifier'>
            <span>
              <CopyToClipboardButton component={IconButton} text={text} label={<CopyIcon style={{ color: '#ebebeb' }}/>}/>
            </span>
          </StyledTooltip>
        </span>
      </Typography>
    </DialogTitle>
  )
}

DialogTitleRoverEvent.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
}

export default React.memo(DialogTitleRoverEvent)
