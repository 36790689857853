// External dependencies.
import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
// Material UI dependencies.
import { withStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
// Project internal dependencies.

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
  },
})

const CustomPopover = props => {
  const [open, setOpen] = useState(false)
  const anchorEl = useRef(null)

  const onClose = () => setOpen(false)

  const handleClickButton = event => {
    event.stopPropagation()
    setOpen(true)
  }

  const handleClose = event => {
    event.stopPropagation()
    setOpen(false)
  }

  const {
    children,
    classes,
    fullWidth,
    className,
    padded,
    title,
    component,
    anchorOrigin = {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin = {
      vertical: 'top',
      horizontal: 'center',
    },
    useCustomComponent,
    ...otherProps
  } = props

  const Component = component || 'span'

  return (
    <React.Fragment>
      { component
        ? useCustomComponent
          ? <Component
            {...otherProps}
            ref={anchorEl}
            className={className}
            onClick={handleClickButton}>
            {title || <Typography variant='button'><Trans i18nKey='popover.open'/></Typography>}
          </Component>
          : <span
            ref={anchorEl}
            onClick={handleClickButton}>
            {component}
          </span>
        : <Button
          color='primary'
          className={className}
          fullWidth={fullWidth || false}
          buttonRef={anchorEl}
          onClick={handleClickButton}>
          {title || <Typography variant='button'><Trans i18nKey='popover.open'/></Typography>}
        </Button>
      }
      <Popover
        open={open}
        anchorEl={anchorEl.current}
        anchorReference='anchorEl'
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}>
        <Paper className={padded ? classes.paper : ''}>
          {typeof children === 'function'
            ? children({ onClose: onClose })
            : children
          }
        </Paper>
      </Popover>
    </React.Fragment>
  )
}

CustomPopover.propTypes = {
  useCustomComponent: PropTypes.bool,
  fullWidth: PropTypes.bool,
  padded: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  className: PropTypes.string,
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object,
  classes: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.array,
    PropTypes.object,
  ]),
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.array,
    PropTypes.object,
  ]),
}

export default withStyles(styles)(CustomPopover)
