import { isUserCompanyCustomer, getCompanyId, isUserAdmin, getCompanyName } from 'utils/company'
import { getFullUserName } from 'utils/users'
import config from 'config'
import { getUTCDate } from 'utils/dateTime'

export const convertRawUser = raw => {
  const isCustomerUser = isUserCompanyCustomer(raw)
  const isAdmin = isUserAdmin(raw)
  return {
    ...raw,
    companyId: getCompanyId(raw),
    companyName: getCompanyName(raw),
    fullName: getFullUserName(raw),
    last_activity: raw.last_activity ? getUTCDate(raw.last_activity).format(config.DATETIME_FORMAT) : null,
    artifactPermissions: {
      delete: !isCustomerUser,
      update: !isCustomerUser,
      add: !isCustomerUser,
      read: true,
    },
    projectPermissions: {
      delete: !isCustomerUser,
      update: !isCustomerUser,
      add: !isCustomerUser,
      read: true,
    },
    pipelinePermissions: {
      delete: !isCustomerUser,
      update: !isCustomerUser,
      add: !isCustomerUser,
      read: true,
    },
    missionPermissions: {
      delete: !isCustomerUser,
      update: !isCustomerUser,
      add: !isCustomerUser,
      read: true,
    },
    projectCRSPermissions: {
      delete: isAdmin,
      update: !isCustomerUser,
      add: !isCustomerUser,
      read: true,
    },
  }
}
