import React, { useState } from 'react'
import PropTypes from 'prop-types'
// Material UI
import {
  DialogTitle,
  DialogContent,
  Checkbox,
  DialogActions,
  Button,
  MenuItem,
  Grid,
  Typography,
  Radio,
  TextField,
} from '@material-ui/core'
// Project deps
import VirtualizedList from 'components/reusable/VirtualizedList'
import StyledChip from 'components/reusable/Chip'
import { removeById, findById } from 'utils/list'
import { escape } from 'utils/regex'
import ClosableDialog from 'components/reusable/ClosableDialog'

const MultipleFilters = props => {
  const { open, defaultFilters, filtered, onClose, onSubmit } = props
  const [search, setSearch] = useState(defaultFilters.reduce((all, { filterType, id }) => {
    if (filterType === 'search') {
      return {
        ...all,
        [id]: '',
      }
    }
    return all
  }, {}))
  const [filters, setFilters] = useState(Object.keys(filtered).reduce((all, id) => {
    const { multiple = true, filterType = 'default' } = defaultFilters.find(f => f.id === id)
    const [firstFilteredValue] = (filtered[id] || [])
    return {
      ...all,
      [id]: filterType === 'search'
        ? filtered[id] || []
        : multiple
          ? (filtered[id] || []).map(val => val.value)
          : (firstFilteredValue ? firstFilteredValue.value : ''),
    }
  }, {}))

  const onChangeMultiple = (name, value) => e => {
    setFilters(prevState => {
      const selectedFilters = (prevState[name] || [])
      const isChecked = selectedFilters.find(v => v === value)
      return {
        ...prevState,
        [name]: isChecked ? selectedFilters.filter(v => v !== value) : [value, ...selectedFilters],
      }
    })
  }
  const onChangeSingle = (name, value) => e => {
    setFilters(prevState => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  const onChangeSearch = name => e => {
    const { value } = e.target
    setSearch(prevState => ({ ...prevState, [name]: value }))
  }

  const onToggleFilterOption = (filterId, option) => e => {
    const optionId = option.id
    setFilters(prevState => {
      const options = prevState[filterId] || []
      const isSelected = findById(optionId, options)
      if (isSelected) {
        return {
          ...prevState,
          [filterId]: removeById(optionId, options),
        }
      } else {
        return {
          ...prevState,
          [filterId]: [...options, option],
        }
      }
    })
  }
  const onDelete = (filterId, id) => e => {
    setFilters(prevState => {
      const prevFilters = prevState[filterId] || []
      return {
        ...prevState,
        [filterId]: removeById(id, prevFilters),
      }
    })
  }
  const onRemove = (filterId, id) => e => {
    setFilters(prevState => {
      const prevFilters = prevState[filterId] || []
      return {
        ...prevState,
        [filterId]: prevFilters.filter(option => option !== id),
      }
    })
  }

  const onSubmitClick = () => {
    const updatedFilters = Object.keys(filters).reduce((all, id) => {
      const { multiple = true, filterOptions, filterType = 'default' } = defaultFilters.find(f => f.id === id)
      const filtersById = filters[id]
      return {
        ...all,
        [id]: filterType === 'search'
          ? filtersById
          : multiple
            ? filtersById.map(value => filterOptions.find(f => f.value === value))
            : [filterOptions.find(f => f.value === filtersById)].filter(Boolean),
      }
    }, {})
    onSubmit(updatedFilters)
  }

  return (
    <ClosableDialog open={open} onClose={onClose} maxWidth='md' fullWidth>
      <DialogTitle>Filter</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {defaultFilters.map(({ filterOptions, label, id, multiple = true, filterType = 'default', gridProps = {}, style = {} }) => {
            const currentFilters = filters[id]
            const searchString = search[id]
            const transformedSearch = escape(searchString || '')
            const regex = new RegExp(transformedSearch, 'i')
            // const filtered = filters[id] || (multiple ? [] : '')
            if (filterType === 'search') {
              return (
                <Grid key={id} item xs={12} {...gridProps}>
                  <TextField
                    value={searchString}
                    label='Search'
                    onChange={onChangeSearch(id)}
                    fullWidth
                    variant='outlined'
                    type='search'
                  />
                  {currentFilters.length > 0 && (
                    <div>
                      {currentFilters.map(option => (
                        <StyledChip
                          style={{ marginLeft: 4, marginTop: 4 }}
                          onDelete={onDelete(id, option.id)}
                          label={(option.label || '').slice(0, 25)}
                          key={option.id}
                        />
                      ))}
                    </div>
                  )}
                  <div style={{ height: 200, overflow: 'auto', minWidth: 420, ...style }}>
                    <VirtualizedList
                      rowHeight={40}
                      autoSizeHeight={false}
                      listHeight={200}
                      list={searchString ? filterOptions.filter(option => option.label.toString().match(regex)) : filterOptions}
                      renderItem={(option, options) => {
                        const isSelected = findById(option.id, currentFilters)
                        return (
                          <MenuItem
                            key={options.key}
                            style={{ ...options.style, paddingLeft: 0, paddingRight: 0 }}
                            onClick={onToggleFilterOption(id, option)}>
                            <Checkbox checked={Boolean(isSelected)}/>
                            {typeof option.labelRender === 'function' ? option.labelRender() : option.label}
                          </MenuItem>
                        )
                      }}
                    />
                  </div>
                </Grid>
              )
            }
            const overflow = filterOptions.length > 5
            return (
              <Grid key={id} item xs={12} sm={6} md={4} {...gridProps}>
                <Typography style={{ fontWeight: 'bold' }}>{label}</Typography>
                <div style={{ height: overflow ? 200 : 'auto', overflow: 'auto' }}>
                  {
                    filterOptions.map(({ value, label, labelRender, id: optionId }, index) => (
                      <MenuItem style={{ height: 40 }} key={optionId} value={value} onClick={multiple ? onChangeMultiple(id, value) : onChangeSingle(id, value)}>
                        {multiple
                          ? <Checkbox checked={currentFilters.includes(optionId)}/>
                          : <Radio checked={currentFilters.includes(optionId)}/>
                        }
                        <React.Fragment>{typeof labelRender === 'function' ? labelRender() : label}</React.Fragment>
                      </MenuItem>
                    ))
                  }
                </div>
                {overflow && currentFilters.length > 0 && (
                  <div>
                    {currentFilters.map(option => {
                      const filter = filterOptions.find(filterOption => filterOption.id === option)
                      const label = filter
                        ? filter.label
                        : option
                      return (
                        <StyledChip
                          style={{ marginLeft: 4, marginTop: 4 }}
                          onDelete={onRemove(id, option)}
                          label={(label || '')}
                          key={option}
                        />
                      )
                    })}
                  </div>
                )}
              </Grid>
            )
          })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color='primary' onClick={onSubmitClick}>Apply</Button>
      </DialogActions>
    </ClosableDialog>
  )
}

MultipleFilters.propTypes = {
  open: PropTypes.bool,
  id: PropTypes.string,
  type: PropTypes.oneOf(['default', 'search']),
  defaultFilters: PropTypes.array,
  filtered: PropTypes.array,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default MultipleFilters
