import { toast } from 'react-toastify'
// Project deps
import i18next from 'i18n'

// If browser has no clipboard property support
export function fallbackCopyTextToClipboard (text) {
  const textArea = document.createElement('textarea')
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()
  try {
    const successful = document.execCommand('copy')
    const msg = successful ? 'successful' : 'unsuccessful'
    console.log('Fallback: Copying text command was ' + msg)
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err)
  }

  document.body.removeChild(textArea)
}

// Main function to copy text to clipboard
export function copyTextToClipboard (text, t) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(function () {
    toast.success(i18next.t('copyToClipboard.success'))
  }, function (err) {
    toast.warn(i18next.t('copyToClipboard.error'))
    console.error('Async: Could not copy text: ', err)
  })
}
