import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/styles'

const StyledTooltip = withStyles({
  tooltip: {
    fontSize: 12,
    maxWidth: 'none',
  },
  popper: {
    zIndex: 21474836471,
  },
})(Tooltip)

export default StyledTooltip
