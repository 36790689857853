import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
// import { DatePicker } from 'material-ui-pickers'
// Material UI
import { makeStyles } from '@material-ui/core/styles'
import { TextField, Slider, Tooltip, Typography } from '@material-ui/core'
// Icons
// Project deps
// import { isCoordinateLieInRectangle } from '../../templates/utils'
import StyledTooltip from 'components/reusable/StyledTooltip'
import { getValue } from 'utils/templatedForm'

function ValueLabelComponent (props) {
  const { children, open, value } = props

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  )
}

ValueLabelComponent.propTypes = {
  children: PropTypes.any,
  open: PropTypes.boolean,
  value: PropTypes.any,
}

const useStyles = makeStyles(theme => ({
  underline: {
    '&&:before': {
      borderBottom: 'none',
    },
  },
}))

/**
 * Renders a boolean input field.
 * @param name The name of the field.
 * @param option The field definition.
 * @return A component for inputting boolean data.
 */
const SliderOption = options => {
  const {
    name,
    option,
    disabled,
    setValue,
  } = options
  const { tooltip, min, max, step, marks = [] } = option
  const classes = useStyles()
  const value = getValue(options)
  const [stateValue, setStateValue] = useState(0)
  useEffect(() => {
    setStateValue(value || 0)
  }, [value])

  const onChange = (_event, newValue) => {
    setStateValue(newValue)
  }
  const onChangeCommitted = (_event, newValue) => {
    setValue(name, newValue, option)
  }
  const handleInputChange = event => {
    setStateValue(event.target.value === '' ? 0 : Number(event.target.value))
  }

  const handleBlur = () => {
    if (stateValue < min) {
      setValue(name, min, option)
    } else if (stateValue > max) {
      setValue(name, max, option)
    } else {
      setValue(name, stateValue, option)
    }
  }
  const labelId = option.name + '-slider'
  return (
    <StyledTooltip title={tooltip || ''} placement='top-start'>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography>{option.name}:</Typography>
          <TextField
            fullWidth
            // className={classes.input}
            // variant='outlined'
            value={stateValue + ''}
            margin='dense'
            style={{ width: 50, marginLeft: 8 }}
            onChange={handleInputChange}
            onBlur={handleBlur}
            InputProps={{
              classes: {
                underline: classes.underline,
              },
            }}
            inputProps={{
              step: step,
              min: min,
              max: max,
              type: 'number',
              'aria-labelledby': labelId,
            }}
          />
        </div>
        <Slider
          disabled={disabled}
          value={stateValue}
          min={min}
          max={max}
          step={step}
          marks={marks}
          onChange={onChange}
          onChangeCommitted={onChangeCommitted}
          aria-labelledby={labelId}
          valueLabelDisplay='auto'
          ValueLabelComponent={ValueLabelComponent}
        />
      </div>
    </StyledTooltip>
  )
}

SliderOption.propTypes = {
  state: PropTypes.object,
  extra: PropTypes.any,
  name: PropTypes.string,
  option: PropTypes.object,
  disabled: PropTypes.bool,
  classes: PropTypes.object,
  values: PropTypes.object,
  setValue: PropTypes.func,
  formTemplate: PropTypes.object,
}

export default SliderOption
