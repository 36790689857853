import { matchPath } from 'react-router-dom'
import { isEmpty } from 'ramda'
// Project deps
import { Routes, Route, BasicRoutes } from 'templates/route'
import { tabMap, CompanyTabs, UserTabs } from 'templates/tabs'
import config from 'config'

export const routeToLidarmill = url => {
  return `${config.LIDARMILL_BASE}/${url}`
}
export const routeToBayer = url => {
  return `${config.BAYER_BASE}/${url}`
}
export const routeToComponentInfo = url => {
  return `${config.COMPONENT_INFO_BASE}/${url}`
}
export const routeUserProject = (id, isBayer = false) => {
  const url = `projects/${id}`
  return isBayer ? routeToBayer(url) : routeToLidarmill(url)
}
export const routeUserProjects = (id, isBayer = false) => {
  const url = `users/${id}/projects`
  return isBayer ? routeToBayer(url) : routeToLidarmill(url)
}
export const routeCompanyProjects = (id, isBayer = false) => {
  const url = `company/${id}/projects`
  return isBayer ? routeToBayer(url) : routeToLidarmill(url)
}

export const routeComponentInfoRover = serial => routeToComponentInfo(`${serial && serial.toLowerCase().startsWith('r') ? serial : `al${serial}`}`)
export const routeLogin = () => '/login'
export const routeRecovery = () => '/recovery'
export const routeEmailConfirmation = () => '/email_confirmation/'
export const routeRegisterSuccess = () => BasicRoutes.REGISTER_SUCCESS
export const routeCompanyUserRegisterSuccess = () => BasicRoutes.COMPANY_USER_REGISTER_SUCCESS
export const routeRegister = () => BasicRoutes.REGISTER
export const routeError = () => BasicRoutes.ERROR
export const routePayments = () => BasicRoutes.PAYMENTS
export const routeDashboard = () => BasicRoutes.DASHBOARD
export const routeSystemTypes = () => BasicRoutes.SYSTEM_TYPES
export const routeExternalRedirect = url => `/external_redirect?url=${encodeURIComponent(url)}`

export const routeRoversSystemTypes = systemTypeId => getRoute(Route.SYSTEM_TYPES, systemTypeId)
export const routeRecalls = recallId => getRoute(Route.RECALLS, recallId)
export const routeChangelogs = (productName, version) => [BasicRoutes.RELEASES, 'changelogs', productName, version].filter(Boolean).join('/')
export const routeRovers = roverSerial => {
  const prefix = localStorage.getItem('rovers-route-prefix')
  return [prefix, prefix ? BasicRoutes.ROVERS.replace('/', '') : BasicRoutes.ROVERS, roverSerial].filter(Boolean).join('/')
}
export const routeRoverUpdates = roverSerial => [routeRovers(roverSerial), 'updates'].filter(Boolean).join('/')
export const routeRoverEvent = (roverSerial, eventId, subEventId, subSubEventId) => [routeRovers(roverSerial), eventId, subEventId, subSubEventId].filter(Boolean).join('/')
export const routeUsers = () => BasicRoutes.USERS
export const routeUser = id => [BasicRoutes.USERS, id].filter(Boolean).join('/')
export const routeUserSubscriptions = id => [routeUser(id), UserTabs.SUBSCRIPTION].filter(Boolean).join('/')
export const routeUserDatasetTypes = id => [routeUser(id), UserTabs.SYSTEMS].filter(Boolean).join('/')
export const routeCompany = id => [BasicRoutes.COMPANY, id].filter(Boolean).join('/')
export const routeCompanyUsers = id => [routeCompany(id), CompanyTabs.USERS].filter(Boolean).join('/')
export const routeCompanyGeneral = id => [routeCompany(id), CompanyTabs.GENERAL].filter(Boolean).join('/')
export const routeCompanyUpdates = id => [routeCompany(id), CompanyTabs.UPDATES].filter(Boolean).join('/')
export const routeCompanyRoverUpdates = (id, roverSerial) => [routeCompany(id), CompanyTabs.UPDATES, roverSerial].filter(Boolean).join('/')
export const routeCompanyRovers = id => [routeCompany(id), CompanyTabs.ROVERS].filter(Boolean).join('/')
export const routeCompanySubscriptions = id => [routeCompany(id), CompanyTabs.SUBSCRIPTION].filter(Boolean).join('/')
export const routeCompanySubCompanies = id => [routeCompany(id), CompanyTabs.SUB_COMPANIES].filter(Boolean).join('/')
export const routeCompanySubscriptionsShop = id => [routeCompany(id), CompanyTabs.SUBSCRIPTION, 'shop'].filter(Boolean).join('/')
export const routeCompanyDatasetTypes = id => [routeCompany(id), CompanyTabs.SYSTEMS].filter(Boolean).join('/')
export const routeSubscription = subscriptionId => [BasicRoutes.SUBSCRIPTION, subscriptionId].join('/')
export const routeUserSubscription = (userId, subscriptionId) => [routeUser(userId), UserTabs.SUBSCRIPTION, subscriptionId].filter(Boolean).join('/')
export const routeCompanySubscription = (companyId, subscriptionId, componentId, queryString) => {
  if (companyId) {
    return [routeCompany(companyId), CompanyTabs.SUBSCRIPTION, subscriptionId].join('/') + (queryString ? `?${queryString}` : '')
  }
  return routeSubscription(subscriptionId)
}
export const routeCompanySubscriptionLicenseKey = (companyId, subscriptionId, licenseKeyId) => {
  if (companyId) {
    return [routeCompanySubscription(companyId, subscriptionId), 'keys', licenseKeyId].join('/')
  }
  return [routeSubscription(subscriptionId), 'keys', licenseKeyId].join('/')
}
export const routeCompanySubscriptionGrant = (companyId, subscriptionId, grantId) => {
  if (companyId) {
    return [routeCompanySubscription(companyId, subscriptionId), 'grants', grantId].join('/')
  }
  return [routeSubscription(subscriptionId), 'grants', grantId].join('/')
}

export const routeCompanySubscriptionPaymentMethod = (companyId, subscriptionId) => {
  if (companyId) {
    return [routeCompanySubscription(companyId, subscriptionId), 'payment'].join('/')
  }
  return [routeSubscription(subscriptionId), 'payment'].join('/')
}

export const linkRoute = (route, target = '_blank') => {
  const anchor = document.createElement('a')
  anchor.href = `${window.location.origin}${route}`
  anchor.target = target
  document.body.appendChild(anchor)
  anchor.click()
  document.body.removeChild(anchor)
}

export const getRoute = (route, id, tabName) => {
  if (tabName) {
    const tabs = tabMap[route] || []
    const foundTab = tabs.find(tab => tab.tabName === tabName)
    let path
    if (foundTab) {
      path = foundTab && foundTab.path
    } else {
      path = tabs[0].path
    }
    if (path) {
      if (typeof path === 'function') {
        return path(id)
      }
    } else {
      return path
    }
  }
  return id ? `${Routes[route]}/${id}` : `${Routes[route]}/`
}

// There is a hacky way to find params to the components that are not children of the Route component
// Whenever you will need some params from the route just add them here and call getParams(location.pathname)
const paths = [
  { path: '/dashboard', exact: true },
  { path: '/dashboard/:tab', exact: true },
  { path: '/payments/:tab', exact: true },
  { path: '/payments' },
  { path: '/users/:user_id/:tab', exact: true },
  { path: '/users/:user_id', exact: true },
  { path: '/users' },
]

// This function finds first corresponding path that is isExact = true
export const getMatch = path => {
  path = path || window.location.hash.slice(1, window.location.hash.length)
  const matchObj = paths.map(r => {
    const match = matchPath(path, { path: r.path, exact: Boolean(r.exact) })
    return match
  }).find(p => p && !isEmpty(p) && p.isExact)
  return isEmpty(matchObj) ? {} : (matchObj || {})
}

export const redirectTo = (url, target = '_blank') => {
  const a = document.createElement('a')
  a.style.display = 'none'
  document.body.appendChild(a)
  a.href = url
  a.target = target
  a.click()
  document.body.removeChild(a)
}
