import TableCell from '@material-ui/core/TableCell'
import { withStyles } from '@material-ui/styles'

const StyledTableCell = withStyles(theme => ({
  root: {
    padding: theme.spacing(0.25, 0, 0.25, 0.5),
  },
}))(TableCell)

export default StyledTableCell
