import config from 'config'
import { getUTCDate } from 'utils/dateTime'

export const convertRawLog = raw => {
  const {
    user,
    text,
    ip,
    timestamp,
  } = raw
  const momentTimestamp = getUTCDate(timestamp).format(config.DATETIME_FORMAT)
  return {
    ...raw,
    timestamp: momentTimestamp,
    searchString: user + ' ' + text + ' ' + ip + ' ' + momentTimestamp,
  }
}
