const specials = [
  '/', '.', '*', '+', '?', '|',
  '(', ')', '[', ']', '{', '}', '\\',
]
const escapeRegex = new RegExp(
  '(\\' + specials.join('|\\') + ')', 'g',
)
export function escape (string) {
  return (string || '').replace(escapeRegex, '\\$1')
}

export const wingtraSerialPattern = /^WINGTRA-[A-Za-z0-9]{6}$/
