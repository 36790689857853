import { getDefaultPagination } from 'modules/utils'
import { isUserAdmin, isUserCompanyAdmin, isUserCompanyCustomer, isUserCompanyEmployee, isUserAdminOrPLSUser, getCompanyId, getParentCompanyId } from 'utils/company'

export const getUsers = state => {
  return state.users.get('users') || []
}
export const getNonCompanyUsers = state => {
  return state.users.get('nonCompanyUsers') || []
}

export const getPLSUsers = state => {
  return state.users.get('plsUsers')
}

export const getUsersIsLoading = state => {
  return state.users.get('getUsersIsLoading')
}

export const isLoggedIn = state => {
  return !!state.users.get('token')
}

export const isAdmin = state => {
  const users = state.users
  const user = users && users.get('user')
  return users && !!(users.get('token') && user && isUserAdminOrPLSUser(user))
}

export const isCRSAdvanced = state => {
  const users = state.users
  return users && users.get('token') && users.get('user')
    ? users.get('user').is_crs_advanced
    : false
}

export const shouldRedirectToClearCookies = state => {
  return state.users.get('shouldRedirectToClearCookies')
}

export const isCurrentUserCompanyAdmin = (state, companyId) => {
  const loggedUser = getLoggedUser(state)
  const loggedUserCompanyId = getCompanyId(loggedUser)
  const currentCompany = state.companies.get('currentCompany') || {}
  const isUserExist = Boolean(token(state) && loggedUser)
  const isUserEmployee = isUserExist && isUserCompanyEmployee(loggedUser)
  const isUserViewingOwnCompany = isUserExist && loggedUserCompanyId === companyId
  const isUserCompanyAdmin = isUserEmployee && Boolean(loggedUser.is_company_admin)
  const isLoggedUserAdminOfCurrentCompany = isUserCompanyAdmin && isUserViewingOwnCompany
  const isLoggedUserAdminOfParentOfCurrentCompany = isUserCompanyAdmin && !isUserViewingOwnCompany && loggedUserCompanyId === getParentCompanyId(currentCompany)
  return isUserExist && (isLoggedUserAdminOfCurrentCompany || isLoggedUserAdminOfParentOfCurrentCompany)
}

export const getUserPermissions = state => {
  const loggedUser = getLoggedUser(state) || {}
  const loggedUserCompanyId = getCompanyId(loggedUser)
  const activeUser = getActiveUser(state) || {}
  const activeUserCompanyId = getCompanyId(activeUser)
  const activeCompany = state.companies.get('currentCompany') || {}
  const sameCompany = loggedUserCompanyId === activeUserCompanyId
  const parentCompanyAdmin = !sameCompany && isUserCompanyAdmin(loggedUser) && getParentCompanyId(activeCompany) === loggedUserCompanyId && activeCompany.id === activeUserCompanyId
  const userOwnProfile = loggedUser && activeUser ? loggedUser.id === activeUser.id : false
  const isAdmin = isUserAdmin(loggedUser)
  const isCompanyAdmin = isUserCompanyAdmin(loggedUser) && sameCompany
  const isCompanyEmployee = isUserCompanyEmployee(loggedUser) && sameCompany
  const isAdminOrEmployee = isAdmin || isCompanyAdmin || parentCompanyAdmin || (isCompanyEmployee && isUserCompanyCustomer(activeUser))
  return {
    // read: isAdmin || isUserCompanyAdmin || isUserCompanyEmployee,
    update: userOwnProfile || isAdminOrEmployee,
    delete: userOwnProfile || isAdminOrEmployee,
    password: userOwnProfile || isAdmin,
    transferUser: isAdmin || parentCompanyAdmin,
    activate: isAdmin || parentCompanyAdmin ||
      ((isCompanyAdmin || (isCompanyEmployee && isUserCompanyCustomer(activeUser))) && !userOwnProfile),
  }
}

export const isCurrentUserCompanyEmployee = state => {
  const user = getLoggedUser(state)
  return !!(token(state) && user && isUserCompanyEmployee(user))
}

export const isCurrentUserCompanyCustomer = state => {
  const user = getLoggedUser(state)
  return !!(token(state) && user && isUserCompanyCustomer(user))
}

export const isCurrentUserAdminOrCompanyAdmin = (state, companyId) => {
  return isAdmin(state) || isCurrentUserCompanyAdmin(state, companyId)
}

export const isLoginLoading = state => {
  return !!state.users.get('loginIsLoading')
}

export const getLoginError = state => {
  return state.users.get('loginErrorMessage')
}

export const isRecoverLoading = state => {
  return !!state.users.get('recoverIsLoading')
}

export const isRecoverSuccess = state => {
  return !!state.users.get('recoverIsSuccess')
}

export const isSendConfirmEmailLoading = state => {
  return !!state.users.get('sendConfirmEmailIsLoading')
}

export const isSendConfirmEmailSuccess = state => {
  return !!state.users.get('sendConfirmEmailIsSuccess')
}

export const isConfirmEmailLoading = state => {
  return !!state.users.get('confirmEmailIsLoading')
}

export const isConfirmEmailSuccess = state => {
  return !!state.users.get('confirmEmailIsSuccess')
}

export const getLoggedUser = state => {
  return state.users.get('user')
}
/**
 * Retrieves the timezone of the current user from the Redux state.
 *
 * @function getUserTimezone
 * @param {Object} state - The Redux state from which the user's timezone is to be retrieved.
 * @returns {string} The timezone of the user if available, otherwise an empty string.
 */
export const getUserTimezone = state => {
  const user = state.users.get('user')
  return user ? user.time_zone || '' : ''
}
export const getActiveUser = state => {
  return state.users.get('activeUser')
}

export const getActiveUserIsLoading = state => {
  return state.users.get('getActiveUserIsLoading')
}

export const deleteUserIsLoading = state => state.users.get('deleteUserIsLoading')

export const updateUserIsLoading = state => {
  return state.users.get('updateUserIsLoading')
}

export const token = state => {
  return state.users.get('token')
}

export const getProjectsStatistic = state => state.users.get('projectsStatistic') || []
export const isGetProjectsStatisticsLoading = state => state.users.get('getProjectsStatisticIsLoading')

export const isRegisterLoading = state => !!state.users.get('registerIsLoading')

export const isAcceptTermsFormOpen = state => state.users.get('acceptTermsFormOpen')

export const isGetSystemTypesLoading = (state, userId) => {
  const systemTypesByUserLoading = state.users.get('systemTypesByUserLoading') || {}
  return systemTypesByUserLoading[userId] || false
}

export const getSubscriptions = (state, userId) => {
  const subscriptionsByUser = state.users.get('subscriptionsByUser') || {}
  return subscriptionsByUser[userId] || []
}

export const isGetSubscriptionsLoading = (state, userId) => {
  const subscriptionsByUserLoading = state.users.get('subscriptionsByUserLoading') || {}
  return subscriptionsByUserLoading[userId] || false
}

export const isUserSuccessfullyUpdated = state => state.users.get('updateUserSucceed')

export const getAllUserPermissions = state => state.users.get('permissionsByUser') || {}
export const getAllUserPermissionsLoading = state => state.users.get('permissionsByUserLoading') || {}

export const getLoggedUserPermissions = state => {
  if (state.users) {
    const loggedUserPermissions = state.users.get('loggedUserPermissions') || []
    return loggedUserPermissions
  }
  return []
}

export const isGetUserPermissionsLoading = (state, userId) => {
  const permissionsByUserLoading = state.users.get('permissionsByUserLoading') || {}
  return permissionsByUserLoading[userId] || false
}

export const isRequestRecoveryLoading = state => state.users.get('requestRecoveryIsLoading')
export const isRequestRecoverySuccess = state => state.users.get('requestRecoveryIsSuccess')
export const isPasswordNotSet = state => state.users.get('passwordNotSet')

export const getAllowedToLoginAt = state => state.users.get('allowedToLoginAt')
export const isTwoFactorLogin = state => state.users.get('twoFactorLogin')
export const isTwoFactorLoginLoading = state => !!state.users.get('twoFactorLoginIsLoading')
export const getQR = (state, userId) => state.users.get('qr')[userId]
export const isGetQRLoading = (state, userId) => !!state.users.get('qrIsLoading')[userId]
export const isActivateTwoFactorAuthLoading = (state, userId) => !!state.users.get('activateTwoFactorAuthIsLoading')[userId]
export const isDeactivateTwoFactorAuthLoading = (state, userId) => !!state.users.get('deactivateTwoFactorAuthIsLoading')[userId]

export const isGetUserDBLogsLoading = state => state.users.get('getUserDBLogsLoading')
export const getUserDBLogs = (state, userId) => state.users.get('userLogs')[userId] || []
export const getUserDBLogsPagination = (state, userId) => state.users.get('getUserDBLogsPagination')[userId] || getDefaultPagination()
export const isGetReleasesLoading = state => !!state.users.get('releasesIsLoading')
export const getReleases = state => state.users.get('releases')
export const getTotalReleases = state => state.users.get('totalReleases')
export const getUserProjects = (state, userId) => state.users.get('projectsByUser')[userId] || []
export const isGetUserProjectsLoading = (state, userId) => !!state.users.get('isGetUserProjectsLoading')[userId]
