import i18n from 'i18n'
import { path } from 'ramda'
import { CompanyRole } from 'types/company'

export const getResellerId = user => path(['reseller', 'id'], user)
export const getParentCompanyId = company => path(['parent', 'id'], company)
export const getCompanyId = user => path(['company', 'id'], user)
export const getCompanyName = user => path(['company', 'name'], user)
export const isUserActive = user => Boolean(path(['is_active'], user))
export const isUserPLS = user => isUserActive(user) && Boolean(path(['is_pls'], user))
export const isUserAdmin = user => isUserActive(user) && Boolean(path(['is_superuser'], user))
export const isUserAdminOrPLSUser = user => isUserAdmin(user) || isUserPLS(user)
export const isUserCompanyAdmin = user => Boolean(path(['is_company_admin'], user))
export const isUserCompanyCustomer = user => path(['company_role'], user) === CompanyRole.CUSTOMER
export const isUserCompanyEmployee = user => path(['company_role'], user) === CompanyRole.EMPLOYEE

export const getActiveUserProjects = (loggedUser, activeUser, projects, permissions, showAllProjects) => {
  if (!loggedUser && !activeUser) return []
  if (!loggedUser && activeUser) return []
  if (loggedUser && !activeUser) {
    return isUserCompanyCustomer(loggedUser)
      ? projects
      : showAllProjects ? projects : projects.filter(project => project.userId === loggedUser.id)
  }
  if (loggedUser.id === activeUser.id) {
    return isUserCompanyCustomer(loggedUser)
      ? projects
      : showAllProjects ? projects : projects.filter(project => project.userId === loggedUser.id)
  }
  return activeUser
    ? isUserCompanyCustomer(activeUser)
      ? permissions.find(permission => permission.type === 'company')
        ? projects
        : projects.filter(
          project => permissions.find(permission => permission.type === 'project' && permission.projectId === project.id))
      : showAllProjects ? projects : projects.filter(project => project.userId === activeUser.id)
    : []
}

export const prettifyCompanyRole = role => {
  switch (role) {
    case CompanyRole.CUSTOMER:
      return i18n.t('company.role.customer')
    case CompanyRole.EMPLOYEE:
      return i18n.t('company.role.employee')
    default:
      return i18n.t('company.role.unknown')
  }
}
