import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import FileFields from './FileFields'
import { isFileValid } from './utils'

const EditFileDialog = props => {
  const { file, files, eventType, open, onClose, onSubmit, allowEventType, documentTypes } = props
  const [newFileState, setNewFileState] = useState(({ ...file }))
  const onChange = id => e => {
    const { value, name } = e.target
    setNewFileState(prevState => ({ ...prevState, [name]: value }))
  }
  const isValid = isFileValid(newFileState, files, eventType)
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <FileFields
          file={newFileState}
          files={files}
          eventType={eventType}
          allowEventType={allowEventType}
          documentTypes={documentTypes}
          editFileName={false}
          onChange={onChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color='primary' disabled={!isValid} onClick={e => onSubmit(newFileState)}>Submit</Button>
      </DialogActions>
    </Dialog>
  )
}

EditFileDialog.propTypes = {
  allowEventType: PropTypes.bool,
  documentTypes: PropTypes.array,
  files: PropTypes.array,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  file: PropTypes.object,
  eventType: PropTypes.string,
}

export default EditFileDialog
