
export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

export function getBaseName (fileName) {
  // const result = /(?:\.([^.]+))?$/i.exec(fileName)
  // return result === null ? undefined : result[1]
  return fileName.substr(0, fileName.lastIndexOf('.'))
}

/**
 * Returns the file extension from the fileName
 * @param {string} fileName
 */
export function getFileExtension (fileName) {
  const splittedFileName = fileName.split('.')
  return splittedFileName[splittedFileName.length - 1]
}

export const isImageFile = ext => {
  return (
    ext.endsWith('png') ||
    ext.endsWith('jpeg') ||
    ext.endsWith('jpg')
  )
}
