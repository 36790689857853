import { isNil } from 'ramda'

export function concatMap (transform, list) {
  return list.map(transform).reduce((a, b) => [ ...a, ...b ], [])
}

/**
 * Shortcut for searching in lists where the items have an `id` field.
 * Same semantics as `Array.find`, returns `undefined` when no element found.
 */
export function findById (id, entries) {
  if (isNil(id)) {
    return undefined
  }
  return entries.find(entry => entry.id.toString() === id.toString())
}

// Turns an array of objects into a dict of objects keyed by
// the value indexKey.
// A little nasty but I can't figure out how to make it work
// via a map() or such.
export function arrayToDict (array, indexKey) {
  const normalizedObject = {}
  for (let i = 0; i < array.length; i++) {
    const key = array[i][indexKey]
    normalizedObject[key] = array[i]
  }
  return normalizedObject
}

/**
 * This utility function merges a new entry into a list. It uses the `id` field of the
 * list elements. It removes all elements from `oldList` which have the same `id` as
 * newEntry and inserts `newEntry` into the list.
 * @param oldList The old list.
 * @param newEntry Element to add to the old list.
 * @return A new list containing `newEntry` and those entries from `oldList` which
 * have a different `id`.
 */
// function insertById<I, T extends HasId<I>>(oldList: T[], newEntry: T): T[]
export function insertById (oldList, newEntry) {
  const unchangedOldEntries = oldList.filter(item => item.id !== newEntry.id)
  return [...unchangedOldEntries, newEntry]
}

/**
 * Executes `transform` on the element with the id `id`.
 *
*/
export function mapById (id, entries, transform) {
  if (isNil(id)) {
    return entries
  }
  return entries.map(entry => entry.id.toString() === id.toString() ? transform(entry) : entry)
}
/**
 * Concatenate an array of arrays into single array
 * @param {*} list
*/
export function concatenate (list) {
  return list.reduce((a, b) => [ ...a, ...b ], [])
}

/**
 * Removes the element with the id `id` from the list.
 */
// export function removeById<I, T extends HasId<I>>(id: I, entries: T[]): T[] {
export function removeById (id, entries) {
  if (isNil(id)) {
    return entries
  }
  return entries.filter(entry => entry.id.toString() !== id.toString())
}

/**
 * This utility function merges two lists. It uses the `id` field of the list elements.
 * It retains all elements from `oldList` for which there is no element in `newEntries`
 * which has the same `id`.
 * @param oldList The old list.
 * @param newEntries Elements to add to the old list.
 * @return A new list containing all the entries from `newEntries` and those entries from
 *     `oldList` which were not replaced.
 */
// export function mergeById<I, T extends HasId<I>>(oldList: T[], newEntries: T[]): T[] {

export function mergeById (oldList, newEntries) {
  const unchangedOldEntries = oldList.filter(item => newEntries.every(newItem => item.id !== newItem.id))
  return [...unchangedOldEntries, ...newEntries]
}

/**
 * Returns an array with only unique values
 * @param {*} array
 */
export function makeUnique (array) {
  return array.reduce((accum, element) => accum.indexOf(element) === -1 ? [ ...accum, element ] : accum, [])
}
/**
 * Returns an array with only unique values. Removes duplicates by property `fieldName` of the object
 * @param {Array.<any>} array
 */
export function makeUniqueByField (array, fieldName) {
  return array.reduce((accum, element) =>
    !accum.find(elem => elem[fieldName] === element[fieldName])
      ? [ ...accum, element ]
      : accum,
  [],
  )
}
/**
 * Returns an array with only unique values. Removes duplicates by `id` property
 * @param {Array.<any>} array
 */
export function makeUniqueById (array) {
  return makeUniqueByField(array, 'id')
}
