import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  getSalesProducts: null,
  getSalesProductsLoading: null,
  getSalesProductsSuccess: ['salesProducts', 'grantTypes', 'salesBundles'],
  getSalesProductsFailure: null,

  // createPayPalBillingAgreement
  executePaypalBillingAgreement: ['paymentToken'],
  executePaypalBillingAgreementLoading: null,
  executePaypalBillingAgreementSuccess: ['subscriptionType'],
  executePaypalBillingAgreementFailure: ['errorMessage'],

  // createStripeBillingAgreement
  executeStripeBillingAgreement: ['companyId', 'userId', 'data'],
  executeStripeBillingAgreementLoading: null,
  executeStripeBillingAgreementSuccess: ['subscriptionType'],
  executeStripeBillingAgreementFailure: ['errorMessage'],

  // createPayPalBillingAgreement
  createPaypalBillingAgreement: null,
  createPaypalBillingAgreementLoading: null,
  createPaypalBillingAgreementSuccess: ['approvalUrl'],
  createPaypalBillingAgreementFailure: ['errorMessage'],

  setPaypalToken: ['paypalSuccess', 'paymentToken'],

  // create Stripe subscription form dialog window
  closeStripeFormDialog: null,
  openStripeFormDialog: ['salesProductToken', 'durationToken', 'edit', 'dialogState'],
  // cancel Stripe subscription dialog window
  closeStripeCancelDialog: null,
  openStripeCancelDialog: ['subscriptionId', 'companyId'],

  activateOfflineSubscription: ['companyId', 'payload', 'onSuccess'],
  activateOfflineSubscriptionLoading: ['companyId'],
  activateOfflineSubscriptionSuccess: ['companyId', 'subscription'],
  activateOfflineSubscriptionFailure: ['companyId'],

  deactivateSubscriptions: ['companyId', 'subscriptions', 'data', 'onSuccess'],
  deactivateSubscriptionsLoading: ['companyId'],
  deactivateSubscriptionsSuccess: ['companyId'],
  deactivateSubscriptionsFailure: ['companyId', 'subscriptionId'],

  deactivateOfflineSubscriptionsSuccess: ['companyId', 'subscriptions'],
  deactivateSubscriptionLoading: ['subscriptionId'],
  deactivateSubscriptionSuccess: ['subscriptionId'],
  deactivateSubscriptionFailure: ['subscriptionId', 'failureMessage'],

  cancelStripeBillingAgreementSuccess: null,

  getSubscriptionInvoices: ['subscriptionId'],
  getSubscriptionInvoicesLoading: ['subscriptionId'],
  getSubscriptionInvoicesFailure: ['subscriptionId'],
  getSubscriptionInvoicesSuccess: ['subscriptionId', 'invoices'],

  getSubscriptionLicenseKeys: ['subscriptionId'],
  getSubscriptionLicenseKeysLoading: ['subscriptionId'],
  getSubscriptionLicenseKeysFailure: ['subscriptionId'],
  getSubscriptionLicenseKeysSuccess: ['subscriptionId', 'licenseKeys'],

  getLicenseKeysAttachments: ['licenseKeysIds'],
  getLicenseKeysAttachmentsLoading: ['licenseKeysIds'],
  getLicenseKeysAttachmentsFailure: ['licenseKeysIds'],
  getLicenseKeysAttachmentsSuccess: ['licenseKeysIds', 'attachmentsByLicenseKey'],

  getSubscriptionGrants: ['subscriptionId'],
  getSubscriptionGrantsLoading: ['subscriptionId'],
  getSubscriptionGrantsFailure: ['subscriptionId'],
  getSubscriptionGrantsSuccess: ['subscriptionId', 'grants'],

  getPlanGrants: ['planToken'],
  getPlanGrantsLoading: ['planToken'],
  getPlanGrantsFailure: ['planToken'],
  getPlanGrantsSuccess: ['planToken', 'grants'],

  createPlanGrant: ['productId', 'grant'],
  createPlanGrantLoading: ['productId'],
  createPlanGrantFailure: ['productId'],
  createPlanGrantSuccess: ['productId', 'grant'],

  deletePlanGrant: ['productId', 'grantId', 'onSuccess'],
  deletePlanGrantLoading: ['productId'],
  deletePlanGrantFailure: ['productId'],
  deletePlanGrantSuccess: ['productId', 'grantId'],

  stripeRefund: ['subscriptionId', 'chargeId', 'reason'],
  stripeRefundLoading: ['subscriptionId', 'chargeId'],
  stripeRefundSuccess: ['subscriptionId', 'chargeId', 'refund'],
  stripeRefundFailure: ['subscriptionId', 'chargeId'],

  getSubscriptionCard: ['subscriptionId'],
  getSubscriptionCardLoading: ['subscriptionId'],
  getSubscriptionCardSuccess: ['subscriptionId', 'card'],
  getSubscriptionCardFailure: ['subscriptionId', 'message'],

  updateSubscriptions: ['salesProducts'],

  updateSubscriptionCard: ['subscriptionId', 'token'],
  updateSubscriptionCardLoading: ['subscriptionId'],
  updateSubscriptionCardSuccess: ['subscriptionId'],
  updateSubscriptionCardFailure: ['subscriptionId'],

  redirectToSubscription: ['subscriptionId', 'subscriptionTab', 'componentId', 'onError'],

  transferSubscriptions: ['subscriptionIds', 'oldCompanyId', 'data', 'succesCallback'],
  transferSubscriptionsLoading: ['subscriptionIds', 'oldCompanyId', 'data'],
  transferSubscriptionsSuccess: ['subscriptionIds', 'oldCompanyId', 'data', 'subscription'],
  transferSubscriptionsFailure: ['subscriptionIds', 'oldCompanyId', 'data'],

  getAllSubscriptions: ['pagination', 'filterString'],
  getAllSubscriptionsLoading: [],
  getAllSubscriptionsSuccess: ['subscriptions', 'pagination'],
  getAllSubscriptionsFailure: [],

  editSubscriptionCard: ['subscriptionId'],
  getCheckoutSession: ['sessionId', 'sessionState'],
  getCheckoutSessionLoading: ['sessionId'],
  getCheckoutSessionSuccess: ['sessionId', 'session'],
  getCheckoutSessionFailure: ['sessionId'],

  setCheckoutSession: ['data'],
  setRetryPaymentState: ['data'],

  retryInvoice: ['subscriptionId', 'invoiceId'],
  retryInvoiceLoading: ['invoiceId'],
  retryInvoiceSuccess: ['invoiceId', 'invoice'],
  retryInvoiceFailure: ['invoiceId'],

  retryGetSubscription: ['subscriptionId'],
  retryGetSubscriptionLoading: ['subscriptionId'],
  retryGetSubscriptionSuccess: ['subscriptionId', 'subscription'],
  retryGetSubscriptionFailure: ['subscriptionId'],

  getSubscriptionDBLogs: ['subscriptionId', 'pagination', 'onSuccess'],
  getSubscriptionDBLogsLoading: ['subscriptionId'],
  getSubscriptionDBLogsSuccess: ['subscriptionId', 'logs', 'pagination'],
  getSubscriptionDBLogsFailure: ['subscriptionId', 'errorMessage'],

  extendOfflineSubscription: ['companyId', 'subscriptionId', 'payload', 'onSuccess'],
  extendOfflineSubscriptionLoading: null,
  extendOfflineSubscriptionSuccess: ['companyId', 'subscriptionId', 'payload'],
  extendOfflineSubscriptionFailure: ['errorMessage'],

  getExtendEvents: ['subscriptionId', 'onSuccess'],
  getExtendEventsLoading: ['subscriptionId'],
  getExtendEventsSuccess: ['subscriptionId', 'events'],
  getExtendEventsFailure: ['subscriptionId', 'errorMessage'],

  getCancelEvents: ['subscriptionId', 'onSuccess'],
  getCancelEventsLoading: ['subscriptionId'],
  getCancelEventsSuccess: ['subscriptionId', 'events'],
  getCancelEventsFailure: ['subscriptionId', 'errorMessage'],
}, { prefix: 'BILLING_AGREEMENTS/' })

export const BillingAgreementTypes = Types
export default Creators
