import React from 'react'
import PropTypes from 'prop-types'
// Material UI
import { Typography } from '@material-ui/core'
// Project deps
import Warning from 'components/reusable/Warning'
import { getValue } from 'utils/templatedForm'

const TextOption = props => {
  const { option } = props
  const { variant = 'text' } = option
  const value = getValue(props)

  return (
    variant === 'text'
      ? <Typography>{value}</Typography>
      : variant === 'warning' || variant === 'error' || variant === 'info'
        ? <Warning variant={variant}>{value}</Warning>
        : null
  )
}

TextOption.propTypes = {
  option: PropTypes.object,
}

export default TextOption
