const RoverTypesToParsingFunction = {
  'RECON': rover => isReconRover(rover.serial),
  'Phoenix LiDAR Systems': rover => !isReconRover(rover.serial),
}

export const isReconRover = roverSerial => {
  const lowerCasedValue = (roverSerial || '').toLowerCase()
  return lowerCasedValue.startsWith('r')
}

export const getAvailableRoverForTypes = (rovers, roverTypes) => {
  if (roverTypes.length === 0) {
    return rovers
  }
  const checks = roverTypes.map(roverType => RoverTypesToParsingFunction[roverType])
  return rovers.filter(rover => checks.some(check => check(rover)))
}
