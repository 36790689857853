import smartRound from 'smart-round'
/**
 * Given an `input` string, trims the number to `precision` decimals.
 * If the number is neither a float nor an integer, then returns `input`.
 * If `precision` is less than one, then returns `input`.
 */
export function withMaxPrecision (input, precision) {
  // This regex matches a single integer or floating point number.
  const regex = /^[+-]?\d+(\.\d+)?$/g
  const matched = input.match(regex)
  if (matched && typeof precision !== 'undefined' && precision > 0) {
    const split = matched[0].split('.')
    if (split.length === 2) {
      const trimmedFraction = split[1].slice(0, precision)
      return `${split[0]}.${trimmedFraction}`
    }
  }
  return input
}

export function isFloatNumber (n) {
  return n === +n && n !== (n | 0)
}

const rounder = smartRound(4, 3, 3)

/**
 * Rounds provided input number value to the precision of 3
 * @param {Number|String} input
 */
export function withRounding (input, precision = 5) {
  const stringRepresentation = (typeof input === 'number' && !isNaN(input)) || typeof input === 'string'
    ? input.toString()
    : null
  if (stringRepresentation) {
    const regex = /^[+-]?\d+(\.\d+)?$/g
    const matched = stringRepresentation.match(regex)
    if (matched) {
      const number = +matched[0]
      return rounder(number, false)
    }
  }
  return input
}
