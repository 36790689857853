import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  // get constants for register form
  // get constants
  getConstants: null,
  getConstantsLoading: null,
  getConstantsSuccess: ['countries', 'industries', 'timeZones', 'daEventTypes'],
  getConstantsFailure: ['errorMessage'],

  getProducts: null,
  getProductsLoading: null,
  getProductsSuccess: ['products'],
  getProductsFailure: ['errorMessage'],

  getProductReleases: ['productName'],
  getProductReleasesLoading: ['productName'],
  getProductReleasesSuccess: ['productName', 'releases', 'shouldShowPublic'],
  getProductReleasesFailure: ['productName', 'errorMessage'],

  getTags: null,
  getTagsLoading: null,
  getTagsSuccess: ['tags'],
  getTagsFailure: ['errorMessage'],

  getLogs: ['search', 'pageSize', 'page', 'orderBy', 'order'],
  getLogsLoading: null,
  getLogsSuccess: ['logs', 'pagination'],
  getLogsFailure: ['errorMessage'],

  getDAEvents: ['search', 'pageSize', 'page', 'orderBy', 'order', 'filter', 'withLoading'],
  getDAEventsLoading: null,
  getDAEventsSuccess: ['daEvents', 'pagination'],
  getDAEventsFailure: ['errorMessage'],

  getAllDAEvents: null,
  getAllDAEventsLoading: null,
  getAllDAEventsSuccess: ['daEvents'],
  getAllDAEventsFailure: ['errorMessage'],

  getDARequestEvents: ['search', 'pageSize', 'page', 'orderBy', 'order', 'filter', 'withLoading'],
  getDARequestEventsLoading: null,
  getDARequestEventsSuccess: ['daEvents', 'pagination'],
  getDARequestEventsFailure: ['errorMessage'],

  getAllDARequestEvents: null,
  getAllDARequestEventsLoading: null,
  getAllDARequestEventsSuccess: ['darEvents'],
  getAllDARequestEventsFailure: ['errorMessage'],

  getRentEvents: ['search', 'pageSize', 'page', 'orderBy', 'order', 'filter', 'withLoading'],
  getRentEventsLoading: null,
  getRentEventsSuccess: ['rentEvents', 'pagination'],
  getRentEventsFailure: ['errorMessage'],

  getAllRentEvents: null,
  getAllRentEventsLoading: null,
  getAllRentEventsSuccess: ['rentEvents'],
  getAllRentEventsFailure: ['errorMessage'],

  getNotifications: ['search', 'pageSize', 'page', 'orderBy', 'order', 'filter'],
  getNotificationsLoading: null,
  getNotificationsSuccess: ['notifications', 'pagination'],
  getNotificationsFailure: ['errorMessage'],

  getAllSystemTypes: null,
  getAllSystemTypesLoading: null,
  getAllSystemTypesSuccess: ['systemTypes'],
  getAllSystemTypesFailure: null,

  getAllCustomerTypes: null,
  getAllCustomerTypesLoading: null,
  getAllCustomerTypesSuccess: ['customerTypes'],
  getAllCustomerTypesFailure: null,

  changeUserSettingsProjects: ['settings'],

  getPropertyTypes: null,
  getPropertyTypesLoading: null,
  getPropertyTypesSuccess: ['propertyTypes'],
  getPropertyTypesFailure: ['errorMessage'],

  getComponentTypes: null,
  getComponentTypesLoading: null,
  getComponentTypesSuccess: ['componentTypes'],
  getComponentTypesFailure: ['errorMessage'],

  getULData: null,
  getULDataLoading: null,
  getULDataSuccess: ['sales_invoices', 'sales_orders', 'purchase_orders', 'credit_notes'],
  getULDataFailure: null,

  getDAREventTypes: null,
  getDAREventTypesLoading: null,
  getDAREventTypesSuccess: ['darEventTypes'],
  getDAREventTypesFailure: null,

  getDAEventIssueTypes: null,
  getDAEventIssueTypesLoading: null,
  getDAEventIssueTypesSuccess: ['daIssueTypes'],
  getDAEventIssueTypesFailure: null,

  getCheckListSteps: null,
  getCheckListStepsLoading: null,
  getCheckListStepsSuccess: ['checkListSteps'],
  getCheckListStepsFailure: null,

  getRMAStatuses: null,
  getRMAStatusesLoading: null,
  getRMAStatusesSuccess: ['rmaStatuses'],
  getRMAStatusesFailure: null,

  getSystemTypeStatuses: null,
  getSystemTypeStatusesLoading: null,
  getSystemTypeStatusesSuccess: ['systemTypeStatuses'],
  getSystemTypeStatusesFailure: null,

  getAllProjects: null,
  getAllProjectsSuccess: ['projects'],

  setHeaderDimensions: ['width', 'height'],

  getWorkLists: null,
  getWorkListsLoading: null,
  getWorkListsSuccess: ['workLists'],
  getWorkListsFailure: null,

  getAntennas: [],
  getAntennasLoading: null,
  getAntennasSuccess: ['antennas'],
  getAntennasFailure: null,
})

export const AppTypes = Types
export default Creators
