import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
// Material UI
import { makeStyles } from '@material-ui/core/styles'
import { Grid, TextField, Typography } from '@material-ui/core'
// Icons
import SecurityIcon from '@material-ui/icons/Security'
// Project deps
import UsersActions from 'modules/users/actions'
import { getQR, isDeactivateTwoFactorAuthLoading, isActivateTwoFactorAuthLoading, isGetQRLoading } from 'modules/users/selectors'
import LoadingButton from 'components/reusable/LoadingButton'

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(1),
  },
  qr: {
    maxHeight: 300,
    maxWidth: 300,
  },
  notScan: {
    borderLeft: '2px solid #FFC107',
    padding: theme.spacing(0, 1),
    marginTop: theme.spacing(2),
    alignSelf: 'flex-start',
    flex: 1,
  },
}))

const TwoFactorAuth = props => {
  const { user, userId, force2fa = false } = props
  const classes = useStyles()
  const [pin, setPin] = useState('')
  const [enableTwoFactorAuth, setEnableTwoFactorAuth] = useState(false)
  const dispatch = useDispatch()
  const getTwoFactorAuth = () => dispatch(UsersActions.getTwoFactorAuth(userId))
  const activateTwoFactorAuth = (pin, key) => dispatch(UsersActions.activateTwoFactorAuth(userId, pin, key))
  const deactivateTwoFactorAuth = () => dispatch(UsersActions.deactivateTwoFactorAuth(userId))
  const [isLoadingQR, isActivate2FALoading, isDeactivate2FALoading] = useSelector(state => [
    isGetQRLoading(state, userId),
    isActivateTwoFactorAuthLoading(state, userId),
    isDeactivateTwoFactorAuthLoading(state, userId),
  ])
  const authData = useSelector(state => getQR(state, userId))
  if (user.use_2fa) {
    return (
      <div className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
            <Typography>Status: <b>Enabled</b></Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <LoadingButton
              fullWidth
              isLoading={isDeactivate2FALoading}
              variant='contained'
              color='primary'
              onClick={deactivateTwoFactorAuth}>
              Disable two-factor authentication
            </LoadingButton>
          </Grid>
        </Grid>
      </div>
    )
  }
  return (
    <div className={classes.container}>
      { (isLoadingQR || !enableTwoFactorAuth) && (
        <div>
          <Grid container spacing={3}>
            {!force2fa && <React.Fragment><Grid item xs={12}>
              <Typography>Increase your account&apos;s security by enabling Two-Factor Authentication (2FA)</Typography>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
              <Typography>Status: <b>Disabled</b></Typography>
            </Grid>
            </React.Fragment>
            }
            <Grid item xs={12} sm={force2fa ? 12 : 6} md={force2fa ? 6 : 4} lg={force2fa ? 6 : 3}>
              <LoadingButton
                startIcon={<SecurityIcon/>}
                fullWidth
                isLoading={isLoadingQR}
                variant='contained'
                color='primary'
                onClick={e => {
                  setEnableTwoFactorAuth(true)
                  getTwoFactorAuth()
                }}>
                Enable two-factor authentication
              </LoadingButton>
            </Grid>
          </Grid>
        </div>
      )}
      {authData && enableTwoFactorAuth && (
        <React.Fragment>
          <Grid container style={{ maxWidth: 1048 }} spacing={1}>
            <Grid item xs={12} style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
              <div className={classes.qr} dangerouslySetInnerHTML={{ __html: authData[0] }}></div>
              <Typography className={classes.notScan}>
                <b>Can&apos;t scan the code?</b><br/>
                To add the entry manually, provide the following details to the application on your phone.<br/>
                <b>Account</b>: {user.email}<br/>
                <b>Key</b>: {authData[1]}<br/>
                <b>Time based</b>: Yes<br/>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField value={pin} onChange={e => {
                setPin(e.target.value)
              }} fullWidth label='Pin code' variant='outlined'/>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={force2fa ? 12 : 6} md={force2fa ? 6 : 4} lg={force2fa ? 6 : 3}>
              <LoadingButton isLoading={isActivate2FALoading} fullWidth disabled={!pin} onClick={e => {
                activateTwoFactorAuth(pin, authData[1])
              }} variant='contained' color='primary'>Register with two-factor app</LoadingButton>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </div>
  )
}

TwoFactorAuth.propTypes = {
  userId: PropTypes.string,
  user: PropTypes.object,
  force2fa: PropTypes.bool,
}

export default TwoFactorAuth
