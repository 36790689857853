import React from 'react'
import PropTypes from 'prop-types'
// Material UI
import { Dialog, IconButton } from '@material-ui/core'
// Icons
import CloseIcon from '@material-ui/icons/Close'

/**
 * Generic dialog with close button in top-right corner
 */
const ClosableDialog = ({ children, closeByButton = true, iconStyle = {}, ...otherProps }) => {
  const { onClose, ...restProps } = otherProps
  return (
    <Dialog {...restProps}{...(!closeByButton && { onClose })}>
      <IconButton style={{ position: 'absolute', right: 4, top: 4, zIndex: 1000, ...iconStyle }} onClick={onClose}>
        <CloseIcon/>
      </IconButton>
      {children}
    </Dialog>
  )
}

ClosableDialog.propTypes = {
  children: PropTypes.any,
  closeByButton: PropTypes.bool,
  iconStyle: PropTypes.object,
}

export default ClosableDialog
