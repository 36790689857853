import UrlPattern from 'url-pattern'
export const uuidSegmentValueCharset = 'a-z0-9-'

export const patternPaypal = new UrlPattern('/paypal/:status(/)(?token=:token)', {
  segmentValueCharset: 'a-zA-Z0-9-',
})

export const patternUuid = /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/
export const ieLicenseKeyPattern = /\b[0-9a-z]{4}\b-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-\b[0-9a-z]{3}\b/
export const ieLicenseKeyPatternMask = '8888-8888-8888-8888-8888-888'
export const ieLicenseKeyFormatChars = {
  '9': '[0-9]',
  '8': '[0-9a-z]',
  'a': '[A-Za-z]',
  '*': '[A-Za-z0-9]',
}
