import React from 'react'
import ClosableDialog from 'components/reusable/ClosableDialog'

const StyledClosableDialog = props => {
  return (
    <ClosableDialog iconStyle={{ color: '#fff' }} {...props}/>
  )
}

export default StyledClosableDialog
