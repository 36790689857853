// External dependencies.
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
// Material UI dependencies.
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
// Icons
// Project internal dependencies.
import { GrantTypes } from 'types/grants'
import { getUsers, getLoggedUser, getActiveUser, isAdmin } from 'modules/users/selectors'
import { getGrantsForUser } from 'modules/grants/selectors'
import { isActiveSubscription } from 'utils/subscription'
import { getCompanyId, isUserAdmin } from 'utils/company'
import { getCompanySubscriptions, isGetCompanySubscriptionsLoading } from 'modules/companies/selectors'
// Local deps
import SubscriptionStatus from './SubscriptionStatus'
import { findById } from 'utils/list'

const styles = theme => ({
  ...theme.global,
  list: {
    borderBottom: '1px solid #efefef',
  },
  container: {
    // paddingLeft: theme.spacing(2),
    // fontSize: '0.8125rem',
  },
  text: {
    color: theme.palette.grey.dark,
  },
  secondaryText: {
    display: 'inline-block',
    color: theme.palette.grey.alternative,
  },
  secondaryStatusText: {
    maxWidth: 280,
  },
  item: {
    paddingLeft: theme.spacing(2),
    paddingRight: 0,
  },
})

const SubscriptionStatusAndQuota = props => {
  const {
    isSubscriptionsLoading,
    hasOngoingSubscription,
    canDownloadFiles,
    expirationDate,
    isUserAdmin,
    classes,
  } = props
  return (
    <List disablePadding className={classes.list}>
      <SubscriptionStatus
        isAdmin={isUserAdmin}
        isSubscriptionsLoading={isSubscriptionsLoading}
        hasOngoingSubscription={hasOngoingSubscription}
        canDownloadFiles={canDownloadFiles}
        expirationDate={expirationDate}
        classes={classes}
      />
    </List>
  )
}

const mapStateToProps = (state, props) => {
  const { userId, companyId: companyIdFromProps } = props
  const loggedUser = getLoggedUser(state)
  const activeUser = getActiveUser(state)
  const currentUser = loggedUser && userId === loggedUser.id
    ? loggedUser
    : activeUser && userId === activeUser.id
      ? activeUser
      : findById(userId, getUsers(state))
  const isCurrentUserAdmin = isUserAdmin(currentUser)
  const loggedUserId = loggedUser && loggedUser.id
  const companyId = companyIdFromProps || getCompanyId(currentUser)

  const grantsForUser = getGrantsForUser(state)
  // const grantListRequestStatuses = getStatuses(state)

  // TODO: Only go through artifacts created since the start of
  // the current billing period.
  const secondsInMotion = 0
  const minutesInMotion = Math.ceil(secondsInMotion / 60)

  // Grants.
  const { navlabUsed, navlabMax, grants: userGrants } =
    (isAdmin(state) ? grantsForUser[userId] : grantsForUser[loggedUserId]) ||
    { navlabUsed: 0, navlabMax: 0, grants: [] }
  const grants = userGrants.sort((grant1, grant2) => grant2.created.getTime() - grant1.created.getTime())
  // TODO check for date?
  const fdownloadGrants = grants.filter(
    grant => grant.grantType === GrantTypes.FILE_DOWNLOADING && (grant.value > 0 || grant.value === -1))
  const [downloadingGrant] = fdownloadGrants
  const navProcessingLimitGrants =
    grants.filter(grant => grant.grantType === GrantTypes.NAVIGATION_ROVER_PROCESSING_LIMIT)
  const navProcessingLimitGrant = navProcessingLimitGrants
    .reduce((max, grant) => grant.created > max.created ? grant : max, navProcessingLimitGrants[0])

  const subscriptions = companyId ? getCompanySubscriptions(state, companyId) : []
  const isSubscriptionsLoading = companyId ? isGetCompanySubscriptionsLoading(state, companyId) : false
  const hasOngoingSubscription = subscriptions.some(sub => isActiveSubscription(sub.subscription_state))
  return {
    isUserAdmin: isCurrentUserAdmin,
    userId,
    hasOngoingSubscription, // currentUser && currentUser.has_ongoing_subscription,
    isSubscriptionsLoading,
    canDownloadFiles: typeof downloadingGrant !== 'undefined',
    expirationDate: typeof downloadingGrant !== 'undefined' && downloadingGrant.endDate,
    minutesInMotion,
    navlabUsed,
    navlabMax,
    navProcessingLimitGrant,
  }
}

SubscriptionStatusAndQuota.propTypes = {
  isUserAdmin: PropTypes.bool,
  canDownloadFiles: PropTypes.bool,
  hasOngoingSubscription: PropTypes.bool,
  isSubscriptionsLoading: PropTypes.bool,
  expirationDate: PropTypes.any,
  classes: PropTypes.object,
  navProcessingLimitGrant: PropTypes.object,
  navlabMax: PropTypes.number,
  navlabUsed: PropTypes.number,
  minutesInMotion: PropTypes.number,
}

export default connect(mapStateToProps)(withStyles(styles)(SubscriptionStatusAndQuota))
