// External dependencies.
import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
// Material UI dependencies.
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
// Project deps
import { tabMap, getTabPath } from 'templates/tabs'

const Navigation = props => {
  const { fullWidth = false } = props
  return (
    <Switch>
      <Route path='/error' component={renderNav.bind(this, props, undefined, undefined, fullWidth)} />
      <Route path='/dashboard' component={renderNav.bind(this, props, 'dashboard', undefined, fullWidth)} />
      <Route path='/payments' component={renderNav.bind(this, props, 'payments', undefined, fullWidth)} />
      <Route path='/company/:company_id' component={renderNav.bind(this, props, 'company', 'company_id', fullWidth)} />
      <Route path='/users/:user_id' component={renderNav.bind(this, props, 'users', 'user_id', fullWidth)} />
      <Route path='/users' component={renderNav.bind(this, props, undefined, undefined, fullWidth)} />
      { fullWidth && <Route path='/' component={renderNav.bind(this, props)} /> }
    </Switch>
  )
}

Navigation.propTypes = {
  fullWidth: PropTypes.bool,
  isUserAdmin: PropTypes.bool,
}

function renderNav (props, basicUrl, idFieldName, isFullWidth = false) {
  const { match, classes, tabsValue, onTabsChange } = props
  const tabs = tabMap[basicUrl]
  return (
    tabs && tabs.length > 0 ? (
      <Tabs
        value={tabsValue}
        onChange={onTabsChange}
        variant='scrollable'
        // style={{ marginTop: 10 }}
        textColor='inherit'>
        { tabs.map((tab, tabIndex) => {
          const { label, display = true, redirect = false } = tab
          const path = getTabPath(tab.path, match.params[idFieldName], props)
          const shouldDisplay = typeof display === 'function' ? display(props) : display
          return (
            <Tab
              id={path}
              key={path}
              textColor='inherit'
              component='a'
              href={path}
              label={typeof label === 'function' ? label(props) : label}
              value={redirect || tabIndex}
              style={{ display: shouldDisplay ? 'inline-flex' : 'none' }}
              // style={{ wordBreak: isFullWidth ? 'inherit' : 'break-word' }}
              classes={{
                root: classes.tabsRoot,
                selected: classes.tabsSelected,
              }}
            />
          )
        })}
      </Tabs>
    ) : <div></div>
  )
}

renderNav.propTypes = {
  match: PropTypes.object,
  classes: PropTypes.object,
  tabsValue: PropTypes.string,
  onResize: PropTypes.func,
  onTabsChange: PropTypes.func,
}

export default Navigation
