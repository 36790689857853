
import { isImageFile, getFileExtension } from 'utils/files'
import { DocumentType } from 'types/roverEvents'

export const convertRawAttachment = raw => {
  const extension = (getFileExtension(raw.file || '') || '').toLowerCase()
  const isImage = isImageFile(extension)
  return {
    ...raw,
    extension,
    ext: extension,
    isImage,
    uploaded: true,
    thumbnail: isImage && raw.document_type === DocumentType.THUMBNAIL,
  }
}
