import React from 'react'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { StripeProvider } from 'react-stripe-elements'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// Project deps
import App from 'components/App/App'
import i18next from 'i18n'
import config from 'config'
// Local deps
import * as serviceWorker from './serviceWorker'
import createStore from './store'
import './style.css'

const { store, persistor, history } = createStore()

class Main extends React.Component {
  constructor (props) {
    super(props)
    this.state = { stripe: null }
  }
  componentDidMount () {
    const onLoadNBP = function () {
      window.NBP.init('mostcommon_100000', `${process.env.PUBLIC_URL}/libs/collections/`, false)
    }
    if (window.NBP) {
      onLoadNBP()
    } else {
      document.querySelector('#nbp').addEventListener('load', onLoadNBP)
    }

    if (window.Stripe) {
      this.setState({ stripe: window.Stripe(config.STRIPE_API_KEY) })
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({ stripe: window.Stripe(config.STRIPE_API_KEY) })
      })
    }
  }
  render () {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <I18nextProvider i18n={i18next}>
              <Router history={history}>
                <StripeProvider stripe={this.state.stripe}>
                  <App/>
                </StripeProvider>
              </Router>
              <ToastContainer autoClose={5000} />
            </I18nextProvider>
          </PersistGate>
        </Provider>
      </MuiPickersUtilsProvider>
    )
  }
}

ReactDOM.render(<Main/>, document.getElementById('root'))
serviceWorker.unregister()
