import React, { useState } from 'react'
import { v4 } from 'uuid'
import { useDispatch, useSelector } from 'react-redux'
// Material UI
import { Dialog, DialogContent, DialogActions, Button, DialogTitle } from '@material-ui/core'
import { getShowRegisterSystemDialogState, isRegisterSystemLoading } from 'modules/rovers/selectors'
import RoverActions from 'modules/rovers/actions'
import TemplatedForm from 'components/reusable/TemplatedForm'
import { UserSaleEvent } from 'templates/AddUserRoverEventTemplate'
import LoadingButton from 'components/reusable/LoadingButton'
import { getBackendTransformedValues } from 'utils/templates'
import { getLoggedUser } from 'modules/users/selectors'
import { getCompanyId } from 'utils/company'

const RegisterSystemDialog = () => {
  const showRegisterSystemDialogState = useSelector(state => getShowRegisterSystemDialogState(state))
  const loggedUser = useSelector(state => getLoggedUser(state))
  const dispatch = useDispatch()
  const setRegisterSystemDialog = () => dispatch(RoverActions.setRegisterSystemDialog(false))
  const registerSystem = (companyId, data, onSuccess) => dispatch(RoverActions.registerSystem(companyId, data, onSuccess))
  const [key, setKey] = useState(v4())
  const [eventValues, setEventValues] = useState(undefined)
  const isValid = eventValues && eventValues.valid
  const state = useSelector(state => state)
  const isLoading = useSelector(state => isRegisterSystemLoading(state))
  const { show } = showRegisterSystemDialogState

  const onClose = () => {
    setRegisterSystemDialog()
  }
  const onChangeEventValues = (values, valid) => {
    setEventValues(prevState => ({
      ...prevState,
      ...values,
      valid,
    }))
  }
  const reinitializeForm = () => {
    setEventValues(undefined)
    setKey(v4())
  }
  const onSubmit = () => {
    const transformedValues = getBackendTransformedValues(undefined, state, eventValues, eventValues, UserSaleEvent)
    registerSystem(getCompanyId(loggedUser), transformedValues, reinitializeForm)
  }

  return (
    <Dialog maxWidth='sm' fullWidth open={show} onClose={onClose}>
      <DialogTitle>Register system</DialogTitle>
      <DialogContent>
        <TemplatedForm
          key={key}
          formTemplate={UserSaleEvent}
          state={state}
          values={eventValues}
          valid={isValid}
          onChange={onChangeEventValues}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton disabled={!isValid} isLoading={isLoading} color='primary' onClick={onSubmit}>Register system</LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default RegisterSystemDialog
