import { Map } from 'immutable'

export const INITIAL_STATE = Map({
  registerIsLoading: false,
  registerErrorMessage: null,
  // All available companies
  companies: [],
  getCompaniesIsLoading: false,
  getCompaniesErrorMessage: null,

  // Current company users
  users: [],
  getUsersIsLoading: false,
  getUsersErrorMessage: null,

  // Current company rovers
  rovers: {},
  getRoversIsLoading: {},
  getRoversErrorMessage: null,
  addedRovers: {},

  // Current company positions
  positions: {},
  positionsLoading: {},
  addPositionIsLoading: false,
  deletePosition: {},

  companyProjects: {},
  // Current company projects
  projects: {},
  getProjectsIsLoading: false,
  getProjectsErrorMessage: null,

  // Current company info
  currentCompany: {},
  getCompanyIsLoading: false,

  // Last generated invite url
  inviteUrl: {},
  generateInviteUrlIsLoading: {},
  generateInviteUrlErrorMessage: null,

  updateCompanyIsLoading: false,
  updateCompanyErrorMessage: null,
  updateCompanySucceed: false,

  deleteCompanyIsLoading: false,
  deleteCompanyErrorMessage: null,

  systemTypesByCompany: {},
  systemTypesByCompanyLoading: {},

  selectedCompanyFilters: {},
  subscriptionsByCompanyFilters: {},
  subscriptionsByCompany: {},
  subscriptionsByCompanyLoading: {},

  permissionsByCompany: {},
  permissionsByCompanyLoading: {},

  updateSubscriptionIsLoading: false,

  checkTokenIsLoading: {},
  isTokenValid: {},

  getCompanyDBLogsLoading: false,
  getCompanyDBLogsPagination: {},
  companyLogs: {},

  productUpdates: {},
  getProductUpdatesLoading: {},
  childCompaniesIsLoading: {},
  childCompanies: {},
  loggedUserCompany: null,

  companyWideMaxVersions: [],
  getWideMaxVersionIsLoading: false,
})
