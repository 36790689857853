import React from 'react'
import { useSelector } from 'react-redux'
// Material UI
import { Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core'
// Project deps
import TwoFactorAuth from 'components/Auth/TwoFactorAuth'
import { getLoggedUser, isAdmin } from 'modules/users/selectors'

const Check2FADialog = props => {
  const loggedUser = useSelector(state => getLoggedUser(state))
  const isUserAdmin = useSelector(state => isAdmin(state))

  if (!loggedUser || !loggedUser.id || loggedUser.use_2fa || (!loggedUser.use_2fa && !isUserAdmin)) {
    return null
  }
  return (
    <Dialog open maxWidth='md' fullWidth>
      <DialogTitle>Enable Two-Factor Authentication (2FA)</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography>Two-factor authentication is disabled for your account, we ask you to enable it</Typography>
          </Grid>
        </Grid>
        <TwoFactorAuth force2fa userId={loggedUser.id} user={loggedUser}/>
      </DialogContent>
    </Dialog>
  )
}

export default Check2FADialog
