import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
// Material UI
// Project deps
import { isGetUserDBLogsLoading, getUserDBLogs as getUserLogs, getUserDBLogsPagination } from 'modules/users/selectors'
import UsersActions from 'modules/users/actions'
import LogsDialog from 'components/reusable/LogsDialog'

const ShowUserLogsDialog = props => {
  const { open, onClose, userId, showRelated } = props
  const dispatch = useDispatch()
  const getUserDBLogs = userId => (search, pageSize, page, orderBy, order) =>
    dispatch(UsersActions.getUserDBLogs(userId, { search, pageSize, page, orderBy, order }))

  return (
    <LogsDialog
      id={userId}
      getStateLogs={getUserLogs}
      getLogsPagination={getUserDBLogsPagination}
      getLogs={getUserDBLogs}
      getIsLoadingState={isGetUserDBLogsLoading}
      title='User DB logs'
      open={open}
      onClose={onClose}
      showRelated={showRelated}
    />
  )
}

ShowUserLogsDialog.propTypes = {
  open: PropTypes.bool,
  showRelated: PropTypes.bool,
  userId: PropTypes.string,
  onClose: PropTypes.func,
  event: PropTypes.object,
}

export default ShowUserLogsDialog
