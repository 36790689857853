import React from 'react'
import PropTypes from 'prop-types'
// Material UI
import { Button, TextField, InputAdornment, IconButton } from '@material-ui/core'
// Icons
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
// Project deps
import { getFormFieldErrorMessage } from '../utils'
/**
 * Renders a integral number input field.
 * @param name The name of the field.
 * @param option The field definition.
 * @return A component for inputting integer data.
 */
const LinksOption = props => {
  const {
    option,
    // disabled,
    values,
    name,
    state,
    extra,
    formTemplate,
    setValue,
  } = props
  const { newItem = 'text', fieldName = '', placeholder = '', addButtonLabel = 'Add new item' } = option
  const value = values[name]
  const onAddNewItem = e => {
    setValue(name, [...value, newItem], option)
  }

  const onRemoveItem = index => e => {
    const newValue = [...value]
    newValue.splice(index, 1)
    setValue(name, newValue, option)
  }
  const onChange = index => e => {
    const { value: textValue } = e.target
    setValue(name, value.map((v, i) => i === index ? textValue : v), option)
  }
  return (
    <div>
      {
        value.map((value, index) => {
          const errorMessage = getFormFieldErrorMessage(value, option, values, state, extra, formTemplate, props)
          const error = Boolean(errorMessage)
          return (
            <div style={{ display: 'flex', marginBottom: 8 }} key={name + '-' + index}>
              <TextField
                onChange={onChange(index)}
                value={fieldName ? value[fieldName] : value}
                fullWidth
                variant='outlined'
                error={error}
                helperText={errorMessage}
                style={{ flex: 1 }}
                placeholder={placeholder}
                InputProps={{
                  /*
                  classes: {
                    input: classes.code,
                  },
                  */
                  endAdornment: <InputAdornment position='end'>
                    <IconButton
                      onClick={onRemoveItem(index)}
                      size='small'
                    >
                      <DeleteIcon/>
                    </IconButton>
                  </InputAdornment>,
                }}
              />
            </div>
          )
        })
      }
      <Button
        startIcon={<AddIcon/>}
        fullWidth
        style={{
          background: '#e0e0e0',
          color: '#6a6a6a',
        }}
        onClick={onAddNewItem}
      >
        {addButtonLabel}
      </Button>
    </div>
  )
}

LinksOption.propTypes = {
  state: PropTypes.object,
  extra: PropTypes.any,
  name: PropTypes.string,
  option: PropTypes.object,
  disabled: PropTypes.bool,
  classes: PropTypes.object,
  values: PropTypes.object,
  setValue: PropTypes.func,
  formTemplate: PropTypes.object,
}

export default LinksOption
