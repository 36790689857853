import { createReducer } from 'reduxsauce'
import { INITIAL_STATE } from './initialState'
import { GrantsTypes } from './actions'

function filterGrantPermission (newGrant, state) {
  return state.get('grantPermissions').filter(({ grant }) =>
    grant.userId !== newGrant.userId ||
    grant.grantType !== newGrant.grantType ||
    grant.context !== newGrant.context,
  )
}

// Check grant
export const checkGrantLoading = (state, { grant }) => {
  const grantPermissions = filterGrantPermission(grant, state)
  return state.merge({
    checkGrantLoading: true,
    checkGrantErrorMessage: null,
    grantPermissions: [
      ...grantPermissions, {
        grant,
        isLoading: true,
      },
    ],
  })
}

export const checkGrantSuccess = (state, { grant, allowed }) => {
  const grantPermissions = filterGrantPermission(grant, state)
  return state.merge({
    checkGrantLoading: false,
    checkGrantErrorMessage: null,
    grantPermissions: [
      ...grantPermissions, {
        grant,
        allowed,
        isLoading: false,
      },
    ],
  })
}

export const checkGrantFailure = (state, { errorMessage }) =>
  state.merge({
    checkGrantLoading: false,
    checkGrantErrorMessage: errorMessage,
  })

export const checkGrantsLoading = (state, { key }) => {
  return state.merge({
    checkGrantsLoading: {
      ...state.get('checkGrantsLoading'),
      ...(key && { [key]: true }),
    },
  })
}

export const checkGrantsSuccess = (state, { grants, key }) => {
  const firstGrant = grants[0].grant
  const grantPermissions = state.get('grantPermissions').filter(({ grant }) =>
    grant.userId !== firstGrant.userId ||
    grant.grantType !== firstGrant.grantType ||
    grant.context !== firstGrant.context,
  )
  return state.merge({
    checkGrantLoading: false,
    checkGrantErrorMessage: null,
    checkGrantsLoading: {
      ...state.get('checkGrantsLoading'),
      ...(key && { [key]: false }),
    },
    grantPermissions: [
      ...grantPermissions,
      ...grants.map(grant => ({ ...grant, isLoading: false })),
    ],
  })
}

export const checkGrantsFailure = (state, { key, errorMessage }) => {
  return state.merge({
    checkGrantLoading: false,
    checkGrantErrorMessage: errorMessage,
    checkGrantsLoading: {
      ...state.get('checkGrantsLoading'),
      ...(key && { [key]: false }),
    },
  })
}

// List grants
export const getGrantsLoading = (state, { userId }) =>
  state.merge({
    getGrantsLoading: true,
    getGrantsErrorMessage: null,
  })

export const getGrantsSuccess = (state, { grants, navlabUsed, navlabMax, userId }) => {
  const grantStateItem = { grants, navlabUsed, navlabMax }
  const grantsForUser = {
    ...state.get('grantsForUser'),
    [userId]: grantStateItem,
  }
  return state.merge({
    getGrantsLoading: false,
    getGrantsErrorMessage: null,
    grantsForUser,
  })
}

export const getGrantsFailure = (state, { errorMessage, userId }) =>
  state.merge({
    getGrantsLoading: false,
    getGrantsErrorMessage: errorMessage,
  })

// Get billingAgreement
export const getGrantsForActiveUserLoading = state =>
  state.merge({
    getGrantsForActiveUserIsLoading: true,
    getGrantsForUserErrorMessage: '',
  })

export const getGrantsForActiveUserSuccess = state =>
  state.merge({
    getGrantsForActiveUserIsLoading: false,
  })

export const getGrantsForActiveUserFailure = (state, { errorMessage }) =>
  state.merge({
    getGrantsForActiveUserIsLoading: false,
    getGrantsForActiveUserErrorMessage: errorMessage,
  })

// Create grant
export const createGrantLoading = state =>
  state.merge({
    createGrantLoading: true,
    createGrantErrorMessage: null,
  })

export const createGrantSuccess = (state, { grant, userId }) => {
  return state.merge({
    createGrantLoading: false,
    createGrantErrorMessage: null,
  })
}

export const createGrantFailure = (state, { errorMessage }) =>
  state.merge({
    createGrantLoading: false,
    createGrantErrorMessage: errorMessage,
  })

export const deleteGrantLoading = state =>
  state.merge({
    deleteGrantLoading: true,
    createGrantErrorMessage: null,
  })

export const deleteGrantSuccess = (state, { grant, userId }) => {
  return state.merge({
    deleteGrantLoading: false,
    createGrantErrorMessage: null,
  })
}

export const deleteGrantFailure = (state, { errorMessage }) =>
  state.merge({
    deleteGrantLoading: false,
    createGrantErrorMessage: errorMessage,
  })

export const reducer = createReducer(INITIAL_STATE, {
  [GrantsTypes.CHECK_GRANT_LOADING]: checkGrantLoading,
  [GrantsTypes.CHECK_GRANT_SUCCESS]: checkGrantSuccess,
  [GrantsTypes.CHECK_GRANT_FAILURE]: checkGrantFailure,

  [GrantsTypes.CHECK_GRANTS_LOADING]: checkGrantsLoading,
  [GrantsTypes.CHECK_GRANTS_SUCCESS]: checkGrantsSuccess,
  [GrantsTypes.CHECK_GRANTS_FAILURE]: checkGrantsFailure,

  [GrantsTypes.CREATE_GRANT_LOADING]: createGrantLoading,
  [GrantsTypes.CREATE_GRANT_SUCCESS]: createGrantSuccess,
  [GrantsTypes.CREATE_GRANT_FAILURE]: createGrantFailure,

  [GrantsTypes.DELETE_GRANT_LOADING]: deleteGrantLoading,
  [GrantsTypes.DELETE_GRANT_SUCCESS]: deleteGrantSuccess,
  [GrantsTypes.DELETE_GRANT_FAILURE]: deleteGrantFailure,

  [GrantsTypes.GET_GRANTS_LOADING]: getGrantsLoading,
  [GrantsTypes.GET_GRANTS_SUCCESS]: getGrantsSuccess,
  [GrantsTypes.GET_GRANTS_FAILURE]: getGrantsFailure,

  [GrantsTypes.GET_GRANTS_FOR_ACTIVE_USER_LOADING]: getGrantsForActiveUserLoading,
  [GrantsTypes.GET_GRANTS_FOR_ACTIVE_USER_SUCCESS]: getGrantsForActiveUserSuccess,
  [GrantsTypes.GET_GRANTS_FOR_ACTIVE_USER_FAILURE]: getGrantsForActiveUserFailure,
})
