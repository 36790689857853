import React from 'react'
import PropTypes from 'prop-types'
// import { DatePicker } from 'material-ui-pickers'
// Material UI
import TextField from '@material-ui/core/TextField'
// Icons
// Project deps
import { withMaxPrecision } from 'utils/numeric'
import { getValue } from 'utils/templatedForm'
import { getFormFieldErrorMessage } from '../utils'

function getTransformedValue (rawValue, precision) {
  // Maybe there is some more clever way to do this
  // TODO: improve
  const value = typeof rawValue === 'string'
    ? rawValue.split(',').reduce((allStrings, str) => {
      const matched = str.match(new RegExp(`(\\d\\d{0,4})(\\.\\d{0,${precision}})?`, 'g'))
      return matched
        ? matched.length === 1
          ? [...allStrings, str]
          : [...allStrings, withMaxPrecision(matched[0], precision)]
        : allStrings
    }, []).join(',')
    : ''
  return value
}

/**
 * Renders a multi-floating point number input field.
 * Multi means that user can enter multiple float values separated by the coma
 * @param name The name of the field.
 * @param option The field definition.
 * @return A component for inputting floating point data.
 */
class MultiFloatOption extends React.Component {
  constructor (props) {
    super(props)
    const value = this.getPrecisedValue(props)
    this.state = {
      value,
      originalValue: value,
    }
  }

  componentDidUpdate () {
    const newValue = this.getPrecisedValue(this.props)
    if (this.state.originalValue !== newValue) {
      this.setState({
        value: newValue,
        originalValue: newValue,
      })
    }
  }

  getPrecisedValue = props => {
    const { option } = props
    const rawValue = getValue(props)
    const precision = typeof option.precision === 'number' ? option.precision : 2
    return getTransformedValue(rawValue, precision)
  }

  onChange = event => {
    const stringValue = event.target.value
    // Break the function if input character is not a digit or ',' or '.'
    if (!(/\d|\.|,/).test(stringValue[stringValue.length - 1]) && stringValue.length > 0) return
    this.setState({ value: stringValue })
  }

  callOnChange = value => {
    const { originalValue } = this.state
    const { setValue = () => {}, option, name } = this.props
    const { onChange: onChangeCoordinate } = option
    if (originalValue !== value) {
      const precision = typeof option.precision === 'number' ? option.precision : 2
      const transformedValue = getTransformedValue(value, precision)
      if (typeof onChangeCoordinate === 'function') onChangeCoordinate(name, transformedValue, this.props)
      setValue(name, transformedValue, option)
      this.setState({ value: transformedValue })
    }
  }

  onBlur = event => {
    const { value } = this.state
    this.callOnChange(value)
  }
  onKeyDown = event => {
    if (event.keyCode === 13 && this.input) this.input.blur()
  }
  setRef = node => {
    this.input = node
  }

  render () {
    const { value } = this.state
    const {
      state,
      extra,
      option,
      disabled,
      values: formValues,
      formTemplate,
    } = this.props
    const { extraRender } = option

    const errorMessage = getFormFieldErrorMessage(value, option, formValues, state, extra, formTemplate, this.props)
    const error = Boolean(errorMessage)

    return (
      <React.Fragment>
        <TextField
          inputRef={this.setRef}
          label={error ? errorMessage : option.name}
          placeholder={error ? option.name : ''}
          error={error}
          value={value}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onKeyDown={this.onKeyDown}
          disabled={disabled}
          fullWidth
        />
        { typeof extraRender === 'function' && extraRender(state, formValues, extra, this.props) }
      </React.Fragment>
    )
  }
}

MultiFloatOption.propTypes = {
  value: PropTypes.string,
  label: PropTypes.any,
  state: PropTypes.object,
  extra: PropTypes.any,
  name: PropTypes.string,
  option: PropTypes.object,
  disabled: PropTypes.bool,
  classes: PropTypes.object,
  values: PropTypes.object,
  setValue: PropTypes.func,
  formTemplate: PropTypes.object,
}

export default MultiFloatOption
