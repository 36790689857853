import { createReducer } from 'reduxsauce'
import { omit, path } from 'ramda'
// Project deps
import { findById, mapById, removeById } from 'utils/list'
import { convertRawUser } from 'types/users'
import { UsersTypes } from 'modules/users/actions'
// Local deps
import { INITIAL_STATE } from './initialState'
import { CompaniesTypes } from './actions'
import { KeyTypes } from 'modules/keys/actions'
import { convertRawSubscription, convertSubscriptionSalesProduct } from 'types/billingAgreement'
import { convertRawRover } from 'types/rovers'
import { BillingAgreementTypes } from 'modules/billingAgreement/actions'
import { convertRawLog } from 'types/logs'
import { convertRawCompany } from 'types/companies'

export const getCompaniesLoading = state =>
  state.merge({
    getCompaniesIsLoading: true,
    getCompaniesErrorMessage: '',
  })

export const getCompaniesSuccess = (state, { companies }) =>
  state.merge({
    companies: companies.map(convertRawCompany),
    getCompaniesIsLoading: false,
    getCompaniesErrorMessage: null,
  })

export const getCompaniesFailure = (state, { errorMessage }) =>
  state.merge({
    companies: [],
    getCompaniesIsLoading: false,
    getCompaniesErrorMessage: errorMessage,
  })

export const getChildCompaniesLoading = (state, { companyId }) =>
  state.merge({
    childCompaniesIsLoading: {
      ...state.get('childCompaniesIsLoading'),
      [companyId]: true,
    },
  })

export const getChildCompaniesSuccess = (state, { companyId, companies }) =>
  state.merge({
    childCompanies: {
      ...state.get('childCompanies'),
      [companyId]: companies.map(convertRawCompany),
    },
    childCompaniesIsLoading: {
      ...state.get('childCompaniesIsLoading'),
      [companyId]: false,
    },
  })

export const getChildCompaniesFailure = (state, { companyId, errorMessage }) =>
  state.merge({
    childCompaniesIsLoading: {
      ...state.get('childCompaniesIsLoading'),
      [companyId]: false,
    },
  })

export const getCompanyUsersLoading = state =>
  state.merge({
    getUsersIsLoading: true,
    getUsersErrorMessage: '',
  })

export const getCompanyUsersSuccess = (state, { users }) =>
  state.merge({
    users: users.map(convertRawUser),
    getUsersIsLoading: false,
    getUsersErrorMessage: null,
  })

export const getCompanyUsersFailure = (state, { errorMessage }) =>
  state.merge({
    users: [],
    getUsersIsLoading: false,
    getUsersErrorMessage: errorMessage,
  })

export const getCompanyRoversLoading = (state, { companyId }) =>
  state.merge({
    getRoversIsLoading: {
      ...state.get('getRoversIsLoading'),
      [companyId]: true,
    },
    getRoversErrorMessage: '',
  })

export const getCompanyRoversSuccess = (state, { companyId, rovers }) =>
  state.merge({
    rovers: {
      ...state.get('rovers'),
      [companyId]: rovers.map(convertRawRover),
    },
    getRoversIsLoading: {
      ...state.get('getRoversIsLoading'),
      [companyId]: false,
    },
    getRoversErrorMessage: null,
  })

export const getCompanyRoversFailure = (state, { companyId, errorMessage }) =>
  state.merge({
    getRoversIsLoading: {
      ...state.get('getRoversIsLoading'),
      [companyId]: false,
    },
    getRoversErrorMessage: errorMessage,
  })

export const getCompanyLoading = state =>
  state.merge({
    getCompanyIsLoading: true,
    getCompanyErrorMessage: '',
  })

export const getCompanySuccess = (state, { company }) => {
  const loggedUserCompany = state.get('loggedUserCompany')
  return state.merge({
    loggedUserCompany: loggedUserCompany && loggedUserCompany.id === company.id ? convertRawCompany(company) : loggedUserCompany,
    currentCompany: company,
    getCompanyIsLoading: false,
    getCompanyErrorMessage: null,
  })
}

export const getCompanyFailure = (state, { errorMessage }) =>
  state.merge({
    currentCompany: {},
    getCompanyIsLoading: false,
    getCompanyErrorMessage: errorMessage,
  })

export const getLoggedUserCompanyLoading = state =>
  state.merge({
    getCompanyIsLoading: true,
    getCompanyErrorMessage: '',
  })

export const getLoggedUserCompanySuccess = (state, { company }) =>
  state.merge({
    loggedUserCompany: convertRawCompany(company),
    getCompanyIsLoading: false,
    getCompanyErrorMessage: null,
  })

export const getLoggedUserCompanyFailure = (state, { errorMessage }) =>
  state.merge({
    loggedUserCompany: null,
    getCompanyIsLoading: false,
    getCompanyErrorMessage: errorMessage,
  })

export const updateCompanyLoading = state =>
  state.merge({
    updateCompanySucceed: false,
    updateCompanyIsLoading: true,
    updateCompanyErrorMessage: '',
  })

export const updateCompanySuccess = (state, { company }) => {
  const loggedUserCompany = state.get('loggedUserCompany')
  return state.merge({
    loggedUserCompany: loggedUserCompany && loggedUserCompany.id === company.id ? convertRawCompany(company) : loggedUserCompany,
    updateCompanySucceed: true,
    currentCompany: company,
    updateCompanyIsLoading: false,
    updateCompanyErrorMessage: null,
  })
}

export const updateCompanyFailure = (state, { errorMessage }) =>
  state.merge({
    updateCompanySucceed: false,
    currentCompany: {},
    updateCompanyIsLoading: false,
    updateCompanyErrorMessage: errorMessage,
  })

export const generateInviteUrlLoading = (state, { companyId }) =>
  state.merge({
    generateInviteUrlIsLoading: {
      [companyId]: true,
    },
    generateInviteUrlErrorMessage: '',
  })

export const generateInviteUrlSuccess = (state, { companyId }) =>
  state.merge({
    generateInviteUrlIsLoading: {
      [companyId]: false,
    },
    generateInviteUrlErrorMessage: null,
  })

export const generateInviteUrlFailure = (state, { errorMessage, companyId }) =>
  state.merge({
    generateInviteUrlIsLoading: {
      [companyId]: false,
    },
    generateInviteUrlErrorMessage: errorMessage,
  })

export const deleteCompanyLoading = state =>
  state.merge({
    deleteCompanyIsLoading: true,
    deleteCompanyErrorMessage: '',
  })

export const deleteCompanySuccess = (state, { companyId }) =>
  state.merge({
    currentCompany: {},
    deleteCompanyIsLoading: false,
    deleteCompanyErrorMessage: null,
    companies: removeById(companyId, state.get('companies')),
  })

export const deleteCompanyFailure = (state, { errorMessage }) =>
  state.merge({
    currentCompany: {},
    deleteCompanyIsLoading: false,
    deleteCompanyErrorMessage: errorMessage,
  })

export const getCompanySystemTypesLoading = (state, { companyId }) => {
  return state.merge({
    systemTypesByCompanyLoading: {
      ...state.get('systemTypesByCompanyLoading'),
      [companyId]: true,
    },
  })
}

export const getCompanySystemTypesSuccess = (state, { companyId, systemTypes }) => {
  return state.merge({
    systemTypesByCompany: {
      ...state.get('systemTypesByCompany'),
      [companyId]: systemTypes,
    },
    systemTypesByCompanyLoading: {
      ...state.get('systemTypesByCompanyLoading'),
      [companyId]: false,
    },
  })
}

export const getCompanySystemTypesFailure = (state, { companyId }) => {
  return state.merge({
    systemTypesByCompanyLoading: {
      ...state.get('systemTypesByCompanyLoading'),
      [companyId]: false,
    },
  })
}

export const getCompanySubscriptionsLoading = (state, { companyId }) => {
  return state.merge({
    subscriptionsByCompanyLoading: {
      ...state.get('subscriptionsByCompanyLoading'),
      [companyId]: true,
    },
  })
}

export const getCompanySubscriptionsSuccess = (state, { companyId, subscriptions, salesProducts }) => {
  return state.merge({
    subscriptionsByCompany: {
      ...state.get('subscriptionsByCompany'),
      [companyId]: subscriptions.map(subscription => convertRawSubscription(subscription, salesProducts)),
    },
    subscriptionsByCompanyLoading: {
      ...state.get('subscriptionsByCompanyLoading'),
      [companyId]: false,
    },
  })
}

export const getCompanySubscriptionSuccess = (state, { companyId, subscriptionId, subscription, salesProducts }) => {
  const subscriptions = state.get('subscriptionsByCompany')
  const companySubscriptions = subscriptions[companyId]
  const shouldAdd = !companySubscriptions.find(sub => sub.id === subscriptionId)
  const convertedSubscription = convertRawSubscription(subscription, salesProducts)
  return state.merge({
    subscriptionsByCompany: {
      ...subscriptions,
      [companyId]: shouldAdd
        ? [convertedSubscription, ...companySubscriptions]
        : mapById(subscriptionId, companySubscriptions, () => convertedSubscription),
    },
    subscriptionsByCompanyLoading: {
      ...state.get('subscriptionsByCompanyLoading'),
      [companyId]: false,
    },
  })
}

export const getCompanySubscriptionsByFiltersLoading = (state, { companyId, filters }) => {
  return state.merge({
    subscriptionsByCompanyFiltersLoading: {
      ...state.get('subscriptionsByCompanyFiltersLoading'),
      [companyId]: true,
    },
    selectedCompanyFilters: {
      ...state.get('selectedCompanyFilters'),
      [companyId]: filters,
    },
  })
}

export const getCompanySubscriptionsByFiltersFailure = (state, { companyId }) => {
  return state.merge({
    subscriptionsByCompanyFiltersLoading: {
      ...state.get('subscriptionsByCompanyFiltersLoading'),
      [companyId]: false,
    },

  })
}

export const getCompanySubscriptionsByFiltersSuccess = (state, { companyId, filters, subscriptions, salesProducts }) => {
  return state.merge({
    subscriptionsByCompanyFiltersLoading: {
      ...state.get('subscriptionsByCompanyFiltersLoading'),
      [companyId]: false,
    },
    subscriptionsByCompanyFilters: {
      ...state.get('subscriptionsByCompanyFilters'),
      [companyId]: subscriptions.map(sub => convertRawSubscription(sub, salesProducts)),
    },
  })
}

export const getCompanySubscriptionsFailure = (state, { companyId }) => {
  return state.merge({
    subscriptionsByCompanyLoading: {
      ...state.get('subscriptionsByCompanyLoading'),
      [companyId]: false,
    },
  })
}

export const addCompanySystemTypeFailure = state => state.merge({})
export const addCompanySystemTypeLoading = state => state.merge({})
export const addCompanySystemTypeSuccess = (state, { companyId, systemId, systemType }) => {
  const systemTypesByCompany = state.get('systemTypesByCompany')
  return state.merge({
    systemTypesByCompany: {
      ...systemTypesByCompany,
      [companyId]: [
        ...(systemTypesByCompany[companyId] || []),
        systemType,
      ],
    },
  })
}

export const deleteCompanySystemTypeFailure = state => state.merge({})
export const deleteCompanySystemTypeLoading = state => state.merge({})
export const deleteCompanySystemTypeSuccess = (state, { companyId, systemId }) => {
  const systemTypesByCompany = state.get('systemTypesByCompany')
  return state.merge({
    systemTypesByCompany: {
      ...systemTypesByCompany,
      [companyId]: removeById(systemId, systemTypesByCompany[companyId] || []),
    },
  })
}

export const updateUserSuccess = (state, { user }) => state.merge({
  users: mapById(user.id, state.get('users'), _user => user),
})

/*
export const addCompanyPermissionsLoading = (state, { companyId }) => {
  return state.merge({
    permissionsByCompanyLoading: {
      ...state.get('permissionsByCompanyLoading'),
      [companyId]: true,
    },
  })
}
export const addCompanyPermissionsSuccess = (state, { companyId, permissions }) => {
  return state.merge({
    permissionsByCompany: {
      ...state.get('permissionsByCompany'),
      [companyId]: permissions,
    },
    permissionsByCompanyLoading: {
      ...state.get('permissionsByCompanyLoading'),
      [companyId]: false,
    },
  })
}
export const addCompanyPermissionsFailure = (state, { companyId }) => {
  return state.merge({
    permissionsByCompanyLoading: {
      ...state.get('permissionsByCompanyLoading'),
      [companyId]: false,
    },
  })
}
*/

export const deleteProjectsSuccess = (state, { projectIds }) => {
  return state.merge({
    projects: state.get('projects').filter(project => !projectIds.includes(project.id)),
  })
}

export const registerEmployeeLoading = state =>
  state.merge({
    registerIsLoading: true,
    registerErrorMessage: null,
  })

export const registerEmployeeSuccess = state => {
  return state.merge({
    registerIsLoading: false,
    registerErrorMessage: null,
  })
}

export const registerEmployeeFailure = (state, { errorMessage }) =>
  state.merge({
    registerIsLoading: false,
    registerErrorMessage: errorMessage,
  })

export const adminRegisterEmployeeLoading = state =>
  state.merge({
    registerIsLoading: true,
    registerErrorMessage: null,
  })

export const adminRegisterEmployeeSuccess = (state, { companyId, user }) => {
  return state.merge({
    users: [
      convertRawUser(user),
      ...state.get('users'),
    ],
    registerIsLoading: false,
    registerErrorMessage: null,
  })
}

export const adminRegisterEmployeeFailure = (state, { errorMessage }) =>
  state.merge({
    registerIsLoading: false,
    registerErrorMessage: errorMessage,
  })

// Get list of all company positions
export const getPositionsLoading = (state, { companyId }) =>
  state.merge({
    positionsLoading: {
      ...state.get('positionsLoading'),
      [companyId]: true,
    },
  })

export const getPositionsSuccess = (state, { companyId, positions }) => {
  return state.merge({
    positions: {
      ...state.get('positions'),
      [companyId]: positions || [],
    },
    positionsLoading: {
      ...state.get('positionsLoading'),
      [companyId]: false,
    },
  })
}
export const getPositionsFailure = (state, { companyId, errorMessage }) =>
  state.merge({
    positionsLoading: {
      ...state.get('positionsLoading'),
      [companyId]: false,
    },
  })

// Add position
export const addReferenceStationPositionLoading = state => {
  return state.merge({
    addPositionIsLoading: true,
  })
}

export const addReferenceStationPositionSuccess = (state, { companyId, position }) => {
  const allPositions = state.get('positions')
  const companyPositions = allPositions[companyId] || []
  return state.merge({
    positions: {
      ...allPositions,
      [companyId]: [
        ...companyPositions,
        position,
      ],
    },
    addPositionIsLoading: false,
  })
}

export const addReferenceStationPositionFailure = (state, { errorMessage }) => {
  return state.merge({
    addPositionIsLoading: false,
  })
}

// Update position
export const updateReferenceStationPositionLoading = state => state.merge({})
export const updateReferenceStationPositionSuccess = (state, { companyId, id, position }) => {
  const allPositions = state.get('positions')
  const companyPositions = allPositions[companyId] || []
  return state.merge({
    positions: {
      ...allPositions,
      [companyId]: mapById(id, companyPositions, () => position),
    },
  })
}
export const updateReferenceStationPositionFailure = state => state.merge({})

// Delete position
export const deleteReferenceStationPositionLoading = (state, { id }) => {
  return state.merge({
    deletePosition: {
      ...state.get('deletePosition'),
      [id]: true,
    },
  })
}
export const deleteReferenceStationPositionSuccess = (state, { companyId, id }) => {
  const allPositions = state.get('positions')
  const companyPositions = allPositions[companyId] || []
  return state.merge({
    positions: {
      ...allPositions,
      [companyId]: removeById(id, companyPositions),
    },
    deletePosition: {
      ...state.get('deletePosition'),
      [id]: false,
    },
  })
}
export const deleteReferenceStationPositionFailure = (state, { id, errorMessage }) => {
  return state.merge({
    deletePosition: {
      ...state.get('deletePosition'),
      [id]: false,
    },
  })
}

export const updateSubscriptionLoading = (state, { companyId, subscriptionId }) => {
  return state.merge({
    updateSubscriptionIsLoading: true,
  })
}

export const updateSubscriptionSuccess = (state, { companyId, subscriptionId, subscription, salesProducts }) => {
  const companySubscriptions = state.get('subscriptionsByCompany')[companyId] || []
  const companySubscriptionsByFilters = state.get('subscriptionsByCompanyFilters')[companyId] || []

  return state.merge({
    subscriptionsByCompany: {
      ...state.get('subscriptionsByCompany'),
      [companyId]: mapById(subscriptionId, companySubscriptions, () => convertRawSubscription(subscription, salesProducts)),
    },
    subscriptionsByCompanyFilters: {
      ...state.get('subscriptionsByCompanyFilters'),
      [companyId]: mapById(subscriptionId, companySubscriptionsByFilters, () => convertRawSubscription(subscription, salesProducts)),
    },
    updateSubscriptionIsLoading: false,
  })
}

export const updateSubscriptionFailure = (state, { companyId, subscriptionId }) => {
  return state.merge({
    updateSubscriptionIsLoading: false,
  })
}

// Check invite token
export const checkInviteTokenLoading = (state, { token }) => state.merge({
  checkTokenIsLoading: {
    ...state.get('checkTokenIsLoading'),
    [token]: true,
  },
  isTokenValid: {
    ...state.get('isTokenValid'),
    [token]: false,
  },
})
export const checkInviteTokenSuccess = (state, { token }) => {
  return state.merge({
    checkTokenIsLoading: {
      ...state.get('checkTokenIsLoading'),
      [token]: false,
    },
    isTokenValid: {
      ...state.get('isTokenValid'),
      [token]: true,
    },
  })
}
export const checkInviteTokenFailure = (state, { token }) => state.merge({
  checkTokenIsLoading: {
    ...state.get('checkTokenIsLoading'),
    [token]: false,
  },
})

export const addKeySuccess = (state, { companyId, subscriptionId, key }) => {
  const subscriptionsByCompany = state.get('subscriptionsByCompany')
  return state.merge({
    subscriptionsByCompany: {
      ...subscriptionsByCompany,
      [companyId]: mapById(subscriptionId, subscriptionsByCompany[companyId] || [], subscription => ({
        ...subscription,
        license_keys: [
          { id: key.id },
          ...subscription.license_keys,
        ],
      })),
    },
  })
}

export const addRovers = (state, { rovers, subscription }) => state.merge({
  addedRovers: {
    ...state.get('addedRovers'),
    ...(rovers.reduce((all, rover) => ({ ...all, [rover.serial]: subscription }))),
  },
})

export const removeRover = (state, { rover_serial }) => state.merge({
  addedRovers: omit([rover_serial], state.get('addedRovers')),
})

export const updateCompanyAddressesLoading = (state, { companyId, addresses }) =>
  state.merge({
    updateCompanyIsLoading: true,
    updateCompanyErrorMessage: '',
  })

export const updateCompanyAddressesSuccess = (state, { companyId, addresses }) => {
  const loggedUserCompany = state.get('loggedUserCompany')
  return state.merge({
    loggedUserCompany: loggedUserCompany && loggedUserCompany.id === companyId ? { ...loggedUserCompany, addresses } : loggedUserCompany,
    currentCompany: { ...state.get('currentCompany'), addresses },
    updateCompanyIsLoading: false,
    updateCompanyErrorMessage: null,
  })
}

export const updateCompanyAddressesFailure = (state, { errorMessage }) =>
  state.merge({
    currentCompany: {},
    updateCompanyIsLoading: false,
    updateCompanyErrorMessage: errorMessage,
  })

export const getCompanyProjectsLoading = state =>
  state.merge({
    getProjectsIsLoading: true,
  })

export const getCompanyProjectsSuccess = (state, { companyId, projects }) =>
  state.merge({
    projects: {
      ...state.get('projects'),
      [companyId]: projects,
    },
    getProjectsIsLoading: false,
  })

export const getCompanyProjectsFailure = (state, { errorMessage }) =>
  state.merge({
    getProjectsIsLoading: false,
  })

export const getCompanyWideMaxVersionLoading = state =>
  state.merge({
    getWideMaxVersionIsLoading: true,
  })

export const getCompanyWideMaxVersionSuccess = (state, { companyId, companyWideMaxVersions }) => {
  return state.merge({
    companyWideMaxVersions: [
      ...state.get('companyWideMaxVersions'),
      ...companyWideMaxVersions,
    ],
    getWideMaxVersionIsLoading: false,
  })
}

export const getCompanyWideMaxVersionFailure = (state, { errorMessage }) =>
  state.merge({
    getWideMaxVersionIsLoading: false,
  })

// When we transfer subscription we should remove that from the store
export const transferSubscriptionsSuccess = (state, { subscriptionIds, oldCompanyId, data, subscription }) => {
  return state.merge({
    subscriptionsByCompany: {
      ...state.get('subscriptionsByCompany'),
      [oldCompanyId]: !data?.reseller?.id ? (state.get('subscriptionsByCompany')[oldCompanyId] || [])
        .filter(subscription => !subscriptionIds.includes(subscription.id)) : (state.get('subscriptionsByCompany')[oldCompanyId] || []),
    },
    subscriptionsByCompanyFilters: {
      ...state.get('subscriptionsByCompanyFilters'),
      [oldCompanyId]: !data?.reseller?.id ? (state.get('subscriptionsByCompanyFilters')[oldCompanyId] || [])
        .filter(subscription => !subscriptionIds.includes(subscription.id)) : (state.get('subscriptionsByCompanyFilters')[oldCompanyId] || []),
    },
  })
}

export const extendOfflineSubscriptionSuccess = (state, { companyId, subscriptionId, payload }) => {
  return state.merge({
    subscriptionsByCompany: {
      ...state.get('subscriptionsByCompany'),
      [companyId]: mapById(subscriptionId, state.get('subscriptionsByCompany')[companyId] || [], subscription => ({ ...subscription, end_date: payload.end_date })),
    },
    subscriptionsByCompanyFilters: {
      ...state.get('subscriptionsByCompanyFilters'),
      [companyId]: mapById(subscriptionId, state.get('subscriptionsByCompanyFilters')[companyId] || [], subscription => ({ ...subscription, end_date: payload.end_date })),
    },
  })
}

export const getCompanyDBLogsSuccess = (state, { companyId, logs, pagination }) => {
  return state.merge({
    getCompanyDBLogsLoading: false,
    getCompanyDBLogsPagination: {
      ...state.get('getCompanyDBLogsPagination'),
      [companyId]: pagination,
    },
    companyLogs: {
      ...state.get('companyLogs'),
      [companyId]: logs.map(convertRawLog),
    },
  })
}
export const getCompanyDBLogsLoading = state => state.merge({
  getCompanyDBLogsLoading: true,
})
export const getCompanyDBLogsFailure = state => state.merge({
  getCompanyDBLogsLoading: false,
})

export const getCompanyProductUpdatesLoading = (state, { companyId, productName }) =>
  state.merge({
    getProductUpdatesLoading: {
      ...state.get('getProductUpdatesLoading'),
      [companyId + productName]: true,
    },
  })
export const getCompanyProductUpdatesSuccess = (state, { companyId, productName, updates }) => {
  return state.merge({
    getProductUpdatesLoading: {
      ...state.get('getProductUpdatesLoading'),
      [companyId + productName]: false,
    },
    productUpdates: {
      ...state.get('productUpdates'),
      [companyId + productName]: updates.map(update => ({
        ...update,
        version: path(['release', 'version'], update),
        date: path(['release', 'date'], update),
      })),
    },
  })
}
export const getCompanyProductUpdatesFailure = (state, { companyId, productName }) =>
  state.merge({
    getProductUpdatesLoading: {
      ...state.get('getProductUpdatesLoading'),
      [companyId + productName]: false,
    },
  })

const addCompaniesSubscriptions = (subscriptionsByCompanies, companyId, subscription, salesProducts) => {
  const subscriptionsForCompany = subscriptionsByCompanies[companyId] || []
  return {
    ...subscriptionsByCompanies,
    [companyId]: [convertRawSubscription(subscription, salesProducts), ...subscriptionsForCompany],
  }
}
export const activateOfflineSubscriptionSuccess = (state, { companyId, subscription, salesProducts }) => {
  return state.merge({
    subscriptionsByCompany: addCompaniesSubscriptions(state.get('subscriptionsByCompany'), companyId, subscription, salesProducts),
    subscriptionsByCompanyFilters: addCompaniesSubscriptions(state.get('subscriptionsByCompanyFilters'), companyId, subscription, salesProducts),
  })
}

const updateCompaniesSubscriptions = (subscriptionsByCompanies, salesProducts) => {
  const allSubscriptions = subscriptionsByCompanies || {}
  return Object.keys(allSubscriptions).reduce((all, companyId) => ({
    ...all,
    [companyId]: (allSubscriptions[companyId] || []).map(sub => ({ ...sub, plan: convertSubscriptionSalesProduct(sub, salesProducts) })),
  }), {})
}
export const updateSubscriptions = (state, { salesProducts }) => {
  return state.merge({
    subscriptionsByCompany: updateCompaniesSubscriptions(state.get('subscriptionsByCompany'), salesProducts),
    subscriptionsByCompanyFilters: updateCompaniesSubscriptions(state.get('subscriptionsByCompanyFilters'), salesProducts),
  })
}

const mapUpdateCompaniesSubscriptions = (subscriptionsByCompanies, companyId, subscriptions, salesProducts) => {
  const subscriptionsForCompany = subscriptionsByCompanies[companyId] || []
  return {
    ...subscriptionsByCompanies,
    [companyId]: subscriptionsForCompany.map(subscription => {
      const newSub = findById(subscription.id, subscriptions)
      if (newSub) {
        return convertRawSubscription(newSub, salesProducts)
      }
      return subscription
    }),
  }
}
export const deactivateOfflineSubscriptionsSuccess = (state, { companyId, subscriptions, salesProducts }) => {
  return state.merge({
    subscriptionsByCompany: mapUpdateCompaniesSubscriptions(state.get('subscriptionsByCompany'), companyId, subscriptions, salesProducts),
    subscriptionsByCompanyFilters: mapUpdateCompaniesSubscriptions(state.get('subscriptionsByCompanyFilters'), companyId, subscriptions, salesProducts),
  })
}

export const reducer = createReducer(INITIAL_STATE, {
  [CompaniesTypes.GET_COMPANIES_LOADING]: getCompaniesLoading,
  [CompaniesTypes.GET_COMPANIES_SUCCESS]: getCompaniesSuccess,
  [CompaniesTypes.GET_COMPANIES_FAILURE]: getCompaniesFailure,

  [CompaniesTypes.GET_CHILD_COMPANIES_LOADING]: getChildCompaniesLoading,
  [CompaniesTypes.GET_CHILD_COMPANIES_SUCCESS]: getChildCompaniesSuccess,
  [CompaniesTypes.GET_CHILD_COMPANIES_FAILURE]: getChildCompaniesFailure,

  [CompaniesTypes.GET_COMPANY_USERS_LOADING]: getCompanyUsersLoading,
  [CompaniesTypes.GET_COMPANY_USERS_SUCCESS]: getCompanyUsersSuccess,
  [CompaniesTypes.GET_COMPANY_USERS_FAILURE]: getCompanyUsersFailure,

  [CompaniesTypes.GET_COMPANY_ROVERS_LOADING]: getCompanyRoversLoading,
  [CompaniesTypes.GET_COMPANY_ROVERS_SUCCESS]: getCompanyRoversSuccess,
  [CompaniesTypes.GET_COMPANY_ROVERS_FAILURE]: getCompanyRoversFailure,

  [CompaniesTypes.GET_COMPANY_LOADING]: getCompanyLoading,
  [CompaniesTypes.GET_COMPANY_SUCCESS]: getCompanySuccess,
  [CompaniesTypes.GET_COMPANY_FAILURE]: getCompanyFailure,

  [CompaniesTypes.GET_LOGGED_USER_COMPANY_LOADING]: getLoggedUserCompanyLoading,
  [CompaniesTypes.GET_LOGGED_USER_COMPANY_SUCCESS]: getLoggedUserCompanySuccess,
  [CompaniesTypes.GET_LOGGED_USER_COMPANY_FAILURE]: getLoggedUserCompanyFailure,

  [CompaniesTypes.GENERATE_INVITE_URL_LOADING]: generateInviteUrlLoading,
  [CompaniesTypes.GENERATE_INVITE_URL_SUCCESS]: generateInviteUrlSuccess,
  [CompaniesTypes.GENERATE_INVITE_URL_FAILURE]: generateInviteUrlFailure,

  [CompaniesTypes.UPDATE_COMPANY_LOADING]: updateCompanyLoading,
  [CompaniesTypes.UPDATE_COMPANY_SUCCESS]: updateCompanySuccess,
  [CompaniesTypes.UPDATE_COMPANY_FAILURE]: updateCompanyFailure,

  [CompaniesTypes.UPDATE_COMPANY_ADDRESSES_LOADING]: updateCompanyAddressesLoading,
  [CompaniesTypes.UPDATE_COMPANY_ADDRESSES_SUCCESS]: updateCompanyAddressesSuccess,
  [CompaniesTypes.UPDATE_COMPANY_ADDRESSES_FAILURE]: updateCompanyAddressesFailure,

  [CompaniesTypes.GET_COMPANY_SYSTEM_TYPES_LOADING]: getCompanySystemTypesLoading,
  [CompaniesTypes.GET_COMPANY_SYSTEM_TYPES_SUCCESS]: getCompanySystemTypesSuccess,
  [CompaniesTypes.GET_COMPANY_SYSTEM_TYPES_FAILURE]: getCompanySystemTypesFailure,

  [CompaniesTypes.ADD_COMPANY_SYSTEM_TYPE_LOADING]: addCompanySystemTypeLoading,
  [CompaniesTypes.ADD_COMPANY_SYSTEM_TYPE_SUCCESS]: addCompanySystemTypeSuccess,
  [CompaniesTypes.ADD_COMPANY_SYSTEM_TYPE_FAILURE]: addCompanySystemTypeFailure,

  [CompaniesTypes.DELETE_COMPANY_SYSTEM_TYPE_LOADING]: deleteCompanySystemTypeLoading,
  [CompaniesTypes.DELETE_COMPANY_SYSTEM_TYPE_SUCCESS]: deleteCompanySystemTypeSuccess,
  [CompaniesTypes.DELETE_COMPANY_SYSTEM_TYPE_FAILURE]: deleteCompanySystemTypeFailure,

  [CompaniesTypes.GET_COMPANY_SUBSCRIPTIONS_LOADING]: getCompanySubscriptionsLoading,
  [CompaniesTypes.GET_COMPANY_SUBSCRIPTIONS_SUCCESS]: getCompanySubscriptionsSuccess,
  [CompaniesTypes.GET_COMPANY_SUBSCRIPTIONS_FAILURE]: getCompanySubscriptionsFailure,
  [CompaniesTypes.GET_COMPANY_SUBSCRIPTION_SUCCESS]: getCompanySubscriptionSuccess,

  [CompaniesTypes.GET_COMPANY_SUBSCRIPTIONS_BY_FILTERS_LOADING]: getCompanySubscriptionsByFiltersLoading,
  [CompaniesTypes.GET_COMPANY_SUBSCRIPTIONS_BY_FILTERS_SUCCESS]: getCompanySubscriptionsByFiltersSuccess,
  [CompaniesTypes.GET_COMPANY_SUBSCRIPTIONS_BY_FILTERS_FAILURE]: getCompanySubscriptionsByFiltersFailure,

  [CompaniesTypes.REGISTER_EMPLOYEE_LOADING]: registerEmployeeLoading,
  [CompaniesTypes.REGISTER_EMPLOYEE_SUCCESS]: registerEmployeeSuccess,
  [CompaniesTypes.REGISTER_EMPLOYEE_FAILURE]: registerEmployeeFailure,

  [CompaniesTypes.ADMIN_REGISTER_EMPLOYEE_LOADING]: adminRegisterEmployeeLoading,
  [CompaniesTypes.ADMIN_REGISTER_EMPLOYEE_SUCCESS]: adminRegisterEmployeeSuccess,
  [CompaniesTypes.ADMIN_REGISTER_EMPLOYEE_FAILURE]: adminRegisterEmployeeFailure,

  [CompaniesTypes.DELETE_COMPANY_LOADING]: deleteCompanyLoading,
  [CompaniesTypes.DELETE_COMPANY_SUCCESS]: deleteCompanySuccess,
  [CompaniesTypes.DELETE_COMPANY_FAILURE]: deleteCompanyFailure,

  [CompaniesTypes.CHECK_INVITE_TOKEN_LOADING]: checkInviteTokenLoading,
  [CompaniesTypes.CHECK_INVITE_TOKEN_SUCCESS]: checkInviteTokenSuccess,
  [CompaniesTypes.CHECK_INVITE_TOKEN_FAILURE]: checkInviteTokenFailure,

  [CompaniesTypes.GET_COMPANY_PROJECTS_LOADING]: getCompanyProjectsLoading,
  [CompaniesTypes.GET_COMPANY_PROJECTS_SUCCESS]: getCompanyProjectsSuccess,
  [CompaniesTypes.GET_COMPANY_PROJECTS_FAILURE]: getCompanyProjectsFailure,

  [CompaniesTypes.GET_COMPANY_WIDE_MAX_VERSION_LOADING]: getCompanyWideMaxVersionLoading,
  [CompaniesTypes.GET_COMPANY_WIDE_MAX_VERSION_SUCCESS]: getCompanyWideMaxVersionSuccess,
  [CompaniesTypes.GET_COMPANY_WIDE_MAX_VERSION_FAILURE]: getCompanyWideMaxVersionFailure,

  [CompaniesTypes.GET_COMPANY_DB_LOGS_LOADING]: getCompanyDBLogsLoading,
  [CompaniesTypes.GET_COMPANY_DB_LOGS_SUCCESS]: getCompanyDBLogsSuccess,
  [CompaniesTypes.GET_COMPANY_DB_LOGS_FAILURE]: getCompanyDBLogsFailure,

  [CompaniesTypes.GET_COMPANY_PRODUCT_UPDATES_LOADING]: getCompanyProductUpdatesLoading,
  [CompaniesTypes.GET_COMPANY_PRODUCT_UPDATES_SUCCESS]: getCompanyProductUpdatesSuccess,
  [CompaniesTypes.GET_COMPANY_PRODUCT_UPDATES_FAILURE]: getCompanyProductUpdatesFailure,

  [CompaniesTypes.ADD_ROVERS]: addRovers,
  [CompaniesTypes.REMOVE_ROVER]: removeRover,

  [BillingAgreementTypes.UPDATE_SUBSCRIPTIONS]: updateSubscriptions,
  [BillingAgreementTypes.ACTIVATE_OFFLINE_SUBSCRIPTION_SUCCESS]: activateOfflineSubscriptionSuccess,
  [BillingAgreementTypes.DEACTIVATE_OFFLINE_SUBSCRIPTIONS_SUCCESS]: deactivateOfflineSubscriptionsSuccess,
  [UsersTypes.UPDATE_USER_SUCCESS]: updateUserSuccess,
  [UsersTypes.UPDATE_SUBSCRIPTION_LOADING]: updateSubscriptionLoading,
  [UsersTypes.UPDATE_SUBSCRIPTION_SUCCESS]: updateSubscriptionSuccess,
  [UsersTypes.UPDATE_SUBSCRIPTION_FAILURE]: updateSubscriptionFailure,
  [KeyTypes.ADD_KEY_SUCCESS]: addKeySuccess,
  [BillingAgreementTypes.TRANSFER_SUBSCRIPTIONS_SUCCESS]: transferSubscriptionsSuccess,
  [BillingAgreementTypes.EXTEND_OFFLINE_SUBSCRIPTION_SUCCESS]: extendOfflineSubscriptionSuccess,
})
