import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
// Project deps
import { isLoggedIn } from 'modules/users/selectors'

const PrivateRoute = ({ isUserLoggedIn, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        isUserLoggedIn
          ? <Component {...props} />
          : <Redirect to={{ pathname: '/', state: { isRegistrated: false } }} />
      )}
    />
  )
}

const mapStateToProps = state => ({
  isUserLoggedIn: isLoggedIn(state),
})

PrivateRoute.propTypes = {
  isUserLoggedIn: PropTypes.bool,
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
    PropTypes.object,
  ]),
}

export default connect(mapStateToProps)(PrivateRoute)
