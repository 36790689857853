import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
// Material UI
import { makeStyles } from '@material-ui/core/styles'
import { CardActionArea, IconButton } from '@material-ui/core'
// Icons
import DocumentIcon from '@material-ui/icons/Description'
import ImageIcon from '@material-ui/icons/Image'
import DeleteIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'
// Project deps
import { getFileExtension } from 'utils/files'
import Tooltip from 'components/reusable/StyledTooltip'
// Local deps
import { BLOCK_SIZE } from './utils'

const useStyles = makeStyles(theme => ({
  block: {
    height: ({ size }) => size,
    width: ({ size }) => size,
    background: '#eeeeee',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    position: 'relative',
    flexDirection: 'column',
    borderRadius: theme.spacing(1),
    boxShadow: theme.mixins.boxShadow,
  },
  fileContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: ({ size }) => size + 10,
  },
  icon: {
    color: '#a9a9a9',
  },
  extension: {
    fontSize: '0.85em',
    fontStyle: 'italic',
    color: '#a9a9a9',
  },
  actionButton: {
    position: 'absolute',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  deleteButton: {
    right: theme.spacing(0.5),
    top: theme.spacing(0.5),
    padding: '1px',
  },
  editButton: {
    right: theme.spacing(0.5),
    top: theme.spacing(3),
    padding: '2px',
  },
  image: {
    height: ({ size }) => size,
    width: ({ size }) => size,
    borderRadius: theme.spacing(1),
  },
}))

const File = props => {
  const { file, src, onRemoveFile, onOpenImage, size = BLOCK_SIZE, fileName, compressedSrc, onEditFile } = props
  const classes = useStyles({ size })
  const fileType = file.type || file.ext || ''
  const isImageFile = fileType.startsWith('image')
  const ext = getFileExtension(file.name)

  const actions = (
    <React.Fragment>
      <IconButton size='small' className={classNames(classes.actionButton, classes.deleteButton)} onClick={onRemoveFile}><DeleteIcon style={{ fontSize: '1rem' }}/></IconButton>
      {onEditFile && <IconButton size='small' className={classNames(classes.actionButton, classes.editButton)} onClick={onEditFile}><EditIcon style={{ fontSize: '0.875rem' }}/></IconButton>}
    </React.Fragment>
  )
  return (
    <Tooltip title={fileName} placement='top'>
      <div className={classes.fileContainer}>
        <div className={classes.block}>
          {isImageFile
            ? fileType.endsWith('png') || fileType.endsWith('jpg') || fileType.endsWith('jpeg')
              ? (
                <CardActionArea onClick={onOpenImage} style={{ height: '100%', borderRadius: 4 }}>
                  <img className={classes.image} src={compressedSrc || src} alt={file.name}/>
                  {actions}
                </CardActionArea>
              )
              : (
                <React.Fragment>
                  <ImageIcon className={classes.icon}/>
                  {actions}
                  <span className={classes.extension}>{ext}</span>
                </React.Fragment>
              )
            : <React.Fragment>
              <DocumentIcon className={classes.icon}/>
              {actions}
              <span className={classes.extension}>{ext}</span>
            </React.Fragment>
          }
        </div>
      </div>
    </Tooltip>
  )
}

File.propTypes = {
  src: PropTypes.string,
  compressedSrc: PropTypes.string,
  fileName: PropTypes.string,
  size: PropTypes.number,
  file: PropTypes.any,
  onRemoveFile: PropTypes.func,
  onOpenImage: PropTypes.func,
  onEditFile: PropTypes.func,
}

export default File
