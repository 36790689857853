import ListItem from '@material-ui/core/ListItem'
import { withStyles } from '@material-ui/styles'
import { theme } from 'HOC/withRoot'

const StyledListItem = withStyles({
  selected: {
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
})(ListItem)

export default StyledListItem
