export const getUserArtifactPermissions = user => {
  return user.artifactPermissions || { delete: false, update: false, add: false, read: false }
}

export const getUserProjectPermissions = user => {
  return user.projectPermissions || { delete: false, update: false, add: false, read: false }
}

export const getUserPipelinePermissions = user => {
  return user.pipelinePermissions || { delete: false, update: false, add: false, read: false }
}

export const getUserMissionPermissions = user => {
  return user.missionPermissions || { delete: false, update: false, add: false, read: false }
}

export const getUserProjectCRSPermissions = user => {
  return user.projectCRSPermissions || { delete: false, update: false, add: false, read: false }
}

export const getFullUserName = (user, email = false) => {
  if (!user) {
    return ''
  }
  return user.first_name + ' ' + user.last_name + (email ? ` (${user.email})` : '')
}

export const getAddress = data => {
  return [
    data.city.replace(/,/, '').trim(),
    data.street.replace(/,/, '').trim(),
    data.state.replace(/,/, '').trim(),
    data.zip.replace(/,/, '').trim(),
  ].join(',')
}

export const getSystemSerialNumber = data => {
  return data.systemSerial.map(serialNumber => serialNumber.replace(/,/, '').trim()).join(', ')
}
