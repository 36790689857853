import { DataType } from 'types/form'
import { validateRoverSerial } from './utils'

export const UserSaleEvent = {
  rover_serial: {
    name: value => {
      const lowerCasedValue = (value || '').toLowerCase()
      return lowerCasedValue.startsWith('r')
        ? 'Recon serial number'
        : +lowerCasedValue[0] >= 2 && +lowerCasedValue[0] <= 9
          ? 'PLS serial number'
          : 'Serial number'
    },
    dataType: DataType.STRING,
    initialValue: '',
    variant: 'outlined',
    customCheck: (value, numberValue) => {
      return validateRoverSerial(value)
    },
  },
}
