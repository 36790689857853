import React from 'react'
import moment from 'utils/moment'
import config from 'config'
import { convertRawPlanGrant } from './grants'
import { capitalize, orderBy } from 'lodash'
import { makeUnique } from 'utils/list'
import SubscriptionLabel from 'components/reusable/SubscriptionLabel'

/**
 * Converts `backend` bundle to a `salesProduct` like structure to reuse it in UI
 * @param {Bundle} raw
 * @returns {SalesProduct}
 */
export const convertRawBundle = (raw, grantTypes = []) => {
  const { sales_product_durations = [] } = raw
  return convertRawSalesProduct({
    ...raw,
    durations: sales_product_durations,
    public: sales_product_durations.some(duration => duration.sales_product.public),
    require_rover: sales_product_durations[0].sales_product.require_rover,
    licensing_products: makeUnique([
      { name: raw.name },
      ...sales_product_durations.reduce((all, duration) => [...all, ...duration.sales_product.licensing_products], []),
    ].map(({ name }) => name)).map(licensingProductName => ({ name: licensingProductName })),
    sales_product_type: 'bundle',
    // rover_types: makeUnique(sales_product_durations.reduce((all, duration) => [...all, ...duration.sales_product.rover_types], [])),
  }, grantTypes)
}

const DurationPriority = {
  'demo': 1,
  'day': 2,
  'month': 3,
  'year': 4,
  'perpetual': 5,
}
/**
 * Converts `backend` duration to the format used by the frontend
 * @param {RawDuration} duration
 * @returns {Duration}
 */
export const convertRawDuration = duration => {
  if (!duration) {
    return null
  }
  const durationType = duration.duration_type
  const convertedDuration = {
    ...duration,
    dependencies: (duration.dependencies || []).map(convertRawDuration),
    type: durationType,
    label: capitalize(durationType),
    token: duration.stripe_token,
    priority: DurationPriority[durationType] || 100,
  }
  return {
    ...convertedDuration,
    value: convertedDuration,
  }
}
/**
 * Converts `backend` sales product to the format used by the frontend
 * @param {RawSalesProduct} duration
 * @returns {SalesProduct}
 */
export const convertRawSalesProduct = (raw, allGrantTypes = []) => {
  const {
    id,
    interval,
    is_hidden,
    // p_type,
    name,
    description,
    price,
    stripe_token,
    stripe_plan_token,
    has_rover_feature,
    has_se_feature,
    extension,
    require_rover,
    durations = [],
    sales_product_type,
    grant_types = [],
    licensing_products = [],
    public: is_public,
    rover_types = [],
    hardware_warranty,
  } = raw
  const isLegacy = 'products' in raw
  return {
    ...raw,
    id,
    name,
    description: description || '',
    // type: p_type,
    price,
    interval: interval || '',
    isHidden: Boolean(is_hidden),
    isPublic: Boolean(is_public),
    token: isLegacy ? stripe_plan_token : stripe_token,
    extension: Boolean(extension),
    hasRoverFeature: Boolean(has_rover_feature),
    hasSEFeature: Boolean(has_se_feature),
    requireRover: require_rover,
    type: sales_product_type,
    durations: durations.map(convertRawDuration),
    licensingProducts: [...(name || '').includes('PLS Care') ? ['PLS Care'] : [], ...licensing_products.map(licensingProduct => licensingProduct.name)],
    grantTypes: grant_types.map(grantType => convertRawPlanGrant(grantType, allGrantTypes)),
    roverTypes: rover_types,
    isLegacy: isLegacy,
    hardwareWarranty: hardware_warranty,
  }
}

export const convertSubscriptionSalesProduct = (subscription, salesProducts = []) => {
  const {
    plan,
    product_duration,
  } = subscription
  const isLegacy = !product_duration
  const salesProduct = isLegacy
    ? plan && Object.keys(plan).length > 0 ? convertRawSalesProduct(plan) : {}
    : product_duration && product_duration.sales_product
      ? convertRawSalesProduct(salesProducts.find(salesProduct => salesProduct.id === product_duration.sales_product.id) || product_duration.sales_product)
      : {}
  return salesProduct
}

export const convertRawSubscription = (raw, salesProducts = []) => {
  const {
    activated_date,
    canceled_date,
    end_date,
    created,
    license_type,
    plan_type,
    nav_serial,
    pls_serial,
    subscription_state,
    subscription_type,
    is_archived,
    product_duration,
    rovers = [],
  } = raw
  const isLegacy = !product_duration
  const salesProduct = convertSubscriptionSalesProduct(raw, salesProducts)
  const roverSerialsSorted = orderBy(rovers, ['serial'], ['asc'])
  const convertedSubscription = {
    ...raw,
    plan: salesProduct,
    is_archived: Boolean(is_archived),
    activated_date: activated_date ? moment(activated_date).format(config.DATETIME_FORMAT) : '',
    canceled_date: canceled_date ? moment(canceled_date).format(config.DATETIME_FORMAT) : '',
    end_date: end_date ? moment(end_date).format(config.DATETIME_FORMAT) : '',
    created_date: created ? moment(created).format(config.DATETIME_FORMAT) : '',
    license_type: license_type || '',
    plan_type: plan_type || '',
    nav_serial: nav_serial || '',
    pls_serial: pls_serial || '',
    subscription_state: subscription_state || '',
    subscription_type: subscription_type || '',
    product_duration: convertRawDuration(product_duration),
    planName: salesProduct.name || '',
    roverSerials: roverSerialsSorted,
    roverSerialsSortString: roverSerialsSorted.map(rover => rover.serial).join(','),
    // mark legacy subscription to distinguish it
    isLegacy: isLegacy,
  }
  convertedSubscription.label = <SubscriptionLabel subscription={convertedSubscription}/>
  return {
    ...convertedSubscription,
    value: convertedSubscription,
  }
}

export const StripePlanType = {
  SF_NAVLAB: 'sf_navlab',
  NAVLAB: 'navlab',
  HOSTING: 'hosting',
  EXTRA_TB: 'extra_tb',
  AI_CLASSIFICATION: 'ai_classification',
  UNLIMITED_STORAGE: 'unlimited_storage',
  SPATIAL_EXPLORER: 'spatial_explorer',
  SPATIAL_EXPLORER_IE: 'spatial_explorer_ie',
  ROVER: 'rover',
  IE_PC_MASTER: 'ie_pc_master',
  PLS_CARE: 'pls_care',
  FLIGHT_PLANNER: 'flight_planner',
}

export const StripeDialogState = {
  EDIT_CARD: 'edit-card',
  SUBSCRIBE: 'subscribe',
}
