import { BillingAgreementTypes } from 'modules/billingAgreement/actions'
import UsersActions, { UsersTypes } from 'modules/users/actions'

export const userReloadMiddleware = store => next => action => {
  if (action.type === BillingAgreementTypes.CANCEL_STRIPE_BILLING_AGREEMENT_SUCCESS ||
    action.type === BillingAgreementTypes.EXECUTE_PAYPAL_BILLING_AGREEMENT_SUCCESS ||
    action.type === BillingAgreementTypes.EXECUTE_STRIPE_BILLING_AGREEMENT_SUCCESS
  ) {
    next(UsersActions.getMe())
    next(action)
  } else if (
    action.type === UsersTypes.LOGIN ||
    action.type === UsersTypes.TWO_FACTOR_LOGIN
  ) {
    const newAction = {
      ...action,
      dispatch: next,
    }
    next(newAction)
  } else {
    next(action)
  }
}
