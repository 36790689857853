import styled from 'styled-components'
import Chip from '@material-ui/core/Chip'

const StyledChip = styled(Chip)`
  height: 22px !important;
  border-radius: 6px !important;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`

export default StyledChip
