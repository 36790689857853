import React from 'react'
import PropTypes from 'prop-types'
// Material UI
import { withStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import ListItemText from '@material-ui/core/ListItemText'
// Project deps
import VirtualizedList from 'components/reusable/VirtualizedList'

const styles = theme => ({
  listItem: {
    height: 'auto',
    alignItems: 'flex-start',
    padding: theme.spacing(0.5, 1),
  },
})

const SearchBlock = ({ data, itemRenderer, getUrl, classes, location, history, onClose, rowHeight }) => {
  // Handlers
  const onItemClick = url => e => {
    e.stopPropagation()
    e.preventDefault()
    if (location.pathname === url) {
      document.location.reload()
    } else {
      history.push(url)
      onClose(e)
    }
  }
  return (
    data.length > 0
      ? <VirtualizedList
        listHeight={440}
        rowHeight={rowHeight}
        list={data}
        renderItem={(renderItemProps, { style, index, key }) => {
          const { id } = renderItemProps
          const url = getUrl(id, renderItemProps)
          return (
            <MenuItem
              classes={{ root: classes.listItem }}
              key={key}
              style={style}
              onClick={onItemClick(url)}
              component='a'
              href={url}
            >
              <ListItemText
                primary={itemRenderer.primary(renderItemProps)}
                secondary={typeof itemRenderer.secondary === 'function' ? itemRenderer.secondary(renderItemProps) : null}
              />
            </MenuItem>
          )
        }}
      />
      : <Typography style={{ padding: 8 }}>No data</Typography>
  )
}

SearchBlock.propTypes = {
  rowHeight: PropTypes.any,
  location: PropTypes.object,
  history: PropTypes.object,
  itemRenderer: PropTypes.object,
  title: PropTypes.string,
  data: PropTypes.array.isRequired,
  getUrl: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func,
}

export default withStyles(styles)(SearchBlock)
