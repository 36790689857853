import React from 'react'
import PropTypes from 'prop-types'
// Material UI
import Chip from 'components/reusable/Chip'
// Project deps
import { getTagColor, getTagTextColor } from 'utils/tags'
import { theme } from 'HOC/withRoot'

const Tag = props => {
  const { name, style = {}, ...other } = props
  return (
    <Chip
      size='small'
      style={{
        background: getTagColor(name),
        color: getTagTextColor(name),
        height: 20,
        border: 'none',
        boxShadow: theme.mixins.boxShadow,
        ...style,
      }}
      variant='outlined'
      label={name}
      {...other}
    />
  )
}

Tag.propTypes = {
  name: PropTypes.string,
  style: PropTypes.object,
}

export default Tag
