import { createReducer } from 'reduxsauce'

import { INITIAL_STATE } from './initialState'
import { TransactionsTypes } from './actions'

export const getTransactionsLoading = state =>
  state.merge({
    getTransactionsIsLoading: true,
    getTransactionsErrorMessage: '',
  })

export const getTransactionsSuccess = (state, { transactions }) =>
  state.merge({
    transactions,
    getTransactionsIsLoading: false,
    getTransactionsErrorMessage: null,
  })

export const getTransactionsFailure = (state, { errorMessage }) =>
  state.merge({
    transactions: null,
    getTransactionsIsLoading: false,
    getTransactionsErrorMessage: errorMessage,
  })

export const getSuccessfulTransactionsLoading = state =>
  state.merge({
    getTransactionsIsLoading: true,
    getTransactionsErrorMessage: '',
  })

export const getSuccessfulTransactionsSuccess = (state, { successfulTransactions }) =>
  state.merge({
    successfulTransactions,
    getTransactionsIsLoading: false,
    getTransactionsErrorMessage: null,
  })

export const getSuccessfulTransactionsFailure = (state, { errorMessage }) =>
  state.merge({
    successfulTransactions: null,
    getTransactionsIsLoading: false,
    getTransactionsErrorMessage: errorMessage,
  })

export const reducer = createReducer(INITIAL_STATE, {
  [TransactionsTypes.GET_COMPANY_TRANSACTIONS_LOADING]: getTransactionsLoading,
  [TransactionsTypes.GET_COMPANY_TRANSACTIONS_SUCCESS]: getTransactionsSuccess,
  [TransactionsTypes.GET_COMPANY_TRANSACTIONS_FAILURE]: getTransactionsFailure,
  [TransactionsTypes.GET_SUCCESSFUL_TRANSACTIONS_LOADING]: getSuccessfulTransactionsLoading,
  [TransactionsTypes.GET_SUCCESSFUL_TRANSACTIONS_SUCCESS]: getSuccessfulTransactionsSuccess,
  [TransactionsTypes.GET_SUCCESSFUL_TRANSACTIONS_FAILURE]: getSuccessfulTransactionsFailure,
})
