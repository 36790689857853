import React from 'react'
import PropTypes from 'prop-types'
// Material UI
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, Grid } from '@material-ui/core'
// Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
/**
 * Renders material expansion panel with the content based on the 'options' (array) property (an array of options that should be rendered inside)
 * @param name The name of the field.
 * @param option The field definition.
 * @param formTemplate The full template of the form.
 * @return A component for inputting floating point data.
 */
const DropdownPanelOption = props => {
  const { option, formTemplate, renderOption } = props
  const { name, options = [] } = option
  return (
    <ExpansionPanel>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{name}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container>
          {
            options.map(fieldName => {
              const fieldOption = formTemplate[fieldName]
              const { gridProps = {} } = fieldOption
              return (
                <Grid item xs={12} {...gridProps} key={fieldName}>
                  {renderOption(fieldName, fieldOption, props)}
                </Grid>
              )
            })
          }
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

DropdownPanelOption.propTypes = {
  option: PropTypes.object,
  formTemplate: PropTypes.object,
  renderOption: PropTypes.func,
}

export default DropdownPanelOption
