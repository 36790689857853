import React from 'react'
import PropTypes from 'prop-types'
// Project deps
import StringOption from './StringOption'
/**
 * Renders a integral number input field.
 * @param name The name of the field.
 * @param option The field definition.
 * @return A component for inputting integer data.
 */
const IntegerOption = props => {
  /*
  const rawValue = values[name]
  const value = typeof rawValue === 'string' ? rawValue : ''
  const onChange = event => setValue(name, event.target.value, option)
  const errorMessage = getFormFieldErrorMessage(value, option, values, state, extra)
  const error = Boolean(errorMessage)
  */
  return <StringOption {...props}
    type='number'
    onWheel={e => {
      if (document.activeElement === e.target) {
        const tempNode = document.activeElement
        e.target.blur()
        setTimeout(() => {
          tempNode.focus()
        }, 0)
      }
      return false
    }}
  />
}

IntegerOption.propTypes = {
  state: PropTypes.object,
  extra: PropTypes.any,
  name: PropTypes.string,
  option: PropTypes.object,
  disabled: PropTypes.bool,
  classes: PropTypes.object,
  values: PropTypes.object,
  setValue: PropTypes.func,
  formTemplate: PropTypes.object,
}

export default IntegerOption
