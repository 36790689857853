import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  getUsers: ['excludeArray'],
  getUsersLoading: null,
  getUsersSuccess: ['users', 'nonCompanyUsers'],
  getUsersFailure: ['errorMessage'],

  // getUser
  getUser: ['payload'],
  getUserLoading: null,
  getUserSuccess: ['users'],
  getUserFailure: ['errorMessage'],

  // getActiveUser
  getActiveUser: ['user_id'],
  getActiveUserLoading: null,
  getActiveUserSuccess: ['user'],
  getActiveUserFailure: ['errorMessage'],

  setActiveUserSubscription: ['subscription'],
  setActiveUserGrants: ['grants'],

  // getMe
  getMe: null,
  getMeLoading: null,
  getMeSuccess: ['user', 'permissions'],
  getMeFailure: ['errorMessage'],

  // createUser
  createUser: ['payload'],
  createUserLoading: null,
  createUserSuccess: null,
  createUserFailure: ['errorMessage'],

  // login
  login: ['payload', 'redirectURL', 'dispatch'],
  loginLoading: null,
  loginSuccess: ['token', 'user', 'redirectUrl'],
  loginFailure: ['errorMessage', 'passwordNotSet', 'allowedToLoginAt'],

  // login as
  loginAs: ['userId', 'loggedAs'],
  loginAsLoading: null,
  loginAsSuccess: ['token', 'user', 'loggedAs'],
  loginAsFailure: ['errorMessage', 'passwordNotSet'],

  // register
  register: ['payload', 'createdByUser'],
  registerLoading: null,
  registerSuccess: ['user', 'createdByUser'],
  registerFailure: ['errorMessage', 'createdByUser'],

  // register
  adminRegister: ['payload', 'onSuccess'],
  // logout
  logout: ['url'],
  logoutLoading: null,
  logoutSuccess: ['url'],
  logoutFailure: null,

  // request recovery
  requestRecovery: ['payload'],
  requestRecoveryLoading: null,
  requestRecoverySuccess: null,
  requestRecoveryFailure: ['errorMessage'],

  // recover password
  recover: ['payload'],
  recoverLoading: null,
  recoverSuccess: null,
  recoverFailure: ['errorMessage'],

  confirmEmail: ['token'],
  confirmEmailLoading: null,
  confirmEmailSuccess: null,
  confirmEmailFailure: ['errorMessage'],

  sendConfirmEmail: ['email'],
  sendConfirmEmailLoading: null,
  sendConfirmEmailSuccess: null,
  sendConfirmEmailFailure: ['errorMessage'],

  // deleteUser
  deleteUser: ['userId', 'user'],
  deleteUserLoading: null,
  deleteUserSuccess: ['userId'],
  deleteUserFailure: ['errorMessage'],

  // updateUser
  updateUser: ['payload', 'withSuccessMessage'],
  updateUserLoading: null,
  updateUserSuccess: ['user'],
  updateUserFailure: ['errorMessage'],

  // change password
  changePassword: ['id', 'oldPassword', 'newPassword', 'repeatedPassword'],
  changePasswordLoading: null,
  changePasswordSuccess: null,
  changePasswordFailure: ['errorMessage'],

  hideSystemSettings: null,
  showSystemSettings: null,

  acceptTerms: null,

  setAcceptTermsFormOpen: ['open'],

  getProjectsStatistic: null,
  getProjectsStatisticLoading: null,
  getProjectsStatisticSuccess: ['projectsStatistic'],
  getProjectsStatisticFailure: null,

  getSubscriptions: ['userId'],
  getSubscriptionsLoading: ['userId'],
  getSubscriptionsSuccess: ['userId', 'subscriptions'],
  getSubscriptionsFailure: ['userId'],

  updateSubscription: ['companyId', 'subscriptionId', 'payload', 'onSuccess'],
  updateSubscriptionLoading: ['companyId', 'subscriptionId'],
  updateSubscriptionSuccess: ['companyId', 'subscriptionId', 'subscription'],
  updateSubscriptionFailure: ['companyId', 'subscriptionId'],

  getUserPermissions: ['userId'],
  getUserPermissionsLoading: ['userId'],
  getUserPermissionsSuccess: ['userId', 'permissions'],
  getUserPermissionsFailure: ['userId'],

  updateCRSStatus: ['title', 'total', 'current'],
  // login with two-factor authentication
  twoFactorLogin: ['payload', 'redirectURL', 'dispatch'],
  twoFactorLoginLoading: null,
  twoFactorLoginSuccess: ['token', 'user', 'redirectUrl'],
  twoFactorLoginFailure: ['errorMessage', 'isTokenExpired'],

  setTwoFactorLogin: ['temp_token'],

  getTwoFactorAuth: ['userId'],
  getTwoFactorAuthLoading: ['userId'],
  getTwoFactorAuthSuccess: ['userId', 'qr', 'key'],
  getTwoFactorAuthFailure: ['userId'],

  activateTwoFactorAuth: ['userId', 'pin', 'key'],
  activateTwoFactorAuthLoading: ['userId'],
  activateTwoFactorAuthSuccess: ['userId', 'user'],
  activateTwoFactorAuthFailure: ['userId'],

  deactivateTwoFactorAuth: ['userId'],
  deactivateTwoFactorAuthLoading: ['userId'],
  deactivateTwoFactorAuthSuccess: ['userId', 'user'],
  deactivateTwoFactorAuthFailure: ['userId'],

  getUserDBLogs: ['userId', 'pagination', 'onSuccess'],
  getUserDBLogsLoading: ['userId'],
  getUserDBLogsSuccess: ['userId', 'logs', 'pagination'],
  getUserDBLogsFailure: ['userId'],

  getUserReleases: null,
  getUserReleasesLoading: null,
  getUserReleasesSuccess: ['releases'],
  getUserReleasesFailure: ['errorMessage'],

  setUserSeenReleases: ['productName', 'numberOfReleases', 'onSuccess'],
  setUserSeenReleasesLoading: null,
  setUserSeenReleasesSuccess: ['productName', 'numberOfReleases'],
  setUserSeenReleasesFailure: ['errorMessage'],

  getUserProjects: ['userId'],
  getUserProjectsLoading: ['userId'],
  getUserProjectsSuccess: ['userId', 'projects'],
  getUserProjectsFailure: ['userId', 'errorMessage'],
})

export const UsersTypes = Types
export default Creators
