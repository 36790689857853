import React from 'react'
import PropTypes from 'prop-types'
// Material UI
import { TableHead, TableRow, Checkbox, TableSortLabel, Tooltip } from '@material-ui/core'
// Project deps
// Local deps
import TableCell from './StyledTableCell'

const EnhancedTableHead = props => {
  const {
    order,
    orderBy,
    cols,
    expandableRowRender,
    enableRowSelection,
    numChecked,
    onCheckAll,
    onUnCheckAll,
    display,
    onRequestSort,
    header,
  } = props
  const createSortHandler = property => event => onRequestSort(event, property)
  const shouldRenderExpandIcon = expandableRowRender
  return (
    <TableHead>
      {header && (
        <TableRow>
          {header.map((h, index) => h
            ? <TableCell key={h.title} colSpan={h.span}>{h.title}</TableCell>
            : <TableCell key={'header-cell-' + index}></TableCell>,
          )}
        </TableRow>
      )}
      <TableRow>
        {
          enableRowSelection && (
            <TableCell padding='checkbox'>
              <Checkbox
                checked={numChecked > 0}
                onChange={
                  numChecked
                    ? onUnCheckAll
                    : onCheckAll
                }
                color='primary'
              />
            </TableCell>
          )
        }
        { shouldRenderExpandIcon && <TableCell/> }
        {cols.map(
          (col, index) => {
            const { options = {}, label, id } = col
            const { sort = true, orderBy: orderByFromOptions } = options
            const isFirstHeadCell = !shouldRenderExpandIcon && !enableRowSelection && index === 0
            const columnOrderBy = orderByFromOptions || id
            return (
              display[index] &&
              <TableCell
                key={id}
                align={col.numeric ? 'right' : 'left'}
                padding={col.disablePadding ? 'none' : 'default'}
                style={{ ...(isFirstHeadCell && { paddingLeft: 4 }) }}
                sortDirection={orderBy === id ? order : false}>
                { sort
                  ? <Tooltip
                    title='Sort'
                    placement={col.numeric ? 'bottom-end' : 'bottom-start'}
                    enterDelay={300}>
                    <TableSortLabel
                      active={orderBy === columnOrderBy}
                      direction={order}
                      onClick={createSortHandler(columnOrderBy)}>
                      {label}
                    </TableSortLabel>
                  </Tooltip>
                  : label
                }
              </TableCell>
            )
          },
        )}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  cols: PropTypes.array,
  expandableRowRender: PropTypes.func,
  enableRowSelection: PropTypes.bool,
  numChecked: PropTypes.number,
  onCheckAll: PropTypes.func,
  onUnCheckAll: PropTypes.func,
  header: PropTypes.array,
  display: PropTypes.array,
}

export default EnhancedTableHead
