import React from 'react'
import PropTypes from 'prop-types'
// Material UI
import { makeStyles } from '@material-ui/core/styles'
import { CardActionArea, IconButton } from '@material-ui/core'
// Icons
import DocumentIcon from '@material-ui/icons/Description'
import DeleteIcon from '@material-ui/icons/Close'
// Project deps
import Tooltip from 'components/reusable/StyledTooltip'
// Local deps
import { BLOCK_SIZE } from './utils'

const useStyles = makeStyles(theme => ({
  block: {
    height: ({ size }) => size,
    width: ({ size }) => size,
    // margin: theme.spacing(1),
    background: '#eeeeee',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    position: 'relative',
    flexDirection: 'column',
    borderRadius: theme.spacing(1),
    boxShadow: theme.mixins.boxShadow,
  },
  fileContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: ({ size }) => size + 10,
  },
  icon: {
    color: '#a9a9a9',
  },
  extension: {
    fontSize: '0.85em',
    fontStyle: 'italic',
    color: '#a9a9a9',
  },
  deleteButton: {
    position: 'absolute',
    right: theme.spacing(0.5),
    top: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    padding: theme.spacing(0),
    '&&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  image: {
    height: ({ size }) => size,
    width: ({ size }) => size,
    borderRadius: theme.spacing(1),
  },
}))

const File = props => {
  const { file, src, onRemoveFile, onOpenImage, size = BLOCK_SIZE, fileName, compressedSrc, allowDelete = true } = props
  const classes = useStyles({ size })
  const { ext, isImage } = file
  if (isImage) {
    return (
      <Tooltip title={fileName} placement='top'>
        <div className={classes.fileContainer}>
          <div className={classes.block}>
            <CardActionArea onClick={onOpenImage} style={{ height: '100%', borderRadius: 4 }}>
              <img className={classes.image} src={compressedSrc || src} alt={fileName}/>
              {allowDelete && <IconButton size='small' className={classes.deleteButton} onClick={onRemoveFile}>
                <DeleteIcon style={{ fontSize: '1rem' }}/>
              </IconButton>
              }
            </CardActionArea>
          </div>
        </div>
      </Tooltip>
    )
  } else {
    return (
      <Tooltip title={fileName} placement='top'>
        <div className={classes.fileContainer}>
          <div className={classes.block}>
            <DocumentIcon className={classes.icon}/>
            {allowDelete && <IconButton size='small' className={classes.deleteButton} onClick={onRemoveFile}>
              <DeleteIcon style={{ fontSize: '1rem' }}/>
            </IconButton>
            }
            <span className={classes.extension}>{ext}</span>
          </div>
        </div>
      </Tooltip>
    )
  }
}

File.propTypes = {
  allowDelete: PropTypes.bool,
  src: PropTypes.string,
  compressedSrc: PropTypes.string,
  fileName: PropTypes.string,
  size: PropTypes.number,
  file: PropTypes.any,
  onRemoveFile: PropTypes.func,
  onOpenImage: PropTypes.func,
}

export default File
