import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import i18n from 'i18n'
import { path } from 'ramda'
import { useParams, useLocation } from 'react-router-dom'
// Material UI
// Icons
// Project deps
import CustomTable from 'components/reusable/CustomTable'
import RoversActions from 'modules/rovers/actions'
import { getRoverUpdates as getRoverUpdatesSelector, getRoverVersion as getRoverVersionSelector, isGetRoverUpdatesLoading, isGetRoverVersionLoading } from 'modules/rovers/selectors'
import { getLoggedUser } from 'modules/users/selectors'
import { isUserPLS } from 'utils/company'
import { routeChangelogs } from 'utils/routing'
import Warning from 'components/reusable/Warning'
import { Typography } from '@material-ui/core'

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery () {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

const RoverUpdates = props => {
  const { rover_id: serial } = useParams()
  const query = useQuery()
  const version = query.get('se_version')
  const loggedUser = useSelector(getLoggedUser)
  const isPLS = isUserPLS(loggedUser)
  const dispatch = useDispatch()
  const getRoverUpdates = serial => dispatch(RoversActions.getRoverUpdates(serial))
  const getRoverVersion = serial => dispatch(RoversActions.getRoverVersion(serial))
  useEffect(() => {
    if (serial) {
      getRoverUpdates(serial)
      getRoverVersion(serial)
    }
  }, [serial])
  const roverVersion = useSelector(state => getRoverVersionSelector(state, serial))
  const updates = useSelector(state => getRoverUpdatesSelector(state, serial))
  const isLoadingUpdates = useSelector(state => isGetRoverUpdatesLoading(state, serial))
  const isLoadingVersion = useSelector(state => isGetRoverVersionLoading(state, serial))
  const columns = [
    {
      id: 'date',
      label: i18n.t('roverUpdates.columns.date'),
      options: {
        setCellProps: () => ({
          style: {
            width: '10%',
          },
        }),
      },
    },
    {
      id: 'version',
      label: i18n.t('roverUpdates.columns.version'),
      options: {
        customBodyRender: (value, row) => (
          <React.Fragment>
            {value}
            {(!row.link || (row.link && row.reason)) && <Warning disableIcon variant='warning'>
              {isPLS
                ? row.reason
                : !row.link
                  ? row.downloadable
                    ? 'Not available with current subscription'
                    : 'Not available'
                  : ''
              }
            </Warning>
            }
          </React.Fragment>
        ),
      },
      setCellProps: () => ({
        style: {
          width: '10%',
        },
      }),
    },
    {
      id: 'release',
      label: i18n.t('roverUpdates.columns.products'),
      options: {
        customBodyRender: (value, row) => (value ? value.products : []).map(product => product.name).join(', '),
      },
      setCellProps: () => ({
        style: {
          width: '10%',
        },
      }),
    },
    ...(
      isPLS
        ? [
          {
            id: 'operating_system',
            label: i18n.t('roverUpdates.columns.operating_system'),
            options: {
              setCellProps: () => ({
                style: {
                  width: '15%',
                },
              }),
            },
          },
          {
            id: 'hardwares',
            label: i18n.t('roverUpdates.columns.hardwares'),
            options: {
              customBodyRender: (value, row) => (value || []).join(', '),
            },
          },
        ]
        : []
    ),
    {
      id: 'link',
      label: i18n.t('roverUpdates.columns.link'),
      options: {
        // copy: true,
        customBodyRender: (value, row) => {
          const products = path(['release', 'products'], row) || []
          const [firstProduct] = products
          return [
            value ? { label: 'Download', href: value } : null,
            firstProduct ? { label: 'Changelog', href: routeChangelogs(firstProduct.name.toLowerCase(), row.version) } : null,
          ]
            .filter(Boolean)
            .map(v => <a style={{ marginLeft: 8 }} key={v.label} href={v.href}>{v.label}</a>)
        },
      },
    },
  ]

  return (
    <div style={{ padding: 8 }}>
      {!isLoadingVersion &&
      <React.Fragment>
        {roverVersion && <Typography>
          According to our database, rover <b>{serial}</b> is <b>{roverVersion.hardware || 'unknown'}</b>/<b>{roverVersion.os || 'unknown'}</b> running version <b>{roverVersion.firmware_version || 'unknown'}</b>. The following firmware versions are available.
        </Typography>
        }
        <Warning disableIcon variant='warning'>
          Please contact support at <b><a href='mailto:support@phoenixlidar.com'>support@phoenixlidar.com</a></b> before downgrading, or before changing major versions (e.g. updating from version 6.X.X to 7.X.X)
        </Warning>
      </React.Fragment>
      }
      <CustomTable
        loadingLabel={i18n.t('roverUpdates.loading')}
        isLoading={isLoadingUpdates}
        data={updates}
        columns={columns}
        scrollTo={version}
        disablePagination
        options={{
          tableId: 'updates',
          order: 'desc',
          orderBy: 'date',
          setRowProps: row => ({
            style: {
              backgroundColor: `${row.version}` === version ? '#e1ffc4' : '',
            },
          }),
        }}
        noData={i18n.t('roverUpdates.empty')}
      />
    </div>
  )
}

RoverUpdates.propTypes = {
}

export default RoverUpdates
