import { isNil } from 'ramda'
import { createReducer } from 'reduxsauce'
import { INITIAL_STATE } from './initialState'
import { SystemTypeTypes } from './actions'
import { convertRawSystemType, convertRawSystemTypeImage } from 'types/systemTypes'
import { updatePagination } from '../utils'
import { mapById, removeById } from 'utils/list'
import { convertRawAttachment } from 'types/attachments'
import { convertRawLog } from 'types/logs'

export const getSystemTypesLoading = state => {
  return state.merge({ getSystemTypesIsLoading: true })
}
export const getSystemTypesSuccess = (state, { systemTypes, pagination }) => {
  return state.merge({
    getSystemTypesIsLoading: false,
    systemTypes: systemTypes.map(convertRawSystemType),
    pagination,
    // filteredCustomers: filteredCustomers.map(convertRawCustomer),
  })
}
export const getSystemTypesFailure = state => {
  return state.merge({ getSystemTypesIsLoading: false })
}

export const getAllSystemTypesLoading = state => {
  return state.merge({ getAllSystemTypesIsLoading: true })
}
export const getAllSystemTypesSuccess = (state, { systemTypes }) => {
  return state.merge({
    getAllSystemTypesIsLoading: false,
    allSystemTypes: systemTypes.map(convertRawSystemType),
    // filteredCustomers: filteredCustomers.map(convertRawCustomer),
  })
}
export const getAllSystemTypesFailure = state => {
  return state.merge({ getAllSystemTypesIsLoading: false })
}

export const getSystemTypeLoading = state => {
  return state.merge({ })
}
export const getSystemTypeSuccess = (state, { systemTypeId, systemType }) => {
  const selectedSystemType = state.get('selectedSystemType')
  return state.merge({
    allSystemTypes: state.get('allSystemTypes').map(st => st.id === systemTypeId ? convertRawSystemType(systemType, st) : st),
    systemTypes: state.get('systemTypes').map(st => st.id === systemTypeId ? convertRawSystemType(systemType, st) : st),
    selectedSystemType: selectedSystemType
      ? selectedSystemType.id === systemTypeId
        ? convertRawSystemType(systemType, selectedSystemType)
        : selectedSystemType
      : null,
    // filteredCustomers: filteredCustomers.map(convertRawCustomer),
  })
}
export const getSystemTypeFailure = state => {
  return state.merge({ })
}

export const getSystemTypeConfigurationsLoading = (state, { systemTypeId }) => {
  return state.merge({
    getSystemTypeConfigurationsIsLoading: {
      ...state.get('getSystemTypeConfigurationsIsLoading'),
      [systemTypeId]: true,
    },
  })
}
export const getSystemTypeConfigurationsSuccess = (state, { systemTypeId, configurations }) => {
  return state.merge({
    getSystemTypeConfigurationsIsLoading: {
      ...state.get('getSystemTypeConfigurationsIsLoading'),
      [systemTypeId]: false,
    },
    configurations: {
      ...state.get('configurations'),
      [systemTypeId]: configurations.map(convertRawSystemType),
    },
  })
}
export const getSystemTypeConfigurationsFailure = (state, { systemTypeId }) => {
  return state.merge({
    getSystemTypeConfigurationsIsLoading: {
      ...state.get('getSystemTypeConfigurationsIsLoading'),
      [systemTypeId]: false,
    },
  })
}

export const getSystemTypePermissionsSuccess = (state, { permissions }) => {
  return state.merge({
    getSystemTypePermissionsIsLoading: false,
    permissions,
  })
}
export const getSystemTypePermissionsFailure = state => {
  return state.merge({
    getSystemTypePermissionsIsLoading: false,
  })
}
export const getSystemTypePermissionsLoading = state => {
  return state.merge({
    getSystemTypePermissionsIsLoading: true,
  })
}

export const validateSystemTypeLoading = (state, { systemTypeId }) => {
  return state.merge({
    validateSystemTypeIsLoading: {
      ...state.get('validateSystemTypeIsLoading'),
      [systemTypeId]: true,
    },
  })
}
export const validateSystemTypeSuccess = (state, { systemTypeId, deniedSystemPaths }) => {
  return state.merge({
    validateSystemTypeIsLoading: {
      ...state.get('validateSystemTypeIsLoading'),
      [systemTypeId]: false,
    },
    deniedSystemPaths: {
      ...state.get('deniedSystemPaths'),
      [systemTypeId]: deniedSystemPaths,
    },
  })
}
export const validateSystemTypeFailure = (state, { systemTypeId }) => {
  return state.merge({
    validateSystemTypeIsLoading: {
      ...state.get('validateSystemTypeIsLoading'),
      [systemTypeId]: false,
    },
  })
}

export const validateProfileConfigurationsLoading = (state, { profileId }) => {
  return state.merge({
    validateProfileConfigurationsIsLoading: {
      ...state.get('validateProfileConfigurationsIsLoading'),
      [profileId]: true,
    },
  })
}
export const validateProfileConfigurationsSuccess = (state, { profileId, deniedProfileConfigurationsPaths }) => {
  return state.merge({
    validateProfileConfigurationsIsLoading: {
      ...state.get('validateProfileConfigurationsIsLoading'),
      [profileId]: false,
    },
    deniedProfileConfigurationsPaths: {
      ...state.get('deniedProfileConfigurationsPaths'),
      [profileId]: deniedProfileConfigurationsPaths,
    },
  })
}
export const validateProfileConfigurationsFailure = (state, { profileId }) => {
  return state.merge({
    validateProfileConfigurationsIsLoading: {
      ...state.get('validateProfileConfigurationsIsLoading'),
      [profileId]: false,
    },
  })
}

export const getSystemTypeProfilesLoading = (state, { systemTypeId }) => {
  return state.merge({
    getSystemTypeProfilesIsLoading: {
      ...state.get('getSystemTypeProfilesIsLoading'),
      [systemTypeId]: true,
    },
  })
}
export const getSystemTypeProfilesSuccess = (state, { systemTypeId, profiles, filteredProfiles = [] }) => {
  return state.merge({
    getSystemTypeProfilesIsLoading: {
      ...state.get('getSystemTypeProfilesIsLoading'),
      [systemTypeId]: false,
    },
    filteredProfiles: {
      ...state.get('filteredProfiles'),
      [systemTypeId]: filteredProfiles.map(convertRawSystemType),
    },
    profiles: {
      ...state.get('profiles'),
      [systemTypeId]: profiles.map(convertRawSystemType),
    },
  })
}
export const getSystemTypeProfilesFailure = (state, { systemTypeId }) => {
  return state.merge({
    getSystemTypeProfilesIsLoading: {
      ...state.get('getSystemTypeProfilesIsLoading'),
      [systemTypeId]: false,
    },
  })
}

export const addSystemTypeLoading = state => {
  return state.merge({
    addSystemIsLoading: true,
  })
}
export const addSystemTypeSuccess = (state, { systemType }) => {
  const convertedSystemType = convertRawSystemType(systemType)
  return state.merge({
    allSystemTypes: [
      convertedSystemType,
      ...state.get('allSystemTypes'),
    ],
    systemTypes: [
      convertedSystemType,
      ...state.get('systemTypes'),
    ],
    addSystemIsLoading: false,
    pagination: updatePagination(state.get('pagination'), 'add'),
  })
}
export const addSystemTypeFailure = state => {
  return state.merge({
    addSystemIsLoading: false,
  })
}

export const addConfigurationToSystemTypeLoading = state => {
  return state.merge({ addConfigurationIsLoading: true })
}
export const addConfigurationToSystemTypeSuccess = (state, { systemTypeId, configuration }) => {
  const configurations = state.get('configurations')
  return state.merge({
    addConfigurationIsLoading: false,
    configurations: {
      ...configurations,
      [systemTypeId]: [
        convertRawSystemType(configuration),
        ...(configurations[systemTypeId] || []),
      ],
    },
  })
}
export const addConfigurationToSystemTypeFailure = state => {
  return state.merge({ addConfigurationIsLoading: false })
}

export const updateConfigurationLoading = state => {
  return state.merge({ updateConfigurationIsLoading: true })
}
export const updateConfigurationSuccess = (state, { systemTypeId, configuration }) => {
  const configurations = state.get('configurations')
  return state.merge({
    updateConfigurationIsLoading: false,
    configurations: {
      ...configurations,
      [systemTypeId]: mapById(configuration.id, (configurations[systemTypeId] || []), () => configuration),
    },
  })
}
export const updateConfigurationFailure = state => {
  return state.merge({ updateConfigurationIsLoading: false })
}

export const addProfileToSystemTypeLoading = state => {
  return state.merge({ addConfigurationIsLoading: true })
}
export const addProfileToSystemTypeSuccess = (state, { systemTypeId, profile }) => {
  const profiles = state.get('profiles')
  return state.merge({
    addConfigurationIsLoading: false,
    profiles: {
      ...profiles,
      [systemTypeId]: [
        convertRawSystemType(profile),
        ...(profiles[systemTypeId] || []),
      ],
    },
  })
}

export const addProfileToSystemTypeFailure = state => {
  return state.merge({ addConfigurationIsLoading: false })
}

export const updateProfileLoading = state => {
  return state.merge({ updateConfigurationIsLoading: true })
}
export const updateProfileSuccess = (state, { systemTypeId, profile }) => {
  const profiles = state.get('profiles')
  return state.merge({
    updateConfigurationIsLoading: false,
    profiles: {
      ...profiles,
      [systemTypeId]: mapById(profile.id, (profiles[systemTypeId] || []), () => profile),
    },
  })
}

export const updateProfileFailure = state => {
  return state.merge({ updateConfigurationIsLoading: false })
}

export const setDefaultProfileLoading = state => {
  return state.merge({})
}
export const setDefaultProfileSuccess = (state, { systemTypeId, profileId }) => {
  const selectedSystemType = state.get('selectedSystemType')
  return state.merge({
    allSystemTypes: mapById(systemTypeId, state.get('allSystemTypes'), systemType => ({ ...systemType, default_profile: { id: profileId } })),
    systemTypes: mapById(systemTypeId, state.get('systemTypes'), systemType => ({ ...systemType, default_profile: { id: profileId } })),
    selectedSystemType: selectedSystemType
      ? selectedSystemType.id === systemTypeId
        ? { ...selectedSystemType, default_profile: { id: profileId } }
        : selectedSystemType
      : null,
  })
}
export const setDefaultProfileFailure = state => {
  return state.merge({})
}

export const getProfileLoading = state => {
  return state.merge({ })
}
export const getProfileSuccess = (state, { systemTypeId, profileId, profile }) => {
  const profiles = state.get('profiles')
  return state.merge({
    profiles: {
      ...profiles,
      [systemTypeId]: mapById(profileId, profiles[systemTypeId] || [], () => convertRawSystemType(profile)),
    },
  })
}
export const getProfileFailure = state => {
  return state.merge({ })
}

export const updateSystemTypeLoading = state => {
  return state.merge({ updateSystemTypeIsLoading: true })
}
export const updateSystemTypeSuccess = (state, { systemTypeId, systemType }) => {
  const selectedSystemType = state.get('selectedSystemType')
  return state.merge({
    updateSystemTypeIsLoading: false,
    allSystemTypes: mapById(systemTypeId, state.get('allSystemTypes'), st => convertRawSystemType(systemType, st)),
    systemTypes: mapById(systemTypeId, state.get('systemTypes'), st => convertRawSystemType(systemType, st)),
    selectedSystemType: selectedSystemType
      ? selectedSystemType.id === systemTypeId
        ? convertRawSystemType(systemType, selectedSystemType)
        : selectedSystemType
      : null,
  })
}
export const updateSystemTypeFailure = state => {
  return state.merge({ updateSystemTypeIsLoading: false })
}

export const deleteSystemTypeLoading = state => {
  return state.merge({ })
}
export const deleteSystemTypeSuccess = (state, { systemTypeId }) => {
  return state.merge({
    systemTypes: removeById(systemTypeId, state.get('systemTypes')),
    allSystemTypes: removeById(systemTypeId, state.get('allSystemTypes')),
    pagination: updatePagination(state.get('pagination'), 'remove'),
  })
}
export const deleteSystemTypeFailure = state => {
  return state.merge({ })
}

export const updateProfileNameLoading = state => {
  return state.merge({ updateProfilesIsLoading: true })
}
export const updateProfileNameSuccess = (state, { systemTypeId, profiles }) => {
  const stateProfiles = state.get('profiles')
  return state.merge({
    updateProfilesIsLoading: false,
    profiles: {
      ...stateProfiles,
      [systemTypeId]: profiles.map(convertRawSystemType),
    },
  })
}
export const updateProfileNameFailure = state => {
  return state.merge({ updateProfilesIsLoading: false })
}

export const addSystemTypeImagesLoading = state => {
  return state.merge({ addImagesIsLoading: true })
}
export const addSystemTypeImagesSuccess = (state, { systemTypeId, images }) => {
  const systemTypeImages = state.get('systemTypeImages')
  return state.merge({
    addImagesIsLoading: false,
    systemTypeImages: {
      ...systemTypeImages,
      [systemTypeId]: [
        ...images.map(convertRawSystemTypeImage),
        ...(systemTypeImages[systemTypeId] || []),
      ],
    },
  })
}
export const addSystemTypeImagesFailure = state => {
  return state.merge({ addImagesIsLoading: false })
}

export const getSystemTypeImagesLoading = (state, { systemTypeId }) => {
  return state.merge({
    getImagesIsLoading: {
      ...state.get('getImagesIsLoading'),
      [systemTypeId]: true,
    },
  })
}
export const getSystemTypeImagesSuccess = (state, { systemTypeId, images }) => {
  const systemTypeImages = state.get('systemTypeImages')
  return state.merge({
    getImagesIsLoading: {
      ...state.get('getImagesIsLoading'),
      [systemTypeId]: false,
    },
    systemTypeImages: {
      ...systemTypeImages,
      [systemTypeId]: images.map(convertRawSystemTypeImage),
    },
  })
}
export const getSystemTypeImagesFailure = (state, { systemTypeId }) => {
  return state.merge({
    getImagesIsLoading: {
      ...state.get('getImagesIsLoading'),
      [systemTypeId]: false,
    },
  })
}

export const deleteSystemTypeImagesLoading = (state, { systemTypeId }) => {
  return state.merge({
  })
}
export const deleteSystemTypeImagesSuccess = (state, { systemTypeId, images }) => {
  const systemTypeImages = state.get('systemTypeImages')
  return state.merge({
    systemTypeImages: {
      ...systemTypeImages,
      [systemTypeId]: (systemTypeImages[systemTypeId] || []).filter(({ id }) => !images.includes(id)),
    },
  })
}
export const deleteSystemTypeImagesFailure = (state, { systemTypeId }) => {
  return state.merge({
  })
}

export const setExpandedProfile = (state, { id }) => {
  return state.merge({ expandProfile: isNil(id) ? null : id.toString() })
}
export const setExpandedConfiguration = (state, { id }) => {
  return state.merge({ expandConfiguration: isNil(id) ? null : id.toString() })
}
export const selectSystemTypeSuccess = (state, { systemType, updateSearch = true }) => {
  return state.merge({
    selectedSystemType: convertRawSystemType(systemType),
    selectSystemTypeWithSearch: updateSearch,
    selectSystemTypeIsLoading: false,
  })
}
export const selectSystemTypeLoading = (state, { systemType }) => {
  return state.merge({ selectSystemTypeIsLoading: true })
}
export const selectSystemTypeFailure = (state, { systemType }) => {
  return state.merge({ selectSystemTypeIsLoading: false })
}
export const deselectSystemType = state => {
  return state.merge({ selectedSystemType: null, selectSystemTypeWithSearch: true })
}

export const addSystemTypeAttachments = (state, { systemTypeId, attachments }) => {
  const selectedSystemType = state.get('selectedSystemType')
  return state.merge({
    allSystemTypes: mapById(systemTypeId, state.get('allSystemTypes'), st => ({ ...st, attachments: [...(st.attachments || []), ...attachments.map(convertRawAttachment)] })),
    systemTypes: mapById(systemTypeId, state.get('systemTypes'), st => ({ ...st, attachments: [...(st.attachments || []), ...attachments.map(convertRawAttachment)] })),
    selectedSystemType: selectedSystemType
      ? selectedSystemType.id === systemTypeId
        ? { ...selectedSystemType, attachments: [...(selectedSystemType.attachments || []), ...attachments.map(convertRawAttachment)] }
        : selectedSystemType
      : null,
  })
}

export const getLogsSuccess = (state, { systemTypeId, logs, pagination }) => {
  return state.merge({
    getLogsLoading: false,
    logsPagination: {
      ...state.get('logsPagination'),
      [systemTypeId]: pagination,
    },
    logs: {
      ...state.get('logs'),
      [systemTypeId]: logs.map(convertRawLog),
    },
  })
}
export const getLogsLoading = (state, { event }) => state.merge({
  getLogsLoading: true,
})
export const getLogsFailure = (state, { event }) => state.merge({
  getLogsLoading: false,
})

export const getTodoLogsSuccess = (state, { todoId, logs, pagination }) => {
  return state.merge({
    getTodoLogsLoading: false,
    todoLogsPagination: {
      ...state.get('todoLogsPagination'),
      [todoId]: pagination,
    },
    todoLogs: {
      ...state.get('todoLogs'),
      [todoId]: logs.map(convertRawLog),
    },
  })
}
export const getTodoLogsLoading = (state, { event }) => state.merge({
  getTodoLogsLoading: true,
})
export const getTodoLogsFailure = (state, { event }) => state.merge({
  getTodoLogsLoading: false,
})

export const reducer = createReducer(INITIAL_STATE, {
  [SystemTypeTypes.GET_SYSTEM_TYPES_LOADING]: getSystemTypesLoading,
  [SystemTypeTypes.GET_SYSTEM_TYPES_SUCCESS]: getSystemTypesSuccess,
  [SystemTypeTypes.GET_SYSTEM_TYPES_FAILURE]: getSystemTypesFailure,

  [SystemTypeTypes.GET_ALL_SYSTEM_TYPES_LOADING]: getAllSystemTypesLoading,
  [SystemTypeTypes.GET_ALL_SYSTEM_TYPES_SUCCESS]: getAllSystemTypesSuccess,
  [SystemTypeTypes.GET_ALL_SYSTEM_TYPES_FAILURE]: getAllSystemTypesFailure,

  [SystemTypeTypes.GET_SYSTEM_TYPE_LOADING]: getSystemTypeLoading,
  [SystemTypeTypes.GET_SYSTEM_TYPE_SUCCESS]: getSystemTypeSuccess,
  [SystemTypeTypes.GET_SYSTEM_TYPE_FAILURE]: getSystemTypeFailure,

  [SystemTypeTypes.GET_SYSTEM_TYPE_CONFIGURATIONS_LOADING]: getSystemTypeConfigurationsLoading,
  [SystemTypeTypes.GET_SYSTEM_TYPE_CONFIGURATIONS_SUCCESS]: getSystemTypeConfigurationsSuccess,
  [SystemTypeTypes.GET_SYSTEM_TYPE_CONFIGURATIONS_FAILURE]: getSystemTypeConfigurationsFailure,

  [SystemTypeTypes.GET_SYSTEM_TYPE_PROFILES_LOADING]: getSystemTypeProfilesLoading,
  [SystemTypeTypes.GET_SYSTEM_TYPE_PROFILES_SUCCESS]: getSystemTypeProfilesSuccess,
  [SystemTypeTypes.GET_SYSTEM_TYPE_PROFILES_FAILURE]: getSystemTypeProfilesFailure,

  [SystemTypeTypes.GET_SYSTEM_TYPE_PERMISSIONS_LOADING]: getSystemTypePermissionsLoading,
  [SystemTypeTypes.GET_SYSTEM_TYPE_PERMISSIONS_SUCCESS]: getSystemTypePermissionsSuccess,
  [SystemTypeTypes.GET_SYSTEM_TYPE_PERMISSIONS_FAILURE]: getSystemTypePermissionsFailure,

  [SystemTypeTypes.VALIDATE_SYSTEM_TYPE_LOADING]: validateSystemTypeLoading,
  [SystemTypeTypes.VALIDATE_SYSTEM_TYPE_SUCCESS]: validateSystemTypeSuccess,
  [SystemTypeTypes.VALIDATE_SYSTEM_TYPE_FAILURE]: validateSystemTypeFailure,

  [SystemTypeTypes.VALIDATE_PROFILE_CONFIGURATIONS_LOADING]: validateProfileConfigurationsLoading,
  [SystemTypeTypes.VALIDATE_PROFILE_CONFIGURATIONS_SUCCESS]: validateProfileConfigurationsSuccess,
  [SystemTypeTypes.VALIDATE_PROFILE_CONFIGURATIONS_FAILURE]: validateProfileConfigurationsFailure,

  [SystemTypeTypes.ADD_SYSTEM_TYPE_LOADING]: addSystemTypeLoading,
  [SystemTypeTypes.ADD_SYSTEM_TYPE_SUCCESS]: addSystemTypeSuccess,
  [SystemTypeTypes.ADD_SYSTEM_TYPE_FAILURE]: addSystemTypeFailure,

  [SystemTypeTypes.ADD_CONFIGURATION_TO_SYSTEM_TYPE_LOADING]: addConfigurationToSystemTypeLoading,
  [SystemTypeTypes.ADD_CONFIGURATION_TO_SYSTEM_TYPE_SUCCESS]: addConfigurationToSystemTypeSuccess,
  [SystemTypeTypes.ADD_CONFIGURATION_TO_SYSTEM_TYPE_FAILURE]: addConfigurationToSystemTypeFailure,

  [SystemTypeTypes.UPDATE_CONFIGURATION_LOADING]: updateConfigurationLoading,
  [SystemTypeTypes.UPDATE_CONFIGURATION_SUCCESS]: updateConfigurationSuccess,
  [SystemTypeTypes.UPDATE_CONFIGURATION_FAILURE]: updateConfigurationFailure,

  [SystemTypeTypes.ADD_PROFILE_TO_SYSTEM_TYPE_LOADING]: addProfileToSystemTypeLoading,
  [SystemTypeTypes.ADD_PROFILE_TO_SYSTEM_TYPE_SUCCESS]: addProfileToSystemTypeSuccess,
  [SystemTypeTypes.ADD_PROFILE_TO_SYSTEM_TYPE_FAILURE]: addProfileToSystemTypeFailure,

  [SystemTypeTypes.UPDATE_PROFILE_LOADING]: updateProfileLoading,
  [SystemTypeTypes.UPDATE_PROFILE_SUCCESS]: updateProfileSuccess,
  [SystemTypeTypes.UPDATE_PROFILE_FAILURE]: updateProfileFailure,

  [SystemTypeTypes.SET_DEFAULT_PROFILE_LOADING]: setDefaultProfileLoading,
  [SystemTypeTypes.SET_DEFAULT_PROFILE_SUCCESS]: setDefaultProfileSuccess,
  [SystemTypeTypes.SET_DEFAULT_PROFILE_FAILURE]: setDefaultProfileFailure,

  [SystemTypeTypes.GET_PROFILE_LOADING]: getProfileLoading,
  [SystemTypeTypes.GET_PROFILE_SUCCESS]: getProfileSuccess,
  [SystemTypeTypes.GET_PROFILE_FAILURE]: getProfileFailure,

  [SystemTypeTypes.UPDATE_SYSTEM_TYPE_LOADING]: updateSystemTypeLoading,
  [SystemTypeTypes.UPDATE_SYSTEM_TYPE_SUCCESS]: updateSystemTypeSuccess,
  [SystemTypeTypes.UPDATE_SYSTEM_TYPE_FAILURE]: updateSystemTypeFailure,

  [SystemTypeTypes.DELETE_SYSTEM_TYPE_LOADING]: deleteSystemTypeLoading,
  [SystemTypeTypes.DELETE_SYSTEM_TYPE_SUCCESS]: deleteSystemTypeSuccess,
  [SystemTypeTypes.DELETE_SYSTEM_TYPE_FAILURE]: deleteSystemTypeFailure,

  [SystemTypeTypes.UPDATE_PROFILE_NAME_LOADING]: updateProfileNameLoading,
  [SystemTypeTypes.UPDATE_PROFILE_NAME_SUCCESS]: updateProfileNameSuccess,
  [SystemTypeTypes.UPDATE_PROFILE_NAME_FAILURE]: updateProfileNameFailure,

  [SystemTypeTypes.ADD_SYSTEM_TYPE_IMAGES_LOADING]: addSystemTypeImagesLoading,
  [SystemTypeTypes.ADD_SYSTEM_TYPE_IMAGES_SUCCESS]: addSystemTypeImagesSuccess,
  [SystemTypeTypes.ADD_SYSTEM_TYPE_IMAGES_FAILURE]: addSystemTypeImagesFailure,

  [SystemTypeTypes.GET_SYSTEM_TYPE_IMAGES_LOADING]: getSystemTypeImagesLoading,
  [SystemTypeTypes.GET_SYSTEM_TYPE_IMAGES_SUCCESS]: getSystemTypeImagesSuccess,
  [SystemTypeTypes.GET_SYSTEM_TYPE_IMAGES_FAILURE]: getSystemTypeImagesFailure,

  [SystemTypeTypes.DELETE_SYSTEM_TYPE_IMAGES_LOADING]: deleteSystemTypeImagesLoading,
  [SystemTypeTypes.DELETE_SYSTEM_TYPE_IMAGES_SUCCESS]: deleteSystemTypeImagesSuccess,
  [SystemTypeTypes.DELETE_SYSTEM_TYPE_IMAGES_FAILURE]: deleteSystemTypeImagesFailure,

  [SystemTypeTypes.SAVE_AS_NEW_SYSTEM_TYPE_LOADING]: addSystemTypeLoading,
  [SystemTypeTypes.SAVE_AS_NEW_SYSTEM_TYPE_SUCCESS]: addSystemTypeSuccess,
  [SystemTypeTypes.SAVE_AS_NEW_SYSTEM_TYPE_FAILURE]: addSystemTypeFailure,

  [SystemTypeTypes.SELECT_SYSTEM_TYPE_SUCCESS]: selectSystemTypeSuccess,
  [SystemTypeTypes.SELECT_SYSTEM_TYPE_LOADING]: selectSystemTypeLoading,
  [SystemTypeTypes.SELECT_SYSTEM_TYPE_FAILURE]: selectSystemTypeFailure,

  [SystemTypeTypes.GET_LOGS_SUCCESS]: getLogsSuccess,
  [SystemTypeTypes.GET_LOGS_LOADING]: getLogsLoading,
  [SystemTypeTypes.GET_LOGS_FAILURE]: getLogsFailure,

  [SystemTypeTypes.GET_TODO_LOGS_SUCCESS]: getTodoLogsSuccess,
  [SystemTypeTypes.GET_TODO_LOGS_LOADING]: getTodoLogsLoading,
  [SystemTypeTypes.GET_TODO_LOGS_FAILURE]: getTodoLogsFailure,

  [SystemTypeTypes.DESELECT_SYSTEM_TYPE]: deselectSystemType,

  [SystemTypeTypes.SET_EXPANDED_PROFILE]: setExpandedProfile,
  [SystemTypeTypes.SET_EXPANDED_CONFIGURATION]: setExpandedConfiguration,
  [SystemTypeTypes.ADD_SYSTEM_TYPE_ATTACHMENTS]: addSystemTypeAttachments,
})
