import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'i18n'
// Material UI
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Typography, CircularProgress } from '@material-ui/core'

const useStyles = makeStyles(theme => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loader: {
    marginRight: theme.spacing(0.5),
  },
}))

const LoadingText = props => {
  const { title = i18n.t('loadingText.default.title') } = props
  const classes = useStyles()
  return (
    <Typography align='center' component='div' variant='body2' className={classes.root}>
      <CircularProgress size={14} className={classes.loader}/> {title}...
    </Typography>
  )
}

LoadingText.propTypes = {
  title: PropTypes.string,
}

export default LoadingText
