import React from 'react'
import PropTypes from 'prop-types'
// import { DatePicker } from 'material-ui-pickers'
// Material UI
import TextField from '@material-ui/core/TextField'
// Icons
// Project deps
import { withMaxPrecision } from 'utils/numeric'
import { getValue } from 'utils/templatedForm'
import { getFormFieldErrorMessage } from '../utils'
import StyledTooltip from 'components/reusable/StyledTooltip'

/**
 * Renders a floating point number input field.
 * @param name The name of the field.
 * @param option The field definition.
 * @return A component for inputting floating point data.
 */

class FloatOption extends React.Component {
  constructor (props) {
    super(props)
    const value = this.getPrecisedValue(props)
    this.state = {
      value,
      originalValue: value,
    }
  }

  componentDidUpdate () {
    const newValue = this.getPrecisedValue(this.props)
    if (this.state.originalValue !== newValue) {
      this.setState({
        value: newValue,
        originalValue: newValue,
      })
    }
  }

  getPrecisedValue = props => {
    const {
      option,
    } = props
    const rawValue = getValue(props)
    const precision = typeof option.precision === 'number' ? option.precision : 10
    const value = typeof rawValue === 'string' ? withMaxPrecision(rawValue, precision) : ''
    return value
  }

  onChange = event => {
    const { option } = this.props
    const precision = typeof option.precision === 'number' ? option.precision : 10
    const stringValue = event.target.value
    const fixedValue = withMaxPrecision(stringValue, precision)
    this.setState({ value: fixedValue })
  }

  callOnChange = value => {
    const { originalValue } = this.state
    const { setValue = () => {}, option, name } = this.props
    const { onChange: onChangeCoordinate } = option

    if (originalValue !== value) {
      if (typeof onChangeCoordinate === 'function') onChangeCoordinate(name, value, this.props)
      setValue(name, value, option)
    }
  }

  onBlur = event => {
    const { value } = this.state
    this.callOnChange(value)
  }
  onKeyDown = event => {
    if (event.keyCode === 13 && this.input) this.input.blur()
  }
  onWheel = event => {
    if (document.activeElement === event.target) {
      const tempNode = document.activeElement
      event.target.blur()
      setTimeout(() => {
        tempNode.focus()
      }, 0)
    }
    return false
  }
  setRef = node => {
    this.input = node
  }

  render () {
    const { value } = this.state
    const {
      state,
      extra,
      option,
      disabled,
      formTemplate,
      values: formValues,
    } = this.props
    const { extraRender, tooltip, variant = 'standard', placeholder = '', inputProps = {} } = option
    const { min, max } = inputProps

    const errorMessage = getFormFieldErrorMessage(value, option, formValues, state, extra, formTemplate, this.props)
    const error = Boolean(errorMessage)

    const label = option.name // getFormFieldLabel(value, option, formValues, state, extra, formTemplate, this.props)
    return (
      <StyledTooltip title={tooltip || ''} placement='top-start'>
        <div>
          <TextField
            variant={variant}
            inputRef={this.setRef}
            {...error ? {
              label: errorMessage,
              placeholder: label,
              InputLabelProps: {
                shrink: true,
              },
            } : {
              label: label,
              placeholder: placeholder,
            }}
            error={error}
            value={value}
            type='number'
            onChange={this.onChange}
            onBlur={this.onBlur}
            onKeyDown={this.onKeyDown}
            onWheel={this.onWheel}
            disabled={disabled}
            fullWidth
            {...min && { min: typeof min === 'function' ? min(state, formValues, value, this.props) : min }}
            {...max && { max: typeof max === 'function' ? max(state, formValues, value, this.props) : max }}
          />
          { typeof extraRender === 'function' && extraRender(state, formValues, extra, this.props) }
        </div>
      </StyledTooltip>
    )
  }
}

FloatOption.propTypes = {
  value: PropTypes.string,
  label: PropTypes.any,
  state: PropTypes.object,
  extra: PropTypes.any,
  name: PropTypes.string,
  option: PropTypes.object,
  disabled: PropTypes.bool,
  classes: PropTypes.object,
  values: PropTypes.object,
  setValue: PropTypes.func,
  formTemplate: PropTypes.object,
}

export default FloatOption
