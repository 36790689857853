import { withStyles } from '@material-ui/styles'
import StyledTooltip from '../StyledTooltip'

const StyledTooltipMenu = withStyles({
  tooltip: {
    backgroundColor: '#fff',
    maxWidth: 'none',
    color: '#000',
    boxShadow: '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)',
  },
})(StyledTooltip)

export default StyledTooltipMenu
