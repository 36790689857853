import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getDeactivateSubscriptionFailureMessage, getDeactivateSubscriptionState } from 'modules/billingAgreement/selectors'
import { CircularProgress } from '@material-ui/core'
import { Check, CloseOutlined } from '@material-ui/icons'

const SubscriptionDeactivationTreeNode = props => {
  const { subscription, label } = props
  const deactivationStatus = useSelector(state => getDeactivateSubscriptionState(state, subscription.id))
  const failureMessage = useSelector(state => getDeactivateSubscriptionFailureMessage(state, subscription.id))
  return (
    <div style={{ width: '100%' }}>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', lineHeight: 2 }}>
          <b style={{ marginRight: 4 }}><i>{label}</i></b>
          {deactivationStatus === 'loading'
            ? <CircularProgress size={20}/>
            : deactivationStatus === 'failed'
              ? <CloseOutlined style={{ color: 'red', borderRadius: 4, padding: 2, backgroundColor: '#ff9e9e' }}/>
              : deactivationStatus === 'success'
                ? <Check style={{ color: 'green', backgroundColor: '#7fef7f', borderRadius: 4, padding: 2 }}/>
                : null
          }
        </div>
        {subscription.label}
      </div>
      {deactivationStatus === 'failed' && failureMessage && <div style={{ marginLeft: -8, marginRight: -8, marginBottom: -4, color: '#fff', borderRadius: 2, padding: '2px 6px', backgroundColor: 'red' }}>{failureMessage}</div>}
    </div>
  )
}

SubscriptionDeactivationTreeNode.propTypes = {
  subscription: PropTypes.object,
  label: PropTypes.string,
}

export default SubscriptionDeactivationTreeNode
