import { createReducer } from 'reduxsauce'

import { INITIAL_STATE } from './initialState'
import { UrlTypes } from './actions'

export const urlChange = (state, { url }) => {
  return state.merge({
    url,
    prevUrl: !state.get('url') ? state.get('prevUrl') : state.get('url'),
  })
}

export const setRedirectURL = (state, { url }) => {
  return state.merge({
    redirectURL: url,
  })
}

export const reducer = createReducer(INITIAL_STATE, {
  [UrlTypes.CHANGE_URL]: urlChange,
  [UrlTypes.SET_REDIRECT_URL]: setRedirectURL,
})
