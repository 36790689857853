import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  // changeUrl
  changeUrl: ['url'],
  setRedirectURL: ['url'],
})

export const UrlTypes = Types
export default Creators
