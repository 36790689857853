import { path } from 'ramda'
import { StripePlanType } from 'types/billingAgreement'
import { getUTCDate } from './dateTime'

export function isPlanHasRoverFeature (plan) {
  if (!plan) {
    return false
  }
  return plan.hasRoverFeature || plan.has_rover_feature || plan.token === 'rover_non_stripe'
}

export function isPlanRequireRover (plan, rovers = []) {
  if (!plan) {
    return false
  }
  const requireRover = plan.requireRover || plan.require_rover
  return requireRover === 'required' || isPlanRequiredIfOwnRover(plan, rovers)
}

export function isPlanNotRequireRover (plan, rovers = []) {
  if (!plan) {
    return false
  }
  const requireRover = plan.requireRover || plan.require_rover
  return requireRover === 'not_required' || isPlanNotRequiredIfOwnRover(plan, rovers)
}

export function isPlanRequiredIfOwnRover (plan, rovers = []) {
  if (!plan) {
    return false
  }
  const requireRover = plan.requireRover || plan.require_rover
  return (requireRover === 'required_if_own' && rovers.length > 0)
}

export function isPlanNotRequiredIfOwnRover (plan, rovers = []) {
  if (!plan) {
    return false
  }
  const requireRover = plan.requireRover || plan.require_rover
  return (requireRover === 'required_if_own' && rovers.length <= 0)
}

export function isPlanHasNoRover (plan) {
  if (!plan) {
    return false
  }
  const requireRover = plan.requireRover || plan.require_rover
  return !requireRover || requireRover === 'not_applied'
}

export function isPlanHasRover (plan, rovers = []) {
  return !isPlanHasNoRover(plan)
}

export function isPlanHasSEFeature (plan) {
  if (!plan) {
    return false
  }
  return plan.hasSEFeature || plan.has_se_feature || plan.token === 'se_no_grants'
}

export function isPlanSEMissionGuidance (plan) {
  if (!plan) {
    return false
  }
  return plan.name === 'SE Mission Guidance'
}

export function isPlanFlightPlanner (plan) {
  if (!plan) {
    return false
  }
  return plan.name === 'Flight Planner' || plan.token === 'flight_planner_non_stripe'
}

export function isPlsCarePlan (plan) {
  if (!plan) {
    return false
  }
  return (plan.type || plan.p_type) === StripePlanType.PLS_CARE
}

export function arePlanTokensSame (planA, planB) {
  if (!planA || !planB) {
    return false
  }
  return planA.id === planB.id
}

export const CheckoutSessionType = {
  CREATE: 'create-subscription',
  CREATE_SCHEDULED: 'create-subscription-scheduled',
  CANCEL_CREATE: 'cancel-create-subscription',
  EDIT_CARD: 'edit-card',
  CANCEL_EDIT_CARD: 'cancel-edit-card',
}

export const CheckoutSessionState = {
  PENDING: 'pending',
  PAID: 'paid',
  FAILED: 'failed',
}

/**
 * Returns only buyable sales products (token and price should exist)
 * @note this function used to determine sales products to be able to bought by the user
 * @param {Array.<SalesProduct>} salesProducts
 * @returns {Array.<SalesProduct>}
 */
export function getBuyableSalesProducts (salesProducts) {
  return salesProducts.filter(salesProduct => salesProduct.isPublic && getBuyableDurations(salesProduct).length > 0)
}
/**
 * Returns only buyable sales products (token and price should exist)
 * @note this function used to determine sales products to be able to bought by the user
 * @param {Array.<SalesProduct>} salesProducts
 * @returns {Array.<SalesProduct>}
 */
export function getBuyableSalesBundles (salesBundles) {
  return salesBundles.filter(salesProduct => getBuyableDurations(salesProduct).length > 0)
}
/**
 * Checks if the given `salesProduct` has type of `plan`
 * @param {SalesProduct} salesProduct
 * @returns {Boolean}
 */
export function isPlanSalesProduct (salesProduct) {
  if (!salesProduct) {
    return false
  }
  return salesProduct.type === 'plan' && !isPluginSalesProduct(salesProduct)
}
/**
 * Checks if the given `salesProduct` has type of `extension`
 * @param {SalesProduct} salesProduct
 * @returns {Boolean}
 */
export function isExtensionSalesProduct (salesProduct) {
  if (!salesProduct) {
    return false
  }
  return salesProduct.type === 'extension'
}
/**
 * Checks if the give salesProduct is plugin.
 * Plugin is the salesProduct which has dependencies in at least one duration and not a bundle itself.
 * @param {SalesProduct} salesProduct
 * @returns {Boolean}
 */
export function isPluginSalesProduct (salesProduct) {
  if (!salesProduct) {
    return false
  }
  return salesProduct.durations.some(duration => duration.dependencies.length > 0) && !isBundleSalesProduct(salesProduct)
}
/**
 * Checks if the given `salesProduct` has type of `extension`
 * @param {SalesProduct} salesProduct
 * @returns {Boolean}
 */
export function isMaintenanceSalesProduct (salesProduct) {
  if (!salesProduct) {
    return false
  }
  return salesProduct.type === 'maintenance' || salesProduct.sales_product_type === 'maintenance'
}
/**
 * Checks if the given `salesProduct` has type of `extension`
 * @param {SalesProduct} salesProduct
 * @returns {Boolean}
 */
export function isBundleSalesProduct (salesProduct) {
  if (!salesProduct) {
    return false
  }
  return salesProduct.type === 'bundle'
}
/**
 * Returns only durations which can be bought by the user
 * @param {SalesProduct} salesProduct
 * @returns {Array.<Duration>}
 */
export function getBuyableDurations (salesProduct) {
  const durations = salesProduct.durations
  return durations.filter(duration => duration.price && duration.token && !duration.deprecated)
}
/**
 * Checks if the current duration has `perpetual` type
 * @param {Duration} duration
 * @returns {Boolean}
 */
export function isPerpetualDuration (duration) {
  if (!duration) {
    return false
  }
  return duration && duration.type === 'perpetual'
}
/**
 * Checks if the current duration has `demo` type
 * @param {Duration} duration
 * @returns {Boolean}
 */
export function isDemoDuration (duration) {
  if (!duration) {
    return false
  }
  return duration && duration.type === 'demo'
}

/**
 * Returns licensing products of the from `salesProduct` lower cased
 * @param {SalesProduct} salesProduct
 * @returns {Array}
 */
export function getSalesProductLicensingProductsLowerCased (salesProduct) {
  if (!salesProduct) {
    return []
  }
  return (salesProduct.licensingProducts || (salesProduct.licensing_products || []).map(licensingProduct => licensingProduct.name) || []).map(name => name.toLowerCase())
}
/**
 * Checks if `salesProduct` has `SpatialExplorer` licensing product
 * @param {SalesProduct} salesProduct
 * @returns {Boolean}
 */
export function isSalesProductHasSELicensing (salesProduct) {
  if (!salesProduct) {
    return false
  }
  return getSalesProductLicensingProductsLowerCased(salesProduct).find(licensingProductName => licensingProductName === 'spatialexplorer')
}
/**
 * Checks if `salesProduct` has `SpatialRover` licensing product
 * @param {SalesProduct} salesProduct
 * @returns {Boolean}
 */
export function isSalesProductHasRoverLicensing (salesProduct) {
  if (!salesProduct) {
    return false
  }
  return getSalesProductLicensingProductsLowerCased(salesProduct).find(licensingProductName => licensingProductName === 'spatialrover')
}

export function isDurationAvailable (dependencies = [], subscriptions = []) {
  return dependencies.length === 0 || dependencies.some(dependencyDuration => {
    return subscriptions.find(subscription => {
      return dependencyDuration.id === path(['product_duration', 'id'], subscription)
    })
  })
}

export function getLicensingProductsForSalesProduct (salesProduct, allLicensingProducts = []) {
  if (!salesProduct) {
    return []
  }
  return getSalesProductLicensingProductsLowerCased(salesProduct)
    .map(licensingProductName => allLicensingProducts.find(licensingProduct => licensingProduct.name.toLowerCase() === licensingProductName))
    .filter(Boolean)
}
/**
 * Checks if the give `licensingProduct` has PLS license key type
 * @param {LicensingProduct} licensingProduct
 * @returns {Boolean}
 */
export function isLicensingProductHasPLSKey (licensingProduct) {
  if (!licensingProduct) {
    return false
  }
  return licensingProduct.license_key_type === 'pls_license_key'
}
/**
 * Checks if the give `licensingProduct` has IE license key type
 * @param {LicensingProduct} licensingProduct
 * @returns {Boolean}
 */
export function isLicensingProductHasIEKey (licensingProduct) {
  if (!licensingProduct) {
    return false
  }
  return licensingProduct.license_key_type === 'ie_license_key'
}
/**
 * Checks if we can enter license key in UI forms
 * @param {Array.<LicensingProduct>} licensingProducts
 * @returns {Boolean}
 */
export function isLicenseKeyOptionAvailable (licensingProducts) {
  return licensingProducts.some(isLicensingProductHasIEKey)
}
/**
 * Checks if we can enter evaluation days in UI forms
 * @param {Array.<LicensingProduct>} licensingProducts
 * @returns {Boolean}
 */
export function isEvaluationDaysOptionAvailable (licensingProducts) {
  return licensingProducts.some(isLicensingProductHasPLSKey)
}
export const isSalesProductHasHardwareWarranty = salesProduct => {
  if (!salesProduct) {
    return false
  }
  return salesProduct && salesProduct.hardwareWarranty
}
/**
 * Returns gaps between pair of maintenances (between 0 and 1, between 1 and 2 and so on...)
 * Gap is the period of time when user doesn't have warranty for the subscription
 * @param {Array.<Subscription>} maintenenaces array of maintenance subscriptions
 * @returns {Boolean}
 */
export function getMaintenancesGaps (maintenenaces) {
  const gaps = []
  for (let i = 1; i < maintenenaces.length; i++) {
    const previousMaintenace = maintenenaces[i - 1]
    const currentMaintenance = maintenenaces[i]
    const previousMaintenaceDate = getUTCDate(previousMaintenace.end_date)
    const currentMaintenanceDate = currentMaintenance.first_payment_date ? getUTCDate(currentMaintenance.first_payment_date) : getUTCDate(currentMaintenance.activated_date)
    if (currentMaintenanceDate.isAfter(previousMaintenaceDate)) {
      gaps.push([previousMaintenaceDate, currentMaintenanceDate])
    }
  }
  return gaps
}
/**
 * Checks if the hardware warranty period for the subscription is expired
 * @param {Subscription} subscription
 * @returns {Boolean}
 */
export function isHardwareWarrantyPeriodExpired (subscription) {
  const hardwareWarrantyYears = path(['plan', 'hardwareWarranty'], subscription)
  return hardwareWarrantyYears && getUTCDate(subscription.activated_date).add(hardwareWarrantyYears, 'years').isBefore(getUTCDate())
}

/**
 * Returns a hash map where `key` is the product name and value is the array of sales products which belongs to that particular product
 * Used to categorize sales products by product name (LiDARMill, Spatial Explorer, ...etc)
 * @param {Array.<SalesProduct>} salesProducts
 * @returns {Object}
 */
export function getSalesProductsGrouppedByProduct (salesProducts) {
  const grouppedSalesProducts = salesProducts.reduce((all, salesProduct) => {
    const licensingProductName = salesProduct.licensingProducts[0]
    return {
      ...all,
      [licensingProductName]: [
        ...(all[licensingProductName] || []),
        salesProduct,
      ],
    }
  }, {})
  return grouppedSalesProducts
}

/**
 * Returns true if the given `salesProduct` has 'TerraSolid' licensing product
 * @param {SalesProduct} salesProduct
 * @returns {Boolean}
 */
export const isTerraSolidSaledProduct = salesProduct => {
  return (salesProduct.licensingProducts || []).find(licensingProductName => licensingProductName === 'TerraSolid')
}
