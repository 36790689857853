import { create as createRand } from 'random-seed'

/**
 * Calculates a random color from a text. Will always calculate the same random color
 * for the same text.
 * @param string The text to colorize.
 * @param saturation The fixed saturation of the color. Default is 100.
 * @param lightness The fixed lightness of the color. Default is 87.5 for colors from
 *  the pastel color-space.
 * @return The random color to be used as CSS color value in `hsl(h, s, l)` format.
 */
export function colorize (text, saturation = 100, lightness = 87.5) {
  const rand = createRand(text)
  const hue = rand.range(360)
  return `hsl(${hue},${saturation}%,${lightness}%)`
}
