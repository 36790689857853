import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  getCustomersStatistic: null,
  getCustomersStatisticLoading: null,
  getCustomersStatisticSuccess: ['customers'],
  getCustomersStatisticFailure: ['errorMessage'],

  getSubscriptionsStatistic: ['startDate', 'endDate'],
  getSubscriptionsStatisticLoading: null,
  getSubscriptionsStatisticSuccess: ['activeCustomers', 'canceledCustomers'],
  getSubscriptionsStatisticFailure: ['errorMessage'],

  getAllSubscriptions: ['startDate', 'endDate'],
  getAllSubscriptionsLoading: null,
  getAllSubscriptionsSuccess: ['allSubscriptions', 'activeSubscriptions', 'canceledSubscriptions'],
  getAllSubscriptionsFailure: null,

  getRefunds: ['startDate', 'endDate'],
  getRefundsLoading: null,
  getRefundsSuccess: ['refunds'],
  getRefundsFailure: ['errorMessage'],

  downloadCSVSubscriptions: null,
  downloadCSVSubscriptionsLoading: null,
  downloadCSVSubscriptionsSuccess: null,
  downloadCSVSubscriptionsFailure: null,

  getSessionsForProduct: ['productName', 'startDate', 'endDate'],
  getSessionsForProductLoading: ['productName'],
  getSessionsForProductSuccess: ['productName', 'sessions', 'startDate', 'endDate'],
  getSessionsForProductFailure: ['productName', 'errorMessage'],
  changeSessionsPreferences: ['productName', 'data'],
})

export const StatisticsTypes = Types

export default Creators
