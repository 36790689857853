import React from 'react'
import { Trans } from 'react-i18next'
// import { DatePicker } from 'material-ui-pickers'
// Material UI
// Icons
// Project deps
import {
  cleanJobOptionValues,
  isVector3FormField,
  isFloatFormField,
  isIntegerFormField,
  isCoordinateFormField,
  isCoordinateSystemsFormField,
  isTabsFormField,
  isCesiumMapFormField,
  isCustomCRSFormField,
  isGPSWeekFormField,
  isStringFormField,
  isSelectionFormField,
} from 'utils/jobOptions'
import { isValueSet } from 'utils/templatedForm'
import { DataType } from 'types/form'

export const valueShouldBeTransformed = (transform, transformOnChangeOf, names) => {
  return transform && transformOnChangeOf && names.find(name => transformOnChangeOf.indexOf(name) !== -1)
}

// This function is used outside of this component to make possible the resetting the fields of the template
export const resetValues = (name, props, prevProps) => {
  const { formTemplate = {}, state = {}, extra = '', actions = {}, extraProps = {}, values, onChange, ...restProps } = props
  const thisProps = prevProps || props
  const names = Array.isArray(name) ? name : [name]

  const reinitializedValues = names.reduce((result, fieldName) => {
    const field = formTemplate[fieldName]
    const { initialValue } = field
    // If initial value was not defined on the field the value will be left empty.
    if (typeof initialValue === 'undefined') {
      return result
    }
    // If the initial value is a function it needs to be evaluated in order to get the desired value.
    if (typeof initialValue === 'function') {
      const calculatedValue = initialValue(state, extra, {
        formTemplate,
        actions,
        extraProps,
        values: result,
        name: fieldName,
        ...restProps,
      })
      // Do not write `undefined` into the state because it would lead to the user seeing an "undefined"
      // in the value of the fields.
      if (typeof calculatedValue === 'undefined') {
        return result
      }
      return {
        ...result,
        [fieldName]: calculatedValue,
      }
    }
    // Otherwise the literal value will be used.
    return {
      ...result,
      [fieldName]: initialValue,
    }
  }, values || {})
  const newValues = Object.keys(reinitializedValues).reduce(
    (result, fieldName) => {
      const field = formTemplate[fieldName]
      const { transform, transformOnChangeOf } = field
      const shouldBeTransformed = valueShouldBeTransformed(transform, transformOnChangeOf, Object.keys(formTemplate))
      if (shouldBeTransformed) {
        return {
          ...result,
          [fieldName]: transform(
            reinitializedValues[fieldName],
            thisProps.state,
            reinitializedValues,
            thisProps.extra,
            formTemplate,
            shouldBeTransformed,
            undefined,
            undefined,
            extraProps,
          ),
        }
      }
      return result
    }, reinitializedValues,
  )

  if (onChange && formTemplate) {
    const cleanedValues = cleanJobOptionValues(formTemplate, newValues, state, extra)
    const valid = validateJobOptionsForm(newValues, formTemplate, state, extra, props)
    onChange(cleanedValues, valid, newValues)
  }
}

/**
 * Performs a check for whether a provided string is a valid floating point number.
 * @param value The value to perform the check on.
 * @return `true` if the provided value was a valid floatingpoint number and `false` otherwise.
 */
export function isFloatFormFieldValid (value, state, formValues, formField, options) {
  if (typeof value !== 'string') {
    return false
  }
  const isFloatField = Boolean(value.match(/^[+-]?([0-9]*[.])?[0-9]+$/))
  if (!isFloatField) {
    return false
  }
  if (formField) {
    return !getNumberSpecificErrorMessage(value, state, formValues, formField, options)
  }
  return true
}

/**
 * Performs a check for whether a provided string is a valid integer number.
 * @param value The value to perform the check on.
 * @return `true` if the provided value was a valid integer number and `false` otherwise.
 */
export function isIntegerFormFieldValid (value, state, formValues, formField, options) {
  if (typeof value !== 'string' && typeof value !== 'number') {
    return false
  }
  const stringValue = value.toString()
  const isIntegerField = Boolean(stringValue.match(/^-?\d+$/))
  if (!isIntegerField) {
    return false
  }
  if (formField) {
    return !getNumberSpecificErrorMessage(value, state, formValues, formField, options)
  }
  return true
}

/**
 * Performs a check for whether a provided value is a valid coordinate.
 * @param value The value to perform the check on.
 * @return `true` if the provided value was a valid coordinate and `false` otherwise.
 */
export function isCoordinateFormFieldValid (value) {
  if (typeof value !== 'string') {
    return false
  }
  const floatRegex = /^[-]?\d+(\.\d+)?$/g
  return Boolean(value.match(floatRegex))
}

function getNumberSpecificErrorMessage (value, formField, state, formValues, options) {
  const { inputProps = { } } = formField
  const { min, max, step, strictValues } = inputProps
  const numberValue = +value
  let minValue = typeof min === 'function' ? min(state, formValues, value, options) : min
  minValue = typeof minValue === 'number' ? minValue : -Infinity
  let maxValue = typeof max === 'function' ? max(state, formValues, value, options) : max
  maxValue = typeof maxValue === 'number' ? maxValue : Infinity
  const stepValue = typeof step === 'function' ? step(state, formValues, value, options) : step
  if (numberValue < minValue) {
    return `Min value is ${minValue}`
  }
  if (numberValue > maxValue) {
    return `Max value is ${maxValue}`
  }
  if (stepValue && strictValues) {
    const coeff = Math.floor(numberValue / stepValue)
    if (numberValue % stepValue !== 0) {
      let min = coeff * stepValue
      min = min < stepValue ? stepValue : min
      // This message is displayed by default when you hovering over the textfield but I want it to be shown to the user
      return `Please enter a valid value. The two nearest valid values are ${min} and ${min + stepValue}`
    }
  }
}

export function getTypeSpecificErrorMessage (value, formField, state, formValues, options) {
  const { dataType, pattern, emptyPattern, customCheck, multiple } = formField
  if (isStringFormField(dataType)) {
    if (pattern) {
      if (!value.match(pattern) && (emptyPattern ? !emptyPattern.test(value) : true)) {
        return <Trans i18nKey='options.errors.stringPattern' />
      }
    }
    if (customCheck) {
      const { okay, message } = customCheck(value)
      if (!okay) {
        return message
      }
    }
  }
  if (isSelectionFormField(dataType)) {
    if (multiple && 'min' in formField) {
      let { min = 0 } = formField
      const { errorMessageItemName } = formField
      min = typeof min === 'function' ? min(state, formValues) : min
      if (value.length < min) {
        const itemName = errorMessageItemName || 'item'
        return `Select ${min} ${min === 1 ? itemName : `${itemName}s`}`
      }
    }
  }
  if (isIntegerFormField(dataType) || isGPSWeekFormField(dataType)) {
    if (!isIntegerFormFieldValid(value)) {
      return <Trans i18nKey='options.errors.integer' />
    }
    if (isIntegerFormField(dataType)) {
      return getNumberSpecificErrorMessage(value, formField, state, formValues, options)
    }
  }
  if (isFloatFormField(dataType) || isVector3FormField(dataType)) {
    if (!isFloatFormFieldValid(value)) {
      return <Trans i18nKey='options.errors.float' />
    }
    if (isFloatFormField(dataType)) {
      return getNumberSpecificErrorMessage(value, formField, state, formValues, options)
    }
  }
  if (dataType === DataType.DATE) {
    if (value) {
      const { optionProps = {} } = formField
      const { minDate: minDate_, maxDate: maxDate_ } = optionProps
      const minDate = typeof minDate_ === 'function' ? minDate_(value, formValues, options) : minDate_
      const maxDate = typeof maxDate_ === 'function' ? maxDate_(value, formValues, options) : maxDate_
      if (maxDate && value.isAfter(maxDate)) {
        return 'Date should not be after maximal date'
      }
      if (minDate && value.isBefore(minDate)) {
        return 'Date should not be before minimal date'
      }
      if (typeof value === 'object' && !value.isValid()) {
        return value.format()
      }
    }
  }
  if (dataType === DataType.SERIAL_NUMBERS) {
    const { multiple = true } = formField
    const canSelectMany = typeof multiple === 'function' ? multiple(state, formValues) : multiple
    const error = value.length <= 0
    const errorMessage = error ? canSelectMany ? 'You must specify at least one rover serial' : 'You must specify one rover serial' : ''
    return errorMessage
  }
}

/**
 * Checks whether a form field is optional in the context of the application.
 * @param formField The field to check.
 * @param state The state of the application.
 * @param formValues The current values of the form.
 * @param extra Any extra data which will be used in the lambdas in the definition.
 * @return Whether the field is currently optional or not.
 */
export function isFormFieldOptional (formField, formValues, state, extra, formTemplate, shouldCheckTab = false, options) {
  const { optional, optionalForTab } = formField
  if (typeof optional === 'function') {
    return shouldCheckTab
      ? typeof optionalForTab === 'function'
        ? optionalForTab(state, formValues, extra, formField, formTemplate, options)
        : optional(state, formValues, extra, formField, formTemplate, options)
      : optional(state, formValues, extra, formField, formTemplate, options)
  }
  return optional
}

/**
 * Returns the error message for a form field.
 * @param value The field value.
 * @param formField The form field.
 * @param formValues All values of the form.
 * @param extra An extra parameter that will be passed to the lambdas.
 * @param state The whole application's state.
 * @return An appropriate error message.
 */
export function getFormFieldErrorMessage (value, formField, formValues, state, extra, formTemplate, options) {
  const isSet = isValueSet(value, formField.dataType)
  const isOptional = isFormFieldOptional(formField, formValues, state, extra, formTemplate, true, options)
  if ((isSet && isOptional) || (isSet && !isOptional)) {
    return getTypeSpecificErrorMessage(value, formField, state, formValues, options)
  }
  if (!isSet && !isOptional) {
    return <Trans i18nKey='options.errors.required' />
  }
  if (!isSet && isOptional) {
    return undefined
  }
}

function validateJobOption (value, formField, formValues, state, extra, formTemplate, options) {
  const { dataType, pattern, emptyPattern, customCheck, options: formFieldOptions = {}, multiple } = formField
  const isOptional = isFormFieldOptional(formField, formValues, state, extra, formTemplate, false, options)
  if (dataType === DataType.JSON) {
    return !value.error
  }
  if (isIntegerFormField(dataType) || isGPSWeekFormField(dataType)) {
    if (isOptional && !value) {
      return true
    }
    return isIntegerFormFieldValid(value, state, formValues, formField, options)
  }
  if (isStringFormField(dataType)) {
    if (isOptional && (!value || (value && emptyPattern ? emptyPattern.test(value) : true))) {
      return true
    }
    if (pattern) {
      if (!value.match(pattern)) {
        return false
      }
    }
    if (customCheck) {
      const { okay } = customCheck(value)
      return okay
    }
  }
  if (isOptional) {
    return true
  }
  if (dataType === DataType.SELECTION) {
    if (multiple && 'min' in formField) {
      let { min = 0 } = formField
      min = typeof min === 'function' ? min(state, formValues) : min
      if (value.length < min) {
        return false
      }
    }
    return Boolean(value)
  }
  if (dataType === DataType.MULTIPLE_SELECTION) {
    return value.length > 0
  }
  if (dataType === DataType.DATE) {
    if (!value || (value && typeof value === 'object' && !value.isValid())) {
      return false
    }
    const { optionProps = {} } = formField
    const { minDate: minDate_, maxDate: maxDate_ } = optionProps
    const minDate = typeof minDate_ === 'function' ? minDate_(value, formValues, options) : minDate_
    const maxDate = typeof maxDate_ === 'function' ? maxDate_(value, formValues, options) : maxDate_
    if ((maxDate && value.isAfter(maxDate)) || (minDate && value.isBefore(minDate))) {
      return false
    }
  }
  if (dataType === DataType.SERIAL_NUMBERS) {
    return value.length > 0
  }
  if (dataType === DataType.UPLOAD_FILES) {
    const isRequired = typeof formFieldOptions.required === 'function'
      ? formFieldOptions.required(state, formValues, extra, formTemplate, formField, options)
      : formFieldOptions.required
    if (isRequired) {
      const { maxFiles = Infinity, minFiles = 0 } = formFieldOptions
      const maxFilesFinal = typeof maxFiles === 'function'
        ? maxFiles(value, formField, formValues, state, extra, formTemplate, options)
        : maxFiles
      const minFilesFinal = typeof minFiles === 'function'
        ? minFiles(value, formField, formValues, state, extra, formTemplate, options)
        : minFiles
      return value.length >= minFilesFinal && value.length <= maxFilesFinal
    }
  }
  if (isCoordinateSystemsFormField(dataType) ||
      isTabsFormField(dataType) ||
      isCesiumMapFormField(dataType) ||
      isCustomCRSFormField(dataType) ||
      dataType === DataType.IMAGE ||
      dataType === DataType.LAST_CHANGED_TAB ||
      dataType === DataType.TABS_TEMPLATE ||
      dataType === DataType.TEXT ||
      dataType === DataType.REF_STATION_POSITION ||
      dataType === DataType.FILELIST_SELECTOR ||
      dataType === DataType.UPLOAD_FILES ||
      dataType === DataType.RADIO_SELECT
  ) {
    return true
  }
  if (!isValueSet(value, dataType)) {
    return false
  }
  if (isFloatFormField(dataType)) {
    return isFloatFormFieldValid(value, state, formValues, formField, options)
  }
  if (isCoordinateFormField(dataType)) {
    return isCoordinateFormFieldValid(value, state, formValues, formField, options)
  }
  return true
}

/**
 * Validates a full job options form. Will take into account all validations for all possible
 * option types and return whether they are all valid.
 * @param formValues The values the user typed into the form fields.
 * @param options The template from which the form was generated.
 * @return If all values were valid.
 */
export function validateJobOptionsForm (formValues = {}, formTemplate = {}, state = {}, extra, options) {
  const isFormValid = (formValues && formTemplate) ? Object.keys(formTemplate).every(name => {
    const formField = formTemplate[name]
    const value = formValues[name]
    return validateJobOption(value, formField, formValues, state, extra, formTemplate, options)
  }) : false
  return isFormValid
}

export const setInitialValues = props => {
  const { values } = props
  if (typeof values === 'undefined') {
    callOnChange(props, getResetValues(props))
  }
}

/**
 * Cleans and validates the values and calls `onChange`.
*/
export const callOnChange = (props, newValues) => {
  const { state, extra, formTemplate, onChange } = props
  if (onChange && formTemplate) {
    const cleanedValues = cleanJobOptionValues(formTemplate, newValues, state, extra)
    const valid = validateJobOptionsForm(newValues, formTemplate, state, extra, props)
    onChange(cleanedValues, valid, newValues)
  }
}

export const getInitialStateValues = ({ state, extra, formTemplate, actions, extraProps = {} }) => {
  // When the `extra` property or the template itself changed the values need to be restored to the
  // initial values. Those can be configured in the template as either functions calculating the initial values
  // from the `state` and the `extra` properties, or literal values.
  return Object.keys(formTemplate).reduce(
    (result, fieldName) => {
      const field = formTemplate[fieldName]
      const { initialValue } = field
      // If initial value was not defined on the field the value will be left empty.
      if (typeof initialValue === 'undefined') {
        return result
      }
      // If the initial value is a function it needs to be evaluated in order to get the desired value.
      if (typeof initialValue === 'function') {
        const calculatedValue = initialValue(
          state,
          extra,
          {
            formTemplate,
            actions,
            extraProps,
            values: result,
            name: fieldName,
          })
        // Do not write `undefined` into the state because it would lead to the user seeing an "undefined"
        // in the value of the fields.
        if (typeof calculatedValue === 'undefined') {
          return result
        }
        return {
          ...result,
          [fieldName]: calculatedValue,
        }
      }
      // Otherwise the literal value will be used.
      return {
        ...result,
        [fieldName]: typeof initialValue === 'undefined' ? null : initialValue,
      }
    },
    {},
  )
}

export const getResetValues = (props, thisProps) => {
  const { formTemplate, extraProps = {} } = props
  const selfProps = thisProps || props
  if (typeof formTemplate !== 'object') {
    return {}
  }
  // When the `extra` property or the template itself changed the values need to be restored to the
  // initial values. Those can be configured in the template as either functions calculating the initial values
  // from the `state` and the `extra` properties, or literal values.
  const newValues = getInitialStateValues(props)

  return Object.keys(formTemplate).reduce(
    (result, fieldName) => {
      const field = formTemplate[fieldName]
      const { transform, transformOnChangeOf } = field
      const shouldBeTransformed = valueShouldBeTransformed(transform, transformOnChangeOf, Object.keys(formTemplate))
      if (shouldBeTransformed) {
        return {
          ...result,
          [fieldName]: transform(
            newValues[fieldName],
            selfProps.state,
            newValues,
            selfProps.extra,
            formTemplate,
            shouldBeTransformed,
            undefined,
            undefined,
            extraProps,
          ),
        }
      }
      return result
    },
    newValues,
  )
}
