import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  getCompanyCalibrations: ['companyId'],
  getCompanyCalibrationsLoading: ['companyId'],
  getCompanyCalibrationsSuccess: ['companyId', 'calibrations'],
  getCompanyCalibrationsFailure: ['companyId'],

  updateCalibration: ['companyId', 'calibrationId', 'data'],
  updateCalibrationLoading: null,
  updateCalibrationSuccess: ['companyId', 'calibrationId', 'calibration'],
  updateCalibrationFailure: ['errorMessage'],

  deleteCalibration: ['companyId', 'calibrationId'],
  deleteCalibrationLoading: null,
  deleteCalibrationSuccess: ['companyId', 'calibrationId'],
  deleteCalibrationFailure: ['errorMessage'],

  addCalibration: ['companyId', 'data', 'successCallback'],
  addCalibrationLoading: null,
  addCalibrationSuccess: ['companyId', 'calibration'],
  addCalibrationFailure: null,

})

export const CalibrationTypes = Types
export default Creators
