import React from 'react'
import PropTypes from 'prop-types'
// Material UI
import { withStyles } from '@material-ui/core/styles'
// Project deps
import CustomTable from 'components/Licensing/CustomTable'

const styles = theme => ({
  ...theme.mixins.licensing,
})

const LogsTable = props => {
  const {
    logs,
    getLogs,
    classes,
    pagination,
    isLoading,
    serverSide = true,
    enableSearch = true,
    enablePagination = true,
    addRelatedObject = true,
  } = props

  const columns = [
    {
      id: 'timestamp',
      label: 'Date',
      cellStyle: {
        width: '12.5%',
      },
    },
    {
      id: 'ip',
      label: 'IP',
      cellStyle: {
        width: '10%',
      },
    },
    {
      id: 'user',
      label: 'User',
      cellStyle: {
        width: '10%',
      },
    },
    {
      id: 'text',
      label: 'Action',
      render: (item, value) => addRelatedObject
        ? `${item.related_object_type ? `${item.related_object_type} ` : ''}${item.related_object_id ? `${item.related_object_id}. ` : ''}${value}`
        : `${value}`,
    },
  ]

  return (
    <CustomTable
      isLoading={isLoading}
      stickyHeader
      disableGutters
      headerCellProps={{
        classes: {
          root: classes.headerStickyCell,
        },
      }}
      headerRowProps={{
        classes: {
          root: classes.headerRow,
        },
      }}
      idField='id'
      pathPrefix='root'
      // searchFields={[ 'root.searchString', ]}
      // searchMapPathToId={{ 'root': 'searchString', }}
      sortOrder='desc'
      sortBy='timestamp'
      noDataLabel='There are no logs'
      onSearchChange={getLogs}
      page={pagination ? pagination.page : 1}
      total={pagination ? pagination.total : logs.length}
      serverSide={serverSide}
      enableSearch={enableSearch}
      enablePagination={enablePagination}
      columns={columns}
      data={logs}
    />
  )
}

LogsTable.propTypes = {
  logs: PropTypes.array,
  isLoading: PropTypes.bool,
  addRelatedObject: PropTypes.bool,
  serverSide: PropTypes.bool,
  enableSearch: PropTypes.bool,
  enablePagination: PropTypes.bool,
  getLogs: PropTypes.func,
  classes: PropTypes.object,
  pagination: PropTypes.object,
}

export default withStyles(styles)(LogsTable)
