import React from 'react'
import PropTypes from 'prop-types'
// import { DatePicker } from 'material-ui-pickers'
// Material UI
import { FormControl, FormLabel, FormControlLabel, RadioGroup, Radio } from '@material-ui/core'
// Icons
// Project deps
// import { isCoordinateLieInRectangle } from '../../templates/utils'
import { getValue } from 'utils/templatedForm'

/**
 * Renders a boolean input field.
 * @param name The name of the field.
 * @param option The field definition.
 * @return A component for inputting boolean data.
 */
const RadioSelectOption = props => {
  const {
    name,
    option,
    disabled,
    setValue,
    state,
    values,
    extra,
    extraProps,
  } = props
  const { onChange: onChangeFromOption, editable = true, options, disabledOptions, spacing = 'between' } = option
  const value = getValue(props)
  const onChange = editable ? event => {
    const { value } = event.target
    if (typeof onChangeFromOption === 'function') {
      onChangeFromOption(name, value, props)
    }
    setValue(name, value, option)
  } : () => {}
  // It is possible to specify the options for the select field as an array of strings as well as
  // a function returning the array of strings.
  // If the `options` property was a function, it needs to be evaluated...
  let createdOptions = []
  if (typeof options === 'function') {
    createdOptions = options(state, values, extra)
  } else {
    // ... otherwise the property can be used directly.
    createdOptions = options || []
  }
  const allDisabledOptions = typeof disabledOptions === 'function' ? disabledOptions(state, values, extra, extraProps) : []
  const isDisabled = disabled
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{option.name}</FormLabel>
      <RadioGroup onChange={onChange} value={value} row aria-label="gender" name="row-radio-buttons-group">
        {createdOptions.map(({ label, value }) => {
          const isOptionDisabled = isDisabled || allDisabledOptions.includes(value)
          return (
            <FormControlLabel
              disabled={isOptionDisabled}
              key={value}
              value={value}
              style={spacing === 'between' ? { flex: 1 } : {}}
              control={<Radio disabled={isOptionDisabled} />}
              label={label}
            />
          )
        })}
      </RadioGroup>
    </FormControl>
  )
}

RadioSelectOption.propTypes = {
  state: PropTypes.object,
  extraProps: PropTypes.object,
  formValues: PropTypes.object,
  extra: PropTypes.any,
  name: PropTypes.string,
  option: PropTypes.object,
  disabled: PropTypes.bool,
  classes: PropTypes.object,
  values: PropTypes.object,
  setValue: PropTypes.func,
  formTemplate: PropTypes.object,
}

export default RadioSelectOption
