import { createReducer } from 'reduxsauce'
import { INITIAL_STATE } from './initialState'
import { RecallsTypes } from './actions'
import { updatePagination } from '../utils'
import { mapById, removeById } from 'utils/list'
import { convertRawRecall, convertRawRecallEvent } from 'types/recalls'

// Get recalls
export const getRecallsLoading = state => {
  return state.merge({
    getRecallsIsLoading: true,
  })
}
export const getRecallsSuccess = (state, { recalls, pagination }) => {
  return state.merge({
    getRecallsIsLoading: false,
    recalls: recalls.map(convertRawRecall),
    pagination,
  })
}
export const getRecallsFailure = state => {
  return state.merge({
    getRecallsIsLoading: true,
  })
}

// Get recall events
export const getRecallEventsLoading = (state, { recallId }) => {
  return state.merge({
    getRecallEventsIsLoading: {
      ...state.get('getRecallEventsIsLoading'),
      [recallId]: true,
    },
  })
}
export const getRecallEventsSuccess = (state, { recallId, recallEvents }) => {
  return state.merge({
    getRecallEventsIsLoading: {
      ...state.get('getRecallEventsIsLoading'),
      [recallId]: false,
    },
    recallEvents: {
      ...state.get('recallEvents'),
      [recallId]: recallEvents.map(convertRawRecallEvent),
    },
  })
}
export const getRecallEventsFailure = (state, { recallId }) => {
  return state.merge({
    getRecallEventsIsLoading: {
      ...state.get('getRecallEventsIsLoading'),
      [recallId]: false,
    },
  })
}

// Create recall
export const createRecallLoading = state => {
  return state.merge({
    createRecallIsLoading: true,
  })
}
export const createRecallSuccess = (state, { recall, recallEvents }) => {
  return state.merge({
    recalls: [
      convertRawRecall(recall),
      ...state.get('recalls'),
    ],
    recallEvents: {
      ...state.get('recallEvents'),
      [recall.id]: recallEvents.map(convertRawRecallEvent),
    },
    pagination: updatePagination(state.get('pagination'), 'add'),
    createRecallIsLoading: false,
  })
}
export const createRecallFailure = state => {
  return state.merge({
    createRecallIsLoading: false,
  })
}

// Edit recall
export const editRecallLoading = (state, { recallId }) => {
  return state.merge({
    editRecallIsLoading: {
      ...state.get('editRecallIsLoading'),
      [recallId]: true,
    },
  })
}
export const editRecallSuccess = (state, { recallId, recall }) => {
  const selectedRecall = state.get('selectedRecall')
  const convertedRecall = convertRawRecall(recall)
  return state.merge({
    selectedRecall: selectedRecall ? selectedRecall.id === recallId ? convertedRecall : selectedRecall : selectedRecall,
    recalls: mapById(recallId, state.get('recalls'), () => convertedRecall),
    editRecallIsLoading: {
      ...state.get('editRecallIsLoading'),
      [recallId]: false,
    },
  })
}
export const editRecallFailure = (state, { recallId }) => {
  return state.merge({
    editRecallIsLoading: {
      ...state.get('editRecallIsLoading'),
      [recallId]: false,
    },
  })
}

// Edit recall event
export const editRecallEventLoading = (state, { recallEventId }) => {
  return state.merge({
    editRecallEventIsLoading: {
      ...state.get('editRecallEventIsLoading'),
      [recallEventId]: true,
    },
  })
}
export const editRecallEventSuccess = (state, { recallId, recallEventId, recallEvent }) => {
  return state.merge({
    recalls: mapById(recallId, state.get('recalls'), recall => ({
      ...recall,
      rover_recall_events: mapById(recallEventId, recall.rover_recall_events || [], re => ({
        ...re,
        is_closed: recallEvent.is_closed,
      })),
    })),
    recallEvents: {
      ...state.get('recallEvents'),
      [recallId]: mapById(recallEventId, (state.get('recallEvents')[recallId] || []), () => convertRawRecallEvent(recallEvent)),
    },
    editRecallEventIsLoading: {
      ...state.get('editRecallEventIsLoading'),
      [recallEventId]: false,
    },
  })
}
export const editRecallEventFailure = (state, { recallEventId }) => {
  return state.merge({
    editRecallEventIsLoading: {
      ...state.get('editRecallEventIsLoading'),
      [recallEventId]: false,
    },
  })
}

// Delete recall event
export const deleteRecallEventLoading = (state, { recallEventId }) => {
  return state.merge({
    deleteRecallEventIsLoading: {
      ...state.get('deleteRecallEventIsLoading'),
      [recallEventId]: true,
    },
  })
}
export const deleteRecallEventSuccess = (state, { recallId, recallEventId }) => {
  return state.merge({
    recalls: mapById(recallId, state.get('recalls'), recall => ({
      ...recall,
      rover_recall_events: removeById(recallEventId, recall.rover_recall_events || []),
    })),
    recallEvents: {
      ...state.get('recallEvents'),
      [recallId]: removeById(recallEventId, state.get('recallEvents')[recallId] || []),
    },
    deleteRecallEventIsLoading: {
      ...state.get('deleteRecallEventIsLoading'),
      [recallId]: false,
    },
  })
}
export const deleteRecallEventFailure = (state, { recallEventId }) => {
  return state.merge({
    deleteRecallEventIsLoading: {
      ...state.get('deleteRecallEventIsLoading'),
      [recallEventId]: false,
    },
  })
}

// Create recall event
export const createRecallEventLoading = (state, { recallEventId }) => {
  return state.merge({
    createRecallEventIsLoading: true,
  })
}
export const createRecallEventSuccess = (state, { recallId, recallEvent }) => {
  return state.merge({
    recalls: mapById(recallId, state.get('recalls'), recall => ({
      ...recall,
      rover_recall_events: [
        recallEvent,
        ...recall.rover_recall_events || [],
      ],
    })),
    recallEvents: {
      ...state.get('recallEvents'),
      [recallId]: [
        convertRawRecallEvent(recallEvent),
        ...(state.get('recallEvents')[recallId] || []),
      ],
    },
    createRecallEventIsLoading: false,
  })
}
export const createRecallEventFailure = (state, { recallEventId }) => {
  return state.merge({
    createRecallEventIsLoading: false,
  })
}

export const selectRecallSuccess = (state, { recall }) => {
  return state.merge({
    selectedRecall: convertRawRecall(recall),
    selectRecallIsLoading: false,
  })
}
export const selectRecallLoading = (state, { systemType }) => {
  return state.merge({ selectRecallIsLoading: true })
}
export const selectRecallFailure = (state, { systemType }) => {
  return state.merge({ selectRecallIsLoading: false })
}
export const deselectRecall = state => {
  return state.merge({ selectedRecall: null })
}

export const deleteRecallAttachmentsSuccess = (state, { eventId, eventType, attachmentIds }) => {
  const selectedRecall = state.get('selectedRecall')
  const removeAttachments = attachments => {
    return (attachments || []).filter(attachment => !attachmentIds.includes(`${attachment.id}`))
  }
  return state.merge({
    selectedRecall: selectedRecall && selectedRecall.id === eventId
      ? { ...selectedRecall, attachments: removeAttachments(selectedRecall.attachments) }
      : selectedRecall,
    recalls: mapById(eventId, state.get('recalls'), recall => ({ ...recall, attachments: removeAttachments(recall.attachments) })),
    deleteAttachmentIsLoading: false,
  })
}
export const deleteRecallAttachmentsLoading = state => state.merge({
  deleteAttachmentIsLoading: true,
})
export const deleteRecallAttachmentsFailure = state => state.merge({
  deleteAttachmentIsLoading: false,
})

export const addRecallAttachmentsSuccess = (state, { eventId, eventType, attachments }) => {
  const selectedRecall = state.get('selectedRecall')
  const addAttachments = oldAttachments => {
    return [...attachments, ...(oldAttachments || [])]
  }
  return state.merge({
    addAttachmentsIsLoading: false,
    selectedRecall: selectedRecall && selectedRecall.id === eventId
      ? { ...selectedRecall, attachments: addAttachments(selectedRecall.attachments) }
      : selectedRecall,
    recalls: mapById(eventId, state.get('recalls'), recall => ({ ...recall, attachments: addAttachments(recall.attachments) })),
  })
}
export const addRecallAttachmentsLoading = state => state.merge({
  addAttachmentsIsLoading: true,
})
export const addRecallAttachmentsFailure = state => state.merge({
  addAttachmentsIsLoading: false,
})

export const reducer = createReducer(INITIAL_STATE, {
  [RecallsTypes.GET_RECALLS_LOADING]: getRecallsLoading,
  [RecallsTypes.GET_RECALLS_SUCCESS]: getRecallsSuccess,
  [RecallsTypes.GET_RECALLS_FAILURE]: getRecallsFailure,

  [RecallsTypes.GET_RECALL_EVENTS_LOADING]: getRecallEventsLoading,
  [RecallsTypes.GET_RECALL_EVENTS_SUCCESS]: getRecallEventsSuccess,
  [RecallsTypes.GET_RECALL_EVENTS_FAILURE]: getRecallEventsFailure,

  [RecallsTypes.CREATE_RECALL_LOADING]: createRecallLoading,
  [RecallsTypes.CREATE_RECALL_SUCCESS]: createRecallSuccess,
  [RecallsTypes.CREATE_RECALL_FAILURE]: createRecallFailure,

  [RecallsTypes.EDIT_RECALL_LOADING]: editRecallLoading,
  [RecallsTypes.EDIT_RECALL_SUCCESS]: editRecallSuccess,
  [RecallsTypes.EDIT_RECALL_FAILURE]: editRecallFailure,

  [RecallsTypes.CREATE_RECALL_EVENT_LOADING]: createRecallEventLoading,
  [RecallsTypes.CREATE_RECALL_EVENT_SUCCESS]: createRecallEventSuccess,
  [RecallsTypes.CREATE_RECALL_EVENT_FAILURE]: createRecallEventFailure,

  [RecallsTypes.EDIT_RECALL_EVENT_LOADING]: editRecallEventLoading,
  [RecallsTypes.EDIT_RECALL_EVENT_SUCCESS]: editRecallEventSuccess,
  [RecallsTypes.EDIT_RECALL_EVENT_FAILURE]: editRecallEventFailure,

  [RecallsTypes.DELETE_RECALL_EVENT_LOADING]: deleteRecallEventLoading,
  [RecallsTypes.DELETE_RECALL_EVENT_SUCCESS]: deleteRecallEventSuccess,
  [RecallsTypes.DELETE_RECALL_EVENT_FAILURE]: deleteRecallEventFailure,

  [RecallsTypes.ADD_RECALL_ATTACHMENTS_LOADING]: addRecallAttachmentsLoading,
  [RecallsTypes.ADD_RECALL_ATTACHMENTS_SUCCESS]: addRecallAttachmentsSuccess,
  [RecallsTypes.ADD_RECALL_ATTACHMENTS_FAILURE]: addRecallAttachmentsFailure,

  [RecallsTypes.DELETE_RECALL_ATTACHMENTS_LOADING]: deleteRecallAttachmentsLoading,
  [RecallsTypes.DELETE_RECALL_ATTACHMENTS_SUCCESS]: deleteRecallAttachmentsSuccess,
  [RecallsTypes.DELETE_RECALL_ATTACHMENTS_FAILURE]: deleteRecallAttachmentsFailure,

  [RecallsTypes.SELECT_RECALL_SUCCESS]: selectRecallSuccess,
  [RecallsTypes.SELECT_RECALL_LOADING]: selectRecallLoading,
  [RecallsTypes.SELECT_RECALL_FAILURE]: selectRecallFailure,
  [RecallsTypes.DESELECT_RECALL]: deselectRecall,
})
