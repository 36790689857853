import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
// Project deps
import { isLoggedIn, getLoggedUser } from 'modules/users/selectors'
import { isUserAdminOrPLSUser } from 'utils/company'

const AdminOrPLSRoute = ({ isUserLoggedIn, isUserAdmin, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        isUserLoggedIn
          ? isUserAdmin
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/error', state: { isRegistrated: false } }} />
          : <Redirect to='/' />
      )}
    />
  )
}

const mapStateToProps = state => ({
  isUserLoggedIn: isLoggedIn(state),
  isUserAdmin: isUserAdminOrPLSUser(getLoggedUser(state)),
})

AdminOrPLSRoute.propTypes = {
  isUserAdmin: PropTypes.bool,
  isUserLoggedIn: PropTypes.bool,
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
    PropTypes.object,
  ]),
}

export default connect(mapStateToProps)(AdminOrPLSRoute)
