import React from 'react'
import PropTypes from 'prop-types'
// import { DatePicker } from 'material-ui-pickers'
// Material UI
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'
// Icons
// Project deps
// import { isCoordinateLieInRectangle } from '../../templates/utils'
import StyledTooltip from 'components/reusable/StyledTooltip'
import { getValue } from 'utils/templatedForm'

/**
 * Renders a boolean input field.
 * @param name The name of the field.
 * @param option The field definition.
 * @return A component for inputting boolean data.
 */
const BooleanOption = options => {
  const {
    name,
    option,
    disabled,
    setValue,
    values,
    state,
  } = options
  const { onChange: onChangeFromOption, tooltip, editable = true, position = 'space-between', labelPlacement = 'start' } = option
  const value = getValue(options)
  const onChange = editable ? (_event, checked) => {
    if (typeof onChangeFromOption === 'function') {
      onChangeFromOption(name, checked, options)
    }
    setValue(name, checked, option)
  } : () => {}
  return (
    <StyledTooltip title={tooltip || ''} placement='top-start'>
      <FormControlLabel
        onChange={onChange}
        labelPlacement={labelPlacement}
        label={
          <Typography style={{ cursor: 'pointer' }}
            variant='subtitle1'
            color='inherit'
            align='left'
          >
            {typeof option.name === 'function' ? option.name(value, state, values) : option.name}
          </Typography>
        }
        control={
          <Switch
            checked={value}
            disabled={disabled}
            color='primary'
          />
        }
        style={{ display: 'flex', justifyContent: position, marginLeft: 0, width: '100%', flexWrap: 'wrap-reverse' }}
      />
    </StyledTooltip>
  )
}

BooleanOption.propTypes = {
  state: PropTypes.object,
  extra: PropTypes.any,
  name: PropTypes.string,
  option: PropTypes.object,
  disabled: PropTypes.bool,
  classes: PropTypes.object,
  values: PropTypes.object,
  setValue: PropTypes.func,
  formTemplate: PropTypes.object,
}

export default BooleanOption
