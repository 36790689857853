
import React from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
// Material UI
import Button from '@material-ui/core/Button'
// Project deps
import i18next from 'i18n'

// If browser has no clipboard property support
export function fallbackCopyTextToClipboard (text) {
  const textArea = document.createElement('textarea')
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()
  try {
    const successful = document.execCommand('copy')
    const msg = successful ? 'successful' : 'unsuccessful'
    console.log('Fallback: Copying text command was ' + msg)
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err)
  }

  document.body.removeChild(textArea)
}
// Main function to copy text to clipboard
export function copyTextToClipboard (text, t) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(function () {
    toast.success(i18next.t('copyToClipboard.success'))
  }, function (err) {
    toast.warn(i18next.t('copyToClipboard.error'))
    console.error('Async: Could not copy text: ', err)
  })
}

// Component that render simple Copy to clipboard button
const CopyToClipboardButton = props => {
  const { label, text, component, ...otherProps } = props
  const onCopyClick = text => e => {
    e.preventDefault()
    e.stopPropagation()
    copyTextToClipboard(text)
  }

  const Component = component || Button
  return (
    <Component onClick={onCopyClick(text)} {...otherProps}>
      {label}
    </Component>
  )
}

CopyToClipboardButton.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  buttonProps: PropTypes.object,
  text: PropTypes.string,
  t: PropTypes.object,
  component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.string,
    PropTypes.object,
  ]),
}

export default CopyToClipboardButton
