import i18n from 'i18n'

export const transactionCompleteStatus = [
  'completed',
  'done',
  'paid',
]

export function isTransactionCompleted (status) {
  const lowerCasedStatus = status.toLowerCase()
  return transactionCompleteStatus.indexOf(lowerCasedStatus) >= 0
}

export const TransactionType = {
  STRIPE: 'stripe',
  PAYPAL: 'paypal',
}

export const SubscriptionTiers = [{
  key: 'tier-1',
  tier: 'Subscription',
  trajectory: true,
  trajectories: 'Unlimited',
  minutes: 900,
  lidar: true,
  price: 725,
}]

export function prettifyTransactionType (type) {
  if (type === TransactionType.STRIPE) {
    return i18n.t('transactions.type.stripe')
  }
  if (type === TransactionType.PAYPAL) {
    return i18n.t('transactions.type.paypal')
  }
  return type
}

/**
 * Converts a `RawPaypalTransaction` to a `Transaction`.
 * @param raw The raw transaction as received by the Api.
 * @return The converted transaction for use in the rest of this application.
 */
export function convertRawPaypalTransaction (raw) {
  const {
    id,
    payer_email,
    payer_name,
    paypal_id,
    status,
    created,
    timestamp,
    updated,
    amount_value,
    amount_currency,
  } = raw
  return {
    id,
    invoiceId: paypal_id,
    transactionType: 'paypal',
    isCompleted: isTransactionCompleted(status),
    created: typeof created === 'string' ? new Date(created) : undefined,
    updated: typeof updated === 'string' ? new Date(updated) : undefined,
    timestamp: typeof timestamp === 'string' ? new Date(timestamp) : undefined,
    amountValue: typeof amount_value === 'number' ? amount_value : 0,
    amountCurrency: amount_currency,
    payerEmail: payer_email,
    payerName: payer_name,
  }
}

/**
 * Converts a `RawStripeTransaction` to a `Transaction`.
 * @param raw The raw transaction as received by the Api.
 * @return The converted transaction for use in the rest of this application.
 */
export function convertRawStripeTransaction (raw) {
  const {
    id,
    invoice_id,
    status,
    created,
    timestamp,
    updated,
    amount_due,
    amount_currency,
    hosted_invoice_url,
  } = raw
  return {
    id,
    invoiceId: invoice_id,
    transactionType: 'stripe',
    isCompleted: isTransactionCompleted(status),
    created: typeof created === 'string' ? new Date(created) : undefined,
    updated: typeof updated === 'string' ? new Date(updated) : undefined,
    timestamp: typeof timestamp === 'string' ? new Date(timestamp) : undefined,
    amountValue: typeof amount_due === 'number' ? amount_due / 100 : 0,
    amountCurrency: amount_currency,
    hostedInvoiceUrl: hosted_invoice_url,
  }
}
