import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
// Material UI
// Project deps
import { isGetCompanyDBLogsLoading, getCompanyDBLogs as getCompanyLogs, getCompanyDBLogsPagination } from 'modules/companies/selectors'
import CompaniesActions from 'modules/companies/actions'
import LogsDialog from 'components/reusable/LogsDialog'

const ShowCompanyLogsDialog = props => {
  const { open, onClose, companyId, showRelated } = props
  const dispatch = useDispatch()
  const getCompanyDBLogs = companyId => (search, pageSize, page, orderBy, order) =>
    dispatch(CompaniesActions.getCompanyDBLogs(companyId, { search, pageSize, page, orderBy, order }))

  return (
    <LogsDialog
      id={companyId}
      getStateLogs={getCompanyLogs}
      getLogsPagination={getCompanyDBLogsPagination}
      getLogs={getCompanyDBLogs}
      getIsLoadingState={isGetCompanyDBLogsLoading}
      title='Company DB logs'
      open={open}
      onClose={onClose}
      showRelated={showRelated}
    />
  )
}

ShowCompanyLogsDialog.propTypes = {
  open: PropTypes.bool,
  showRelated: PropTypes.bool,
  companyId: PropTypes.string,
  onClose: PropTypes.func,
  event: PropTypes.object,
}

export default ShowCompanyLogsDialog
