import React from 'react'
import PropTypes from 'prop-types'
import { omit } from 'ramda'
// import { DatePicker } from 'material-ui-pickers'
// Material UI
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Paper from '@material-ui/core/Paper'
import AppBar from '@material-ui/core/AppBar'
// Icons
// Project deps

const styles = theme => ({
  indicator: {
    transition: 'none',
  },
})

const TabsOption = options => {
  const {
    name,
    option,
    values,
    state,
    setValue,
    formTemplate,
    classes,
    renderOption,
  } = options
  const omitClasses = omit(['classes'], options)
  const { tabs, onChange, editable = true } = option
  const currentTab = values[name] || 0
  const onChangeTab = (e, tab) => {
    if (typeof onChange === 'function') onChange(name, tab, options)
    setValue(name, tab, option)
  }
  return (
    <Paper style={{ width: '100%', marginBottom: 8 }} elevation={1}>
      <AppBar color={'primary'} position={'static'} elevation={0}>
        <Tabs
          value={currentTab}
          onChange={onChangeTab}
          variant={'fullWidth'}
          classes={{
            indicator: classes.indicator,
          }}
        >
          { Object.keys(tabs).map((tab, index) =>
            <Tab
              key={'tab-' + index}
              disabled={!editable ? currentTab !== index : false}
              label={tabs[tab].label}
            />,
          )}
        </Tabs>
      </AppBar>
      <Grid container spacing={1} style={{ padding: 16 }}>
        {
          Object.keys(tabs).map((tab, index) => {
            if (index !== currentTab) {
              return null
            }
            const tabValue = tabs[tab]
            const { fields } = tabValue
            return (
              Object.keys(fields).map(field => {
                const fieldOption = fields[field]
                const { altOption } = fieldOption
                const actualOption = typeof altOption === 'function'
                  ? altOption(state, values, formTemplate, fieldOption)
                  : fieldOption
                const { gridProps = {}, invisibleForTab } = actualOption
                const invisible = typeof invisibleForTab === 'function'
                  ? invisibleForTab(state, values)
                  : invisibleForTab
                return (
                  !invisible &&
                  <Grid item xs={12} {...gridProps} key={field}>
                    {renderOption(field, actualOption, omitClasses)}
                  </Grid>
                )
              })
            )
          })
        }
      </Grid>
    </Paper>
  )
}

TabsOption.propTypes = {
  name: PropTypes.string,
  option: PropTypes.object,
  disabled: PropTypes.bool,
  values: PropTypes.object,
  setValue: PropTypes.func,
  formTemplate: PropTypes.object,
}

export default withStyles(styles)(TabsOption)
