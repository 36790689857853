// External dependencies.
import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
// Material UI dependencies.
import ListItem from '@material-ui/core/ListItem'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
// Icons
import CalendarIcon from '@material-ui/icons/EventOutlined'
import ProblemIcon from '@material-ui/icons/ReportProblemOutlined'
import RenewIcon from '@material-ui/icons/RestoreOutlined'
// Project deps

const SubscriptionStatus = props => {
  const { isAdmin, hasOngoingSubscription, classes, isSubscriptionsLoading } = props
  const { IconComponent, color, primaryText, secondaryText } =
    // Has access cause the superuser
    isAdmin
      ? {
        IconComponent: RenewIcon,
        color: 'orange',
        primaryText: hasOngoingSubscription
          ? <Trans i18nKey='navigator.statusBox.subscribed' />
          : <Trans i18nKey='navigator.statusBox.onlyGrants.primary' />,
        secondaryText: <Trans i18nKey='navigator.statusBox.admin' />,
      }
      : !hasOngoingSubscription
        ? {
          IconComponent: ProblemIcon,
          color: 'red',
          primaryText: <Trans i18nKey='navigator.statusBox.unsubscribed.primary' />,
          secondaryText: undefined,
        } : {
          IconComponent: CalendarIcon,
          color: 'green',
          primaryText: <Trans i18nKey='navigator.statusBox.subscribed' />,
          secondaryText: undefined,
        }
  return (
    <ListItem className={classes.item}>
      {isSubscriptionsLoading
        ? <React.Fragment>
          <ListItemIcon>
            <CircularProgress size={20}/>
          </ListItemIcon>
          <ListItemText primary={<Typography variant='body2' component='div'>
            <Trans i18nKey='navigator.statusBox.loading' /></Typography>}
          />
        </React.Fragment>
        : <React.Fragment>
          <ListItemIcon>
            <IconComponent style={{ color }} />
          </ListItemIcon>
          <ListItemText
            classes={{ root: classes.container }}
            primary={
              <Typography variant='body2' component='div' style={{ color }}>{primaryText}</Typography>
            }
            secondary={
              <Typography variant='body2' component='div' style= {{ color }} className={classes.secondaryStatusText}>{secondaryText}</Typography>
            }
          />
        </React.Fragment>
      }
    </ListItem>
  )
}

SubscriptionStatus.propTypes = {
  isAdmin: PropTypes.bool,
  canDownloadFiles: PropTypes.bool,
  hasOngoingSubscription: PropTypes.bool,
  isSubscriptionsLoading: PropTypes.bool,
  expirationDate: PropTypes.any,
  classes: PropTypes.object,
}

export default SubscriptionStatus
