import React from 'react'
import PropTypes from 'prop-types'
import Tag from 'components/reusable/Tag'
import { useSelector } from 'react-redux'
import { isAdmin } from 'modules/users/selectors'

const SubscriptionLabel = props => {
  const { subscription, margin = true } = props
  const isUserAdmin = useSelector(isAdmin)
  const plan = [subscription.planName, (subscription.product_duration ? subscription.product_duration.label : '')].filter(Boolean).join(', ')
  const subRovers = (subscription.rovers || []).map(rover => rover.serial).join(', ')
  const licenseKeys = (subscription.license_keys || []).map(lk => `${lk.id} (${lk.type})`).join(', ')
  const tags = (subscription.rover_tags || []).map(item => <Tag style={{ margin: 2 }} key={item} name={item}/>)
  const comment = subscription.comment
  const created = [subscription.activated_date, subscription.end_date].filter(Boolean).join(' - ')
  return (
    <div style={{ marginTop: margin ? 4 : 0 }}>{
      [
        plan ? <i key='primary-text'>
          {plan}
          {subRovers ? <span> ({subRovers})</span> : null}
          {(subscription.rover_tags || []).length > 0 ? <div style={{ marginLeft: 2, display: 'inline-block' }}>{tags}</div> : null}
        </i> : '',
        licenseKeys ? <div key='license-keys-text' style={{ fontSize: '0.875rem' }}><b>License keys</b>: <i>{licenseKeys}</i></div> : '',
        isUserAdmin && comment ? <div key='comment-text' style={{ fontSize: '0.875rem' }}><b>Comment</b>: <i>{comment}</i></div> : '',
        created ? <div key='created-date-text' style={{ fontSize: '0.875rem' }}><i>{created}</i></div> : '',
      ]
    }</div>
  )
}

SubscriptionLabel.propTypes = {
  subscription: PropTypes.object,
  margin: PropTypes.bool,
}

export default SubscriptionLabel
