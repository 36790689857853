import { colorize } from 'utils/colorize'

export const TagColor = {
  'rental': '#f4c061',
  'testing': '#afaffe',
  'demo': '#f4f48f',
  'prebuild': '#a6dba6',
}

export const TagTextColor = {
  'rental': '#4b4b4b',
  'testing': '#4b4b4b',
  'demo': '#4b4b4b',
  'prebuild': '#4b4b4b',
}

export const getTagColor = tagName => {
  return colorize(tagName.toUpperCase(), 100, 85)
}

export const getTagTextColor = tagName => {
  return colorize(tagName.toUpperCase(), 100, 20)
}
