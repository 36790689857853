import { combineReducers } from 'redux'
import { all, fork } from 'redux-saga/effects'

import { reducer as companiesReducer } from './companies/reducers'
import { reducer as usersReducer } from './users/reducers'
import { reducer as transactionsReducer } from './transactions/reducers'
import { reducer as billingAgreementReducer } from './billingAgreement/reducers'
import { reducer as urlReducer } from './url/reducers'
import { reducer as _appReducer } from './app/reducers'
import { reducer as supportReducer } from './support/reducers'
import { reducer as grantsReducer } from './grants/reducers'
import { reducer as calibrationsReducer } from './calibrations/reducers'
import { reducer as statisticsReducer } from './statistics/reducers'
import { reducer as roversReducer } from './rovers/reducers'
import { reducer as systemTypesReducer } from './systemTypes/reducers'
import { reducer as recallsReducer } from './recalls/reducers'
import { reducer as rmaReducer } from './rma/reducers'
import { reducer as releasesReducer } from './releases/reducers'

import appSagas from './app/sagas'
import companiesSagas from './companies/sagas'
import usersSagas from './users/sagas'
import billingAgreementSagas from './billingAgreement/sagas'
import transactionsSagas from './transactions/sagas'
import supportSagas from './support/sagas'
import grantsSagas from './grants/sagas'
import calibrationsSagas from './calibrations/sagas'
import statisticsSagas from './statistics/sagas'
import keysSagas from './keys/sagas'
import roversSagas from './rovers/sagas'
import systemTypesSagas from './systemTypes/sagas'
import recallsSagas from './recalls/sagas'
import rmaSagas from './rma/sagas'
import releasesSagas from './releases/sagas'
import { keepProps } from 'utils/dict'

/* Reducers */
export const appReducer = combineReducers({
  app: _appReducer,
  companies: companiesReducer,
  users: usersReducer,
  transactions: transactionsReducer,
  billingAgreement: billingAgreementReducer,
  url: urlReducer,
  support: supportReducer,
  grants: grantsReducer,
  calibrations: calibrationsReducer,
  statistics: statisticsReducer,
  rovers: roversReducer,
  systemTypes: systemTypesReducer,
  recalls: recallsReducer,
  rma: rmaReducer,
  releases: releasesReducer,
})

export const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_SUCCESS') {
    // for all keys defined in your persistConfig(s)
    localStorage.removeItem('persist:root')
    // Keep only app reducer
    state = keepProps(['app'], state)
  }
  return appReducer(state, action)
}

export function * rootSaga () {
  yield all([
    fork(appSagas),
    fork(companiesSagas),
    fork(usersSagas),
    fork(transactionsSagas),
    fork(billingAgreementSagas),
    fork(supportSagas),
    fork(grantsSagas),
    fork(calibrationsSagas),
    fork(statisticsSagas),
    fork(keysSagas),
    fork(roversSagas),
    fork(systemTypesSagas),
    fork(recallsSagas),
    fork(rmaSagas),
    fork(releasesSagas),
  ])
}
