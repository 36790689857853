import React from 'react'
import PropTypes from 'prop-types'
// Material UI
import JSONEditorComponent from 'components/Licensing/JSONEditor'
import { getValue } from 'utils/templatedForm'
// import { getFormFieldErrorMessage } from '../utils'
import { Typography } from '@material-ui/core'

class RenderJsonOption extends React.Component {
  render () {
    const {
      // state,
      // extra,
      option,
      // disabled,
      name,
      setValue,
      // formTemplate,
    } = this.props
    const value = getValue(this.props) || []
    // const errorMessage = getFormFieldErrorMessage(value, option, values, state, extra, formTemplate, this.props)
    // const error = Boolean(errorMessage)
    const label = typeof option.name === 'function' ? option.name(value) : option.name
    return (
      <div>
        <Typography>{label}</Typography>
        <div style={{ height: '450px' }}>
          <JSONEditorComponent onChange={result => {
            const { okay, json, errorMessage } = result
            if (!okay) {
              setValue(name, { json, error: errorMessage }, option)
            } else {
              setValue(name, { json, error: null }, option)
            }
          }}/>
        </div>
      </div>
    )
  }
}

RenderJsonOption.propTypes = {
  extra: PropTypes.any,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  state: PropTypes.object,
  values: PropTypes.object,
  option: PropTypes.object,
  formTemplate: PropTypes.object,
  onWheel: PropTypes.func,
  setValue: PropTypes.func,
}

export default RenderJsonOption
