import React from 'react'
import PropTypes from 'prop-types'
// Material UI
import {
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core'
// Icons
import ClearIcon from '@material-ui/icons/Close'
// Project deps

class SearchTextField extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      value: props.initialValue || '',
    }
  }
  componentDidUpdate (prevProps) {
    if (prevProps.initialValue !== this.props.initialValue) {
      this.setState({ value: this.props.initialValue })
    }
  }
  onChangeState = e => {
    const { onChange, onTypeEnd } = this.props
    const value = e.target.value
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
    // If users stops typing we send the entered value
      onTypeEnd(value)
    }, 300)
    if (typeof onChange === 'function') onChange(e)
    else this.setState({ value })
  }
  onReset = e => {
    const { onReset: onResetFromProps } = this.props
    if (typeof onResetFromProps === 'function') onResetFromProps(e)
    this.setState({ value: '' })
  }

  render () {
    const { value, label } = this.props

    return (
      <TextField
        value={value || this.state.value}
        onChange={this.onChangeState}
        variant='outlined'
        label={label}
        margin='dense'
        style={{ margin: 0 }}
        InputProps={{
          endAdornment:
            <InputAdornment position="end">
              <IconButton onClick={this.onReset} style={{ padding: 4 }}>
                <ClearIcon/>
              </IconButton>
            </InputAdornment>,
        }}
      />
    )
  }
}

SearchTextField.propTypes = {
  onChange: PropTypes.func,
  onTypeEnd: PropTypes.func,
  onReset: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  initialValue: PropTypes.string,
}

export default SearchTextField
