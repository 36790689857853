import { Map } from 'immutable'

export const INITIAL_STATE = Map({
  checkGrantLoading: false,
  checkGrantErrorMessage: null,
  checkGrantsLoading: {},
  deleteGrantLoading: false,
  getGrantsLoading: false,
  getGrantsErrorMessage: null,
  getGrantsForActiveUserLoading: false,
  getGrantsForActiveUserErrorMessage: null,
  createGrantLoading: false,
  createGrantErrorMessage: null,

  grantPermissions: [],
  grantListRequestStatuses: {},
  grantsForUser: {},
})
