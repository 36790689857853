import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  // getTransactions by user
  getCompanyTransactions: ['companyId'],
  getCompanyTransactionsLoading: null,
  getCompanyTransactionsSuccess: ['transactions'],
  getCompanyTransactionsFailure: ['errorMessage'],

  // getSuccessfulTransactions by user
  getSuccessfulTransactions: ['startDate', 'endDate'],
  getSuccessfulTransactionsLoading: null,
  getSuccessfulTransactionsSuccess: ['successfulTransactions'],
  getSuccessfulTransactionsFailure: ['errorMessage'],

})

export const TransactionsTypes = Types
export default Creators
