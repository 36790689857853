import { isProductionEnvironment } from 'config'

export function getCookie (cname) {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export function checkCookie (cname) {
  const cookie = getCookie(cname)
  if (cookie !== '') {
    return true
  } else {
    return false
  }
}

/**
 * Delete cookies by setting the expire date to the past
 */
export const deleteCookie = name => {
  document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.phoenixlidar.com;max-age=0'
}

export const setCookie = (name, value, seconds, dom) => {
  const d = new Date()
  d.setTime(d.getTime() + (seconds * 1000))
  const expires = 'expires=' + d.toUTCString()
  const domain = 'domain=' + dom
  document.cookie = name + '=' + value + ';' + domain + ';' + expires + ';path=/'
}

export const cookieName = isProductionEnvironment() ? 'accounts_user' : 'accounts_develop_user'
