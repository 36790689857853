import { put, takeLatest, call, all } from 'redux-saga/effects'
import SystemTypeActions, { SystemTypeTypes } from './actions'
import { toast } from 'react-toastify'
// import axios from 'axios'
import { makeRequest } from 'utils/request'
import axios from 'utils/axios'
import { SystemTypeAction } from 'types/systemTypes'
import { createQueryStringMaximumEntries, showErrorMessage } from 'utils/api'
import { addAttachments } from 'modules/utils'
import { RoverEventType } from 'types/roverEvents'

function * getSystemTypeProfiles ({ systemTypeId, search }) {
  yield put(SystemTypeActions.getSystemTypeProfilesLoading(systemTypeId))
  try {
    if (search) {
      const [
        { data: { data: profiles } },
        { data: { data: filteredProfiles } },
      ] = yield all([
        call(axios.get, `/rovers_system_types/${systemTypeId}/profiles`),
        call(axios.get, `/rovers_system_types/${systemTypeId}/profiles${createQueryStringMaximumEntries({ search, orderBy: 'updated_at', order: 'desc' })}`),
      ])
      yield put(SystemTypeActions.getSystemTypeProfilesSuccess(systemTypeId, profiles, filteredProfiles))
    } else {
      const { data: { data: profiles } } = yield call(axios.get, `/rovers_system_types/${systemTypeId}/profiles`)
      yield put(SystemTypeActions.getSystemTypeProfilesSuccess(systemTypeId, profiles))
    }
  } catch (e) {
    yield put(SystemTypeActions.getSystemTypeProfilesFailure(systemTypeId, e.message || 'Error ocurred'))
  }
}

function * addProfileToSystemType ({ formData, onSuccess }) {
  yield put(SystemTypeActions.addProfileToSystemTypeLoading())
  try {
    const systemTypeId = formData.system_type
    const { data: { data: profile } } = yield call(makeRequest,
      `/rovers_system_types/${systemTypeId}/profiles`,
      'POST',
      SystemTypeAction.ADD_PROFILE,
      {
        configurations: formData.configurations,
        comment: formData.comment,
        name: formData.name,
      },
    )
    if (formData.attachments && formData.attachments.length > 0) {
      const eventAttachments = yield call(
        addAttachments,
        profile.id,
        RoverEventType.ROVER_SYSTEM_TYPE_PROFILE,
        formData.attachments,
      )
      yield put(SystemTypeActions.addProfileToSystemTypeSuccess(systemTypeId, { ...profile, attachments: eventAttachments }))
      yield put(SystemTypeActions.addSystemTypeAttachments(systemTypeId, eventAttachments))
    } else {
      yield put(SystemTypeActions.addProfileToSystemTypeSuccess(systemTypeId, profile))
    }
    if (typeof onSuccess === 'function') {
      onSuccess()
    }
    yield put(SystemTypeActions.getSystemType(systemTypeId))
    yield put(SystemTypeActions.setExpandedProfile(profile.id))
  } catch (e) {
    yield put(SystemTypeActions.addProfileToSystemTypeFailure(e.message || 'Error ocurred'))
  }
}

function * updateProfile ({ systemTypeId, profileId, formData, oldProfile, onSuccess }) {
  yield put(SystemTypeActions.updateProfileLoading())
  try {
    if (formData.attachments && formData.attachments.length > 0) {
      const eventAttachments = yield call(
        addAttachments,
        profileId,
        RoverEventType.ROVER_SYSTEM_TYPE_PROFILE,
        formData.attachments,
      )
      yield put(SystemTypeActions.addSystemTypeAttachments(systemTypeId, eventAttachments))
      yield put(SystemTypeActions.updateProfileSuccess(
        systemTypeId,
        { ...oldProfile, attachments: [ ...eventAttachments, ...(oldProfile.attachments || []) ] },
      ))
      toast.success('Attachments were successfully added')
    } else {
      yield put(SystemTypeActions.updateProfileSuccess(systemTypeId, oldProfile))
    }
    if (typeof onSuccess === 'function') {
      onSuccess()
    }
  } catch (e) {
    yield put(SystemTypeActions.updateConfigurationFailure(e.message || 'Error ocurred'))
  }
}

function * addFileLinks ({ profileId, formData }) {
  // yield put(SystemTypeActions.addFileLinksLoading())
  try {
    yield call(makeRequest, `/profiles/${profileId}`, 'PATCH', SystemTypeAction.ADD_LINKS, formData)
    yield put(SystemTypeActions.getProfile(profileId))
    // yield put(SystemTypeActions.addFileLinksSuccess(profileId, data))
  } catch (e) {
    // yield put(SystemTypeActions.addFileLinksFailure(e.message || 'Error ocurred'))
  }
}
// NOT USED
function * getProfile ({ profileId }) {
  yield put(SystemTypeActions.getProfileLoading())
  try {
    const { data: { data: profile } } = yield call(makeRequest, `/profiles/${profileId}`, 'GET')
    const systemTypeId = profile.system_type && profile.system_type.id
    yield put(SystemTypeActions.getProfileSuccess(systemTypeId, profileId, profile))
  } catch (e) {
    yield put(SystemTypeActions.getProfileFailure(e.message || 'Error ocurred'))
  }
}

function * updateProfileName ({ systemTypeId, oldProfileName, newProfileName }) {
  yield put(SystemTypeActions.updateProfileNameLoading())
  try {
    const { data: { data: profile } } = yield call(
      makeRequest,
      `/profiles/rename`,
      'POST',
      SystemTypeAction.UPDATE_PROFILE_NAME,
      {
        old_name: oldProfileName,
        new_name: newProfileName,
        system_type_id: systemTypeId,
      },
    )
    yield put(SystemTypeActions.updateProfileNameSuccess(systemTypeId, profile))
  } catch (e) {
    yield put(SystemTypeActions.updateProfileNameFailure(e.message || 'Error ocurred'))
  }
}
/**
 * Validate provided profile configurations json from System Type to check if user changes only allowed fields.
 */
export function * validateProfileConfigurations ({ profileId, configurations, successCallback, failureCallback }) {
  yield put(SystemTypeActions.validateProfileConfigurationsLoading())
  try {
    const { data: { data: result } } = yield call(axios.post, `/profiles/${profileId}/validate_permissions`, configurations)
    const isValid = result.denied_paths?.length === 0
    yield put(SystemTypeActions.validateProfileConfigurationsSuccess(profileId, result.denied_paths))
    if (isValid && typeof successCallback === 'function') {
      successCallback()
    } else if (!isValid && typeof failureCallback === 'function') {
      failureCallback()
    }
  } catch (e) {
    showErrorMessage(e)
    yield put(SystemTypeActions.validateProfileConfigurationsFailure())
  }
}
export function * getSystemTypeProfilesWatcher () {
  yield takeLatest(SystemTypeTypes.GET_SYSTEM_TYPE_PROFILES, getSystemTypeProfiles)
}
export function * addProfileToSystemTypeWatcher () {
  yield takeLatest(SystemTypeTypes.ADD_PROFILE_TO_SYSTEM_TYPE, addProfileToSystemType)
}
export function * addFileLinksWatcher () {
  yield takeLatest(SystemTypeTypes.ADD_FILE_LINKS, addFileLinks)
}
export function * getProfileWatcher () {
  yield takeLatest(SystemTypeTypes.GET_PROFILE, getProfile)
}
export function * updateProfileNameWatcher () {
  yield takeLatest(SystemTypeTypes.UPDATE_PROFILE_NAME, updateProfileName)
}
export function * updateProfileWatcher () {
  yield takeLatest(SystemTypeTypes.UPDATE_PROFILE, updateProfile)
}
export function * validateProfileConfigurationsWatcher () {
  yield takeLatest(SystemTypeTypes.VALIDATE_PROFILE_CONFIGURATIONS, validateProfileConfigurations)
}
