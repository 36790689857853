import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/styles'
import { theme } from 'HOC/withRoot'

const StyledMenuItem = withStyles({
  selected: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: '#fff',
  },
})(MenuItem)

export default StyledMenuItem
