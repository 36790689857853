import React from 'react'
import PropTypes from 'prop-types'
// Material  UI
import { Dialog, DialogActions, Button, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import LoadingButton from 'components/reusable/LoadingButton'

/**
 * Just a custom material dialog with two action buttons
*/
const ConfirmDialog = props => {
  const {
    title,
    onCancel,
    onSubmit,
    open,
    labelCancel = 'Cancel',
    labelConfirm = 'Confirm',
    maxWidth = 'md',
    submitDisabled = false,
    component = 'p',
    height = 'auto',
    children,
    isLoading = false,
    submitOnKeyPress = true,
    submitOnButtonPress = false,
    confirmColor = 'primary',
  } = props
  const onKeyDown = !submitOnKeyPress
    ? () => {}
    : e => {
      switch (e.keyCode) {
        case 27:
          onCancel(e)
          break
        case 13:
          onSubmit(e)
          break
        default:
          break
      }
    }
  return (
    <Dialog
      open={open}
      onClose={submitOnButtonPress ? () => {} : onCancel}
      maxWidth={maxWidth}
      fullWidth
      onKeyDown={onKeyDown}
    >
      {title && (
        <DialogTitle>{title}</DialogTitle>
      )}
      <DialogContent style={{ height }}>
        {
          typeof children === 'string'
            ? <DialogContentText component={component} style={{ wordSpacing: 'normal' }}>
              {children}
            </DialogContentText>
            : children
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{labelCancel}</Button>
        <LoadingButton isLoading={isLoading} onClick={onSubmit} color={confirmColor} disabled={submitDisabled}>{labelConfirm}</LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  isLoading: PropTypes.bool,
  submitOnKeyPress: PropTypes.bool,
  submitOnButtonPress: PropTypes.bool,
  title: PropTypes.node,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  height: PropTypes.any,
  confirmColor: PropTypes.string,
  labelCancel: PropTypes.string,
  labelConfirm: PropTypes.string,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  submitDisabled: PropTypes.bool,
  children: PropTypes.any,
  component: PropTypes.oneOf(['p', 'div', 'pre']),
}

export default ConfirmDialog
