import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  getReleaseChanges: ['releaseId'],
  getReleaseChangesLoading: ['releaseId'],
  getReleaseChangesSuccess: ['releaseId', 'changes'],
  getReleaseChangesFailure: ['releaseId', 'errorMessage'],

}, { prefix: 'RELEASES/' })

export const ReleasesTypes = Types
export default Creators
