import React from 'react'
import PropTypes from 'prop-types'
// Material UI
// Icons
import { ArrowDropDown } from '@material-ui/icons'
// Project deps
import VirtualizedList from 'components/reusable/VirtualizedList'
import { escape } from 'utils/regex'
import { Checkbox, ClickAwayListener, ListItem, Paper, Popper, TextField, Typography, InputAdornment } from '@material-ui/core'
import LoadingText from 'components/reusable/LoadingText'
import StyledChip from '../Chip'

class Autocomplete extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      value: '',
      anchorEl: null,
      errors: [],
    }
    this.anchorRef = React.createRef()
    this.textfieldRef = React.createRef()
    this.timeout = null
    this.getId = this.props.getId || function (item) { return item.serial }
  }

  onAddItem = (item, isItemChecked) => e => {
    const { onChange, value } = this.props
    if (isItemChecked) {
      onChange(value.filter(value => this.getId(value) !== this.getId(item)))
    } else {
      onChange([...value, item])
    }
    // this.anchorRef.current.blur()
    // this.unsetAnchorElement()
  }

  onChangeTextField = e => {
    const { value } = e.target
    if (!this.state.anchorEl) {
      this.setAnchorElement(e)
    }
    this.setState({ value: value })
  }

  setAnchorElement = e => {
    this.setState({ anchorEl: e.currentTarget })
  }

  unsetAnchorElement = e => {
    this.setState({ anchorEl: null })
  }

  render () {
    const { getId } = this
    const {
      value,
      options,
      isLoading,
      error,
      InputLabelProps = {},
      label,
      placeholder,
      inputClassName,
      renderItem,
      loadingLabel = 'Loading rovers',
      emptyLabel = 'No rovers found',
      onSearchFilter = value => value.serial.toString(),
      isChecked = (values, value) => values.find(i => getId(i) === getId(value)),
    } = this.props
    const self = this
    const open = Boolean(this.state.anchorEl)

    const transformedSearch = escape(this.state.value)
    const searchRegex = new RegExp(transformedSearch, 'i')
    const filteredItemsBySearch = this.state.value ? options.filter(option => {
      return onSearchFilter(option).match(searchRegex)
    }) : options
    const maxHeight = filteredItemsBySearch.length > 4 ? 150 : filteredItemsBySearch.length * 36
    return (
      <div>
        <ClickAwayListener onClickAway={this.unsetAnchorElement}>
          <div>
            <TextField
              error={error}
              ref={node => { this.textfieldRef.current = node }}
              inputRef={node => { this.anchorRef.current = node }}
              fullWidth
              variant='outlined'
              label={label}
              value={this.state.value}
              placeholder={placeholder}
              // type='search'
              onChange={this.onChangeTextField}
              onFocus={this.setAnchorElement}
              classes={{
                root: inputClassName,
              }}
              onKeyDown={e => {
                if (e.key === 'Escape') {
                  this.anchorRef.current.blur()
                  this.unsetAnchorElement()
                }
              }}
              InputLabelProps={InputLabelProps}
              inputProps={{
                style: {
                  width: `calc(100% - 54px)`,
                  padding: value.length > 0 ? `10px 14px 18.5px` : `18.5px 14px`,
                },
              }}
              InputProps={{
                style: {
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  padding: 0,
                },
                type: 'search',
                startAdornment: <InputAdornment onClick={e => e.preventDefault()} style={{
                  display: 'flex',
                  height: 'auto',
                  maxHeight: 'none',
                  flexWrap: 'wrap',
                  margin: value.length > 0 ? 4 : 0,
                }} position='start'>
                  {value.map((item, index) => <StyledChip style={{ marginLeft: index === 0 ? 0 : 4, marginTop: 4 }} key={getId(item)} label={onSearchFilter(item)} onDelete={self.onAddItem(item, true)}/>)}
                </InputAdornment>,
                endAdornment: <InputAdornment onClick={e => {
                  // e.preventDefault()
                  e.stopPropagation()
                }} style={{ position: 'absolute', right: 7, top: '50%', color: '#757575', cursor: 'pointer', pointerEvents: 'none' }} position="end"><ArrowDropDown/></InputAdornment>,
              }}
            />
            <Popper
              keepMounted
              disablePortal={false}
              open={open}
              anchorEl={this.textfieldRef.current}
              style={{ zIndex: 100000 }}
              popperOptions={{
                modifiers: {
                  setPopperWidth: {
                    enabled: true,
                    order: 1000,
                    fn: data => {
                      const { instance: { reference, popper } } = data
                      popper.style.width = `${this.textfieldRef.current ? reference.offsetWidth : 500}px`
                      return data
                    },
                  },
                },
              }}
            >
              <Paper
                style={{
                  height: isLoading
                    ? 150
                    : maxHeight,
                  overflow: 'auto',
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                {!isLoading && <VirtualizedList
                  rowHeight={36}
                  selectedData={value}
                  autoSizeHeight={false}
                  listHeight={maxHeight}
                  list={filteredItemsBySearch}
                  renderItem={(item, options) => {
                    const isItemChecked = Boolean(isChecked(value, item))
                    return (
                      <ListItem tab button onClick={self.onAddItem(item, isItemChecked)} key={options.key} style={options.style}>
                        <Checkbox checked={isItemChecked} />
                        {typeof renderItem === 'function' ? renderItem(item) : item}
                      </ListItem>
                    )
                  }}
                />
                }
                {filteredItemsBySearch.length <= 0 && <Typography variant='body2'>{emptyLabel}</Typography>}
                {isLoading && <LoadingText title={loadingLabel}/>}
              </Paper>
            </Popper>
          </div>
        </ClickAwayListener>
      </div>
    )
  }
}

Autocomplete.propTypes = {
  loadingLabel: PropTypes.string,
  emptyLabel: PropTypes.string,
  onSearchFilter: PropTypes.func,
  getId: PropTypes.func,
  isChecked: PropTypes.func,
  extra: PropTypes.any,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.bool,
  label: PropTypes.string,
  inputClassName: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array,
  InputLabelProps: PropTypes.object,
  renderItem: PropTypes.func,
  onWheel: PropTypes.func,
  setValue: PropTypes.func,
  onChange: PropTypes.func,
}

export default Autocomplete
