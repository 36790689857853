/**
 * This function transform every form value and use next transformations:
 * 1) Checks if we need to send current value. If not - to the next value.
 * 2) Checks if we need to change the key for this value in JSON
 * 3) Checks if we need to nest value inside other object in JSON
 * 4) Transforming value if needed
 * 5) Adding final transformed value to the final object
 * @param {Object} state App state
 * @param {Object} values Transformed form values
 * @param {Object} rawValues Untransformed form values
 * @param {Object} fieldsTemplates Form field templates
 * @param {*} loopThroughValues
 */
export const getBackendTransformedValues = (
  extra,
  state,
  values,
  rawValues,
  fieldsTemplates,
  loopThroughValues = false,
) => {
  return Object.keys(loopThroughValues ? values : fieldsTemplates).reduce((allValues, key) => {
    const value = values[key]
    const option = fieldsTemplates[key] || {}
    const {
      backendTransform,
      sendToBackend,
      backendTransformName,
      backendName,
      mergeWith,
    } = option
    // Checks if the value should be send to backend
    const shouldSendToBackend = typeof sendToBackend === 'function'
      ? sendToBackend(state, values, value, extra)
      : typeof sendToBackend === 'boolean'
        ? sendToBackend
        : true
    if (!shouldSendToBackend) return allValues
    const typeOfBackendTransformName = typeof backendTransformName
    // We can provide different key for the value that should be send
    const fieldName = mergeWith || (typeOfBackendTransformName !== 'undefined'
      ? typeOfBackendTransformName === 'function'
        ? backendTransformName(state, values)
        : backendTransformName
      : key
    )
    // We can provide root name in which this value should be nested
    const backendFinalName = typeof backendName === 'function'
      ? backendName(value, state, rawValues)
      : backendName
    const fields = allValues[backendFinalName] || {}
    // We can provide transformation for the value before sending them
    const transformedValue = typeof backendTransform === 'function'
      ? backendTransform(value, state, rawValues, extra)
      : 'backendTransform' in option
        ? backendTransform
        : value
    const field = fieldName ? {
      [fieldName]: mergeWith ? [...allValues[mergeWith], ...transformedValue] : transformedValue,
    } : transformedValue
    return {
      ...allValues,
      ...(backendFinalName ? {
        [backendFinalName]: {
          ...fields,
          ...field,
        },
      } : field
      ),
    }
  }, {})
}
