import { put, takeLatest, fork, call } from 'redux-saga/effects'
import SystemTypeActions, { SystemTypeTypes } from './actions'
// import axios from 'axios'
import axios from 'utils/axios'
import { joinAll } from 'utils/api'
import { toast } from 'react-toastify'

function * addSystemTypeImages ({ systemTypeId, images, onSuccess }) {
  yield put(SystemTypeActions.addSystemTypeImagesLoading(systemTypeId))
  try {
    const tasks = []
    const prefix = 'base64,' // data:image/jpeg;
    for (const image of images) {
      tasks.push(yield fork(axios.post, `/rovers_system_types/${systemTypeId}/upload_image`, {
        name: `${image.name}.${image.extension || 'jpg'}`,
        file: image.src.split(prefix)[1],
      }))
    }
    const joinedResults = yield joinAll(tasks)
    const newImages = joinedResults.reduce((allResults, { data: { data: image } }, index) => ([
      ...allResults,
      image,
    ]), [])
    if (typeof onSuccess === 'function') {
      onSuccess()
    }
    toast.success('Images were successfully added!')
    yield put(SystemTypeActions.addSystemTypeImagesSuccess(systemTypeId, newImages))
  } catch (e) {
    yield put(SystemTypeActions.addSystemTypeImagesFailure(systemTypeId, e.message || 'Error ocurred'))
  }
}

function * getSystemTypeImages ({ systemTypeId }) {
  yield put(SystemTypeActions.getSystemTypeImagesLoading(systemTypeId))
  try {
    const { data: { data: images } } = yield call(axios.get, `/rovers_system_types/${systemTypeId}/images`)
    yield put(SystemTypeActions.getSystemTypeImagesSuccess(systemTypeId, images))
  } catch (e) {
    yield put(SystemTypeActions.getSystemTypeImagesFailure(systemTypeId, e.message || 'Error ocurred'))
  }
}

function * deleteSystemTypeImages ({ systemTypeId, images, onSuccess }) {
  yield put(SystemTypeActions.deleteSystemTypeImagesLoading(systemTypeId))
  try {
    const tasks = []
    for (const imageId of images) {
      tasks.push(yield fork(axios.delete, `/system_types_images/${imageId}`))
    }
    // const joinedResults = yield joinAll(tasks)
    yield joinAll(tasks)
    if (typeof onSuccess === 'function') {
      onSuccess()
    }
    toast.success('Image was successfully deleted!')
    yield put(SystemTypeActions.deleteSystemTypeImagesSuccess(systemTypeId, images))
  } catch (e) {
    yield put(SystemTypeActions.deleteSystemTypeImagesFailure(systemTypeId, e.message || 'Error ocurred'))
  }
}

export function * addSystemTypeImagesWatcher () {
  yield takeLatest(SystemTypeTypes.ADD_SYSTEM_TYPE_IMAGES, addSystemTypeImages)
}
export function * getSystemTypeImagesWatcher () {
  yield takeLatest(SystemTypeTypes.GET_SYSTEM_TYPE_IMAGES, getSystemTypeImages)
}
export function * deleteSystemTypeImagesWatcher () {
  yield takeLatest(SystemTypeTypes.DELETE_SYSTEM_TYPE_IMAGES, deleteSystemTypeImages)
}
