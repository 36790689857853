import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
// Material Ui
import { makeStyles, createStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
// Project deps

const useStyles = makeStyles(theme => createStyles({
  loaderContainer: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.main,
    flexDirection: 'column',
  },
}))

function PageLoader (props) {
  const classes = useStyles()
  if (props.error) {
    console.error(props.error)
    return <div><Trans i18nKey='pageLoader.error'/> Please reload this page</div>
  } else if (props.pastDelay) {
    return <div className={classes.loaderContainer}>
      <CircularProgress size={64} disableShrink/>
    </div>
  } else {
    return null
  }
}

PageLoader.propTypes = {
  error: PropTypes.string,
  pastDelay: PropTypes.bool,
  retry: PropTypes.func,
}

export default PageLoader
