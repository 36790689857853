import moment from 'utils/moment'

export function setTimezone (timezone) {
  moment.tz.setDefault(timezone)
}

export const iframeParams = {
  display: 'none !important',
  width: 1,
  height: 1,
  hidden: true,
}
