import axios from 'axios'
import config from 'config'

const api = axios.create({
  baseURL: config.API_BASE,
  // withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const axiosResources = axios.create({
  baseURL: config.RESOURCES_API_BASE,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})
/*
api.interceptors.request.use(
  function (config) {
    config.headers.withCredentials = true
    return config
  },
  function (err) {
    console.error('api.interceptors.request')
    console.error(err)
    return Promise.reject(err)
  },
)
*/

export default api

export const axiosClient = axios.create({
  baseURL: config.API_BASE_CLIENT,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const axiosLicensing = axios.create({
  baseURL: config.LICENSING_API_BASE_CLIENT,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const axiosComponentInfo = axios.create({
  baseURL: config.COMPONENT_INFO_API_BASE,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const axiosLidarmill = axios.create({
  baseURL: config.LIDARMILL_API_BASE,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const setToken = token => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export const setLoggedInAsUser = userEmail => {
  localStorage.setItem('logged_as', userEmail)
}

export const getLoggedInAsUser = () => {
  return localStorage.getItem('logged_as')
}

export const removeTokenOnceOptions = () => {
  return {
    transformRequest: (data, headers) => {
      delete headers.common.Authorization
      return data
    },
  }
}
