import React from 'react'
import PropTypes from 'prop-types'
import { equals } from 'ramda'
// import { DatePicker } from 'material-ui-pickers'
// Material UI
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
// Icons
// Project deps
import { withMaxPrecision, withRounding } from 'utils/numeric'
import { getValue } from 'utils/templatedForm'
import { getFormFieldErrorMessage } from '../utils'

const labelStyle = {
  color: 'rgba(0, 0, 0, 0.54)',
  fontSize: '13px',
  marginBottom: '0px',
  marginTop: '0px',
}

const styles = theme => ({
  coordinateForm: {
    display: 'flex',
    flexFlow: 'row nowrap',
    width: '100%',
  },
  coordinateText: {
    flexBasis: 1,
    flexGrow: 1,
    flexShrink: 1,
  },
})

/**
 * Renders a floating point vector input field.
 * @param name The name of the field (Label for the entire vector).
 * @param option The field definition.
 * @return A component for inputting floating point vectors.
 */
class Vector3Option extends React.Component {
  constructor (props) {
    super(props)
    const values = this.getValues(props)
    this.state = {
      value: values,
      originalValue: values,
    }
  }

  componentDidUpdate (prevProps) {
    const { name, values: formValues } = this.props
    const { values: prevFormValues } = prevProps
    if (formValues[name] !== prevFormValues[name]) {
      const newValue = this.getValues(this.props)
      if (!equals(this.state.value, newValue)) {
        this.setState({
          value: newValue,
          originalValue: newValue,
        })
      }
    }
  }

  getValues = props => {
    const { option } = props
    const { precision, rounding = true } = option
    const rawValue = getValue(props)
    const nonClampedValue = Array.isArray(rawValue) && rawValue.length === 3 && typeof rawValue[0] === 'string'
      ? rawValue
      : ['', '', '']
    // Clamp the values initially to their precision.
    return nonClampedValue.map((elem, index) => {
      const precisionForElement = precision.length > index && precision[index]
      const precisedValue = withMaxPrecision(rounding ? withRounding(elem) : elem, precisionForElement)
      return typeof precisionForElement === 'number'
        ? Math.abs(+precisedValue) < +`0.${''.padEnd(precisionForElement - 1, '0')}1` ? '0.000' : precisedValue
        : precisedValue
    })
  }

  onChange = index => event => {
    const newVector = [...this.state.value]
    const stringValue = event.target.value
    newVector[index] = stringValue
    this.setState({ value: newVector })
  }

  callOnChange = value => {
    const { originalValue } = this.state
    const { setValue = () => {}, option, name } = this.props
    const { onChange: onChangeCoordinate, format } = option

    if (!equals(originalValue, value)) {
      const formattedValue = typeof format === 'function' ? value.map(x => format(x)) : value
      if (typeof onChangeCoordinate === 'function') onChangeCoordinate(name, formattedValue, this.props)
      setValue(name, formattedValue, option)
    }
  }

  onBlur = event => {
    const { value } = this.state
    const { option } = this.props
    const { precision, rounding = true } = option
    const transformedValue = value.map((strValue, index) => withMaxPrecision(rounding
      ? withRounding(strValue)
      : strValue, precision.length > index && precision[index]))
    this.setState({ value: transformedValue })
    // if use transformedValue then we will get the truncated and rounded number in the artifact options
    this.callOnChange(transformedValue)
  }
  onKeyDown = inputName => event => {
    if (event.keyCode === 13 && this[inputName]) this[inputName].blur()
  }
  setRef = inputName => node => {
    this[inputName] = node
  }

  render () {
    const value = this.state.value
    const {
      state,
      extra,
      classes,
      option,
      disabled,
      values: formValues,
      formTemplate,
    } = this.props

    return (
      <div style={{ width: '100%' }}>
        <Typography style={labelStyle}>{option.name}</Typography>
        <div className={classes.coordinateForm}>
          {
            value.map((componentValue, index) => {
              const componentName = option.componentNames[index]
              const errorMessage = getFormFieldErrorMessage(componentValue, option, formValues, state, extra, formTemplate, this.props)
              const error = Boolean(errorMessage)
              const inputName = 'input' + index
              return <TextField
                inputRef={this.setRef(inputName)}
                key={index}
                label={error ? errorMessage : componentName}
                error={error}
                value={componentValue}
                onChange={this.onChange(index)}
                onBlur={this.onBlur}
                onKeyDown={this.onKeyDown(inputName)}
                disabled={disabled}
                className={classes.coordinateText}
              />
            })
          }
        </div>
      </div>
    )
  }
}

Vector3Option.propTypes = {
  state: PropTypes.object,
  extra: PropTypes.any,
  name: PropTypes.string,
  option: PropTypes.object,
  disabled: PropTypes.bool,
  classes: PropTypes.object,
  values: PropTypes.object,
  setValue: PropTypes.func,
  formTemplate: PropTypes.object,
}

export default withStyles(styles)(Vector3Option)
