import { call, fork } from 'redux-saga/effects'
import { toast } from 'react-toastify'
// Project deps
import i18next from 'i18n'
import history from 'config/history'
import axios from 'utils/axios'
import { joinAll, showErrorMessage } from 'utils/api'
import { routeRegisterSuccess } from 'utils/routing'
import { getRoverEventAttachmentsUrl } from 'utils/roverEvents'
import { DocumentType } from 'types/roverEvents'
import { convertRawAttachment } from 'types/attachments'

function * parallelFilesUpload (files, getData, { url, filesLimit = 4 }) {
  const filesCount = files.length
  let i = 1
  let endIndex = 0
  let result = []
  let tasks = []
  do {
    const startIndex = filesLimit * (i - 1)
    const step = (startIndex + filesLimit)
    endIndex = step > filesCount ? filesCount : step
    for (let i = startIndex; i < endIndex; i++) {
      const file = files[i]
      const data = getData(file, i)
      tasks.push(yield fork(
        axios.post,
        typeof url === 'function' ? url(file, i) : url,
        data,
      ))
    }
    const joinedResults = yield joinAll(tasks)
    result = [...result, ...joinedResults.map(({ data: { data: fileUpload } }) => fileUpload)]
    tasks = []
    i++
  } while (endIndex < filesCount)
  return result
}

export function * addAttachments (eventId, eventType, allAttachments) {
  try {
    const url = getRoverEventAttachmentsUrl(eventId, eventType)
    const attachments = []
    for (const attachment of allAttachments) {
      const isImage = attachment.isImage
      attachments.push(attachment)
      if (isImage) {
        attachments.push({
          extension: attachment.extension,
          file: attachment.compressedFile,
          name: `${attachment.name}-${DocumentType.THUMBNAIL}`,
          type: DocumentType.THUMBNAIL,
          is_thumbnail: true,
        })
      }
    }

    const allAttachmentUpload = yield call(
      parallelFilesUpload,
      attachments,
      attachment => ({
        file: attachment.name + `.${attachment.extension}`,
        name: attachment.name,
        document_type: attachment.type === 'none' ? null : attachment.type,
        is_thumbnail: attachment.is_thumbnail,
      }),
      { url },
    )

    yield call(
      parallelFilesUpload,
      allAttachmentUpload,
      (attachment, index) => {
        const file = attachments[index]
        const { s3_response: { fields } } = attachment
        const formData = new FormData()
        Object.keys(fields).forEach(key => {
          formData.append(key, fields[key])
        })
        formData.append('file', file.file)
        return formData
      },
      {
        url: attachment => {
          const { s3_response: { url } } = attachment
          return url
        },
      },
    )
    return allAttachmentUpload.map(convertRawAttachment)
  } catch (e) {
    throw new Error(e)
  }
}

export function * register (url, body, redirect = true, route, createdByAdmin = false) {
  try {
    const { data } = yield call(axios.post, url, body)
    if (redirect) history.push(route || routeRegisterSuccess())
    if (createdByAdmin) {
      toast.success(i18next.t('toast.user.userRegisterSuccess'))
    } else {
      toast.success(i18next.t('toast.user.registerSuccess'))
    }
    return data
  } catch (e) {
    showErrorMessage(e)
    return e
  }
}

export const getDefaultPagination = settings => {
  const { page = 1, total = 0, total_pages = 0, page_size = 100, order, order_by, filter = '', search = '' } = (settings || {})
  return {
    page,
    total,
    total_pages,
    page_size,
    order,
    order_by,
    filter,
    search,
  }
}

export const updatePagination = (pagination, action) => {
  const { page_size, order, order_by } = pagination
  let { page, total, total_pages } = pagination
  if (action === 'remove') {
    total -= 1
  }
  if (action === 'add') {
    total += 1
  }
  total_pages = total <= page_size ? 1 : Math.ceil(total / page_size)
  page = page > total_pages ? total_pages : page
  return {
    page,
    total,
    total_pages,
    page_size,
    order,
    order_by,
  }
}
