import React from 'react'
import PropTypes from 'prop-types'
// Material UI
// Icons
// Project deps
import Combobox from 'components/reusable/Combobox'
import { getValue } from 'utils/templatedForm'
import { getFormFieldErrorMessage } from '../utils'

/**
 * Renders a selection input field.
 * @param name The name of the field.
 * @param option The field definition.
 * @return A component for selecting an option.
 */
const AutocompleteOption = propOptions => {
  const {
    state,
    extra,
    name,
    option,
    disabled,
    values,
    setValue,
    formTemplate,
  } = propOptions
  const {
    tooltip,
    tooltipProps,
    onChange: onChangeHandle = () => {},
    displayTemplate,
    searchTemplate,
    compare,
    editable = true,
    disableSearch = false,
    autoSizeHeight = true,
    rowHeight = 50,
    viewLimit = 7,
    isLoading,
    createNew = false,
    disablePortal = false,
    disabledReason: disabledReason_,
    startAdornment,
  } = option
  const originalValue = values[name]
  const value = originalValue && typeof originalValue === 'object' && originalValue.createdByUser
    ? originalValue.value
    : getValue(propOptions)
  const onChange = selectedItem => {
    if (typeof onChangeHandle === 'function') onChangeHandle(name, selectedItem, propOptions)
    setValue(name, selectedItem, option)
  }
  const clearSelection = () => setValue(name, '', option)
  const disabledReason = disabled
    ? disabledReason_
      ? typeof disabledReason_ === 'function'
        ? disabledReason_(state, values, extra, option, formTemplate, propOptions)
        : disabledReason_
      : ''
    : ''
  const errorMessage = disabledReason || getFormFieldErrorMessage(value, option, values, state, extra, formTemplate, propOptions)
  const { options, optionProps = {}, extraRender } = option
  // It is possible to specify the options for the select field as an array of strings as well as
  // a function returning the array of strings.
  // If the `options` property was a function, it needs to be evaluated...
  const createdOptions = (typeof options === 'function' ? options(state, values, extra, propOptions) : options) || []
  const props = typeof optionProps === 'function' ? optionProps(state, values, extra, propOptions) : optionProps
  const { helperText = '', ...remainedProps } = (props || {})
  const helpText = typeof helperText === 'function' ? helperText(state, values, extra, propOptions) : helperText
  const optionName = option.name
  const label = typeof optionName === 'function' ? optionName(state, values) : optionName
  const loading = typeof isLoading === 'function'
    ? isLoading(state, values, extra, propOptions)
    : typeof isLoading !== 'undefined' ? isLoading : false

  const searchTemp = entry => {
    return typeof searchTemplate === 'function' ? searchTemplate(entry, state, extra) : entry
  }
  const inputDisplayTemplate = entry => {
    return typeof displayTemplate === 'function' ? displayTemplate(entry, state, extra) : entry
  }
  return (
    <React.Fragment>
      <Combobox
        editable={editable}
        tooltip={tooltip}
        tooltipProps={tooltipProps}
        autoSizeHeight={autoSizeHeight}
        disableSearch={disableSearch}
        rowHeight={rowHeight}
        viewLimit={viewLimit}
        createNew={createNew}
        fullWidth
        error={Boolean(errorMessage)}
        errorMessage={errorMessage}
        label={label}
        items={createdOptions}
        compare={compare}
        searchTemplate={searchTemp}
        inputDisplayTemplate={inputDisplayTemplate}
        onChange={onChange}
        clearSelection={clearSelection}
        value={value || ''}
        selectedItem={originalValue}
        disabled={disabled}
        isLoading={loading}
        disablePortal={disablePortal}
        helperText={helpText}
        startAdornment={startAdornment}
        {...remainedProps}
      />
      { typeof extraRender === 'function' && extraRender(state, values, extra, propOptions) }
    </React.Fragment>
  )
}

AutocompleteOption.propTypes = {
  state: PropTypes.object,
  extra: PropTypes.any,
  name: PropTypes.string,
  option: PropTypes.object,
  disabled: PropTypes.bool,
  classes: PropTypes.object,
  values: PropTypes.object,
  setValue: PropTypes.func,
  formTemplate: PropTypes.object,
}

export default AutocompleteOption
