import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
// Material UI
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Button, Dialog, DialogContent, Paper, MobileStepper, Typography, IconButton, Tooltip } from '@material-ui/core'
// Icons
import DeleteIcon from '@material-ui/icons/Delete'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
// Project deps
import ConfirmDialog from 'components/Licensing/ConfirmDialog'
import Zoom from 'components/reusable/Zoom'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '100%',
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    backgroundColor: theme.palette.background.default,
  },
  img: {
    overflow: 'hidden',
    display: 'block',
    // width: '100%',
  },
  imgContainer: {
    // width: 1080,
    height: 480,
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  deletButton: {
    marginLeft: theme.spacing(2),
  },
}))
const ViewImagesDialog = props => {
  const {
    onClose,
    images,
    defaultStep,
    onDeleteImage: onDeleteImageFromProps,
    imageId: imageIdFromProps = 'image_file',
    imageSrc: imageSrcFromProps = 'image_file',
    canDownload = false,
  } = props

  const classes = useStyles()
  const theme = useTheme()
  const [activeStep, setActiveStep] = useState(0)
  const [dialogOpened, setDialogOpened] = useState(null)
  const maxSteps = images.length

  useEffect(() => {
    window.onkeydown = function (e) {
      if (e.code === 'ArrowLeft') {
        handleBack()
      }
      if (e.code === 'ArrowRight') {
        handleNext()
      }
    }
    return () => {
      window.onkeydown = null
    }
  }, [activeStep])

  useEffect(() => {
    setActiveStep(defaultStep)
  }, [defaultStep])

  const handleNext = () => {
    if (activeStep === maxSteps - 1) {
      setActiveStep(0)
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    if (activeStep === 0) {
      setActiveStep(maxSteps - 1)
    } else {
      setActiveStep(prevActiveStep => prevActiveStep - 1)
    }
  }

  const handleStepChange = step => {
    setActiveStep(step)
  }

  const onDeleteImage = id => e => {
    setDialogOpened({ name: 'delete-image', id })
  }

  const onSubmitDelete = e => {
    onDeleteImageFromProps([images.find(image => image.id === dialogOpened.id)], () => {
      setDialogOpened(null)
      setActiveStep(0)
      if (images.length - 1 <= 0) {
        onClose(e)
      }
    })
  }
  const onCancelDelete = e => {
    setDialogOpened(null)
  }

  const activeImage = images[activeStep]
  const imageSrc = activeImage.uploaded ? 'file_url' : imageSrcFromProps
  const imageId = activeImage.uploaded ? 'id' : imageIdFromProps

  return (
    <Dialog open onClose={onClose} maxWidth='xl' fullWidth>
      <DialogContent style={{ padding: 0 }}>
        { dialogOpened && dialogOpened.name === 'delete-image' &&
        <ConfirmDialog
          title='Delete image'
          onCancel={onCancelDelete}
          onSubmit={onSubmitDelete}
          open
        >
          Are you sure you want to delete selected image?
        </ConfirmDialog>
        }
        <div className={classes.root}>
          {false && <Paper square elevation={0} className={classes.header}>
            <Typography>Created at: {activeImage && activeImage.created_at}</Typography>
          </Paper>
          }
          {(canDownload || onDeleteImageFromProps) && <Paper square elevation={0} className={classes.header}>
            {canDownload &&
              <Typography style={{ textDecoration: 'underline' }} component='a' href={activeImage[imageSrc]}>
                Download image
              </Typography>
            }
            {onDeleteImageFromProps &&
              <Tooltip title='Delete image'>
                <IconButton onClick={onDeleteImage(activeImage && activeImage.id)} className={classes.deletButton}>
                  <DeleteIcon/>
                </IconButton>
              </Tooltip>
            }
          </Paper>
          }
          <SwipeableViews
            axis='x'
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {images.map((image, index) => (
              <div className={classes.imgContainer} key={image[imageId] + index}>
                {activeStep === index ? activeImage ? <Zoom src={activeImage[imageSrc]}/> : null : null}
              </div>
            ))}
          </SwipeableViews>
          <MobileStepper
            steps={maxSteps}
            position='static'
            variant='text'
            activeStep={activeStep}
            nextButton={
              <Button size='small' onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                Next
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
              </Button>
            }
            backButton={
              <Button size='small' onClick={handleBack} disabled={activeStep === 0}>
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                Back
              </Button>
            }
          />
        </div>
      </DialogContent>
    </Dialog>
  )
}

ViewImagesDialog.propTypes = {
  canDownload: PropTypes.bool,
  imageId: PropTypes.string,
  imageSrc: PropTypes.string,
  defaultStep: PropTypes.number,
  images: PropTypes.array,
  onClose: PropTypes.func,
  onDeleteImage: PropTypes.func,
}

export default ViewImagesDialog
