import { BillingAgreementTypes } from 'modules/billingAgreement/actions'
import { getAllSalesProducts } from 'modules/billingAgreement/selectors'
import { CompaniesTypes } from 'modules/companies/actions'
import { UsersTypes } from 'modules/users/actions'

export const subscriptionsUpdateMiddleware = store => next => action => {
  if (
    action.type === BillingAgreementTypes.GET_SALES_PRODUCTS_SUCCESS ||
    action.type === BillingAgreementTypes.CREATE_PLAN_GRANT_SUCCESS ||
    action.type === BillingAgreementTypes.DELETE_PLAN_GRANT_SUCCESS
  ) {
    next(action)
    const state = store.getState()
    const salesProducts = getAllSalesProducts(state)
    const newAction = {
      type: BillingAgreementTypes.UPDATE_SUBSCRIPTIONS,
      salesProducts,
    }
    next(newAction)
  } else if (
    action.type === BillingAgreementTypes.GET_ALL_SUBSCRIPTIONS_SUCCESS ||
    action.type === BillingAgreementTypes.ACTIVATE_OFFLINE_SUBSCRIPTION_SUCCESS ||
    action.type === BillingAgreementTypes.DEACTIVATE_OFFLINE_SUBSCRIPTIONS_SUCCESS ||
    action.type === CompaniesTypes.GET_COMPANY_SUBSCRIPTIONS_BY_FILTERS_SUCCESS ||
    action.type === CompaniesTypes.GET_COMPANY_SUBSCRIPTIONS_SUCCESS ||
    action.type === CompaniesTypes.GET_COMPANY_SUBSCRIPTION_SUCCESS ||
    action.type === UsersTypes.UPDATE_SUBSCRIPTION_SUCCESS
  ) {
    const state = store.getState()
    const salesProducts = getAllSalesProducts(state)
    const newAction = {
      ...action,
      salesProducts,
    }
    next(newAction)
  } else {
    next(action)
  }
}
