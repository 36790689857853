import { put, takeLatest, call } from 'redux-saga/effects'
// import axios from 'axios'
// Project deps
import { makeRequest } from 'utils/request'
import axios from 'utils/axios'
import { RoverAction } from 'types/rovers'
// Local deps
import RoverActions, { RoverTypes } from './actions'

function * getRoverCalibrations ({ serial }) {
  yield put(RoverActions.getRoverCalibrationsLoading(serial))
  try {
    const { data: { data: calibrations } } = yield call(axios.get, `/rovers/${serial}/calibrations`)
    yield put(RoverActions.getRoverCalibrationsSuccess(
      serial,
      calibrations,
    ))
  } catch (e) {
    yield put(RoverActions.getRoverCalibrationsFailure(serial, e.message || 'Error ocurred'))
  }
}

function * editRoverCalibration ({ serial, slug, formData }) {
  yield put(RoverActions.editRoverCalibrationLoading(serial, slug))
  try {
    yield call(makeRequest, `/rover_calibrations/${slug}`, 'PATCH', RoverAction.EDIT_CALIBRATION, formData)
    yield put(RoverActions.editRoverCalibrationSuccess(serial, slug, formData))
  } catch (e) {
    yield put(RoverActions.editRoverCalibrationFailure(serial, slug, e.message || 'Error ocurred'))
  }
}

function * deleteRoverCalibration ({ serial, slug }) {
  yield put(RoverActions.deleteRoverCalibrationLoading(serial, slug))
  try {
    yield call(makeRequest, `/rover_calibrations/${slug}`, 'DELETE', RoverAction.DELETE_CALIBRATION)
    yield put(RoverActions.deleteRoverCalibrationSuccess(serial, slug))
  } catch (e) {
    yield put(RoverActions.deleteRoverCalibrationFailure(serial, slug, e.message || 'Error ocurred'))
  }
}

export function * getRoverCalibrationsWatcher () {
  yield takeLatest(RoverTypes.GET_ROVER_CALIBRATIONS, getRoverCalibrations)
}
export function * editRoverCalibrationWatcher () {
  yield takeLatest(RoverTypes.EDIT_ROVER_CALIBRATION, editRoverCalibration)
}
export function * deleteRoverCalibrationWatcher () {
  yield takeLatest(RoverTypes.DELETE_ROVER_CALIBRATION, deleteRoverCalibration)
}
