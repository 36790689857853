import { Map } from 'immutable'
import { getDefaultPagination } from '../utils'

/*
const defaultPagination = {
  page: 1,
  total: 0,
  total_pages: 0,
  page_size: 100,
}
*/

export const INITIAL_STATE = Map({
  roverFeatures: [],
  customerRovers: {},
  roversMap: null,
  rovers: [],
  allRovers: [],
  roversPagination: getDefaultPagination({ page_size: 50, order_by: 'updated_at', order: 'desc' }),
  addRoverIsLoading: false,
  roverCalibrations: {},
  editRoverCalibrationIsLoading: {},
  gerRoverCalibrationsIsLoading: {},
  roverCustomSettings: {},
  expandRoverSetting: null,
  editRoverCustomSettingIsLoading: {},
  getRoverCustomSettingsIsLoading: {},
  getRoversIsLoading: false,
  getCustomerRoversIsLoading: false,

  roverEvents: {},
  roverProjects: {},
  roverCompanyProjects: {},
  getRoverEventsIsLoading: {},
  getRoverProjectsIsLoading: {},
  addRoverEventIsLoading: {},

  // Systems
  systems: [],
  systemsPagination: {},
  getSystemsIsLoading: false,
  // Reports
  reports: {},
  reportsPagination: {},
  getSystemReportsIsLoading: {},
  // Components
  components: {},
  componentsPagination: {},
  getReportComponentsIsLoading: {},
  getSystemComponentsIsLoading: false,
  // Comments
  comments: [],
  commentsPagination: { total: 0 },
  getCommentsIsLoading: false,

  licenses: [],
  getLicensesIsLoading: false,

  componentTypes: [],
  propertyTypes: [],

  approveEventLoading: false,
  disapproveEventLoading: false,
  closeEventLoading: false,

  showRMADialog: { show: false, rmaId: null },
  showRegisterSystemDialog: { show: false },
  registerSystemLoading: false,

  getEventDBLogsLoading: false,
  eventDBLogs: {},
  eventDBLogsPagination: {},

  getCheckListStepDBLogsLoading: false,
  checkListStepDBLogs: {},
  checkListStepDBLogsPagination: {},

  editEventLoading: false,
  deleteEventIsLoading: false,

  getRoversCSVLoading: false,
  roversCSV: null,

  roverUpdates: {},
  getRoverUpdatesLoading: {},

  roverVersion: {},
  getRoverVersionLoading: {},
})
