import React from 'react'
import PropTypes from 'prop-types'
// Material UI
// Icons
// Project deps
import Autocomplete from 'components/reusable/Autocomplete'
import { getFormFieldErrorMessage } from '../utils'

class ComboboxOption extends React.Component {
  render () {
    const {
      option,
      disabled,
      values,
      name,
      state,
      extra,
      formTemplate,
      setValue,
    } = this.props
    const { options, isLoading = false, alwaysShowPlaceholder = false, placeholder = '', renderItem, isChecked, getId, onSearchFilter } = option
    const value = values[name]
    const createdOptions = (typeof options === 'function' ? options(state, values, extra, this.props) : options) || []
    const isLoadingFinal = typeof isLoading === 'function' ? isLoading(state, values, extra, this.props) : isLoading
    // const roversMap = getRoversMap(state)
    // const type = this.props.type || option.type || 'text'
    // const value = this.state.value || ''
    // const { okay, message } = customCheck(value, undefined, state)
    const errorMessage = getFormFieldErrorMessage(value, option, values, state, extra, formTemplate, this.props)
    const error = Boolean(errorMessage)
    const label = typeof option.name === 'function' ? option.name(value) : option.name
    const placeHolder = typeof placeholder === 'function' ? placeholder(state, values, extra, this.props) : placeholder
    const onChange = value => {
      setValue(name, value, option)
    }
    return (
      <Autocomplete
        isLoading={isLoadingFinal}
        value={value}
        label={label}
        placeholder={label}
        error={error}
        {...error ? {
          label: errorMessage,
          placeholder: alwaysShowPlaceholder ? placeHolder : label,
          InputLabelProps: {
            shrink: true,
          },
        } : {
          label: label,
          placeholder: placeHolder,
        }}
        getId={getId}
        onSearchFilter={onSearchFilter}
        renderItem={renderItem}
        isChecked={isChecked}
        options={createdOptions}
        errorMessage={errorMessage}
        disabled={disabled}
        onChange={onChange}
      />
    )
  }
}

ComboboxOption.propTypes = {
  extra: PropTypes.any,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  state: PropTypes.object,
  values: PropTypes.object,
  option: PropTypes.object,
  formTemplate: PropTypes.object,
  onWheel: PropTypes.func,
  setValue: PropTypes.func,
}

export default ComboboxOption
