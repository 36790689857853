// External dependencies.
import React from 'react'
import i18n from 'i18n'
// Material UI
// Icons
// Internal dependencies.
import moment from 'utils/moment'
import Chip from 'components/reusable/Chip'
import { SubscriptionTypes, SubscriptionStatus } from 'utils/subscription'
// import { JobRunLog } from "../types/job-run-logs";
// import { DataFileUploadLog } from "../types/datafile-upload-logs";
// import { prettifyJobType } from './jobs'
// import { getSupportedLocale } from "./locale";

export const DisplayDateType = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
}

export const SubscriptionStatusIcon = {
  'active': 'green',
  'expired': 'red',
  'incomplete': 'red',
  'incomplete_expired': 'red',
  'trialing': 'red',
  'past_due': 'red',
  'canceled': 'orange',
  'unpaid': 'grey',
}

export function getSubscriptionStatusIcon (status) {
  const lowerCasedStatus = status && status.toLowerCase()
  const style = {
    backgroundColor: SubscriptionStatusIcon[lowerCasedStatus] || 'green',
    color: '#fff',
  }
  return <Chip
    style={style}
    label={
      <React.Fragment>
        {getPrettySubscriptionStatus(status)}
      </React.Fragment>
    }
    size='small'
  />
}

export function getPrettySubscriptionStatus (status) {
  const lowerCasedStatus = status && status.toLowerCase()
  return status ? SubscriptionStatus[lowerCasedStatus] || lowerCasedStatus : i18n.t('subscriptions.status.unknown')
}

export function getPrettySubscriptionType (type) {
  const lowerCasedType = type && type.toLowerCase()
  return type ? SubscriptionTypes[lowerCasedType] || lowerCasedType : i18n.t('subscriptions.type.unknown')
}

export function getMappedColorsObject (colors, func) {
  return Object
    .keys(colors)
    .reduce((res, key) => ({ ...res, [key]: { background: func ? func(colors[key]) : colors[key] } }), {})
}

export function prettifyDuration (totalSeconds, withNull = false) {
  const duration = moment.duration(totalSeconds, 'seconds')
  const hours = duration.hours()
  const minutes = duration.minutes()
  const seconds = duration.seconds()
  const hoursString = (hours === 0 && !withNull) ? '' : hours < 10 ? `0${hours}:` : `${hours}:`
  const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`
  const secondsString = seconds < 10 ? `0${seconds}` : `${seconds}`
  return `${hoursString}${minutesString}:${secondsString}`
}

export function getPrettyDateString (date) {
  // const locale = getSupportedLocale();
  return date.toLocaleDateString('en', { weekday: 'short', month: 'numeric', day: 'numeric' })
}

/**
 * Split the interval `[startDate, endDate]` into intervals of length `displayType`.
 * The generated intervals will be aligned to `displayType`, which means that splitting an interval
 * `[15.04.2018 .. 27.05.2018]` into months will result in two intervals:
 * 1: `[15.04.2018 .. 30.04.2018]`
 * 2: `[01.05.2018 .. 27.05.2018]`
 * This function accepts human intervals, where the interval `[15.03.2018 .. 15.03.2018]`
 * means "from 2018-03-15T00:00:00.000Z to (and including) 2018-03-15T23:59:59.999".
 * This function returns programmatic intervals, meaning `start` denotes the beginning of an interval
 * and `end` denotes the first instant of the next interval.
 */
function splitInterval (startDate, endDate, displayType) {
  // Helper utilities.
  const startOf = unit => moment(startDate).startOf(unit)
  const endOf = unit => moment(endDate).endOf(unit)

  // Convert the enum to a string type.
  const unit =
    displayType === DisplayDateType.DAY ? 'day'
      : displayType === DisplayDateType.WEEK ? 'week'
        : displayType === DisplayDateType.MONTH ? 'month'
          : undefined
  // Compute the number of intervals by stretching the selected range to alignment borders.
  const numIntervals = Math.round(moment.duration(endOf(unit).diff(startOf(unit))).as(unit))

  // We want the statistics to include the `endDate` day,
  // but not the following, independent of the specified time.
  const start = moment(startDate).startOf('day')
  const end = moment(endDate).add(1, 'day').startOf('day')

  // Clamp the split intervals at `[start .. end]`.
  return Array(numIntervals).fill(undefined).map((_, index) => ({
    start: moment.max(start, moment(startOf(unit)).add(index, unit)).toDate(),
    end: moment.min(end, moment(startOf(unit)).add(index + 1, unit)).toDate(),
  }))
}

/**
 * Create labels for the split intervals.
 */
export function getPipelineChartLabels (startDate, endDate, displayType) {
  // const locale = getSupportedLocale();
  const intervals = splitInterval(startDate, endDate, displayType)

  if (displayType === DisplayDateType.DAY) {
    // Prettify the date string.
    return intervals.map(({ start }) => getPrettyDateString(start))
  }
  if (displayType === DisplayDateType.WEEK) {
    return intervals.map(({ start, end }) => {
      const momentStart = moment(start)
      const momentEnd = moment(end).subtract(1, 'days')
      const prettyStart = getPrettyDateString(start)
      return momentStart.isSame(momentEnd, 'day')
        // When the start and end of the interval are the same, then just print the prettified date.
        ? prettyStart
        // When the start and end are different, then print both, separated by a `'~'`.
        : `${prettyStart}~${getPrettyDateString(momentEnd.toDate())}`
    })
  }
  if (displayType === DisplayDateType.MONTH) {
    // Return the prettified month.
    return intervals.map(({ start }) => start.toLocaleString('en', { year: 'numeric', month: 'short' }))
  }
}

export function getColsWidth (firstCellWidthPerc, totalCells) {
  return (100 - firstCellWidthPerc) / (totalCells - 1)
}
