import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  checkGrant: ['grant', 'key', 'field', 'resultField'],
  checkGrantLoading: ['grant'],
  checkGrantSuccess: ['grant', 'allowed'],
  checkGrantFailure: ['errorMessage'],

  checkGrants: ['grants', 'key'],
  checkGrantsLoading: ['key'],
  checkGrantsSuccess: ['grants', 'key'],
  checkGrantsFailure: ['errorMessage'],

  getGrants: ['userId'],
  getGrantsLoading: null,
  getGrantsSuccess: ['grants', 'navlabUsed', 'navlabMax', 'userId'],
  getGrantsFailure: ['errorMessage'],

  getGrantsForActiveUser: null,
  getGrantsForActiveUserLoading: null,
  getGrantsForActiveUserSuccess: ['grants'],
  getGrantsForActiveUserFailure: ['errorMessage'],

  createGrant: ['subscriptionId', 'grant'],
  createGrantLoading: null,
  createGrantSuccess: ['grant', 'subscriptionId'],
  createGrantFailure: ['errorMessage'],

  deleteGrant: ['subscriptionId', 'grant', 'onSuccess'],
  deleteGrantLoading: null,
  deleteGrantSuccess: ['grant', 'subscriptionId'],
  deleteGrantFailure: ['errorMessage'],
})

export const GrantsTypes = Types
export default Creators
