import { Map } from 'immutable'
import { getDefaultPagination } from '../utils'

export const INITIAL_STATE = Map({
  rma: [],
  allRma: [],
  pagination: getDefaultPagination({ page_size: 50, order: 'desc', order_by: 'timestamp' }),
  getRMAIsLoading: false,

  selectedRMA: null,
  selectRMAIsLoading: false,
})
