import { put, takeLatest, fork, call, select } from 'redux-saga/effects'
import { omit } from 'ramda'
import { toast } from 'react-toastify'
// import axios from 'axios'
// Project deps
import { makeRequest } from 'utils/request'
import axios from 'utils/axios'
import { SystemTypeAction } from 'types/systemTypes'
import { createQueryString, createQueryStringMaximumEntries, getPaginationFromResponse, joinAll, showErrorMessage } from 'utils/api'
import { RoverEventType, StepState } from 'types/roverEvents'
// Local deps
import SystemTypeActions, { SystemTypeTypes } from './actions'
import {
  getSystemTypeProfilesWatcher,
  addProfileToSystemTypeWatcher,
  addFileLinksWatcher,
  getProfileWatcher,
  updateProfileNameWatcher,
  updateProfileWatcher,
  validateProfileConfigurationsWatcher,
} from './profilesSagas'
import {
  getSystemTypeConfigurationsWatcher,
  addConfigurationToSystemTypeWatcher,
  updateConfigurationWatcher,
} from './configurationsSagas'
import {
  addSystemTypeImagesWatcher,
  getSystemTypeImagesWatcher,
  deleteSystemTypeImagesWatcher,
} from './imagesSagas'
import { addAttachments } from 'modules/utils'
import { getLoggedUser } from 'modules/users/selectors'
import { getCompanyId, isUserAdminOrPLSUser } from 'utils/company'

function * getSystemTypes ({ search = '', pageSize = 50, page = 1, orderBy = 'updated_at', order = 'desc' }) {
  yield put(SystemTypeActions.getSystemTypesLoading())
  const loggedUser = yield select(state => getLoggedUser(state))
  const isAdminOrPLSUser = isUserAdminOrPLSUser(loggedUser)
  const companyId = getCompanyId(loggedUser)
  // check if the user is admin or PLSUser and use different endpoint for getting system types
  const endpointPrefix = isAdminOrPLSUser
    ? ''
    : `/companies/${companyId}`
  try {
    const { data: { data: systemTypes, pagination } } = yield call(
      axios.get,
      `${endpointPrefix}/rovers_system_types${createQueryString({ search, pageSize, page, order, orderBy })}`,
    )
    yield put(SystemTypeActions.getSystemTypesSuccess(systemTypes, getPaginationFromResponse(pagination.total, page, pageSize, order, orderBy)))
  } catch (e) {
    yield put(SystemTypeActions.getSystemTypesFailure(e.message || 'Error ocurred'))
  }
}

function * getAllSystemTypes () {
  yield put(SystemTypeActions.getAllSystemTypesLoading())
  try {
    const { data: { data: systemTypes } } = yield call(
      axios.get,
      `/rovers_system_types${createQueryStringMaximumEntries({ order: 'desc', orderBy: 'updated_at' })}`,
    )
    yield put(SystemTypeActions.getAllSystemTypesSuccess(systemTypes))
  } catch (e) {
    yield put(SystemTypeActions.getAllSystemTypesFailure(e.message || 'Error ocurred'))
  }
}

function * getSystemType ({ systemTypeId }) {
  yield put(SystemTypeActions.getSystemTypeLoading(systemTypeId))
  try {
    const { data: { data: systemType } } = yield call(axios.get, `/rovers_system_types/${systemTypeId}`)
    yield put(SystemTypeActions.getSystemTypeSuccess(systemTypeId, systemType))
  } catch (e) {
    yield put(SystemTypeActions.getSystemTypeFailure(systemTypeId, e.message || 'Error ocurred'))
  }
}

/**
 * Fetch system type json permission sets for a specific user.
 */
function * getSystemTypesPermissions ({ userId }) {
  yield put(SystemTypeActions.getSystemTypePermissionsLoading())
  try {
    const { data: { data: permissions } } = yield call(
      axios.get,
      `/users/${userId}/permissions/rover_system_type_json_sets`,
    )
    yield put(SystemTypeActions.getSystemTypePermissionsSuccess(permissions))
  } catch (e) {
    yield put(SystemTypeActions.getSystemTypePermissionsFailure(e.message || 'Error ocurred'))
  }
}
function * addSystemType ({ formData, onSuccess }) {
  yield put(SystemTypeActions.addSystemTypeLoading())
  try {
    const { data: { data: systemType } } = yield call(makeRequest,
      '/rovers_system_types',
      'POST',
      SystemTypeAction.ADD_SYSTEM_TYPE,
      {
        name: formData.name,
        comment: formData.comment,
        configurations: [
          {
            configuration: formData.configuration,
            comment: 'Initial configuration',
          },
        ],
      },
    )
    if (formData.attachments) {
      const eventAttachments = yield call(
        addAttachments,
        systemType.id,
        RoverEventType.ROVER_SYSTEM_TYPE,
        formData.attachments,
      )
      yield put(SystemTypeActions.addSystemTypeSuccess({ ...systemType, attachments: eventAttachments }))
    } else {
      yield put(SystemTypeActions.addSystemTypeSuccess(systemType))
    }
    if (typeof onSuccess === 'function') {
      onSuccess()
    }
  } catch (e) {
    yield put(SystemTypeActions.addSystemTypeFailure(e.message || 'Error ocurred'))
  }
}

function * saveAsNewSystemType ({ formData, profile, configuration, oldSystemType, onSuccess }) {
  yield put(SystemTypeActions.saveAsNewSystemTypeLoading())
  const loggedUser = yield select(state => getLoggedUser(state))
  try {
    const { data: { data: systemType } } = yield call(makeRequest,
      '/rovers_system_types',
      'POST',
      SystemTypeAction.ADD_SYSTEM_TYPE,
      {
        name: formData.name,
        comment: formData.comment,
        configurations: [],
      },
      false,
    )
    const systemTypeId = systemType.id.toString()
    let createdConfiguration
    let createdProfile
    if (configuration) {
      const { data: { data: createdConfiguration_ } } = yield call(makeRequest,
        `/rovers_system_types/${systemTypeId}/configurations`,
        'POST',
        SystemTypeAction.ADD_CONFIGURATION,
        {
          configuration: configuration.configuration,
          transform: configuration.transform,
          comment: `copied from ${oldSystemType.name}`,
        },
        false,
      )
      createdConfiguration = createdConfiguration_
    }
    if (profile) {
      const { data: { data: createdProfile_ } } = yield call(makeRequest,
        `/rovers_system_types/${systemTypeId}/profiles`,
        'POST',
        SystemTypeAction.ADD_PROFILE,
        {
          configurations: profile.configurations,
          configuration: profile.configuration,
          transform: profile.transform,
          comment: `copied from ${oldSystemType.name}`,
          name: profile.name,
        },
        false,
      )
      createdProfile = createdProfile_
    }
    yield call(
      axios.post,
      `/rovers_system_types/${systemTypeId}/todos`,
      {
        assignee: { id: loggedUser.id },
        state: StepState.NOT_DONE,
        text: `Update configuration. Copied from ${oldSystemType.name}`,
        state_comment: '',
      },
    )
    yield put(SystemTypeActions.saveAsNewSystemTypeSuccess(systemType, createdProfile, createdConfiguration))
    if (typeof onSuccess === 'function') {
      onSuccess()
    }
    if (createdConfiguration) {
      yield put(SystemTypeActions.setExpandedConfiguration(createdConfiguration.id))
    } else {
      yield put(SystemTypeActions.setExpandedProfile(createdProfile.id))
    }
    yield put(SystemTypeActions.selectSystemType(systemTypeId, false))
    toast.success('System type has been successfully created!')
  } catch (e) {
    yield put(SystemTypeActions.saveAsNewSystemTypeFailure(e.message || 'Error ocurred'))
  }
}

function * updateSystemType ({ systemTypeId, formData, oldSystemType, onSuccess }) {
  yield put(SystemTypeActions.updateSystemTypeLoading(systemTypeId))
  try {
    let newSystemType = oldSystemType
    const attachments = formData.attachments || []
    const todos = formData.todos || []
    const dataToSend = {
      ...omit(['attachments', 'todos'], formData),
    }
    if (todos.length > 0) {
      const creatableTodos = todos.filter(todo => !todo.isAdded)
      const changableTodos = todos.filter(todo => todo.isAdded)
      if (creatableTodos.length > 0) {
        const tasks = []
        for (const todo of creatableTodos) {
          tasks.push(yield fork(
            axios.post,
            `/rovers_system_types/${systemTypeId}/todos`,
            {
              assignee: todo.assignee ? { id: todo.assignee.id } : null,
              state: todo.work_state,
              text: todo.name,
              state_comment: todo.comment,
            },
          ))
        }
        yield joinAll(tasks)
      }
      if (changableTodos.length > 0) {
        const tasks = []
        for (const todo of changableTodos) {
          tasks.push(yield fork(
            axios.patch,
            `/todos/${todo.stepId}`,
            {
              ...('work_state' in todo && { state: todo.work_state }),
              ...('comment' in todo && { state_comment: todo.comment }),
              ...('assignee' in todo && { assignee: todo.assignee ? { id: todo.assignee.id } : null }),
            },
          ))
        }
        yield joinAll(tasks)
      }
      if (Object.keys(dataToSend).length <= 0) {
        /**
         * After adding/updating todos status for the system may change - so update it
         * We do it here because if we changed the fields of the system itself we will update it anyway later
         */
        const { data: { data: systemType } } = yield call(axios.get, `/rovers_system_types/${systemTypeId}`)
        newSystemType = systemType
        toast.success('TODOs were successfully updated!')
      }
    }
    if (Object.keys(dataToSend).length > 0) {
      const { data: { data: systemType } } = yield call(
        makeRequest,
        `/rovers_system_types/${systemTypeId}`,
        'PATCH',
        SystemTypeAction.UPDATE_SYSTEM_TYPE,
        dataToSend,
      )
      newSystemType = systemType
    }
    if (attachments.length > 0) {
      const eventAttachments = yield call(
        addAttachments,
        systemTypeId,
        RoverEventType.ROVER_SYSTEM_TYPE,
        attachments,
      )
      yield put(SystemTypeActions.updateSystemTypeSuccess(
        systemTypeId,
        { ...newSystemType, attachments: [ ...eventAttachments, ...(newSystemType.attachments || []) ] },
      ))
      if (Object.keys(dataToSend).length <= 0) {
        toast.success('Attachments were successfully added')
      }
    } else {
      yield put(SystemTypeActions.updateSystemTypeSuccess(systemTypeId, newSystemType))
    }
    if (onSuccess) {
      onSuccess()
    }
  } catch (e) {
    yield put(SystemTypeActions.updateSystemTypeFailure(systemTypeId, e.message || 'Error ocurred'))
  }
}

function * deleteSystemType ({ systemTypeId }) {
  yield put(SystemTypeActions.deleteSystemTypeLoading(systemTypeId))
  try {
    yield call(makeRequest, `/rovers_system_types/${systemTypeId}`, 'DELETE', SystemTypeAction.DELETE_SYSTEM_TYPE)
    yield put(SystemTypeActions.deleteSystemTypeSuccess(systemTypeId))
  } catch (e) {
    yield put(SystemTypeActions.deleteSystemTypeFailure(systemTypeId, e.message || 'Error ocurred'))
  }
}

function * setDefaultProfile ({ systemTypeId, profileId }) {
  yield put(SystemTypeActions.setDefaultProfileLoading())
  try {
    yield call(makeRequest,
      `/rovers_system_types/${systemTypeId}`,
      'PATCH',
      SystemTypeAction.SET_DEFAULT_PROFILE,
      {
        default_profile: { id: profileId },
      },
    )
    yield put(SystemTypeActions.setDefaultProfileSuccess(systemTypeId, profileId))
  } catch (e) {
    yield put(SystemTypeActions.setDefaultProfileFailure(e.message || 'Error ocurred'))
  }
}

/**
 * Validate provided system configurations json from System Type to check if user changes only allowed fields.
 */
export function * validateSystemType ({ systemTypeId, configurations, successCallback, failureCallback }) {
  yield put(SystemTypeActions.validateSystemTypeLoading())
  try {
    const { data: { data: result } } = yield call(axios.post, `/system_type_configurations/${systemTypeId}/validate_permissions`, configurations)
    const isValid = result.denied_paths?.length === 0
    yield put(SystemTypeActions.validateSystemTypeSuccess(systemTypeId, result.denied_paths))
    if (isValid && typeof successCallback === 'function') {
      successCallback()
    } else if (!isValid && typeof failureCallback === 'function') {
      failureCallback()
    }
  } catch (e) {
    showErrorMessage(e)
    yield put(SystemTypeActions.validateSystemTypeFailure())
  }
}
// NOT USED
function * updateFileLink ({ profileId, fileLinkId, formData }) {
  // yield put(SystemTypeActions.updateFileLinkLoading())
  try {
    yield call(makeRequest, `/file_links/${fileLinkId}`, 'PATCH', SystemTypeAction.UPDATE_LINK, formData)
    yield put(SystemTypeActions.getProfile(profileId))
    // yield put(SystemTypeActions.updateFileLinkSuccess(fileLinkId, formData))
  } catch (e) {
    // yield put(SystemTypeActions.updateFileLinkFailure(e.message || 'Error ocurred'))
  }
}

function * selectSystemType ({ systemTypeId, updateSearch = true }) {
  yield put(SystemTypeActions.selectSystemTypeLoading())
  try {
    const { data: { data: systemType } } = yield call(axios.get, `/rovers_system_types/${systemTypeId}`)
    yield put(SystemTypeActions.selectSystemTypeSuccess(systemType, updateSearch))
  } catch (e) {
    yield put(SystemTypeActions.selectSystemTypeFailure(e.message || 'Error ocurred'))
  }
}

function * getLogs ({ systemTypeId, pagination, onSuccess }) {
  const { search = '', pageSize = 100, page = 1, orderBy = 'timestamp', order = 'desc' } = pagination
  yield put(SystemTypeActions.getLogsLoading(systemTypeId))
  try {
    const { data: { data: logs, pagination } } = yield call(axios.get, `/rovers_system_types/${systemTypeId}/db_logs/${createQueryString({ search, pageSize, page, order, orderBy })}`)
    yield put(SystemTypeActions.getLogsSuccess(systemTypeId, logs, getPaginationFromResponse(pagination.total, page, pageSize)))
    if (onSuccess) {
      onSuccess()
    }
  } catch (e) {
    showErrorMessage(e)
    yield put(SystemTypeActions.getLogsFailure(systemTypeId, e.message || e))
  }
}

function * getTodoLogs ({ todoId, pagination, onSuccess }) {
  const { search = '', pageSize = 100, page = 1, orderBy = 'timestamp', order = 'desc' } = pagination
  yield put(SystemTypeActions.getTodoLogsLoading(todoId))
  try {
    const { data: { data: logs, pagination } } = yield call(axios.get, `/to_be_dones/${todoId}/db_logs/${createQueryString({ search, pageSize, page, order, orderBy })}`)
    yield put(SystemTypeActions.getTodoLogsSuccess(todoId, logs, getPaginationFromResponse(pagination.total, page, pageSize)))
    if (onSuccess) {
      onSuccess()
    }
  } catch (e) {
    showErrorMessage(e)
    yield put(SystemTypeActions.getTodoLogsFailure(todoId, e.message || e))
  }
}

function * getLogsWatcher () {
  yield takeLatest(SystemTypeTypes.GET_LOGS, getLogs)
}
function * getTodoLogsWatcher () {
  yield takeLatest(SystemTypeTypes.GET_TODO_LOGS, getTodoLogs)
}
function * getSystemTypesWatcher () {
  yield takeLatest(SystemTypeTypes.GET_SYSTEM_TYPES, getSystemTypes)
}
function * getAllSystemTypesWatcher () {
  yield takeLatest(SystemTypeTypes.GET_ALL_SYSTEM_TYPES, getAllSystemTypes)
}
function * getSystemTypeWatcher () {
  yield takeLatest(SystemTypeTypes.GET_SYSTEM_TYPE, getSystemType)
}
function * addSystemTypeWatcher () {
  yield takeLatest(SystemTypeTypes.ADD_SYSTEM_TYPE, addSystemType)
}
function * saveAsNewSystemTypeWatcher () {
  yield takeLatest(SystemTypeTypes.SAVE_AS_NEW_SYSTEM_TYPE, saveAsNewSystemType)
}
function * setDefaultProfileWatcher () {
  yield takeLatest(SystemTypeTypes.SET_DEFAULT_PROFILE, setDefaultProfile)
}
function * updateFileLinkWatcher () {
  yield takeLatest(SystemTypeTypes.UPDATE_FILE_LINK, updateFileLink)
}
function * updateSystemTypeWatcher () {
  yield takeLatest(SystemTypeTypes.UPDATE_SYSTEM_TYPE, updateSystemType)
}
function * deleteSystemTypeWatcher () {
  yield takeLatest(SystemTypeTypes.DELETE_SYSTEM_TYPE, deleteSystemType)
}
function * selectSystemTypeWatcher () {
  yield takeLatest(SystemTypeTypes.SELECT_SYSTEM_TYPE, selectSystemType)
}
function * getSystemTypesPermissionsWatcher () {
  yield takeLatest(SystemTypeTypes.GET_SYSTEM_TYPE_PERMISSIONS, getSystemTypesPermissions)
}
function * validateSystemTypeWatcher () {
  yield takeLatest(SystemTypeTypes.VALIDATE_SYSTEM_TYPE, validateSystemType)
}

export default function * root () {
  yield fork(getSystemTypesWatcher)
  yield fork(getAllSystemTypesWatcher)
  yield fork(getSystemTypeWatcher)
  yield fork(addSystemTypeWatcher)
  yield fork(setDefaultProfileWatcher)
  yield fork(updateFileLinkWatcher)
  yield fork(updateSystemTypeWatcher)
  yield fork(deleteSystemTypeWatcher)
  yield fork(selectSystemTypeWatcher)
  yield fork(saveAsNewSystemTypeWatcher)
  yield fork(getLogsWatcher)
  yield fork(getTodoLogsWatcher)
  yield fork(getSystemTypesPermissionsWatcher)
  yield fork(validateSystemTypeWatcher)
  // Images
  yield fork(addSystemTypeImagesWatcher)
  yield fork(getSystemTypeImagesWatcher)
  yield fork(deleteSystemTypeImagesWatcher)
  // Configurations
  yield fork(getSystemTypeConfigurationsWatcher)
  yield fork(addConfigurationToSystemTypeWatcher)
  yield fork(updateConfigurationWatcher)
  // Profiles
  yield fork(getSystemTypeProfilesWatcher)
  yield fork(addProfileToSystemTypeWatcher)
  yield fork(addFileLinksWatcher)
  yield fork(getProfileWatcher)
  yield fork(updateProfileNameWatcher)
  yield fork(updateProfileWatcher)
  yield fork(validateProfileConfigurationsWatcher)
}
