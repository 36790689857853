
export const GrantTypes = {
  NAVIGATION_ROVER_PROCESSING_LIMIT: 'navigation_rover_processing_limit',
  LIDAR_PROCESSING: 'lidar_processing',
  FILE_DOWNLOADING: 'file_downloading',
  JOB_PRIORITY: 'job_priority',
  HOSTING: 'hosting',
  FILE_STORAGE: 'file_storage',
}

export const GrantTypesOptions = [
  GrantTypes.NAVIGATION_ROVER_PROCESSING_LIMIT,
  GrantTypes.LIDAR_PROCESSING,
  GrantTypes.FILE_DOWNLOADING,
  GrantTypes.JOB_PRIORITY,
]

export const GrantOptions = {
  [GrantTypes.NAVIGATION_ROVER_PROCESSING_LIMIT]: {
    min: -1,
  },
  [GrantTypes.LIDAR_PROCESSING]: {
    min: -1,
  },
  [GrantTypes.FILE_DOWNLOADING]: {
    min: -1,
  },
  [GrantTypes.JOB_PRIORITY]: {
    min: 0,
    max: 10,
  },
}

export const GrantValuesMap = {
  '-1': 'Unlimited',
  '0': 'Denied',
}

export const MapGrantValueTypeToGrantValue = {
  'unlimited': -1,
  'denied': 0,
}

// Default values to set
export const GrantTypesValues = {
  [GrantTypes.NAVIGATION_ROVER_PROCESSING_LIMIT]: 3,
  [GrantTypes.LIDAR_PROCESSING]: 1,
  [GrantTypes.FILE_DOWNLOADING]: 1,
  [GrantTypes.JOB_PRIORITY]: 5,
}

export function convertRawGrant (raw) {
  const {
    type_grant: grantType,
    value,
    start_date,
    end_date,
    created,
    evaluation_days,
  } = raw
  return {
    grantType: grantType && grantType.id,
    value,
    startDate: new Date(start_date),
    endDate: new Date(end_date),
    created: new Date(created),
    evaluationDays: evaluation_days,
  }
}

export function convertRawPlanGrant (raw, allGrantTypes = []) {
  const {
    created,
    id,
    plan,
    type_grant_id,
    updated,
    value,
  } = raw
  const fromGrantTypes = allGrantTypes.find(grantType => grantType.id === raw.type_grant_id)
  return {
    id: id,
    typeGrantId: type_grant_id,
    updated: new Date(updated),
    created: new Date(created),
    plan,
    deprecated: fromGrantTypes && fromGrantTypes.deprecated,
    description: fromGrantTypes && fromGrantTypes.description,
    target: fromGrantTypes && fromGrantTypes.target,
    value_type: fromGrantTypes && fromGrantTypes.value_type,
    value,
  }
}

export function grantToRaw (raw) {
  const {
    grantType: grant_type,
    value,
    startDate,
    endDate,
    evaluation_days,
    auto_extend,
  } = raw
  return {
    auto_extend,
    ...('evaluation_days' in raw && { evaluation_days }),
    type_grant: grant_type ? { id: grant_type } : null,
    value: value.toString(),
    start_date: startDate ? startDate.toISOString() : null,
    end_date: endDate ? endDate.toISOString() : null,
  }
}

export function copyGrant (existingGrant) {
  const {
    evaluation_days,
  } = existingGrant
  console.log(existingGrant)
  return {
    auto_extend: existingGrant.auto_extend,
    ...('evaluation_days' in existingGrant && evaluation_days && { evaluation_days }),
    type_grant: existingGrant.type_grant,
    value: existingGrant.value.toString(),
    start_date: existingGrant.start_date || null,
    end_date: existingGrant.end_date || null,
  }
}

export function grantToRawPlanGrant (raw) {
  const {
    planToken,
    value,
  } = raw
  return {
    plan: { stripe_plan_token: planToken },
    value: value.toString(),
  }
}
