import React, { createRef } from 'react'
import { Trans } from 'react-i18next'
import PropTypes from 'prop-types'
import deburr from 'lodash/deburr'
import Downshift from 'downshift'
import { equals } from 'ramda'
// Material UI
import { withStyles } from '@material-ui/core/styles'
import { Popper, Fade, Button, FormHelperText, ClickAwayListener, TextField, Paper, ListItemText, Typography, InputAdornment, CircularProgress, Tooltip, IconButton, ListItemIcon } from '@material-ui/core'
// Icons
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'
import ClearIcon from '@material-ui/icons/Close'
import WarningIcon from '@material-ui/icons/Error'
import SuccessIcon from '@material-ui/icons/Check'
// Project deps
import VirtualizedList from 'components/reusable/VirtualizedList'
import ListItem from 'components/reusable/ListItem'
import StyledTooltip from 'components/reusable/StyledTooltip'

/**
 * Material has autocomplete in lab. In will be out in the next release.
 * TODO: change this to use just material ui autocomplete
 */

function renderInput (inputProps) {
  const {
    InputProps,
    suggestions,
    setValue,
    classes,
    disabled,
    ref,
    disableSearch,
    inheritColor = false,
    tooltip,
    tooltipProps = {},
    noItemsText,
    canEdit,
    isLoading,
    numberOfItems,
    onClear,
    editable,
    error,
    errorMessage,
    showNoItemsText,
    inputClassName,
    margin,
    isOpen,
    ...other
  } = inputProps
  const ArrowComponent = isOpen ? ArrowDropUp : ArrowDropDown
  return (
    <StyledTooltip placement='top-start' {...tooltipProps} title={tooltip || ''} classes={{ tooltip: classes.tooltip }}>
      <div>
        <TextField
          disabled={disabled}
          ref={ref}
          classes={{
            root: inputClassName,
          }}
          InputProps={{
            classes: {
              root: inheritColor
                ? classes.inheritColor
                : disableSearch && !disabled
                  ? classes.inputRootDisabled
                  : margin ? classes.inputRoot : '',
              input: disableSearch && !disabled
                ? classes.inputInputDisabled
                : classes.inputInput,
            },
            endAdornment: disableSearch
              ? <InputAdornment onClick={other.inputProps.onClick} position='end'><ArrowComponent/></InputAdornment>
              : typeof other.endAdornment === 'function'
                ? other.endAdornment()
                : editable
                  ? <InputAdornment position='end'>
                    {!disabled && <IconButton disabled={disabled} size='small' onClick={onClear}>
                      <ClearIcon/>
                    </IconButton>
                    }
                    <ArrowComponent onClick={disabled ? () => {} : other.inputProps.onToggleClick} style={{ color: '#757575', cursor: disabled ? 'default' : 'pointer' }}/>
                  </InputAdornment>
                  : null,
            startAdornment: isLoading
              ? <InputAdornment position='start'><CircularProgress size={18}/></InputAdornment>
              : typeof other.startAdornment === 'function'
                ? other.startAdornment(other.selectedItem)
                : null,
            ...InputProps,
          }}
          error={showNoItemsText ? !canEdit || (canEdit && error) : error}
          aria-describedby="component-helper-text"
          {...other}
        />
        {((!canEdit && showNoItemsText && noItemsText) || (canEdit && error)) && (noItemsText || errorMessage) &&
          (!canEdit && showNoItemsText && noItemsText)
          ? <FormHelperText
            style={{ margin: '0px 14px 16px' }}
            error id="component-helper-text">{!canEdit ? noItemsText : errorMessage}</FormHelperText>
          : (canEdit && error && showNoItemsText)
            ? <FormHelperText
              style={{ margin: '0px 14px 16px' }}
              error id="component-helper-text">{!canEdit ? noItemsText : errorMessage}</FormHelperText>
            : null
        }
      </div>
    </StyledTooltip>
  )
}

function renderSuggestion (suggestionProps) {
  const {
    suggestion,
    itemProps,
    selectedItem,
    selectedItemFromEnteredValue,
    options,
    inputDisplayTemplate,
    secondaryText,
    // searchTemplate,
    classes,
    compare,
    onClick,
    tooltip,
  } = suggestionProps
  const { style, key } = options
  // const searchFor = ((searchTemplate ? searchTemplate(suggestion) : suggestion) || '').toString()
  const isCreatedByUser = typeof suggestion === 'object' && suggestion.createdByUser
  const isSelected = isCreatedByUser
    ? selectedItem || selectedItemFromEnteredValue
      ? equals(suggestion, selectedItem || selectedItemFromEnteredValue)
      : false
    : typeof compare === 'function'
      ? selectedItem ? compare(suggestion, selectedItem) : false
      : equals(suggestion, selectedItem || selectedItemFromEnteredValue)
  const tooltipTitle = typeof tooltip === 'function' ? tooltip(suggestion) : (tooltip || '')
  const isDisabled = suggestion.disabled
  const iconType = suggestion.icon
  return (
    <ListItem
      selected={isSelected}
      {...itemProps}
      onClick={isDisabled ? () => {} : onClick}
      key={key}
      component='div'
      style={{
        fontWeight: isDisabled ? 200 : isSelected ? 500 : 400,
        ...(isDisabled && { backgroundColor: 'transparent' }),
        ...style,
      }}
      className={classes.listItem}
      // classes={{ root: classes.listItem }}
    >
      {iconType && <ListItemIcon>
        {iconType === 'error' && <WarningIcon style={{ color: 'orange' }}/>}
        {iconType === 'success' && <SuccessIcon style={{ color: 'green' }}/>}
      </ListItemIcon>
      }
      <ListItemText
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        primary={
          <Tooltip title={tooltipTitle} placement='top' interactive classes={{ popper: classes.popper }}>
            <Typography noWrap style={{ color: isDisabled ? 'inherit' : isSelected ? '#fff' : 'inherit', opacity: isDisabled ? 0.5 : 1 }}>
              {isCreatedByUser
                ? suggestion.value
                : inputDisplayTemplate ? inputDisplayTemplate(suggestion) : `${suggestion}`
              }
            </Typography>
          </Tooltip>
        }
        secondary={
          <Typography color='textSecondary'>
            {typeof secondaryText === 'function' ? secondaryText(suggestion) : ''}
          </Typography>
        }
      />
    </ListItem>
  )
}
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.object,
}

function getSuggestions (
  suggestions,
  value,
  { showEmpty = false } = {},
  searchTemplate,
  selectedItem,
  matchFunction,
  createdValue,
) {
  const inputValue = deburr((value || '').trim()).toLowerCase()
  const substrings = inputValue.split(' ')
  const suggestionsWithCreatedValue = [
    ...(createdValue ? [createdValue] : []),
    ...suggestions,
  ]
  return !showEmpty
    ? []
    : selectedItem === value ? suggestionsWithCreatedValue : suggestionsWithCreatedValue.filter(suggestion => {
      if (typeof suggestion === 'object' && suggestion.createdByUser) {
        const string = suggestion.value.toLowerCase()
        return substrings.every(substring => string.indexOf(substring) >= 0)
      }
      if (typeof matchFunction === 'function') {
        return substrings.every(substring => matchFunction(suggestion, substring))
      }
      const string = ((searchTemplate
        ? searchTemplate(suggestion)
        : suggestion
      ) || '').toString().toLowerCase()
      return substrings.every(substring => string.indexOf(substring) >= 0)
    })
}

/*
function getSelectedItem (suggestions, value, searchTemplate) {
  const inputValue = deburr(value.trim()).toLowerCase()
  return suggestions.find(suggestion => ((searchTemplate
    ? searchTemplate(suggestion)
    : suggestion
  ) || '').toString().toLowerCase() === inputValue)
}
*/

const styles = theme => ({
  root: {
    // flexGrow: 1,
    // width: '100%'
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    height: '100%',
    zIndex: 1000,
    left: 0,
    right: 0,
  },
  inputRoot: {
    flexWrap: 'wrap',
    marginBottom: theme.spacing(1),
  },
  inheritColor: {
    flexWrap: 'wrap',
    color: 'inherit !important',
  },
  inputRootDisabled: {
    cursor: 'pointer',
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
    marginBottom: theme.spacing(0),
  },
  inputInputDisabled: {
    width: 'auto',
    flexGrow: 1,
    cursor: 'pointer',
    color: 'transparent',
    textShadow: '0 0 0 #000',
    '&&:focus': {
      outline: 'none',
    },
  },
  listItem: {
    '&&:hover': {
      background: 'rgba(0,0,0,0.12)',
      cursor: 'pointer',
    },
  },
  tooltip: {
    maxWidth: 500,
  },
  popper: {
    zIndex: 100001,
    maxWidth: 500,
  },
})

class IntegrationDownshift extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      value: props.value,
      open: false,
      createdValue: null,
    }
    this.prevStart = -1
    this.prevEnd = -1
    this.prevSelection = ''
    this.anchorRef = createRef(null)
  }

  componentDidUpdate (prevProps, prevState) {
    const currentValue = this.props.value
    const prevValue = prevProps.value
    if (currentValue !== prevValue) {
      this.setState({ value: currentValue })
    }
  }

  handleClose = event => {
    if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) {
      return
    }
    this.setState({ open: false })
  };

  render () {
    const {
      editable = true,
      items = [],
      fullWidth = true,
      isLoading = false,
      inheritColor = false,
      clearOnSelect = false,
      label,
      error,
      errorMessage,
      inputDisplayTemplate,
      match: matchFunction,
      compare: compareItemsFunction,
      selectedItem: selectedItemFromProps,

      searchTemplate,
      clearSelection: clearSelectionFromProps,
      value: propValue,
      secondaryText,
      classes,
      onChange: onChangeFromProps,
      disabled,
      autoSizeHeight = true,
      disableSearch = false,
      rowHeight = 50,
      viewLimit = 7,
      tooltip,
      tooltipProps,
      optionTooltip,
      optionTooltipProps,
      width = 200,
      disablePortal = true,
      placeholder: placeholderFromProps,
      noItemsText = 'No items available',
      showNoItemsText = false,
      margin = false,
      useDefaultPopper = true,
      createNew = false,
      inputClassName,
      errorAsHelperText = false,
      ...otherProps
    } = this.props
    const { value: enteredValue, createdValue } = this.state
    const placeholder = placeholderFromProps || label || ''
    const selectedItemFromEnteredValue = propValue
    // const selectedItemFromEnteredValue = getSelectedItem(items, enteredValue || '', searchTemplate)
    return (
      <div className={classes.root} style={{ width: fullWidth ? '100%' : 'auto' }}>
        <Downshift
          id='downshift-options'
          inputValue={enteredValue}
          initialSelectedItem={propValue}
          selectedItem={propValue}
          onSelect={item => {
            if (typeof item === 'undefined') return
            if (item !== null) {
              if (typeof item === 'object' && item.createdByUser) {
                if (item.value === propValue) this.setState({ value: propValue })
              } else {
                const searchFor = ((searchTemplate ? searchTemplate(item) : item) || '').toString()
                const isSelected = propValue === searchFor
                if (isSelected) this.setState({ value: propValue })
              }
            }
            if (clearOnSelect) {
              this.setState({ value: '' })
            }
            if (typeof onChangeFromProps === 'function') onChangeFromProps(item)
          }}
        >
          {({
            clearSelection,
            selectItem,
            getInputProps,
            getItemProps,
            getLabelProps,
            getMenuProps,
            selectedItem,
            highlightedIndex,
            inputValue,
            isOpen,
            openMenu,
            closeMenu,
          }) => {
            const suggestions = getSuggestions(
              items,
              enteredValue || '',
              { showEmpty: true },
              searchTemplate,
              selectedItem,
              matchFunction,
              createdValue,
            )
            const onClear = () => {
              clearSelection()
              this.setState({ value: '', createdValue: null })
              if (typeof clearSelectionFromProps === 'function') clearSelectionFromProps()
            }
            const { onBlur, onChange, onFocus, onKeyDown, ...inputProps } = getInputProps({
              onChange: editable ? event => {
                if (!this.state.open) {
                  this.setState({ open: true })
                }
                const value = event.target.value
                this.setState({ value })
                if (value === '') {
                  onClear()
                }
              } : () => {},
              onClick: event => {
                if (!useDefaultPopper) {
                  event.preventDefault()
                  event.stopPropagation()
                }
                this.setState({ open: true })
                // openMenu()
              },
              onToggleClick: event => {
                if (!useDefaultPopper) {
                  event.preventDefault()
                  event.stopPropagation()
                }
                this.setState({ open: !this.state.open })
              },
              onFocus: event => {
                if (!useDefaultPopper) {
                  event.preventDefault()
                  event.stopPropagation()
                }
                this.setState({ open: true })
                // openMenu()
              },
              onBlur: event => {
                if (!useDefaultPopper) {
                  event.preventDefault()
                  event.stopPropagation()
                }
                this.prevStart = -1
                this.prevEnd = -1
                this.prevSelection = ''
                this.handleClose(event)
              },
              onKeyDown: e => {
                const node = e.target
                const isHomeKeyPressed = e.key === 'Home'
                const isEndKeyPressed = e.key === 'End'
                const action = isHomeKeyPressed || isEndKeyPressed
                  ? e.key
                  : null
                const direction = isHomeKeyPressed ? 'backward' : isEndKeyPressed ? 'forward' : 'none'
                if (e.keyCode === 13 && suggestions.length === 1) {
                  if (!useDefaultPopper) {
                    e.preventDefault()
                    e.stopPropagation()
                  }
                  selectItem(suggestions[0])
                  this.setState({ open: false })
                  if (this.anchorRef.current) {
                    this.anchorRef.current.blur()
                  }
                  return
                }
                if (action) {
                  const newSelectionStart = isHomeKeyPressed ? 0 : node.selectionEnd
                  const newSelectionEnd = isHomeKeyPressed ? node.selectionStart : node.value.length
                  if (e.shiftKey) {
                    if (
                      node.selectionStart === node.selectionEnd ||
                      !(node.selectionStart === this.prevStart &&
                        node.selectionEnd === this.prevEnd &&
                        this.prevSelection === action
                      )
                    ) {
                      this.prevStart = newSelectionStart
                      this.prevEnd = newSelectionEnd
                    }
                  }
                  if (!e.ctrlKey && !e.shiftKey) {
                    if (isHomeKeyPressed) {
                      this.prevStart = 0
                      this.prevEnd = 0
                    }
                    if (isEndKeyPressed) {
                      this.prevStart = node.value.length
                      this.prevEnd = node.value.length
                    }
                  }
                  node.setSelectionRange(this.prevStart, this.prevEnd, direction)
                  this.prevSelection = action
                }
              },
              placeholder,
            })
            return (
              <div className={classes.container}>
                <Popper
                  open={this.state.open}
                  anchorEl={this.anchorRef.current}
                  role={undefined}
                  transition
                  placement='bottom-start'
                  disablePortal={disablePortal}
                  {...(
                    useDefaultPopper
                      ? {
                        popperOptions: {
                          modifiers: {
                            setPopperWidth: {
                              enabled: true,
                              order: 1000,
                              fn: data => {
                                const { instance: { reference, popper } } = data
                                popper.style.width = `${this.anchorRef.current ? reference.offsetWidth : width}px`
                                return data
                              },
                            },
                          },
                        },
                        style: {
                          zIndex: 100000,
                        },
                      }
                      : {
                        style: {
                          zIndex: 100000,
                          width: (this.anchorRef.current ? this.anchorRef.current.offsetWidth : width),
                        },
                      }
                  )}
                >
                  {({ TransitionProps, placement }) => (
                    <Fade
                      {...TransitionProps}
                    // style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                      <Paper
                        className={classes.paper}
                        square
                        style={{
                          height: isLoading
                            ? 150
                            : suggestions.length <= viewLimit
                              ? suggestions.length * rowHeight
                              : rowHeight * viewLimit,
                        }}
                      >
                        <ClickAwayListener onClickAway={this.handleClose}>
                          <div style={{ width: '100%', height: '100%' }}{...getMenuProps()}>
                            {isLoading
                              ? <div style={{
                                display: 'flex',
                                height: 150,
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}><CircularProgress/></div>
                              : suggestions.length === 0 && editable
                                ? <Paper style={{ padding: 8 }}>
                                  <Typography variant='body2'>
                                    <Trans i18nKey='combobox.noResults' />
                                    { createNew
                                      ? <Button style={{ background: 'none', textDecoration: 'underline' }} onClick={e => {
                                        const newCreatedValue = { value: enteredValue, createdByUser: true }
                                        selectItem(newCreatedValue)
                                        this.setState(
                                          { createdValue: newCreatedValue },
                                          () => {
                                            if (!useDefaultPopper) {
                                              e.preventDefault()
                                              e.stopPropagation()
                                            }
                                            this.setState({ open: false })
                                            if (this.anchorRef.current) {
                                              this.anchorRef.current.blur()
                                            }
                                          },
                                        )
                                      }}>Create a new entry</Button>
                                      : selectedItem && <Trans i18nKey='combobox.previousResult' />
                                    }
                                  </Typography>
                                  { selectedItem && !createNew &&
                                    <Typography variant='body2' style={{ textDecoration: 'underline' }}>
                                      {selectedItem}
                                    </Typography>
                                  }
                                </Paper>
                                : <VirtualizedList
                                  rowHeight={rowHeight}
                                  autoSizeHeight={autoSizeHeight}
                                  list={suggestions}
                                  renderItem={(item, options) => renderSuggestion({
                                    suggestion: item,
                                    itemProps: getItemProps({ item }),
                                    secondaryText,
                                    highlightedIndex,
                                    selectedItem: selectedItemFromProps,
                                    selectedItemFromEnteredValue,
                                    options,
                                    inputDisplayTemplate,
                                    searchTemplate,
                                    enteredValue,
                                    classes,
                                    tooltip: optionTooltip,
                                    tooltipProps: optionTooltipProps,
                                    compare: compareItemsFunction,
                                    onClick: e => {
                                      if (!useDefaultPopper) {
                                        e.preventDefault()
                                        e.stopPropagation()
                                      }
                                      selectItem(item)
                                      this.handleClose(e)
                                      if (this.anchorRef.current) {
                                        this.anchorRef.current.blur()
                                      }
                                    },
                                  })}
                                />
                            }
                          </div>
                        </ClickAwayListener>
                      </Paper>
                    </Fade>
                  )}
                </Popper>
                {renderInput({
                  ...otherProps,
                  editable,
                  canEdit: items.length > 0,
                  showNoItemsText,
                  margin,
                  fullWidth,
                  disabled,
                  classes,
                  error: Boolean(error),
                  label: errorAsHelperText ? label || '' : error ? errorMessage : (label || ''),
                  InputLabelProps: getLabelProps({ shrink: true }),
                  InputProps: disableSearch ? {} : { onBlur, onChange, onFocus, onKeyDown },
                  inputProps,
                  suggestions,
                  disableSearch,
                  inheritColor,
                  tooltip,
                  tooltipProps,
                  isLoading,
                  ref: this.anchorRef,
                  noItemsText,
                  inputClassName,
                  selectedItem,
                  isOpen: this.state.open,
                  onClear,
                })}
              </div>
            )
          }}
        </Downshift>
      </div>
    )
  }
}

IntegrationDownshift.propTypes = {
  error: PropTypes.bool,
  margin: PropTypes.bool,
  createNew: PropTypes.bool,
  useDefaultPopper: PropTypes.bool,
  clearOnSelect: PropTypes.bool,
  disabled: PropTypes.bool,
  editable: PropTypes.bool,
  isLoading: PropTypes.bool,
  fullWidth: PropTypes.bool,
  inheritColor: PropTypes.bool,
  disableSearch: PropTypes.bool,
  disablePortal: PropTypes.bool,
  autoSizeHeight: PropTypes.bool,
  showNoItemsText: PropTypes.bool,
  errorAsHelperText: PropTypes.bool,
  width: PropTypes.number,
  rowHeight: PropTypes.number,
  viewLimit: PropTypes.number,
  value: PropTypes.string,
  inputClassName: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.node,
  tooltip: PropTypes.node,
  errorMessage: PropTypes.node,
  classes: PropTypes.object,
  selectedItem: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  tooltipProps: PropTypes.object,
  items: PropTypes.array,
  match: PropTypes.func,
  compare: PropTypes.func,
  onChange: PropTypes.func,
  optionTooltip: PropTypes.func,
  optionTooltipProps: PropTypes.object,
  searchTemplate: PropTypes.func,
  clearSelection: PropTypes.func,
  inputDisplayTemplate: PropTypes.func,
  secondaryText: PropTypes.any,
  noItemsText: PropTypes.string,
}

export default withStyles(styles)(IntegrationDownshift)
