import React from 'react'
import i18n from 'i18n'
import { RefundStatus, RefundStatusPretty } from 'types/refunds'
import Chip from 'components/reusable/Chip'

export const RefundStatusIconBackgroundColor = {
  [RefundStatus.SUCCEEDED]: 'green',
  [RefundStatus.FAILED]: 'red',
  [RefundStatus.CANCELED]: 'orange',
  [RefundStatus.PENDING]: 'orange',
}

/**
 * Returns refund status icon shown in UI
 * @param {String} status
 */
export function getRefundStatusIcon (status) {
  const lowerCasedStatus = status && status.toLowerCase()
  const style = {
    backgroundColor: RefundStatusIconBackgroundColor[lowerCasedStatus] || 'green',
    color: '#fff',
  }
  return <Chip
    style={style}
    label={
      <React.Fragment>
        {getPrettyRefundStatus(status)}
      </React.Fragment>
    }
    size='small'
  />
}

/**
 * Returns prettified string representation of refund status
 * @param {String} status
 */
export function getPrettyRefundStatus (status) {
  const lowerCasedStatus = status && status.toLowerCase()
  return status ? RefundStatusPretty[lowerCasedStatus] || lowerCasedStatus : i18n.t('refunds.status.unknown')
}

export function convertRawRefund (raw) {
  const { metadata } = raw
  return {
    ...raw,
    companyId: metadata && metadata.company_id,
    companyName: metadata && metadata.company_name,
  }
}
