import { getDefaultPagination } from 'modules/utils'
import { getCompanyId, getParentCompanyId, isUserAdmin, isUserCompanyAdmin, isUserCompanyCustomer, isUserCompanyEmployee } from 'utils/company'

export const getCompanyUsersIsLoading = state => {
  return state.companies.get('getUsersIsLoading')
}

export const getCompanyUsers = state => {
  return state.companies.get('users')
}
export const getCompanyRoversIsLoading = (state, companyId) => {
  const roversByCompanyLoading = state.companies.get('getRoversIsLoading') || {}
  return roversByCompanyLoading[companyId] || false
}

export const getSelectedCompanyFilters = (state, companyId) => state.companies.get('selectedCompanyFilters')[companyId]

export const getCompanyProjects = (state, companyId) => {
  const projectsByCompany = state.companies.get('projects') || {}
  return projectsByCompany[companyId] || []
}
export const getCompanyWideMaxVersion = state => {
  return state.companies.get('companyWideMaxVersions')
}
export const getCompanyProjectsIsLoading = state => state.companies.get('getProjectsIsLoading')

export const getCompanyRovers = (state, companyId) => {
  const roversByCompany = state.companies.get('rovers') || {}
  return roversByCompany[companyId] || []
}
export const getCompanyAddedRovers = state => state.companies.get('addedRovers')

export const generateInviteUrlIsLoading = (state, companyId) => {
  return !!state.companies.get('generateInviteUrlIsLoading')[companyId]
}

export const getInviteUrl = (state, companyId) => {
  return state.companies.get('inviteUrl')[companyId]
}

export const getCurrentCompany = state => {
  return state.companies.get('currentCompany')
}

export const getLoggedUserCompany = state => {
  return state.companies.get('loggedUserCompany')
}

export const getCurrentCompanyIsLoading = state => {
  return state.companies.get('getCompanyIsLoading')
}

export const getCompanies = state => {
  return state.companies.get('companies')
}

export const getCompaniesIsLoading = state => {
  return state.companies.get('getCompaniesIsLoading')
}

export const updateCompanyIsLoading = state => {
  return state.companies.get('updateCompanyIsLoading')
}

export const deleteCompanyIsLoading = state => {
  return state.companies.get('deleteCompanyIsLoading')
}

export const getCompanySystemTypes = (state, companyId) => {
  const systemTypesByCompany = state.companies.get('systemTypesByCompany') || {}
  return systemTypesByCompany[companyId] || []
}

export const isGetCompanySystemTypesLoading = (state, companyId) => {
  const systemTypesByCompanyLoading = state.companies.get('systemTypesByCompanyLoading') || {}
  return systemTypesByCompanyLoading[companyId] || false
}

export const getCompanySubscriptions = (state, companyId) => {
  const subscriptionsByCompany = state.companies.get('subscriptionsByCompany') || {}
  return subscriptionsByCompany[companyId] || []
}

export const isGetCompanySubscriptionsLoading = (state, companyId) => {
  const subscriptionsByCompanyLoading = state.companies.get('subscriptionsByCompanyLoading') || {}
  return subscriptionsByCompanyLoading[companyId] || false
}

export const getCompanySubscriptionsByFilters = (state, companyId) => {
  const subscriptionsByCompanyFilters = state.companies.get('subscriptionsByCompanyFilters') || {}
  return subscriptionsByCompanyFilters[companyId] || []
}

export const isGetCompanySubscriptionsByFiltersLoading = (state, companyId) => {
  const subscriptionsByCompanyFiltersLoading = state.companies.get('subscriptionsByCompanyFiltersLoading') || {}
  return subscriptionsByCompanyFiltersLoading[companyId] || false
}

export const isCompanySuccessfullyUpdated = state => state.companies.get('updateCompanySucceed')

export const isCompanyRegisterLoading = state => !!state.companies.get('registerIsLoading')

export const isUpdateSubscriptionLoading = state => !!state.companies.get('updateSubscriptionIsLoading')

export const isTokenValid = (state, token) => !!state.companies.get('isTokenValid')[token]
export const isCheckTokenLoading = (state, token) => !!state.companies.get('checkTokenIsLoading')[token]

export const isGetCompanyDBLogsLoading = state => state.companies.get('getCompanyDBLogsLoading')
export const getCompanyDBLogs = (state, companyId) => state.companies.get('companyLogs')[companyId] || []
export const getCompanyDBLogsPagination = (state, companyId) => state.companies.get('getCompanyDBLogsPagination')[companyId] || getDefaultPagination()

export const getCompanyProductUpdates = (state, key) => state.companies.get('productUpdates')[key] || []
export const isGetCompanyProductUpdatesLoading = (state, key) => !!state.companies.get('getProductUpdatesLoading')[key]

export const getChildCompanies = (state, companyId) => state.companies.get('childCompanies')[companyId] || []
export const isChildCompaniesLoading = (state, companyId) => state.companies.get('childCompaniesIsLoading')[companyId]

export const getCompanyPermissions = (state, activeCompanyId) => {
  const activeCompany = getCurrentCompany(state) || {}
  const loggedUser = state.users.get('user') || {}
  const loggedUserCompanyId = getCompanyId(loggedUser)
  const sameCompany = loggedUserCompanyId === activeCompanyId
  const parentCompanyAdmin = !sameCompany && isUserCompanyAdmin(loggedUser) && getParentCompanyId(activeCompany) === loggedUserCompanyId
  const isAdmin = isUserAdmin(loggedUser)
  const isCompanyAdmin = (isUserCompanyAdmin(loggedUser) && sameCompany) || (parentCompanyAdmin && !sameCompany)
  const isCompanyEmployee = isUserCompanyEmployee(loggedUser) && sameCompany
  const isCompanyCustomer = isUserCompanyCustomer(loggedUser) && sameCompany
  const all = isAdmin || isCompanyAdmin || isUserCompanyEmployee || isUserCompanyCustomer
  const isAdminOrCompanyAdmin = isAdmin || isCompanyAdmin
  return {
    // read: all,
    update: isAdminOrCompanyAdmin,
    delete: isAdmin,
    buySubscription: isAdminOrCompanyAdmin,
    useSubscription: all,
    generateInvite: !isCompanyCustomer,
    inviteEmployee: isAdminOrCompanyAdmin,
    inviteCustomer: isAdminOrCompanyAdmin || isCompanyEmployee,
  }
}
