import i18n from 'i18n'
export const RefundStatus = {
  SUCCEEDED: 'succeeded',
  CANCELED: 'canceled',
  FAILED: 'failed',
  PENDING: 'pending',
}

export const RefundStatusPretty = Object.values(RefundStatus).reduce((all, status) => ({
  ...all,
  [status]: i18n.t(`refunds.status.${status}`),
}), {})

export const RefundReasons = ['duplicate', 'fraudulent', 'requested_by_customer']
