import { call, put, takeLatest, fork } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import i18next from 'i18n'
import axios from 'utils/axios'
import { getErrorMessage, showErrorMessage } from 'utils/api'
// Local deps
import CalibrationsActions, { CalibrationTypes } from './actions'

// Sagas
// Add calibration to the company
export function * addCalibration ({ companyId, data, successCallback }) {
  yield put(CalibrationsActions.addCalibrationLoading())
  try {
    const { data: { data: calibration } } = yield call(axios.post, `/companies/${companyId}/calibrations`, data)
    yield put(CalibrationsActions.addCalibrationSuccess(companyId, calibration))
    if (typeof successCallback === 'function') {
      successCallback()
    }
    toast.success(i18next.t('toast.calibrations.addCalibrationSuccess'))
  } catch (e) {
    showErrorMessage(e)
    yield put(CalibrationsActions.addCalibrationFailure())
  }
}
// Get a list of company calibrations
function * getCompanyCalibrations ({ companyId }) {
  yield put(CalibrationsActions.getCompanyCalibrationsLoading(companyId))
  try {
    const { data: { data: calibrations } } = yield call(axios.get, `/companies/${companyId}/calibrations`)
    yield put(CalibrationsActions.getCompanyCalibrationsSuccess(companyId, calibrations))
  } catch (e) {
    yield put(CalibrationsActions.getCompanyCalibrationsFailure(companyId, getErrorMessage(e)))
  }
}
// Update individual caompany calibration
function * updateCalibration ({ companyId, calibrationId, data }) {
  yield put(CalibrationsActions.updateCalibrationLoading())
  try {
    yield call(axios.patch, `/calibrations/${calibrationId}`, data)
    yield put(CalibrationsActions.updateCalibrationSuccess(companyId, calibrationId, data))
    toast.success(i18next.t('toast.calibrations.updateCalibrationSuccess'))
  } catch (e) {
    yield put(CalibrationsActions.updateCalibrationFailure(getErrorMessage(e)))
  }
}
// Delete individual calibration
function * deleteCalibration ({ companyId, calibrationId }) {
  yield put(CalibrationsActions.deleteCalibrationLoading())
  try {
    yield call(axios.delete, `/calibrations/${calibrationId}`)
    yield put(CalibrationsActions.deleteCalibrationSuccess(companyId, calibrationId))
    toast.success(i18next.t('toast.calibrations.deleteCalibrationSuccess'))
  } catch (e) {
    yield put(CalibrationsActions.deleteCalibrationFailure(getErrorMessage(e)))
  }
}

// Watchers
function * getCompanyCalibrationsWatcher () {
  yield takeLatest(CalibrationTypes.GET_COMPANY_CALIBRATIONS, getCompanyCalibrations)
}
function * addCalibrationWatcher () {
  yield takeLatest(CalibrationTypes.ADD_CALIBRATION, addCalibration)
}
function * updateCalibrationWatcher () {
  yield takeLatest(CalibrationTypes.UPDATE_CALIBRATION, updateCalibration)
}
function * deleteCalibrationWatcher () {
  yield takeLatest(CalibrationTypes.DELETE_CALIBRATION, deleteCalibration)
}

export default function * root () {
  yield fork(getCompanyCalibrationsWatcher)
  yield fork(addCalibrationWatcher)
  yield fork(updateCalibrationWatcher)
  yield fork(deleteCalibrationWatcher)
}
