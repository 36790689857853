import { pick } from 'lodash'

/**
 * Similar to the implementation found in newer versions of the `ramda`
 * package. Deeply, recursively merges two objects, using the second argument
 * if both objects define the same terminal key.
 * TODO Replace by `ramda`, which does not compile properly with other versions
 * than the current tech stack.
 * TODO Properly type this.
 * TODO Test this.
 */
// function mergeDeepRight(a: any, b: any): any {
export function mergeDeepRight (a, b) {
  return Object.keys(b).reduce(
    (result, key) => {
      if (typeof result[key] === 'object' && typeof b[key] === 'object') {
        return {
          ...result,
          [key]: mergeDeepRight(result[key], b[key]),
        }
      }
      return {
        ...result,
        [key]: b[key],
      }
    },
    a,
  )
}

/**
 * Returns the new object only with the fields from the `fields` array
 * @param {Array} fields fields that should be kept
 * @param {Object} o new object
 */
export function keepProps (fields, o) {
  return Object.keys(o).reduce((p, n) => {
    if (fields.includes(n)) {
      p[n] = o[n]
    }
    return p
  }, {})
}

/**
* Filters an object by a set of specified keys.
* This utility function takes a source object and a filterKeys object and returns a new object
* that only includes the properties of source that match the keys present in filterKeys.
*/
export const filterObjectByKeys = (source, filterKeys) => pick(source, Object.keys(filterKeys || {}))
