import React from 'react'
import PropTypes from 'prop-types'
import MaskedInput from 'react-input-mask'
// Material UI
import TextField from '@material-ui/core/TextField'
import { getValue } from 'utils/templatedForm'
import { getFormFieldErrorMessage } from '../utils'

/**
 * Renders a string input field.
 * @param name The name of the field.
 * @param option The field definition.
 * @return A component for inputting string data.
 */

function TextMask ({ mask, placeholderChar, guide, keepCharPositions, formatChars }) {
  function TextMaskWithMask (props) {
    const { inputRef, ...other } = props
    return (
      <MaskedInput
        {...other}
        ref={ref => {
          inputRef(ref ? ref.inputElement : null)
        }}
        mask={mask}
        guide={guide}
        maskPlaceholder={placeholderChar}
        formatChars={formatChars}
        alwaysShowMask
      />
    )
  }
  TextMaskWithMask.propTypes = {
    inputRef: PropTypes.any,
  }
  return TextMaskWithMask
}

class RenderStringOption extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      value: getValue(props),
      hasChanged: false,
    }
    const { option } = props
    const { mask, pattern, placeholderChar = '_', guide = true, keepCharPositions = true, formatChars } = option
    this.component = mask && pattern && TextMask({ mask, placeholderChar, guide, keepCharPositions, formatChars })
    this.timeout = null
  }

  componentDidUpdate (prevProps, prevState) {
    const currentValue = this.getStringValue(this.props)
    const prevValue = this.getStringValue(prevProps)
    if (currentValue !== prevValue) {
      this.setState({ value: currentValue, hasChanged: false })
    }
  }

  getStringValue = props => {
    const rawValue = getValue(props)
    return typeof rawValue === 'string' ? rawValue : ''
  }

  onChange_ = value => {
    const {
      name,
      option,
      setValue,
    } = this.props
    const { onChangeOn = 'type' } = option
    const { onChange: onChangeFromProps, transformValue } = option
    const isOnChangeFunction = typeof onChangeFromProps === 'function'
    if (typeof transformValue === 'function') {
      value = transformValue(value)
    }
    if (onChangeOn === 'type') {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        // If users stops typing we send the entered value
        if (isOnChangeFunction) onChangeFromProps(name, value, this.props)
        setValue(name, value, option)
      }, 100)
    }

    this.setState({ value, hasChanged: true })
  }

  onChange = event => {
    const {
      name,
      option,
      setValue,
    } = this.props
    const { onChangeOn = 'type' } = option
    let value = event.target.value
    const { onChange: onChangeFromProps, transformValue } = option
    const isOnChangeFunction = typeof onChangeFromProps === 'function'
    if (typeof transformValue === 'function') {
      value = transformValue(value)
    }
    if (onChangeOn === 'type') {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        // If users stops typing we send the entered value
        if (isOnChangeFunction) onChangeFromProps(name, value, this.props)
        setValue(name, value, option)
      }, 100)
    }

    this.setState({ value, hasChanged: true })
  }

  render () {
    const {
      state,
      extra,
      option,
      disabled,
      values,
      name,
      setValue,
      formTemplate,
      onWheel,
    } = this.props
    const {
      editable = true,
      multiline = false,
      alwaysShowPlaceholder = false,
      placeholder = '',
      InputProps = {},
      inputProps = {},
      getValue: getValueFromOption,
      onChange,
      onChangeOn = 'type',
      variant = 'standard',
      extraRender,
      pattern,
      mask,
      shrink = false,
      onKeyPress: onKeyPressFromProps,
    } = option
    const step = inputProps.step
    const onKeyPress = typeof onKeyPressFromProps === 'function' ? onKeyPressFromProps : undefined
    const placeHolder = typeof placeholder === 'function' ? placeholder(state, values, extra, this.props) : placeholder
    const type = this.props.type || option.type || 'text'
    const isGetValueFunction = typeof getValueFromOption === 'function'
    const value = isGetValueFunction
      ? getValue(this.props)
      : this.state.value
    const errorMessage = getFormFieldErrorMessage(value, option, values, state, extra, formTemplate, this.props)
    const error = Boolean(errorMessage)
    const label = typeof option.name === 'function' ? option.name(value, values) : option.name
    const onBlur = () => {
      if (this.state.hasChanged && onChangeOn === 'blur') {
        setValue(name, value, option)
        if (typeof onChange === 'function') {
          onChange(name, value, this.props)
        }
      }
    }

    return (
      <div>
        <div>
          <TextField
            type={type}
            multiline={multiline}
            error={error}
            {...error ? {
              label: <React.Fragment>{label} ({errorMessage || ''})</React.Fragment>,
              placeholder: alwaysShowPlaceholder ? placeHolder : label,
              InputLabelProps: {
                shrink: true,
              },
            } : {
              ...(shrink && {
                InputLabelProps: {
                  shrink: true,
                },
              }),
              label: label,
              placeholder: placeHolder,
            }}
            onBlur={onBlur}
            value={`${value}`}
            onChange={editable ? this.onChange : () => {}}
            disabled={disabled}
            style={{ height: '100%' }}
            variant={multiline ? 'outlined' : variant}
            onKeyDown={e => {
              if (onKeyPress && (e.keyCode === 40 || e.keyCode === 38)) {
                e.stopPropagation()
                e.preventDefault()
                const value = onKeyPress(e)
                this.onChange_(value)
              }
            }}
            onWheel={onWheel}
            InputProps={{
              ...InputProps,
              ...(multiline ? {
                style: {
                  // height: '100%',
                },
              } : {}
              ),
              ...(pattern && mask && { inputComponent: this.component }),
            }}
            inputProps={{
              ...inputProps,
              ...(step && {
                step: typeof step === 'function' ? step(state, values) : step,
              }),
              ...(multiline ? { style: { minHeight: 150, height: '100%' } } : {}),
              // pattern: pattern,
            }}
            fullWidth
          />
        </div>
        {typeof extraRender === 'function' && extraRender(value)}
      </div>
    )
  }
}

RenderStringOption.propTypes = {
  extra: PropTypes.any,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  state: PropTypes.object,
  values: PropTypes.object,
  option: PropTypes.object,
  formTemplate: PropTypes.object,
  onWheel: PropTypes.func,
  setValue: PropTypes.func,
}

export default RenderStringOption
