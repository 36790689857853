import { toast } from 'react-toastify'
import { getAuthentificationHeader } from 'utils/api'
import axios from 'utils/axios'

export const apiSearch = async (search, userId, token, page, count) => {
  const results = {
    results: [],
    total: 0,
  }
  const urlAccounts = `/search/autocomplete?keyword=${search}&user_id=${userId}&per_page=${count}&page=${page}`
  try {
    const response = await axios.get(
      urlAccounts,
      { headers: getAuthentificationHeader(token) },
    )
    results.results = response.data.data.results
    results.total = response.data.data.total
  } catch (e) {
    if (e.message === 'Network Error') {
      toast.error('Network error!')
    } else {
      const status = e.response.status
      if (status === 401) {
        toast.error('You\'re not authorized')
      }
    }
  }
  try {
    const response = await axios.get(
      `https://componentinfo.phoenixlidar.com/v2/search?search=${search}&indexes=report,component_property`,
      { headers: getAuthentificationHeader(token) },
    )
    results.results = [...results.results, ...response.data.data || []]
    results.total = results.total + response.data.data.length
  } catch (e) {
    if (e.message === 'Network Error') {
      toast.error('Network error!')
    } else {
      const status = e.response.status
      if (status === 401) {
        toast.error('You\'re not authorized')
      }
    }
  }
  return results
}
