import React from 'react'
import PropTypes from 'prop-types'
import { DatePicker } from '@material-ui/pickers'
// Material UI
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
// Icons
import CalendarIcon from '@material-ui/icons/DateRange'
// Project deps
import { getValue } from 'utils/templatedForm'
import { getFormFieldErrorMessage } from '../utils'

/**
 * Renders a date-time option
 */
class DateTimeOption extends React.Component {
  constructor (props) {
    super(props)
    const dateValue = this.getDateValue(props)
    this.state = {
      date: dateValue,
      originalValue: dateValue,
      isOpened: false,
    }
  }

  getDateValue = props => {
    const { option } = props
    const { format } = option
    const value = getValue(props)

    return value
      ? format
        ? typeof format === 'function'
          ? format(value)
          : format
        : ''
      : ''
  }

  componentDidUpdate (props) {
    const { values: pValues, name: pName } = props
    const { values: cValues, name: cName } = this.props
    if (cValues[cName] !== pValues[pName]) {
      const dateValue = this.getDateValue(this.props)
      this.setState({
        date: dateValue,
        originalValue: dateValue,
      })
    }
  }

  setOpened = isOpened => () => this.setState({ isOpened })

  onChange = newValue => {
    const { option, name, setValue } = this.props
    const { onChange: onChangeHandle = () => {} } = option
    const finalNewValue = newValue === '' ? null : newValue
    if (typeof onChangeHandle === 'function') onChangeHandle(name, finalNewValue, this.props)
    setValue(name, finalNewValue, option)
  }

  onChangeDatePicker = value => {
    const { option } = this.props
    const { format } = option
    this.setState({
      date: value
        ? format
          ? typeof format === 'function'
            ? format(value)
            : format
          : value
        : '',
    })
    this.onChange(value)
  }

  onChangeStateDate = event => {
    const value = event.target.value
    if (isNaN(value)) return
    this.setState({ date: value })
  }

  onBlur = () => {
    const value = this.state.date
    if (this.state.originalValue !== value) {
      const { option } = this.props
      const { reverseFormat } = option

      const dateValue = typeof reverseFormat === 'function' && value ? reverseFormat(value) : value
      this.onChange(dateValue)
    }
  }

  onKeyDown = e => {
    if (e.keyCode === 13 && this.input) this.input.blur()
  }
  setRef = node => {
    this.input = node
  }

  render () {
    const { date, isOpened } = this.state
    const {
      option,
      values,
      disabled,
      state,
      extra,
      formTemplate,
    } = this.props
    const { name: optionName, editable = true, variant = 'standard' } = option
    const value = getValue(this.props)
    const errorMessage = getFormFieldErrorMessage(value, option, values, state, extra, formTemplate, this.props)
    const error = Boolean(errorMessage)
    const labelName = typeof optionName === 'function' ? optionName(state, values) : (optionName || '')
    const label = error ? errorMessage : labelName
    const placeholder = option.placeholder || labelName
    return (
      <div style={{ width: '100%' }}>
        <TextField
          variant={variant}
          error={error}
          placeholder={placeholder}
          label={label}
          inputRef={this.setRef}
          value={date || ''}
          onChange={editable ? this.onChangeStateDate : () => {}}
          onBlur={this.onBlur}
          onKeyDown={this.onKeyDown}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: editable ? <InputAdornment position="end">
              <IconButton
                disabled={disabled}
                aria-label="toggle calendar visibility"
                onClick={this.setOpened(true)}
              >
                <CalendarIcon/>
              </IconButton>
            </InputAdornment> : null,
          }}
          disabled={disabled}
          fullWidth
        />
        <DatePicker
          open={isOpened}
          clearable
          fullWidth
          value={value || null}
          onChange={this.onChangeDatePicker}
          onClose={this.setOpened(false)}
          label={option.name}
          style={{ display: 'none' }}
          {...'disableFuture' in option && { disableFuture: option.disableFuture }}
          {...'disablePast' in option && { disablePast: option.disablePast }}
          {...'minDate' in option && { minDate: option.minDate }}
        />
      </div>
    )
  }
}

DateTimeOption.propTypes = {
  state: PropTypes.object,
  extra: PropTypes.any,
  name: PropTypes.string,
  option: PropTypes.object,
  disabled: PropTypes.bool,
  classes: PropTypes.object,
  values: PropTypes.object,
  setValue: PropTypes.func,
  formTemplate: PropTypes.object,
}

export default DateTimeOption
