import { capitalize } from 'lodash'
import { getDurationTypes, getSalesProducts, getSalesProductsTypes } from 'modules/billingAgreement/selectors'
import { getCompanyRovers } from 'modules/companies/selectors'
import { DataType } from 'types/form'
import { LicenseKeyTypes } from 'types/licenseKeys'
import { getSubscriptionStatusIcon } from 'utils/statistics'
import { SubscriptionTypes, SubscriptionStatus, getSubscriptionTypeIcon } from 'utils/subscription'

export const SubscriptionFiltersTemplate = {
  is_archived: {
    name: 'Is archived',
    dataType: DataType.RADIO_SELECT,
    optional: true,
    initialValue: 'false',
    options: [
      { label: 'All', value: 'all' },
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
  },
  // add new filter template for subscriptions
  keyword: {
    name: 'Search',
    variant: 'outlined',
    dataType: DataType.STRING,
    optional: true,
    initialValue: '',
  },
  license_key_types: {
    name: 'License key types',
    dataType: DataType.COMBOBOX,
    optional: true,
    initialValue: [],
    getId: item => item,
    renderItem: item => item,
    onSearchFilter: item => item,
    options: (state, values, companyId) => LicenseKeyTypes,
    gridProps: { xs: 12, sm: 6 },
  },
  rover_serials: {
    name: 'Rover serials',
    dataType: DataType.COMBOBOX,
    optional: true,
    initialValue: [],
    renderItem: rover => rover.serial,
    options: (state, values, companyId) => getCompanyRovers(state, companyId),
    gridProps: { xs: 12, sm: 6 },
    toBackend: items => items.map(rover => rover.serial),
  },
  sales_product_ids: {
    name: 'Sales product',
    dataType: DataType.COMBOBOX,
    optional: true,
    initialValue: [],
    getId: item => item.id,
    renderItem: item => item.name,
    onSearchFilter: item => item.name,
    options: (state, values, companyId) => getSalesProducts(state),
    gridProps: { xs: 12, sm: 6 },
    toBackend: items => items.map(salesProduct => salesProduct.id),
  },
  sales_product_types: {
    name: 'Sales product types',
    dataType: DataType.COMBOBOX,
    optional: true,
    initialValue: [],
    getId: item => item,
    renderItem: item => capitalize(item),
    onSearchFilter: item => capitalize(item),
    options: (state, values, companyId) => getSalesProductsTypes(state),
    gridProps: { xs: 12, sm: 6 },
  },
  duration_types: {
    name: 'Duration types',
    dataType: DataType.COMBOBOX,
    optional: true,
    initialValue: [],
    getId: item => item,
    renderItem: item => capitalize(item),
    onSearchFilter: item => capitalize(item),
    options: (state, values, companyId) => getDurationTypes(state),
    gridProps: { xs: 12, sm: 6 },
  },
  subscription_types: {
    name: 'Subscription types',
    dataType: DataType.COMBOBOX,
    optional: true,
    initialValue: [],
    getId: item => item,
    renderItem: item => getSubscriptionTypeIcon(item),
    onSearchFilter: item => SubscriptionTypes[item],
    options: (state, values, companyId) => Object.keys(SubscriptionTypes),
    gridProps: { xs: 12, sm: 6 },
  },
  subscription_states: {
    name: 'Subscription states',
    dataType: DataType.COMBOBOX,
    optional: true,
    initialValue: [],
    getId: item => item,
    renderItem: item => getSubscriptionStatusIcon(item),
    onSearchFilter: item => SubscriptionStatus[item],
    options: (state, values, companyId) => Object.keys(SubscriptionStatus),
    gridProps: { xs: 12, sm: 6 },
  },
  // assigned_to: {
  //   name: 'Assigned to',
  //   dataType: DataType.COMBOBOX,
  //   optional: true,
  //   initialValue: [],
  //   getId: item => item.id,
  //   renderItem: item => `${item.fullName} (${item.email})`,
  //   options: (state, values, companyId) => getCompanyUsers(state),
  //   onSearchFilter: item => `${item.fullName} (${item.email})`,
  //   gridProps: { xs: 12, sm: 6 },
  // },
  // tags: {
  //   name: 'Tags',
  //   dataType: DataType.COMBOBOX,
  //   optional: true,
  //   initialValue: [],
  //   getId: item => item,
  //   renderItem: item => <span>
  //     <span style={{ display: 'inline-block', marginRight: 4, width: 10, height: 10, borderRadius: 4, background: TagColor[item] }}/>
  //     {item}
  //   </span>,
  //   options: (state, values, companyId) => getTags(state),
  //   onSearchFilter: item => item,
  //   gridProps: { xs: 12, sm: 6 },
  // },
}
