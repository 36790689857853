import React from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
// Material UI
import { withStyles } from '@material-ui/core/styles'
// Project deps
import SupportActions from 'modules/support/actions'
import Layout from 'components/Layout/DefaultLayout'
import Warning from 'components/reusable/Warning'
import { isLoggedIn } from 'modules/users/selectors'

const styles = theme => ({
  ...theme.global,
  root: {
    margin: '20px 16px',
  },
  icon: {
    fontSize: 64,
  },
  openSupportFormButton: {
    padding: 0,
    background: 'inherit',
    border: 'none',
    fontStyle: 'inherit',
    color: 'inherit',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
})

const Error = props => {
  const { classes, openSupportForm, location } = props
  const isRegistrated = location.state ? location.state.isRegistrated : false
  return (
    <Layout>
      <section className={classes.root}>
        <Warning large variant='h6' align='center'>
          <Trans
            style={{ textWrap: 'wrap' }}
            i18nKey={isLoggedIn && isRegistrated ? 'pages.error.registerMessage' : 'pages.error.message'}
            components={[
              <br key='line-break'/>,
              <a key='support-email' href='mailto:support@phoenixlidar.com'>support email</a>,
              <button className={classes.openSupportFormButton} key='support-form' onClick={openSupportForm}>click here</button>,
            ]}
          />
        </Warning>
      </section>
    </Layout>
  )
}

Error.propTypes = {
  classes: PropTypes.object.isRequired,
  openSupportForm: PropTypes.func,
  location: PropTypes.object.isRequired,
}

const mapDispatchToProps = dispatch => ({
  openSupportForm: () => dispatch(SupportActions.openSupportFormDialog()),
})

export default connect(null, mapDispatchToProps)(withRouter(withStyles(styles)(Error)))
