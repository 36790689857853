// External dependencies.
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useResizeDetector } from 'react-resize-detector'
import PropTypes from 'prop-types'
// Material UI dependencies.
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'
// Project internal dependencies.
import AppActions from 'modules/app/actions'
// Local deps
import Header from './Header'

const useStyles = makeStyles(theme => createStyles({
  ...theme.global,
  root: {
    display: 'flex',
    minHeight: '100vh',
    background: theme.palette.background.main,
  },
  appContent: {
    width: '100%',
  },
  mainContent: {
    height: 'auto',
    flex: 1,
    background: theme.palette.background.main,
  },
}))

const Layout = props => {
  const {
    breadcrumbs,
    tab,
    loginAs,
    logs,
    activeUser,
    children,
    style = {},
    activeUserId,
    activeCompany,
    activeCompanyId,
    viewportHeight = false,
  } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const updateHeaderDimensions = (width, height) => dispatch(AppActions.setHeaderDimensions(width, height))
  const onResize = useCallback((width, height) => {
    updateHeaderDimensions(width, height)
  }, [])
  const { height, ref } = useResizeDetector({ onResize, refreshMode: 'debounce', refreshRate: 0 })

  return (
    <div className={classes.root}>
      <CssBaseline />
      <div className={classes.appContent}>
        <Header
          forwardRef={ref}
          breadcrumbs={breadcrumbs}
          tab={tab}
          loginAs={loginAs}
          logs={logs}
          activeUser={activeUser}
          activeCompany={activeCompany}
          activeUserId={activeUserId}
          activeCompanyId={activeCompanyId}
        />
        <main className={classes.mainContent} style={{ ...style, height: `calc(${viewportHeight ? '100vh' : '100%'} - ${height}px)` }}>
          {children}
        </main>
      </div>
    </div>
  )
}

Layout.propTypes = {
  loginAs: PropTypes.bool,
  viewportHeight: PropTypes.bool,
  showAllProjects: PropTypes.bool,
  projects: PropTypes.array,
  tab: PropTypes.string,
  logs: PropTypes.string,
  activeUserId: PropTypes.string,
  activeCompanyId: PropTypes.string,
  onChangeHeaderHeight: PropTypes.func,
  style: PropTypes.object,
  activeUser: PropTypes.object,
  activeCompany: PropTypes.object,
  breadcrumbs: PropTypes.object,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
}

export default Layout
