import React, { useRef, useState, useCallback, useEffect } from 'react'
import { v4 } from 'uuid'
import { path } from 'ramda'
import PropTypes from 'prop-types'
import Webcam from 'react-webcam'
import Compress from 'compress.js'
// Material UI
import { makeStyles } from '@material-ui/core/styles'
import { Button, InputLabel, FormControl, Typography, Dialog, DialogActions, DialogContent, IconButton, Card, CardMedia, CardActions, Select, MenuItem } from '@material-ui/core'
// Icons
import RemoveIcon from '@material-ui/icons/Close'
import DocumentIcon from '@material-ui/icons/Description'
// Project deps
import { mapById, removeById } from 'utils/list'
import LoadingButton from 'components/reusable/LoadingButton'
import DialogTitle from 'components/reusable/StyledDialogTitle'
import DraggablePaper from 'components/reusable/DraggablePaper'
import Warning from '../Warning'
import { getDocumentTypesForRoverEvent } from 'types/roverEvents'
import { getBaseName, getFileExtension, isImageFile } from 'utils/files'
import AddedFilesTypesDialog from './AddedFilesTypesDialog'
import LoadingText from '../LoadingText'
import FileFields from './FileFields'
import { isFileValid } from './utils'

const compress = new Compress()

const useStyles = makeStyles(theme => ({
  card: {
    position: 'relative',
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  uploadInput: {
    display: 'none',
  },
  timerContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 48,
  },
  timer: {
    background: 'rgba(255, 255, 255, 0.78)',
    width: 90,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 48,
    color: '#6a6565',
    boxShadow: 'box-shadow: 0px 0px 7px -1px #242424',
  },
  loadingOverlay: {
    width: '100%',
    height: '100%',
    background: '#8d8d8d',
    position: 'absolute',
    color: '#fff',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 24,
    opacity: 0.7,
  },
  imagesContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    overflowY: 'auto',
  },
  cameraContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    [theme.breakpoints.down('sm')]: {
      // flexDirection: 'column-reverse',
    },
  },
}))

const shouldPreviewFile = file => {
  return file.type.startsWith('image') && isImageFile(file.type)
}

const isNearestRearCamera = device => {
  const label = device.label.toLowerCase()
  return label.includes('back') || label.includes('0')
}

const getScreenOrientation = () => {
  const orientation = path(['screen', 'orientation'], window)
  return orientation ? (orientation.type || '').includes('landscape') ? 'landscape' : 'portrait' : 'portrait'
}

const WebCameraContstraints = {
  'landscape': {
    width: { min: 640, ideal: 4096 },
    height: { min: 480, ideal: 2160 },
    aspectRatio: 1.3333,
    advanced: [
      { width: 4096, height: 2160 },
      { aspectRatio: 1.333 },
    ],
  },
  'portrait': {
    width: { min: 480, ideal: 2160 },
    height: { min: 640, ideal: 4096 },
    aspectRatio: 1.3333,
    advanced: [
      { width: 2160, height: 4096 },
      { aspectRatio: 1.333 },
    ],
  },
}

const AddFilesDialog = props => {
  const {
    onClose,
    addedFiles,
    onSubmit: onSubmitFromProps,
    isLoading,
    accept = ['image/*', '.pdf', '.doc', '.docx', '.txt', '.html'],
    allowWebCam = true,
    allowEventType = true,
    defaultFileType = 'none',
    multiple = true,
    max = Infinity,
    maxFileSize = 3 * 1024 * 1024 * 1024, // 3gb
    eventType,
  } = props
  const DocumentTypes = getDocumentTypesForRoverEvent(eventType)
  const webcamRef = useRef(null)
  const [selectedDeviceId, setSelectedDeviceId] = useState(null)
  const [devices, setDevices] = useState([])
  const [filesSrc, setImgSrc] = useState([])
  const [screenOrientation, setScreenOrientation] = useState(getScreenOrientation())
  const [errors, setErrors] = useState([])
  const timerRef = useRef(null)
  const [isDisabled, setIsDisabled] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const [isCameraAvailable, setIsCameraAvailable] = useState(true)
  const [isCameraInitialized, setIsCameraInitialized] = useState(false)
  const [dialogTypesOpened, setDialogTypesOpened] = useState({ open: false, files: [] })
  const classes = useStyles()

  const changeScreenOrientation = () => {
    const orientation = getScreenOrientation()
    setScreenOrientation(orientation)
  }

  useEffect(() => {
    if (window.screen && window.screen.orientation) {
      window.screen.orientation.addEventListener('change', changeScreenOrientation)
    }
    return () => {
      if (window.screen && window.screen.orientation) {
        window.screen.orientation.removeEventListener('change', changeScreenOrientation)
      }
    }
  }, [])

  const handleDevices = useCallback(
    mediaDevices => {
      const videoMediaDeviceInfos = mediaDevices.filter(device => device && device.kind === 'videoinput')
      if (videoMediaDeviceInfos.length > 1) {
        const [nearestRearCameraId] = videoMediaDeviceInfos
          .filter(isNearestRearCamera)
          .map(i => i.deviceId)
        if (nearestRearCameraId) {
          setSelectedDeviceId(nearestRearCameraId)
        } else if (videoMediaDeviceInfos.length > 1) {
          const [firstCamera] = videoMediaDeviceInfos
          if (firstCamera && firstCamera.deviceId) {
            setSelectedDeviceId(firstCamera.deviceId)
          }
        }
      }
      setDevices(videoMediaDeviceInfos)
    },
    [setDevices],
  )

  useEffect(
    () => {
      if (isCameraInitialized) {
        navigator.mediaDevices.enumerateDevices().then(handleDevices)
      }
    },
    [handleDevices, isCameraInitialized],
  )

  const processesFiles = async (documentType, files) => {
    setIsBusy(true)
    const tempErrors = []
    const tempFiles = [...files].filter(file => !addedFiles.find(addedFile => addedFile.name === file.name))
    const exceededSizeFiles = tempFiles.filter(file => file.size > maxFileSize)
    exceededSizeFiles.forEach(file => tempErrors.push(`Max size is ${maxFileSize}Mb. Cant add file ${file.name}`))
    const newFiles = tempFiles.filter(file => file.size <= maxFileSize)
    const imageFiles = newFiles.filter(file => shouldPreviewFile(file))
    const nonImageFiles = newFiles.filter(file => !shouldPreviewFile(file))
      .map(file => {
        const baseName = getBaseName(file.name)
        const fileExtension = getFileExtension(file.name)
        return {
          file,
          src: '',
          id: v4(),
          name: baseName,
          extension: fileExtension,
          type: documentType,
        }
      })
    const newImages = []
    for (let i = 0; i < imageFiles.length; i++) {
      const file = imageFiles[i]
      const baseName = getBaseName(file.name)
      const fileExtension = getFileExtension(file.name)
      const src = URL.createObjectURL(file)
      newImages.push({
        id: v4(),
        src: src,
        type: documentType,
        name: baseName,
        extension: fileExtension,
        file,
        isImage: true,
      })
    }

    const data = await compress.compress(imageFiles, {
      size: 4,
      quality: 1,
      maxWidth: 250,
      maxHeight: 150,
      resize: true,
    })
    for (let i = 0; i < data.length; i++) {
      const file = data[i]
      newImages[i].compressedSrc = file.prefix + file.data
      const result = await fetch(file.prefix + file.data)
      const blob = await result.blob()
      const compressedFile = new File([blob], newImages[i].file.name, { type: file.ext })
      newImages[i].compressedFile = compressedFile
    }
    setImgSrc(prevState => [
      ...newImages,
      ...nonImageFiles,
      ...prevState,
    ])
    setErrors(tempErrors)
    setIsBusy(false)
  }

  const handleAddFiles = async event => {
    const { target: { files } } = event
    if (allowEventType) {
      setDialogTypesOpened({ open: true, files })
    } else {
      processesFiles(defaultFileType, files)
    }
  }

  const addPhoto = async (src, blobFile) => {
    const data = await compress.compress([blobFile], {
      size: 4,
      quality: 1,
      maxWidth: 250,
      maxHeight: 150,
      resize: true,
    })
    const [compressedPhoto] = data
    const compressedSrc = compressedPhoto.prefix + compressedPhoto.data
    const result = await fetch(compressedSrc)
    const blob = await result.blob()
    setImgSrc(prevState => {
      const imageName = `Image ${prevState.length + 1}`
      return [
        {
          id: v4(),
          src: src,
          name: imageName,
          compressedSrc: compressedSrc,
          compressedFile: new File([blob], imageName, { type: 'image/jpeg' }),
          type: defaultFileType,
          extension: 'jpg',
          file: new File([blobFile], imageName, { type: 'image/jpeg' }),
          isImage: true,
        },
        ...prevState,
      ]
    })
    setIsDisabled(false)
  }

  const takePhoto = async () => {
    const imageSrc = webcamRef.current.getScreenshot({ width: 4096, height: 2160 })
    fetch(imageSrc)
      .then(r => r.blob())
      .then(blobFile => addPhoto(imageSrc, blobFile))
  }

  const capture = useCallback(() => {
    takePhoto()
  }, [webcamRef, setImgSrc])
  const captureWithTimer = useCallback(() => {
    setIsDisabled(true)
    let timer = 3
    const intervalId = setInterval(() => {
      if (timer === 0) {
        clearInterval(intervalId)
        timerRef.current.innerText = ''
        timerRef.current.style.height = 0
        takePhoto()
      } else {
        timerRef.current.innerText = timer--
        timerRef.current.style.height = '90px'
      }
    }, 1000)
  }, [webcamRef, setImgSrc])

  const onRemoveFile = id => () => {
    setImgSrc(prevState => removeById(id, prevState))
  }
  const reset = () => {
    setImgSrc([])
    setErrors([])
  }
  const onSubmit = () => {
    onSubmitFromProps(filesSrc, reset)
  }
  const onChange = id => e => {
    const { value, name } = e.target
    setImgSrc(prevState => mapById(id, prevState, image => ({ ...image, [name]: value })))
  }
  const allFiles = [...addedFiles, ...filesSrc]
  const allCurrentlyAddedFilesValid = filesSrc.every(file => isFileValid(file, allFiles, eventType))
  const disabledButtons = isLoading || isDisabled
  const videoConstraints = WebCameraContstraints[screenOrientation]
  const isValidAmount = filesSrc.length <= max
  return (
    <Dialog PaperComponent={DraggablePaper} PaperProps={{ id: 'add-files-paper-title', style: { height: '100%' } }} open onClose={onClose} maxWidth='lg' fullWidth>
      {dialogTypesOpened.open &&
        <AddedFilesTypesDialog
          eventType={eventType}
          open
          onSubmit={type => {
            processesFiles(type, dialogTypesOpened.files)
            setDialogTypesOpened({ open: false, files: [] })
          }}
          documentTypes={DocumentTypes}
        />
      }
      <DialogTitle style={{ cursor: 'move' }} id='add-files-paper-title'>Upload files</DialogTitle>
      <DialogContent>
        {isBusy && <LoadingText title='Compressing images'/>}
        {isCameraAvailable && allowWebCam ? <div style={{ width: '100%', height: 310, position: 'relative' }}>
          {isLoading && <div className={classes.loadingOverlay}>Sending files to the server...</div>}
          <div className={classes.timerContainer}>
            <div ref={timerRef} className={classes.timer}></div>
          </div>
          <div className={classes.cameraContainer}>
            <div style={{ display: 'flex', flexDirection: 'column', minWidth: 272, justifyContent: 'space-evenly' }}>
              {devices.length > 0 && devices.length > 1 &&
              <FormControl fullWidth variant='outlined'>
                <InputLabel htmlFor='camera'>Camera</InputLabel>
                <Select
                  inputProps={{
                    name: 'camera',
                  }}
                  label='Camera'
                  value={selectedDeviceId}
                  onChange={e => {
                    const { value } = e.target
                    // console.log(streamRef)
                    // streamRef.current.applyConstraints({ deviceId: value })
                    setSelectedDeviceId(value)
                  }}>
                  {devices.map(device => (
                    <MenuItem key={device.deviceId} value={device.deviceId}>{device.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              }
              <Button variant='contained' onClick={capture} fullWidth disabled={disabledButtons} style={{ marginRight: 8 }}>
                Take a photo
              </Button>
              <Button variant='contained' onClick={captureWithTimer} fullWidth disabled={disabledButtons} style={{ marginRight: 8 }}>
                Take a photo with timer (3 seconds)
              </Button>
              <div style={{ width: '100%' }}>
                <input
                  className={classes.uploadInput}
                  type='file'
                  onInput={handleAddFiles}
                  id='upload-files-button'
                  onClick={event => {
                    event.target.value = null
                  }}
                  accept={accept.join(',')}
                  multiple={multiple}
                />
                <label htmlFor='upload-files-button'>
                  <Button variant='contained' fullWidth component='span'>Upload files from folder</Button>
                </label>
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <Webcam
                key={selectedDeviceId}
                width='100%'
                height={254}
                audio={false}
                ref={webcamRef}
                screenshotFormat='image/jpeg'
                screenshotQuality={1}
                // forceScreenshotSourceSize
                videoConstraints={{
                  ...(selectedDeviceId && { deviceId: selectedDeviceId }),
                  ...videoConstraints,
                }}
                onUserMedia={stream => {
                  setIsCameraInitialized(true)
                }}
                onUserMediaError={e => {
                  console.log(e)
                  setIsDisabled(true)
                  setIsCameraAvailable(false)
                }}
              />
            </div>
          </div>
        </div>
          : <div style={{ display: 'flex' }}>
            <div style={{ width: '100%' }}>
              <input
                className={classes.uploadInput}
                type='file'
                onInput={handleAddFiles}
                id='upload-files-button'
                onClick={event => {
                  event.target.value = null
                }}
                accept={accept.join(',')}
                multiple={multiple}
              />
              <label htmlFor='upload-files-button'>
                <Button variant='contained' fullWidth component='span'>Upload files from folder</Button>
              </label>
            </div>
          </div>
        }
        <div>{errors.map((error, index) => <Warning key={index}>{error}</Warning>)}</div>
        {!isValidAmount && <Warning>You&apos;ve added too much files (maximum is {max} files). Remove unnecessary</Warning>}
        <div className={classes.imagesContainer} style={{
          maxHeight: isCameraAvailable && allowWebCam ? `calc(100% - 320px)` : `calc(100% - 48px)`,
        }}>
          { filesSrc.map(file => {
            const isImage = file.isImage
            return (
              <Card className={classes.card} key={file.id} style={{ height: allowEventType ? 275 : 200 }}>
                {isImage && <CardMedia
                  component='img'
                  alt={file.name}
                  style={{ maxWidth: '250px', margin: '0 auto', maxHeight: '150px' }}
                  image={file.compressedSrc || file.src}
                />
                }
                {!isImage &&
                  <div style={{ backgroundColor: '#eee', height: 150, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <DocumentIcon/>
                  </div>
                }
                <IconButton onClick={onRemoveFile(file.id)} color='primary' style={{ position: 'absolute', top: 0, right: 0 }}>
                  <RemoveIcon/>
                </IconButton>
                <CardActions style={{ marginTop: !allowEventType ? 0 : 'auto' }}>
                  <FileFields
                    file={file}
                    files={allFiles}
                    eventType={eventType}
                    allowEventType={allowEventType}
                    documentTypes={DocumentTypes}
                    onChange={onChange}
                  />
                </CardActions>
              </Card>
            )
          },
          )}
        </div>
        {filesSrc.length <= 0 && <Typography style={{
          height: `calc(100% - 48px)`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>No files added</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onTouchStart={onClose} onClick={onClose}>Cancel</Button>
        <LoadingButton
          onClick={onSubmit}
          onTouchStart={onSubmit}
          color='primary'
          isLoading={isLoading}
          disabled={!allCurrentlyAddedFilesValid || filesSrc.length <= 0 || !isValidAmount}>
          Add files
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

AddFilesDialog.propTypes = {
  multiple: PropTypes.bool,
  max: PropTypes.number,
  allowWebCam: PropTypes.bool,
  allowEventType: PropTypes.bool,
  defaultFileType: PropTypes.string,
  eventType: PropTypes.string,
  isLoading: PropTypes.bool,
  maxFileSize: PropTypes.number,
  accept: PropTypes.array,
  addedFiles: PropTypes.array,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default AddFilesDialog
