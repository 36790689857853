/**
 * Returns error map for each field of the attachment
 * @param {Attachment} file
 * @param {Array.<Attachment>} files
 * @param {RoverEventType} eventType
 * @returns {Object}
 */
export const getErrors = (file, files, eventType) => {
  const isNameInvalid = Boolean(files.find(file_ => file_.name === file.name && file_.id !== file.id))
  const errors = {
    name: isNameInvalid,
    hostname: false,
    machine_id: false,
  }
  return errors
}
/**
 * Returns label map for each field of the attachment
 * @param {Attachment} file
 * @param {Array.<Attachment>} files
 * @param {RoverEventType} eventType
 * @returns {Object}
 */
export const getLabels = (file, files, eventType) => {
  const errors = getErrors(file, files, eventType)
  const labels = {
    name: errors.name ? 'Image name should be unique' : 'Name',
    hostname: 'Server Name',
    machine_id: 'Server ID',
  }
  return labels
}
/**
 * Checks if the current attachment is valid (all fields are filled in without errors)
 * @param {Attachment} file
 * @param {Array.<Attachment>} files
 * @param {RoverEventType} eventType
 * @returns {Boolean}
 */
export const isFileValid = (file, files, eventType) => {
  return Object.values(getErrors(file, files, eventType)).every(value => !value)
}
