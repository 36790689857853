import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
// Material UI
import { TextField, InputAdornment, IconButton } from '@material-ui/core'
// Icons
import ClearIcon from '@material-ui/icons/Close'

const DelayedSearchBox = props => {
  const { onChange, clearButton = true, value, delay = 100, inputRef, InputProps = {}, disabled, ...otherProps } = props
  const [localValue, setLocalValue] = useState(value)
  const timerRef = useRef(null)

  useEffect(() => {
    setLocalValue(value)
    return () => {
      clearTimeout(timerRef.current)
    }
  }, [value])

  const handleSearchChange = event => {
    const { value } = event.target
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    if (!value) {
      onChange(value)
      setLocalValue(value)
    } else {
      timerRef.current = setTimeout(() => {
        onChange(value)
      }, delay)
      setLocalValue(value)
    }
  }
  const onClear = event => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    onChange('')
    setLocalValue('')
  }

  return (
    <TextField
      {...otherProps}
      ref={inputRef}
      disabled={disabled}
      value={localValue}
      onChange={handleSearchChange}
      InputProps={{
        endAdornment: clearButton && (
          <InputAdornment position='end'>
            <IconButton disabled={disabled} onClick={onClear} size='small'>
              <ClearIcon/>
            </IconButton>
          </InputAdornment>
        ),
        ...InputProps,
      }}
    />
  )
}

DelayedSearchBox.propTypes = {
  disabled: PropTypes.bool,
  clearButton: PropTypes.bool,
  inputRef: PropTypes.func,
  value: PropTypes.string,
  onChange: PropTypes.func,
  delay: PropTypes.number,
  InputProps: PropTypes.object,
}

export default DelayedSearchBox
