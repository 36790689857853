import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
// Material UI
import { withStyles } from '@material-ui/core/styles'
import { IconButton, Button, Popover, Grid } from '@material-ui/core'
// Icons
import FilterIcon from '@material-ui/icons/FilterList'
// Project deps
import FilterRender from './FilterRender'

const styles = theme => ({
  container: {
    padding: theme.spacing(3),
    overflow: 'hidden',
  },
  item: {
    overflow: 'hidden',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 60,
    height: 48,
    width: '100%',
  },
  filterIcon: {
    '&&:hover': {
      color: theme.palette.primary.main,
    },
  },
})

const CustomFilter = props => {
  const { classes, columns, filters, onFilterChange } = props

  const [open, setOpen] = useState(false)
  const [anchorReference] = useState('anchorEl')
  const anchorEl = useRef(null)

  const handleChange = index => event => {
    const value = event.target.value
    const newFilterList = filters
    newFilterList[index] = typeof value === 'string' ? [value] : value
    onFilterChange(newFilterList)
  }

  const handleReset = () => {
    onFilterChange(filters.reduce(accum => [...accum, []], []))
  }

  const handleClickButton = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const actions = {
    handleChange: handleChange,
    handleReset: handleReset,
    handleClickButton: handleClickButton,
    handleClose: handleClose,
  }
  return (
    <React.Fragment>
      <IconButton
        buttonRef={anchorEl}
        onClick={handleClickButton}
        classes={{ root: classes.filterIcon }}>
        <FilterIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl.current}
        anchorReference={anchorReference}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <Grid container spacing={1} className={classes.container}>
          <Grid item xs={12}>
            <Button
              color='primary'
              onClick={handleReset}><Trans i18nKey='customFilter.reset'/></Button>
          </Grid>
          { columns.map((column, index) =>
            <FilterRender
              key={`filter-${index}`}
              classes={classes}
              filters={filters}
              column={column}
              index={index}
              actions={actions}
            />,
          )}
        </Grid>
      </Popover>
    </React.Fragment>
  )
}

CustomFilter.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(CustomFilter)
