import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  getCompanies: null,
  getCompaniesLoading: null,
  getCompaniesSuccess: ['companies'],
  getCompaniesFailure: ['errorMessage'],

  getChildCompanies: ['companyId', 'failureCallback'],
  getChildCompaniesLoading: ['companyId'],
  getChildCompaniesSuccess: ['companyId', 'companies'],
  getChildCompaniesFailure: ['companyId', 'errorMessage'],

  getPositions: ['companyId'],
  getPositionsLoading: ['companyId'],
  getPositionsSuccess: ['companyId', 'positions'],
  getPositionsFailure: ['companyId', 'errorMessage'],

  addReferenceStationPosition: ['companyId', 'name', 'data'],
  addReferenceStationPositionLoading: null,
  addReferenceStationPositionSuccess: ['companyId', 'position'],
  addReferenceStationPositionFailure: ['errorMessage'],

  getCompanyUsers: ['companyId'],
  getCompanyUsersLoading: null,
  getCompanyUsersSuccess: ['users'],
  getCompanyUsersFailure: ['errorMessage'],

  getCompany: ['companyId'],
  getCompanyLoading: null,
  getCompanySuccess: ['company'],
  getCompanyFailure: ['errorMessage'],

  getLoggedUserCompany: ['companyId'],
  getLoggedUserCompanyLoading: null,
  getLoggedUserCompanySuccess: ['company'],
  getLoggedUserCompanyFailure: ['errorMessage'],

  generateInviteUrl: ['companyId', 'email', 'companyRole'],
  generateInviteUrlLoading: ['companyId'],
  generateInviteUrlSuccess: ['companyId'],
  generateInviteUrlFailure: ['companyId', 'errorMessage'],

  checkInviteToken: ['token'],
  checkInviteTokenLoading: ['token'],
  checkInviteTokenSuccess: ['token'],
  checkInviteTokenFailure: ['token', 'errorMessage'],

  registerEmployee: ['companyId', 'token', 'data'],
  registerEmployeeLoading: null,
  registerEmployeeSuccess: null,
  registerEmployeeFailure: ['errorMessage'],

  adminRegisterEmployee: ['companyId', 'data', 'onSuccess'],
  adminRegisterEmployeeLoading: null,
  adminRegisterEmployeeSuccess: ['companyId', 'user'],
  adminRegisterEmployeeFailure: ['errorMessage'],

  updateCompany: ['companyId', 'data', 'withSuccess'],
  updateCompanyLoading: null,
  updateCompanySuccess: ['company'],
  updateCompanyFailure: ['errorMessage'],

  createCompanyAddresses: ['companyId', 'companyAddresses'],
  updateCompanyAddresses: ['companyId', 'companyAddresses', 'newCompanyAddresses'],
  updateCompanyAddressesLoading: null,
  updateCompanyAddressesSuccess: ['companyId', 'addresses'],
  updateCompanyAddressesFailure: ['errorMessage'],

  deleteCompany: ['companyId'],
  deleteCompanyLoading: null,
  deleteCompanySuccess: ['companyId'],
  deleteCompanyFailure: ['errorMessage'],

  getCompanySystemTypes: ['companyId'],
  getCompanySystemTypesLoading: ['companyId'],
  getCompanySystemTypesSuccess: ['companyId', 'systemTypes'],
  getCompanySystemTypesFailure: ['companyId'],

  addCompanySystemType: ['companyId', 'systemId', 'systemType'],
  addCompanySystemTypeLoading: null,
  addCompanySystemTypeSuccess: ['companyId', 'systemId', 'systemType'],
  addCompanySystemTypeFailure: null,

  deleteCompanySystemType: ['companyId', 'systemId'],
  deleteCompanySystemTypeLoading: null,
  deleteCompanySystemTypeSuccess: ['companyId', 'systemId'],
  deleteCompanySystemTypeFailure: null,

  getCompanySubscriptionsByFilters: ['companyId', 'filters', 'withLoading'],
  getCompanySubscriptionsByFiltersLoading: ['companyId', 'filters'],
  getCompanySubscriptionsByFiltersSuccess: ['companyId', 'subscriptions', 'filters'],
  getCompanySubscriptionsByFiltersFailure: ['companyId'],

  getCompanySubscriptions: ['companyId', 'withLoading'],
  getCompanySubscriptionsLoading: ['companyId'],
  getCompanySubscriptionsSuccess: ['companyId', 'subscriptions'],
  getCompanySubscriptionsFailure: ['companyId'],
  getCompanySubscriptionSuccess: ['companyId', 'subscriptionId', 'subscription'],

  getCompanyRovers: ['companyId'],
  getCompanyRoversLoading: ['companyId'],
  getCompanyRoversSuccess: ['companyId', 'rovers'],
  getCompanyRoversFailure: ['companyId'],

  getCompanyProjects: ['companyId'],
  getCompanyProjectsLoading: ['companyId'],
  getCompanyProjectsSuccess: ['companyId', 'projects'],
  getCompanyProjectsFailure: ['companyId'],

  getCompanyDBLogs: ['companyId', 'pagination', 'onSuccess'],
  getCompanyDBLogsLoading: ['companyId'],
  getCompanyDBLogsSuccess: ['companyId', 'logs', 'pagination'],
  getCompanyDBLogsFailure: ['companyId'],

  getCompanyProductUpdates: ['companyId', 'product'],
  getCompanyProductUpdatesLoading: ['companyId', 'productName'],
  getCompanyProductUpdatesSuccess: ['companyId', 'productName', 'updates'],
  getCompanyProductUpdatesFailure: ['companyId', 'productName'],

  getCompanyWideMaxVersion: ['companyId'],
  getCompanyWideMaxVersionLoading: ['companyId'],
  getCompanyWideMaxVersionSuccess: ['companyId', 'companyWideMaxVersions'],
  getCompanyWideMaxVersionFailure: ['companyId'],

  addRovers: ['rovers', 'subscription'],
  removeRover: ['rover_serial'],
}, { prefix: 'COMPANIES/' })

export const CompaniesTypes = Types
export default Creators
