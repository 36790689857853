import React from 'react'
import PropTypes from 'prop-types'
import { DatePicker, DateTimePicker, KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers'
// Material UI
// Icons
// Project deps
import { getValue } from 'utils/templatedForm'
import { getFormFieldErrorMessage } from '../utils'
import { getUTCDate } from 'utils/dateTime'

/**
 * Renders a boolean input field.
 * @param name The name of the field.
 * @param option The field definition.
 * @return A component for inputting boolean data.
 */
const DateOption = options => {
  const {
    name,
    option,
    disabled,
    setValue,
    state,
    values,
    extra,
    formTemplate,
  } = options
  const { editable = true, optionProps = {}, extraRender } = option
  const {
    disablePast: disablePast_,
    dateTime = false,
    keyboard = false,
    minDate: minDate_,
    maxDate: maxDate_,
    views: views_ = ['year', 'month', 'date'],
    helperTextError = false,
    errorMessage: errorMessage_,
    ...otherOptionProps
  } = optionProps
  const Component = keyboard && dateTime ? KeyboardDateTimePicker : keyboard ? KeyboardDatePicker : dateTime ? DateTimePicker : DatePicker
  const value = getValue(options)
  const hasError = getFormFieldErrorMessage(value, option, values, state, extra, formTemplate, options)
  const errorMessage = hasError
    ? errorMessage_
      ? typeof errorMessage_ === 'function'
        ? errorMessage_(value, values, options)
        : errorMessage_
      : hasError
    : ''
  const minDate = typeof minDate_ === 'function' ? minDate_(value, values, options) : minDate_
  const maxDate = typeof maxDate_ === 'function' ? maxDate_(value, values, options) : maxDate_
  const views = typeof views_ === 'function' ? views_(value, values, options) : views_
  const disablePast = typeof disablePast_ === 'function' ? disablePast_(value, values, options) : disablePast_
  const finalName = typeof option.name === 'function' ? option.name(value, values, options) : option.name
  const onChange = editable ? (date, stringValue) => {
    setValue(name, getUTCDate(keyboard ? stringValue : date), option)
  } : () => {}
  return (
    <div>
      <Component
        label={!helperTextError ? <span>{finalName}{errorMessage ? <span> ({errorMessage})</span> : ''}</span> : finalName}
        value={value}
        fullWidth
        {...otherOptionProps}
        {...minDate && { minDate }}
        {...maxDate && { maxDate }}
        {...'disablePast' in optionProps && { disablePast }}
        views={views}
        disabled={disabled}
        onChange={onChange}
        error={errorMessage}
        invalidLabel=''
        {...!helperTextError ? {
          maxDateMessage: null,
          minDateMessage: null,
        } : {
          maxDateMessage: errorMessage,
          minDateMessage: errorMessage,
        }}
        invalidDateMessage={null}
      />
      {typeof extraRender === 'function' && extraRender(value, state, values, options)}
    </div>
  )
}

DateOption.propTypes = {
  state: PropTypes.object,
  extra: PropTypes.any,
  name: PropTypes.string,
  option: PropTypes.object,
  disabled: PropTypes.bool,
  classes: PropTypes.object,
  values: PropTypes.object,
  setValue: PropTypes.func,
  formTemplate: PropTypes.object,
}

export default DateOption
