import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
// Material UI
import { DialogContent } from '@material-ui/core'
// Project deps
import ClosableDialog from 'components/reusable/StyledClosableDialog'
import DraggablePaper from 'components/reusable/DraggablePaper'
import LogsTable from 'components/Licensing/LogsTable'
import DialogTitleRoverEvent from 'components/reusable/DialogTitleRoverEvent'

const LogsDialog = logsProps => {
  const { id, getStateLogs, getLogsPagination, getLogs, getIsLoadingState, title, open, onClose, showRelated, eventType } = logsProps
  const logs = useSelector(state => getStateLogs(state, id))
  const pagination = useSelector(state => getLogsPagination(state, id))
  const isLoading = useSelector(state => getIsLoadingState(state, id))
  useEffect(() => {
    getLogs(id, eventType)('', 100, 1, 'timestamp', 'desc')
  }, [id])
  return (
    <ClosableDialog
      PaperComponent={DraggablePaper}
      PaperProps={{ style: { maxWidth: 1360 }, id: 'draggable-logs-dialog-title' }}
      maxWidth='lg'
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitleRoverEvent
        id='draggable-logs-dialog-title'
        title={title}
        text={id}
      />
      <DialogContent style={{ minHeight: 500, maxHeight: 'calc(100vh - 48px)' }}>
        <LogsTable
          logs={logs}
          pagination={pagination}
          getLogs={getLogs(id, eventType)}
          isLoading={isLoading}
          addRelatedObject={Boolean(showRelated)}
        />
      </DialogContent>
    </ClosableDialog>
  )
}

LogsDialog.propTypes = {
  open: PropTypes.bool,
  isRover: PropTypes.bool,
  showRelated: PropTypes.bool,
  id: PropTypes.string,
  eventType: PropTypes.string,
  onClose: PropTypes.func,
  event: PropTypes.object,
}

export default LogsDialog
