import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { Trans } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  policy: {
    color: 'red !important',
    marginLeft: 4,
  },
}))

const TermsAndConditions = props => {
  const classes = useStyles()
  return <Typography className={classes.policy} variant='body1' component='a' href='/terms' target='_blank' align='center'>
    <Trans i18nKey='termsAndConditions'/>
  </Typography>
}

export default TermsAndConditions
