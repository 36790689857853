import { createReducer } from 'reduxsauce'
// Project deps
// Local deps
import { INITIAL_STATE } from './initialState'
import { ReleasesTypes } from './actions'

export const getReleaseChangesLoading = (state, { releaseId }) =>
  state.merge({
    getReleaseChangesLoading: {
      ...state.get('getReleaseChangesLoading'),
      [releaseId]: true,
    },
  })
export const getReleaseChangesSuccess = (state, { releaseId, changes }) => {
  return state.merge({
    getReleaseChangesLoading: {
      ...state.get('getReleaseChangesLoading'),
      [releaseId]: false,
    },
    releaseChanges: {
      ...state.get('releaseChanges'),
      [releaseId]: changes,
    },
  })
}
export const getReleaseChangesFailure = (state, { releaseId }) =>
  state.merge({
    getReleaseChangesLoading: {
      ...state.get('getReleaseChangesLoading'),
      [releaseId]: false,
    },
  })

export const reducer = createReducer(INITIAL_STATE, {
  [ReleasesTypes.GET_RELEASE_CHANGES_LOADING]: getReleaseChangesLoading,
  [ReleasesTypes.GET_RELEASE_CHANGES_SUCCESS]: getReleaseChangesSuccess,
  [ReleasesTypes.GET_RELEASE_CHANGES_FAILURE]: getReleaseChangesFailure,
})
