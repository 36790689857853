import React from 'react'
import PropTypes from 'prop-types'
import SectionList from 'react-virtualized-sectionlist'
// Material UI
// Icons
// Project deps
import StyledChip from 'components/reusable/Chip'
// import VirtualizedList from 'components/reusable/VirtualizedList'
import { isRoversLoading } from 'modules/rovers/selectors' // getAllRovers
import { escape } from 'utils/regex'
import { ClickAwayListener, InputAdornment, ListItem, Paper, Popper, TextField } from '@material-ui/core'
import LoadingText from 'components/reusable/LoadingText'
import Warning from 'components/reusable/Warning'
import { getFormFieldErrorMessage } from '../utils'
import { ArrowDropDown } from '@material-ui/icons'

class SerialNumberOption extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      value: '',
      hasChanged: false,
      anchorEl: null,
      serials: [],
      regexSearch: [],
      errors: [],
    }
    this.anchorRef = React.createRef()
    this.textfieldRef = React.createRef()
    this.timeout = null
  }

  onDelete = serialNumber => () => {
    const { values, name, setValue, option } = this.props
    const rovers = values[name] || []
    setValue(name, rovers.filter(rover => rover.serial !== serialNumber), option)
    this.anchorRef.current.focus()
  }

  onAddItem = item => e => {
    const { values, name, setValue, option, state } = this.props
    const serialNumbers = values[name] || []
    setValue(name, [item, ...serialNumbers], option)
    if (this.state.value === item.serial) {
      this.setState({ value: '', regexSearch: [], serials: [], errors: [] })
    }
    const { multiple = true } = option
    const canSelectMany = typeof multiple === 'function' ? multiple(state, values) : multiple
    if (canSelectMany) {
      this.anchorRef.current.focus()
    } else {
      this.onBlur()
    }
  }

  onBlur = e => {
    this.anchorRef.current.blur()
    this.unsetAnchorElement()
  }

  onChangeTextField = e => {
    const { value } = e.target
    if (!this.state.anchorEl) {
      this.setAnchorElement(e)
    }
    if (value) {
      const transformedSearch = escape(value)
      const splittedSearch = transformedSearch.split(new RegExp(/,| /))
        .filter(str => str !== ',' && str !== '' && str !== ' ')
      this.setState({
        value,
        regexSearch: splittedSearch.map(search => new RegExp(search, 'i')),
        serials: splittedSearch,
      })
    } else {
      this.setState({
        value: '',
        regexSearch: [],
        serials: [],
      })
    }
  }

  setAnchorElement = e => {
    this.setState({ anchorEl: e.currentTarget })
  }

  unsetAnchorElement = e => {
    this.setState({ anchorEl: null })
  }

  render () {
    const {
      option,
      disabled,
      values,
      name,
      state,
      extra,
      formTemplate,
    } = this.props
    const { value, regexSearch, serials, errors } = this.state
    const rovers = values[name] || []
    const { multiple = true, options } = option
    const createdOptions = typeof options === 'function' ? options(state, values, extra) : options
    const canSelectMany = typeof multiple === 'function' ? multiple(state, values) : multiple
    const allRovers = createdOptions.filter(rover => !rovers.find(rv => rv.serial === rover.serial))
    const isLoading = isRoversLoading(state)
    // const roversMap = getRoversMap(state)
    // const type = this.props.type || option.type || 'text'
    // const value = this.state.value || ''
    // const { okay, message } = customCheck(value, undefined, state)
    const errorMessage = getFormFieldErrorMessage(rovers, option, values, state, extra, formTemplate, this.props)
    const error = Boolean(errorMessage)
    const label = typeof option.name === 'function' ? option.name(rovers) : option.name
    const self = this
    const open = Boolean(this.state.anchorEl)

    const filteredRoversBySearch = value ? allRovers.filter(({ serial }) => {
      return regexSearch.some(searchRegex => serial.toString().match(searchRegex))
    }) : allRovers
    const isRoversAdded = rovers.length > 0
    const manyErrors = errors.length > 1
    const isDisabled = isRoversAdded && !canSelectMany
    const groups = filteredRoversBySearch.reduce((all, rover) => {
      const groupName = rover.order + '/' + rover.group
      return {
        ...all,
        [groupName]: [
          ...(all[groupName] || []),
          rover,
        ],
      }
    }, {})
    const sections = Object.keys(groups).sort((a, b) => parseInt(b) - parseInt(a)).map(groupName => ({
      title: groupName.split('/')[1],
      data: groups[groupName] || [],
    })).filter(s => s.data.length > 0)
    const totalNumberOfElements = sections.reduce((all, section) => all + section.data.length + 1, 0)
    const height = isLoading
      ? 200
      : totalNumberOfElements > 8 ? 200 : totalNumberOfElements * 25
    if (disabled) {
      return (
        <TextField variant='outlined' label={label} disabled value={rovers.map(rover => rover.serial).join(', ')}/>
      )
    }
    return (
      <div>
        <div>
          <ClickAwayListener onClickAway={this.unsetAnchorElement}>
            <div>
              <TextField
                error={error}
                ref={node => { this.textfieldRef.current = node }}
                inputRef={node => { this.anchorRef.current = node }}
                fullWidth
                variant='outlined'
                label={errorMessage || label}
                value={value}
                // type='search'
                onChange={this.onChangeTextField}
                onFocus={this.setAnchorElement}
                placeholder='Enter rover serial'
                onKeyDown={e => {
                  if (e.keyCode === 13 && value && filteredRoversBySearch.length > 0 && !isDisabled) {
                    const roversNotFound = []
                    const items = serials.map(serial => {
                      const isRoverFound = allRovers.find(rover => rover.serial.toLowerCase() === serial.toLowerCase())
                      if (!isRoverFound) {
                        roversNotFound.push(serial)
                      }
                      return isRoverFound
                    }).filter(Boolean)
                    const finalItems = !canSelectMany
                      ? items.length > 0 ? [items[0]] : []
                      : items
                    self.setState({ errors: roversNotFound })
                    if (finalItems.length > 0) {
                      const { values, name, setValue, option } = self.props
                      const serialNumbers = values[name] || []
                      setValue(name, [...finalItems, ...serialNumbers], option)
                      self.setState({ value: '' })
                      if (!canSelectMany) {
                        this.onBlur()
                      }
                    }
                  }
                  if (e.key === 'Escape') {
                    this.onBlur()
                  }
                }}
                inputProps={{
                  style: {
                    width: `calc(100% - 28px)`,
                    padding: isRoversAdded ? `10px 14px 18.5px` : `18.5px 14px`,
                  },
                }}
                InputProps={{
                  style: {
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    padding: 0,
                  },
                  type: 'search',
                  startAdornment: <InputAdornment style={{
                    display: 'flex',
                    height: 'auto',
                    maxHeight: 'none',
                    flexWrap: 'wrap',
                    margin: isRoversAdded ? 4 : 0,
                  }} position='start'>
                    {rovers.map(rover => (
                      <StyledChip
                        style={{ marginLeft: 4, marginTop: 4 }}
                        onDelete={self.onDelete(rover.serial)}
                        label={rover.serial}
                        key={rover.serial}
                      />
                    ))}
                  </InputAdornment>,
                  endAdornment: <InputAdornment onClick={e => {
                    // e.preventDefault()
                    e.stopPropagation()
                  }} style={{ position: 'absolute', right: 7, top: '50%', color: '#757575', cursor: disabled ? 'default' : 'pointer' }} position="end"><ArrowDropDown/></InputAdornment>,
                }}
              />
              <Popper
                keepMounted
                disablePortal={false}
                open={open}
                anchorEl={this.textfieldRef.current}
                style={{ zIndex: 100000 }}
                popperOptions={{
                  modifiers: {
                    setPopperWidth: {
                      enabled: true,
                      order: 1000,
                      fn: data => {
                        const { instance: { reference, popper } } = data
                        popper.style.width = `${this.textfieldRef.current ? reference.offsetWidth : 500}px`
                        return data
                      },
                    },
                  },
                }}
              >
                <Paper
                  style={{
                    height,
                    overflow: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                  }}>
                  {!isLoading && <SectionList
                    width={934}
                    rowHeight={25}
                    sectionHeaderHeight={25}
                    sectionHeaderRenderer={({ title, sectionIndex, key, style, isScrolling, isVisible, parent }) => {
                      return (
                        <div key={key} style={style}>
                          <h4 style={{ margin: 0, paddingLeft: 8 }}>{title}</h4>
                        </div>
                      )
                    }}
                    height={height}
                    sections={sections}
                    rowRenderer={({ item, sectionIndex, rowIndex, key, style, isScrolling, isVisible, parent }) => (
                      <ListItem disabled={item.isDisabled || isDisabled} tab button onClick={self.onAddItem(item)} key={key} style={style}>
                        {item.serial}
                        {(item.isDisabled || isDisabled) && <Warning variant='warning' disableIcon containerStyle={{ marginLeft: 2 }}>{item.disabledMessage || '(Only one rover can be selected)'}</Warning>}
                      </ListItem>
                    )}
                  />
                  }
                  {isLoading && <LoadingText title='Loading rovers'/>}
                </Paper>
              </Popper>
            </div>
            {/* <Combobox
              viewLimit={4}
              fullWidth
              clearOnSelect
              label={label}
              items={allRovers}
              compare={item => item && item.serial}
              searchTemplate={item => item && item.serial}
              inputDisplayTemplate={item => item && item.serial}
              onChange={self.onChange}
              value={''}
              multiple
              error={error}
              errorMessage={errorMessage}
              selectedItem={null}
              disabled={disabled}
              isLoading={isLoading}
              disablePortal={false}
              variant='outlined'
            />
            */
            }
          </ClickAwayListener>
        </div>
        {errors.length > 0 &&
          <Warning variant='warning'>
            {errors.map(error => error).join(',') + ` rover serial${manyErrors ? 's' : ''} not found`}
          </Warning>
        }
      </div>
    )
  }
}

SerialNumberOption.propTypes = {
  extra: PropTypes.any,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  state: PropTypes.object,
  values: PropTypes.object,
  option: PropTypes.object,
  formTemplate: PropTypes.object,
  onWheel: PropTypes.func,
  setValue: PropTypes.func,
}

export default SerialNumberOption
