import React from 'react'
import PropTypes from 'prop-types'
import { DialogContent, DialogTitle, List, MenuItem } from '@material-ui/core'
import ClosableDialog from 'components/reusable/ClosableDialog'
import { useDispatch, useSelector } from 'react-redux'
import { getReleases } from 'modules/users/selectors'
import UsersActions from 'modules/users/actions'
import { getProducts } from 'modules/app/selectors'

const redirectToChangeLogs = productName => {
  return () => {
    const anchor = document.createElement('a')
    anchor.href = `${window.location.origin}/releases/changelogs/${productName}`
    anchor.target = '_blank'
    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)
  }
}

const ProductReleasesDialog = props => {
  const { open, onClose } = props
  const newReleases = useSelector(getReleases)
  const products = useSelector(getProducts)
  const dispatch = useDispatch()
  const setUserSeenReleases = (productName, numberOfReleases, onSuccess) =>
    dispatch(UsersActions.setUserSeenReleases(productName, numberOfReleases, onSuccess))
  return (
    <ClosableDialog open={open} onClose={onClose}>
      <DialogTitle style={{ paddingRight: 48, paddingTop: 8 }}>Phoenix LiDAR Systems product&apos;s releases</DialogTitle>
      <DialogContent>
        <List>
          {products.map(({ name }) => {
            const path = name.toLowerCase()
            const newReleasesForProduct = newReleases.find(r => r.name === name)
            if (newReleasesForProduct) {
              const releases = newReleasesForProduct.releases
              const changes = newReleasesForProduct.changes
              return (
                <MenuItem key={name} onClick={e => {
                  setUserSeenReleases(name, releases.length, redirectToChangeLogs(path))
                }}>
                  <span style={{ display: 'inline-block', marginRight: '8px' }}>{name}:</span>
                  <span style={{ textDecoration: 'underline', display: 'inline-block', marginLeft: 'auto' }}>
                    {releases.length} new release{releases.length > 1 ? 's' : ''}, {changes} change{changes > 1 ? 's' : ''}
                  </span>
                </MenuItem>
              )
            }
            return (
              <MenuItem key={name} onClick={redirectToChangeLogs(path)}>
                <span style={{ display: 'inline-block', marginRight: '8px' }}>{name}:</span>
                <span style={{ textDecoration: 'underline', display: 'inline-block', marginLeft: 'auto' }}>check releases</span>
              </MenuItem>
            )
          })}
        </List>
      </DialogContent>
    </ClosableDialog>
  )
}

ProductReleasesDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

export default ProductReleasesDialog
