import { getDefaultPagination } from 'modules/utils'

export const isCustomersLoading = state => state.rovers.get('getCustomersIsLoading')
export const isConstantsLoading = state => state.rovers.get('getConstantsIsLoading')

export const getTags = state => state.rovers.get('tags')
export const getProducts = state => state.rovers.get('products')
export const getPackages = state => state.rovers.get('packages')
export const getPackagesFeatures = state => state.rovers.get('packagesFeatures')
export const getReleases = state => state.rovers.get('releases')
export const getRoverFeatures = state => state.rovers.get('roverFeatures')
export const getSpatialExplorerFeatures = state => state.rovers.get('seFeatures')

export const getDeleteLicenseKeyIsLoading = state => state.rovers.get('deleteLicenseKeyIsLoading')
export const getDeleteCustomerIsLoading = state => state.rovers.get('deleteCustomerIsLoading')

export const getEditCustomerIsLoading = state => state.rovers.get('editCustomerIsLoading')

export const getCustomersListIsLoading = state => !!(state.rovers.get('getCustomersIsLoading'))
export const getLicenseKeysListIsLoading = (state, customerId) => !!(state.rovers.get('getLicenseKeysIsLoading')[customerId])
export const getFeaturesListIsLoading = (state, keyId) => !!(state.rovers.get('getFeaturesIsLoading')[keyId])
export const getPackagesListIsLoading = (state, keyId) => !!(state.rovers.get('getPackagesIsLoading')[keyId])

export const getAllLicenseKeys = state => state.rovers.get('licenseKeys')
export const getLicenceKeys = (state, customerId) => state.rovers.get('licenseKeys')[customerId] || []
export const getFilteredLicenceKeys = (state, customerId) => state.rovers.get('filteredLicenseKeys')[customerId] || []
export const getFeatures = (state, keyId) => state.rovers.get('features')[keyId] || []
export const getFilteredFeatures = (state, keyId) => state.rovers.get('filteredFeatures')[keyId] || []
export const getKeyPackages = (state, keyId) => state.rovers.get('keyPackages')[keyId] || []
export const getFilteredKeyPackages = (state, keyId) => state.rovers.get('filteredKeyPackages')[keyId] || []
export const getFilteredKeyPackagesFeatures = (state, keyId) => state.rovers.get('filteredKeyPackagesFeatures')[keyId] || []

export const getCurrentUser = state => state.rovers.get('currentUser') || {}

export const getRovers = state => state.rovers.get('rovers')
export const getAllRovers = state => state.rovers.get('allRovers')
export const getRoversMap = state => state.rovers.get('roversMap')
export const getRoversPagination = state => state.rovers.get('roversPagination')
export const isRoversLoading = state => state.rovers.get('getRoversIsLoading')
export const isCustomerRoversLoading = state => state.rovers.get('getCustomerRoversIsLoading')
export const getCustomerRovers = (state, customerId) => state.rovers.get('customerRovers')[customerId] || []

export const getRoverCalibrations = (state, serial) => state.rovers.get('roverCalibrations')[serial] || []
export const isGetRoverCalibrationsLoading = (state, serial) => !!state.rovers.get('gerRoverCalibrationsIsLoading')[serial]

export const getRoverCustomSettings = (state, serial) => state.rovers.get('roverCustomSettings')[serial] || []
export const getExpandedCustomSetting = state => state.rovers.get('expandRoverSetting')
export const isGetRoverCustomSettingsLoading = (state, serial) => !!state.rovers.get('getRoverCustomSettingsIsLoading')[serial]

export const isAddRoverEventLoading = (state, serial) => !!state.rovers.get('addRoverEventIsLoading')[serial]
export const isGetRoverEventsLoading = (state, serial) => !!state.rovers.get('getRoverEventsIsLoading')[serial]
export const getRoverEvents = (state, serial) => state.rovers.get('roverEvents')[serial] || []
export const getSelectedEvent = state => state.rovers.get('selectedEvent')

export const getRoverReports = (state, serial) => state.rovers.get('reports')[serial] || []
export const getReportComponents = (state, reportId) => state.rovers.get('components')[reportId] || []
export const isReportComponentsLoading = (state, reportId) => !!state.rovers.get('getReportComponentsIsLoading')[reportId]

export const getRoverProjects = (state, serial) => state.rovers.get('roverProjects')[serial] || []
export const getRoverCompanyProjects = (state, serial) => state.rovers.get('roverCompanyProjects')[serial] || []
export const isGetRoverProjectsLoading = (state, serial) => !!state.rovers.get('getRoverProjectsIsLoading')[serial]

export const isApproveEventLoading = state => state.rovers.get('approveEventLoading')
export const isDisapproveEventLoading = state => state.rovers.get('disapproveEventLoading')
export const isCloseEventLoading = state => state.rovers.get('closeEventLoading')
export const isEditEventLoading = state => state.rovers.get('editEventLoading')
export const isRejectEventLoading = state => state.rovers.get('rejectEventLoading')
export const isDeleteEventLoading = state => state.rovers.get('deleteEventLoading')
export const getShowRMADialogState = state => state.rovers.get('showRMADialog')
export const getShowRegisterSystemDialogState = state => state.rovers.get('showRegisterSystemDialog')
export const isRegisterSystemLoading = state => state.rovers.get('registerSystemLoading')
export const isAddAttachmentsLoading = state => state.rovers.get('addAttachmentsIsLoading')

export const isGetEventDBLogsLoading = state => state.rovers.get('getEventDBLogsLoading')
export const getEventDBLogs = (state, eventId) => state.rovers.get('eventDBLogs')[eventId] || []
export const getEventDBLogsPagination = (state, eventId) => state.rovers.get('eventDBLogsPagination')[eventId] || getDefaultPagination()

export const isGetCheckListStepDBLogsLoading = state => state.rovers.get('getCheckListStepDBLogsLoading')
export const getCheckListStepDBLogs = (state, stepId) => state.rovers.get('checkListStepDBLogs')[stepId] || []
export const getCheckListStepDBLogsPagination = (state, stepId) => state.rovers.get('checkListStepDBLogsPagination')[stepId] || getDefaultPagination()

export const isRoversCSVLoading = state => state.rovers.get('getRoversCSVLoading')
export const getRoversCSV = state => state.rovers.get('roversCSV')

export const getRoverUpdates = (state, serial) => state.rovers.get('roverUpdates')[serial] || []
export const isGetRoverUpdatesLoading = (state, serial) => !!state.rovers.get('getRoverUpdatesLoading')[serial]

export const getRoverVersion = (state, serial) => state.rovers.get('roverVersion')[serial] || []
export const isGetRoverVersionLoading = (state, serial) => !!state.rovers.get('getRoverVersionLoading')[serial]
