import React from 'react'
import PropTypes from 'prop-types'
// import { DatePicker } from 'material-ui-pickers'
// Material UI
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
// Icons
// Project deps
import MenuItem from '@material-ui/core/MenuItem'
import StyledTooltip from 'components/reusable/StyledTooltip'
import { getValue } from 'utils/templatedForm'
import { getFormFieldErrorMessage } from '../utils'
import { Checkbox } from '@material-ui/core'
import Warning from 'components/reusable/Warning'

const ITEM_HEIGHT = 50
const ITEM_PADDING_TOP = 8
const MenuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

/**
 * Renders a selection input field.
 * @param name The name of the field.
 * @param option The field definition.
 * @return A component for selecting an option.
 */
export const SelectionOption = propOptions => {
  const {
    state,
    extra,
    name,
    option,
    disabled,
    values: formValues,
    setValue,
    formTemplate,
    extraProps,
  } = propOptions
  const { onChange: onChangeHandle = () => {}, tooltip, tooltipProps = {}, editable = true, variant = 'standard', renderValue, multirow = false, disabledText } = option
  const value = getValue(propOptions)
  const onChange = (event, index) => {
    const value = event.target.value
    if (typeof onChangeHandle === 'function') onChangeHandle(name, value, propOptions)
    setValue(name, value, option)
  }
  const errorMessage = getFormFieldErrorMessage(value, option, formValues, state, extra, formTemplate, propOptions)
  const { options, disabledOptions, showValue = true } = option
  // const onFocus = () => onSetContext(name)
  const optionName = typeof option.name === 'function'
    ? option.name(state, formValues, extra, extraProps)
    : option.name
  const disabledOptionsList = typeof disabledOptions === 'function'
    ? disabledOptions(state, formValues, extra, extraProps)
    : (disabledOptions || [])
  // It is possible to specify the options for the select field as an array of strings as well as
  // a function returning the array of strings.
  // If the `options` property was a function, it needs to be evaluated...
  let createdOptions
  if (typeof options === 'function') {
    createdOptions = options(state, formValues, extra, extraProps)
  } else {
    // ... otherwise the property can be used directly.
    createdOptions = options
  }
  const selectedValue = value || []
  const selectLabel = errorMessage || optionName
  const displayEmpty = errorMessage && selectedValue.length <= 0
  const shrinkLabel = (errorMessage && selectedValue.length <= 0)
  const placeholder = optionName ? <span style={{ color: '#a1a1a1' }}>{optionName}</span> : ''
  return (
    <StyledTooltip placement='top-start' {...tooltipProps} title={tooltip || ''}>
      {!editable
        ? <TextField variant={variant} value={value || ''} fullWidth label={optionName}/>
        : <FormControl style={{ width: '100%' }} variant={variant}>
          <InputLabel shrink={shrinkLabel} error={errorMessage} id={optionName}>{selectLabel}</InputLabel>
          <Select
            labelId={optionName}
            id={optionName + '-select'}
            value={selectedValue}
            error={Boolean(errorMessage)}
            onChange={editable ? onChange : () => {}}
            disabled={disabled}
            inputProps={{ id: optionName }}
            fullWidth
            label={selectLabel}
            renderValue={selected => {
              if (selected.length > 0 && renderValue) {
                return renderValue(state, selected, createdOptions)
              }
              return placeholder
            }}
            multiple
            MenuProps={MenuProps}
            placeholder={optionName}
            displayEmpty={displayEmpty}
            notched={shrinkLabel}
          >
            {
            // Here, `createOptions` contains an array of strings which can be used to render the menu items.
              createdOptions.map((option, index) => {
                const { value, label } = option
                const isDisabled = disabledOptionsList.includes(value)
                const disableText = isDisabled
                  ? typeof disabledText === 'function'
                    ? disabledText(option)
                    : disabledText || ''
                  : ''
                return (
                  <MenuItem key={value + index} value={value} disabled={isDisabled}>
                    <Checkbox checked={selectedValue.includes(value)}/>
                    <div style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                      {multirow
                        ? <Typography component='pre' style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {Array.isArray(label) ? label : `${label}`}{showValue ? `\n(${value})` : ''}
                        </Typography>
                        : <React.Fragment>{label}{showValue ? `(${value})` : ''}</React.Fragment>
                      }
                      {isDisabled && disableText && <Warning variant='warning' disableIcon>{disableText}</Warning>}
                    </div>
                  </MenuItem>
                )
              })
            }
          </Select>
        </FormControl>
      }
    </StyledTooltip>
  )
}

SelectionOption.propTypes = {
  state: PropTypes.object,
  extra: PropTypes.any,
  name: PropTypes.string,
  option: PropTypes.object,
  disabled: PropTypes.bool,
  classes: PropTypes.object,
  values: PropTypes.object,
  setValue: PropTypes.func,
  formTemplate: PropTypes.object,
}

export default SelectionOption
