import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
// import { DatePicker } from 'material-ui-pickers'
// Material UI
import { Button, Typography } from '@material-ui/core'
// Icons
import UploadIcon from '@material-ui/icons/CloudUpload'
import RemoveAllIcon from '@material-ui/icons/Delete'
// Project deps
// import { isCoordinateLieInRectangle } from '../../templates/utils'
// import StyledTooltip from 'components/reusable/StyledTooltip'
import RoverActions from 'modules/rovers/actions'
import RecallActions from 'modules/recalls/actions'
import { getValue } from 'utils/templatedForm'
import File from 'components/reusable/File'
import UploadedFile from 'components/reusable/File/UploadedFile'
import LoadingButton from 'components/reusable/LoadingButton'
import ViewImagesDialog from 'components/reusable/ViewImagesDialog'
import AddFilesDialog from 'components/reusable/AddFilesDialog'
import { BLOCK_SIZE } from 'components/reusable/File/utils'
import { DocumentType, getDocumentTypesForRoverEvent } from 'types/roverEvents'
import ConfirmDialog from 'components/Licensing/ConfirmDialog'
import Warning from 'components/reusable/Warning'
import EditFileDialog from 'components/reusable/AddFilesDialog/EditFileDialog'
import { mapById } from 'utils/list'

/**
 * Renders a boolean input field.
 * @param name The name of the field.
 * @param option The field definition.
 * @return A component for inputting boolean data.
 */
const UploadFilesOption = options => {
  const {
    name,
    option,
    disabled,
    setValue,
    extraProps,
    extra,
    values,
    state,
    formTemplate,
  } = options
  const {
    options: customOptions = {},
    eventType,
    deleteFor = 'event',
  } = option
  const {
    accept = ['image/*', '.pdf', '.doc', '.docx', '.txt', '.html', '.las', '.lic'],
    multiple = true,
    uploadLabel = 'Add documents',
    emptyLabel = 'No documents added',
    size = BLOCK_SIZE,
    maxFiles = Infinity,
    minFiles = 0,
    allowWebCam = true,
    allowEventType = true,
    allowDelete = true,
    allowEdit = false,
    defaultFileType = 'temp',
    errorMessage = '',
    text = '',
  } = customOptions
  const [dialogOpened, setDialogOpened] = useState({ dialog: null, extra: {} })
  const { event, systemType } = extraProps
  const DocumentTypes = getDocumentTypesForRoverEvent(eventType)
  const dispatch = useDispatch()
  const deleteEventAttachments = (attachmentIds, onSuccess) =>
    dispatch(RoverActions.deleteEventAttachments(extra, event.id, event.type, attachmentIds, onSuccess))
  const deleteRecallAttachments = (attachmentIds, onSuccess) =>
    dispatch(RecallActions.deleteRecallAttachments(event.id, event.type, attachmentIds, onSuccess))
  const allFiles = getValue(options) || []
  const files = allFiles.filter(file => !file.uploaded)
  const uploadedFiles = allFiles.filter(file => file.uploaded)
  const images = allFiles.filter(file => file.isImage && file.document_type !== DocumentType.THUMBNAIL)
  const onRemoveFile = file => event => {
    event.stopPropagation()
    event.preventDefault()
    const newFiles = allFiles.filter(f => f.id !== file.id)
    setValue(name, newFiles, option)
  }
  const onEditFile = file => event => {
    event.stopPropagation()
    event.preventDefault()
    setDialogOpened({ dialog: 'edit-file', file })
  }
  const onSubmitEditFile = newFile => {
    const newFiles = mapById(newFile.id, allFiles, () => newFile)
    onCloseDialog()
    setValue(name, newFiles, option)
  }
  const onRemoveUploadedFile = files => event => {
    event.stopPropagation()
    event.preventDefault()
    setDialogOpened({ dialog: 'confirm-delete', files })
  }
  const onCloseDialog = () => {
    setDialogOpened({ dialog: null })
  }
  const onSuccessDelete = () => {
    const filesToDelete = dialogOpened.files.filter(Boolean).map(file => file.id)
    const newFiles = allFiles.filter(f => !filesToDelete.includes(f.id))
    onCloseDialog()
    setValue(name, newFiles, option)
  }
  const onSubmitDeleteUploadedFile = event => {
    const filesToDelete = dialogOpened.files.filter(Boolean).map(file => file.id)
    if (deleteFor === 'event') {
      deleteEventAttachments(filesToDelete, onSuccessDelete)
    }
    if (deleteFor === 'recall') {
      deleteRecallAttachments(filesToDelete, onSuccessDelete)
    }
    if (deleteFor === 'system-type') {
      onSuccessDelete()
      console.log(systemType)
    }
  }
  const onOpenImage = id => () => {
    const imageIndex = images.findIndex(file => file.id === id)
    setDialogOpened({ dialog: 'view-images', index: imageIndex })
  }
  const onRemoveAll = () => {
    setValue(name, [], option)
  }
  const errorMessageFinal = typeof errorMessage === 'function'
    ? errorMessage(allFiles, option, values, state, extra, formTemplate, options)
    : errorMessage
  const minFilesFinal = typeof minFiles === 'function'
    ? minFiles(allFiles, option, values, state, extra, formTemplate, options)
    : minFiles
  const maxFilesFinal = typeof maxFiles === 'function'
    ? maxFiles(allFiles, option, values, state, extra, formTemplate, options)
    : maxFiles
  const minFilesText = minFilesFinal > 1 ? minFilesFinal + ' files' : minFilesFinal + ' file'
  const maxFilesText = minFilesFinal > 1 ? minFilesFinal + ' files' : minFilesFinal + ' file'
  const isValid = allFiles.length >= minFilesFinal && allFiles.length <= maxFilesFinal
  return (
    <div style={{ marginBottom: 8 }}>
      {dialogOpened.dialog === 'edit-file' && <EditFileDialog
        allowEventType={allowEventType}
        documentTypes={DocumentTypes}
        open
        onClose={onCloseDialog}
        onSubmit={onSubmitEditFile}
        file={dialogOpened.file}
        files={allFiles}
        eventType={eventType}
      />}
      {dialogOpened.dialog === 'add-files' && <AddFilesDialog
        accept={accept}
        multiple={multiple}
        onClose={onCloseDialog}
        addedFiles={allFiles}
        allowWebCam={allowWebCam}
        allowEventType={allowEventType}
        defaultFileType={defaultFileType}
        eventType={eventType}
        max={maxFilesFinal}
        onSubmit={(addedFiles, callback) => {
          setValue(name, [...addedFiles, ...allFiles], option)
          onCloseDialog()
        }}
      />
      }
      { dialogOpened.dialog === 'confirm-delete' &&
        <ConfirmDialog
          title='Delete file'
          onCancel={onCloseDialog}
          onSubmit={onSubmitDeleteUploadedFile}
          open
        >
          Are you sure you want to delete selected file?
        </ConfirmDialog>
      }
      {dialogOpened.dialog === 'view-images' && <ViewImagesDialog
        onClose={onCloseDialog}
        images={images}
        defaultStep={dialogOpened.index}
        imageId={'id'}
        imageSrc={'src'}
      />
      }
      <div>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <LoadingButton
              startIcon={<UploadIcon/>}
              fullWidth
              color='primary'
              variant='contained'
              onClick={() => setDialogOpened({ dialog: 'add-files' })}
              disabled={disabled || files.length >= maxFilesFinal}>
              {uploadLabel}
            </LoadingButton>
          </div>
          {multiple && files.length > 0 && <div style={{ flex: 1, marginLeft: 4 }}>
            <Button
              startIcon={<RemoveAllIcon/>}
              onClick={onRemoveAll}
              fullWidth
              color='primary'
              variant='contained'
              component='span'
              disabled={disabled || files.length <= 0}>
              Remove all
            </Button>
          </div>
          }
        </div>
        {isValid && text && <Typography variant='body2'>{text}</Typography>}
        {!isValid && (
          errorMessageFinal
            ? <Warning>{errorMessageFinal}</Warning>
            : <Warning>
              {maxFilesFinal === Infinity
                ? `You should upload at least ${minFilesText}`
                : `You should upload ${minFilesFinal === maxFilesFinal ? minFilesText : `from ${minFilesText} to ${maxFilesText}`}`
              }
            </Warning>
        )}
        {files.length > 0 && <fieldset style={{ border: '1px solid #c4c4c4', borderRadius: 4, marginTop: 4 }}>
          <legend style={{ color: '#797979', fontSize: 12 }}>Added files</legend>
          <div style={{
            padding: 8,
            display: 'grid',
            maxHeight: multiple ? 150 : 75,
            overflow: 'auto',
            gridTemplateColumns: `repeat(auto-fill, minmax(${size + 15}px, 1fr))`,
            gridGap: `4px 0px`,
          }}>
            {files.map(file => (
              <File
                size={size}
                fileName={file.name}
                key={file.id}
                file={file.file}
                src={file.src}
                compressedSrc={file.compressedSrc}
                onRemoveFile={onRemoveFile(file)}
                onEditFile={allowEdit ? onEditFile(file) : undefined}
                onOpenImage={onOpenImage(file.id)}
              />
            ))}
          </div>
        </fieldset>
        }
        {files.length <= 0 && emptyLabel && <div style={{
          height: 50,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}><Typography>{emptyLabel}</Typography></div>}
        {uploadedFiles.length > 0 && <fieldset style={{ border: '1px solid #c4c4c4', borderRadius: 4, marginTop: 4 }}>
          <legend style={{ color: '#797979', fontSize: 12 }}>Uploaded files</legend>
          <div style={{
            padding: 8,
            display: 'grid',
            maxHeight: multiple ? 150 : 75,
            overflow: 'auto',
            gridTemplateColumns: `repeat(auto-fill, minmax(${size + 15}px, 1fr))`,
            gridGap: `4px 0px`,
          }}>
            {uploadedFiles.map(attachment => {
              if (attachment.document_type === DocumentType.THUMBNAIL) {
                return null
              }
              const thumbnail = uploadedFiles.find(eventAttachment => {
                return eventAttachment.name.includes(attachment.name) && eventAttachment.document_type === DocumentType.THUMBNAIL
              })
              return (
                <UploadedFile
                  size={size}
                  fileName={attachment.name}
                  key={attachment.id}
                  file={attachment}
                  src={attachment.file_url}
                  allowDelete={allowDelete}
                  compressedSrc={thumbnail ? thumbnail.file_url : attachment.file_url}
                  onRemoveFile={onRemoveUploadedFile([attachment, thumbnail])}
                  onOpenImage={onOpenImage(attachment.id)}
                />
              )
            })}
          </div>
        </fieldset>
        }
      </div>
    </div>
  )
}

UploadFilesOption.propTypes = {
  state: PropTypes.object,
  extra: PropTypes.any,
  name: PropTypes.string,
  option: PropTypes.object,
  disabled: PropTypes.bool,
  classes: PropTypes.object,
  values: PropTypes.object,
  setValue: PropTypes.func,
  formTemplate: PropTypes.object,
}

export default UploadFilesOption
