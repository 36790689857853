import React from 'react'
import PropTypes from 'prop-types'
// Material UI
import { TableRow, Checkbox, Fade } from '@material-ui/core'
// Icons
import ExpandMoreIcon from '@material-ui/icons/KeyboardArrowRight'
// Project deps
import { mergeDeepRight } from 'utils/dict'
import { copyTextToClipboard } from 'utils/copy'
// Local deps
import TableCell from './StyledTableCell'

const EnhancedTableRow = props => {
  const {
    rowId,
    rowData,
    columns,
    display,
    onClick,
    onExpandCellClick,
    selected,
    checked,
    scrollExpandableRowOnMount,
    onChangeCheck,
    enableRowSelection,
    expandableRowRender,
    shouldRenderExpandableRow,
    options,
    classes,
    index: rowIndex,
  } = props
  const { id } = rowData
  const { setRowProps = () => ({}) } = options
  const rowProps = typeof setRowProps === 'function' ? (setRowProps(rowData) || {}) : {}

  const selectText = node => {
    if (document.selection) { // IE
      const range = document.body.createTextRange()
      range.moveToElementText(node)
      range.select()
    } else if (window.getSelection) {
      const range = document.createRange()
      range.selectNode(node)
      window.getSelection().removeAllRanges()
      window.getSelection().addRange(range)
    }
  }

  const onCopyClick = text => e => {
    e.preventDefault()
    e.stopPropagation()
    selectText(e.target)
    console.log(text)
    console.log(copyTextToClipboard)
    copyTextToClipboard(text)
  }
  let timeout = 100 + rowIndex * 100
  timeout = timeout >= 400 ? 400 : timeout
  return (
    <React.Fragment key={id}>
      <Fade in timeout={timeout} direction='right'>
        <TableRow
          id={rowId}
          key={rowId}
          onClick={onClick}
          tabIndex={-1}
          {...rowProps}
          className={classes.tableRow}
          classes={{ selected: classes.tableRowSelected }}
          selected={selected}
        >
          {
            enableRowSelection && (
              <TableCell padding='checkbox'>
                <Checkbox
                  checked={checked}
                  onChange={onChangeCheck(id)}
                  color='primary'
                />
              </TableCell>
            )
          }
          { expandableRowRender && (
            <TableCell style={{ width: '2%', padding: 0 }}
              onClick={typeof onExpandCellClick === 'function' && shouldRenderExpandableRow ? onExpandCellClick(id) : () => {}}>
              { shouldRenderExpandableRow &&
                  <ExpandMoreIcon
                    style={{ verticalAlign: 'middle', transition: 'transform .15s ease' }}
                    className={selected ? classes.expanded : ''}
                  />
              }
            </TableCell>
          )}
          {
            columns.map((columnData, index) => {
              const { id: columnId, options = {} } = columnData
              const { customBodyRender, setCellProps = () => { return {} }, copy = false, copyText, styles } = options
              const value = rowData[columnId]
              const isFirstCell = !shouldRenderExpandableRow && !enableRowSelection && index === 0
              const cellProps = setCellProps()
              const renderedValue = typeof customBodyRender === 'function'
                ? customBodyRender(value, rowData, rowIndex)
                : value
              const isValueExist = Boolean(value)
              return (
                display[index] &&
                  <TableCell key={columnId} style={styles} {...mergeDeepRight(cellProps, { ...(isFirstCell && { paddingLeft: 4 }) })}>
                    { !copy
                      ? renderedValue
                      : <span
                        className={copy ? classes.bodyTableCell : ''}
                        onClick={e => { e.stopPropagation(); e.preventDefault() }}
                        onDoubleClick={copy && isValueExist ? typeof copyText === 'function'
                          ? onCopyClick(copyText(value))
                          : onCopyClick(value)
                          : () => {}} >
                        {renderedValue}
                      </span>
                    }
                  </TableCell>
              )
            })
          }
        </TableRow>
      </Fade>
      { selected && shouldRenderExpandableRow && (
        <Fade in timeout={400}>
          <TableRow {...rowProps} key={`expanded-row-${id}`} selected={selected} classes={{ selected: classes.tableRowSelected }}>
            <TableCell colSpan={columns.length + (shouldRenderExpandableRow ? 1 : 0)} style={{ padding: 0 }}>
              {
                typeof expandableRowRender === 'function' &&
                    expandableRowRender(rowData, { selected, scrollExpandableRowOnMount })
              }
            </TableCell>
          </TableRow>
        </Fade>
      )
      }
    </React.Fragment>
  )
}

EnhancedTableRow.propTypes = {
  rowId: PropTypes.string,
  display: PropTypes.array,
  columns: PropTypes.array,
  classes: PropTypes.object,
  options: PropTypes.object,
  rowData: PropTypes.object,
  checked: PropTypes.bool,
  selected: PropTypes.bool,
  index: PropTypes.number,
  enableRowSelection: PropTypes.bool,
  scrollExpandableRowOnMount: PropTypes.bool,
  onClick: PropTypes.func,
  onChangeCheck: PropTypes.func,
  onExpandCellClick: PropTypes.func,
  expandableRowRender: PropTypes.func,
  shouldRenderExpandableRow: PropTypes.func,
}

export default EnhancedTableRow
