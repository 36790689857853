import { StepState } from 'types/roverEvents'
import { flatten } from 'ramda'
import { isStepCommentRequired } from './roverEvents'

export function isStepFromWorkList (parentWorkListId, workListId, stepId, step) {
  return (
    step.parentWorkListId === parentWorkListId &&
    step.workListId === workListId &&
    step.stepId === stepId
  )
}

export function findWorkListStep (parentWorkListId, workListId, stepId, steps = []) {
  return steps.find(step => isStepFromWorkList(parentWorkListId, workListId, stepId, step))
}

/**
 * Transform work list steps by adding some fields.
 * @param {*} parentWorkListId
 * @param {*} workListId
 * @param {*} step
 * @param {*} addedSteps
 * @returns {Object} step
 */
export function transformStep (parentWorkListId, workListId, step, addedSteps) {
  const stepId = step.id
  const addedStep = findWorkListStep(parentWorkListId, workListId, stepId, addedSteps)
  const finalStep = addedStep || step
  const stepState = finalStep.status || finalStep.state || StepState.NOT_DONE
  const comment = finalStep.comment || ''
  const stepName = step.name || step.text
  return {
    id: addedStep ? addedStep.id : null,
    stepId: addedStep ? addedStep.step.id : stepId,
    workListId: addedStep ? addedStep.work_list.id : workListId,
    parentWorkListId: addedStep ? addedStep.parent_work_list.id : parentWorkListId,
    name: stepName,
    work_state: stepState,
    prev_work_state: stepState,
    comment: comment,
    prev_comment: comment,
    last_updated_by: finalStep.last_updated_by,
    added_by: finalStep.added_by,
    created: step.created,
    updated: step.updated,
    isAdded: Boolean(addedStep),
    assignee: step.assignee || null,
    prev_assignee: step.assignee || null,
    isValid: true,
  }
}

/**
 * Recursively transform steps in every work list
 */
export function transformWorkListSteps (workList, addedSteps, parentWorkListId) {
  // Transform steps for current work list
  const transformedSteps = workList.steps.map(step => transformStep(parentWorkListId, workList.id, step, addedSteps))
  const childWorkLists = workList.child_work_lists
  if (childWorkLists.length > 0) {
    return {
      ...workList,
      parentWorkListId,
      steps: transformedSteps,
      child_work_lists: childWorkLists.map(childWorkList => transformWorkListSteps(childWorkList, addedSteps, workList.id)),
    }
  }
  return {
    ...workList,
    parentWorkListId,
    steps: transformedSteps,
  }
}

/**
 * Recursively getting steps for workList
 * @param {*} workList
 * @returns
 */
function getAllSteps (workList) {
  const childWorkLists = workList.child_work_lists
  if (childWorkLists.length > 0) {
    return [
      ...workList.steps,
      ...childWorkLists.map(childWorkList => getAllSteps(childWorkList)),
    ]
  }
  return workList.steps
}

/**
 * Recursively getting steps for workList
 * @param {*} workList
 * @returns
 */
function getAllWorkLists (workList, depth) {
  const childWorkLists = workList.child_work_lists
  if (childWorkLists.length > 0) {
    return childWorkLists.map(childWorkList => getAllWorkLists(childWorkList, depth + 1))
  }
  return depth === 0 ? [] : [workList]
}

/**
 * Recursively getting steps for workList
 * @param {*} workList
 * @returns
 */
export function transformRecursiveWorkLists (workList, parentWorkListId) {
  const childWorkLists = workList.child_work_lists
  const newWorkList = {
    ...workList,
    parentWorkListId,
  }
  if (childWorkLists.length > 0) {
    newWorkList.child_work_lists = childWorkLists.map(childWorkList => transformRecursiveWorkLists(childWorkList, workList.id))
  }
  return newWorkList
}

export function getFlattenWorkLists (workLists) {
  const allWorkLists = workLists.map(workList => getAllWorkLists(workList, 0))
  return flatten(allWorkLists)
}

export function getFlattenWorkListsSteps (workLists) {
  return flatten(workLists.map(workList => getAllSteps(workList)))
}

export function transformWorkListAndSteps (workLists, addedSteps) {
  const transformedSteps = addedSteps.map(step => ({
    ...step,
    workListId: step.work_list.id,
    stepId: step.step.id,
    parentWorkListId: step.parent_work_list.id,
  }))
  const workListsTransformed = workLists.map(workList => transformWorkListSteps(workList, transformedSteps, workList.parentWorkListId))
  // Flatten all steps arrays to get array with one depth level
  const allSteps = getFlattenWorkListsSteps(workListsTransformed)
  return {
    workLists: workListsTransformed,
    workListSteps: allSteps,
  }
}

export function getStepsToDelete (workList, steps) {
  return flatten([
    ...steps.filter(step => step.workListId === workList.id),
    ...workList.child_work_lists.map(childWorkList => getStepsToDelete(childWorkList, steps)),
  ])
}

export function getWorkListCreatableSteps (steps) {
  return steps.filter(step => !step.isAdded && (
    step.comment !== step.prev_comment ||
    step.work_state !== step.prev_work_state
  ))
}

export function getWorkListChangableSteps (steps) {
  return steps.filter(step => step.isAdded && (
    step.comment !== step.prev_comment ||
    step.work_state !== step.prev_work_state
  ))
}

export function changeWorkListStepState (parentWorkListId, workListId, stepId, workSteps, { name, value }) {
  return workSteps.map(workStep => {
    if (isStepFromWorkList(parentWorkListId, workListId, stepId, workStep)) {
      const newWorkStep = {
        ...workStep,
        [name]: value,
      }
      newWorkStep.isValid = !(isStepCommentRequired(newWorkStep.work_state) && !newWorkStep.comment)
      return newWorkStep
    }
    return workStep
  })
}
