export const LicenseKeyState = {
  CHECKED_OUT: 'checked_out',
  CHECKED_IN: 'checked_in',
}

/**
 * Converts a `LicenseKey` to a `LicenseKey`.
 * @param raw The raw licenseKey as received by the Api.
 * @return The converted licenseKey for use in the rest of this application.
 */
export function convertRawLicenseKey (raw) {
  const {
    comment,
    created,
    hostname,
    id,
    machine_id,
    rover_serial,
    state,
    updated,
    assigned_to,
    type,
  } = raw
  return {
    comment,
    created: typeof created === 'string' ? new Date(created) : undefined,
    hostname,
    id,
    machine_id,
    rover_serial,
    state,
    updated: typeof updated === 'string' ? new Date(updated) : undefined,
    type: type,
    assigned_to,
  }
}

export const LicenseKeyType = {
  ROVER: 'Rover',
  SE: 'SpatialExplorer',
  IE: 'InertialExplorer',
  NODE_LOCKED: 'NodeLocked',
  NETWORK: 'Network',
}
export const LicenseKeyTypes = Object.values(LicenseKeyType)
export const LicenseKeyTypeBadge = {
  [LicenseKeyType.ROVER]: 'ROV',
  [LicenseKeyType.SE]: 'SE',
  [LicenseKeyType.IE]: 'IE',
  [LicenseKeyType.NODE_LOCKED]: 'NL',
  [LicenseKeyType.NETWORK]: 'NE',
}
