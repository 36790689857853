import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Trans } from 'react-i18next'
import PropTypes from 'prop-types'
import classNames from 'classnames'
// Material UI dependencies.
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import './style.css'
// Project internal dependencies.

const Breadcrumb = props => {
  const { breadcrumb, options } = props
  const { last, tabsValue, onClick, classes } = options
  const { label = '', path: url } = breadcrumb
  // Maximum breadcrumb text length is 120
  const maximumLabelLength = 120
  const longText = label.length >= maximumLabelLength
  const text = label === ''
    ? <Trans i18nKey='breadcrumbs.noLabel'/>
    : longText
      ? label.substring(0, maximumLabelLength) + '...'
      : label
  const prevLabel = useRef(text)
  useEffect(() => {
    prevLabel.current = text
  }, [label])
  const content = (
    last
      ? <React.Fragment>
        <Typography style={{ position: 'relative' }} component={Link} key={url} className={classNames(classes.breadcrumbActive, classes.breadcrumbLink)} onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
          <span key={text} style={{ opacity: 1, position: 'relative' }} className='fadeIn'>
            {text}
            <div key={prevLabel.current} style={{ position: 'absolute', top: -3, left: 0, opacity: 0, width: 130 }} className='fadeOut'>{prevLabel.current}</div>
          </span>
        </Typography>
      </React.Fragment>
      : <Typography component={Link} key={url} to={url} id={url} className={classes.breadcrumbLink} onClick={onClick(tabsValue)}>
        {text}
      </Typography>
  )
  return (
    longText
      ? <Tooltip key={text} title={label}>
        {content}
      </Tooltip>
      : content
  )
}

Breadcrumb.propTypes = {
  breadcrumb: PropTypes.object,
  options: PropTypes.object,
}

export default Breadcrumb
