import { call, put, takeLatest, fork } from 'redux-saga/effects'

import axios from 'utils/axios'
import { getErrorMessage, resolveTasks } from 'utils/api'
import { convertRawPaypalTransaction, convertRawStripeTransaction } from 'types/transactions'
// Local deps
import TransactionsActions, { TransactionsTypes } from './actions'

// Sagas
function * getTransactions ({ companyId }) {
  yield put(TransactionsActions.getCompanyTransactionsLoading())
  try {
    const transactionsConfig = [
      {
        url: `/companies/${companyId}/transactions?sort=created`,
        convertFunction: convertRawPaypalTransaction,
      },
      {
        url: `/companies/${companyId}/stripe_transactions?sort=created`,
        convertFunction: convertRawStripeTransaction,
      },
    ]
    const tasks = []
    for (const config of transactionsConfig) {
      tasks.push(yield fork(axios.get, config.url))
    }
    const allTransactions = yield resolveTasks(tasks)
    yield put(TransactionsActions.getCompanyTransactionsSuccess(
      allTransactions.reduce((allTransactions, transactions, index) => [
        ...allTransactions,
        ...transactions.map(transactionsConfig[index].convertFunction),
      ], [])
        .sort((a, b) => b.created.getTime() - a.created.getTime()),
    ))
  } catch (e) {
    yield put(TransactionsActions.getCompanyTransactionsFailure(getErrorMessage(e)))
  }
}

function * getSuccessfulTransactions ({ startDate, endDate }) {
  yield put(TransactionsActions.getSuccessfulTransactionsLoading())
  try {
    const query = `start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}`
    const url = `/successful_transactions?${query}`
    const { data: { data: usersTransactions } } = yield call(axios.get, url)
    const successfulTransactionDates = Object.keys(usersTransactions).reduce(
      (accum, key) => ({
        ...accum,
        [key]: usersTransactions[key].map(stringDate => new Date(stringDate)),
      }),
      {},
    )
    yield put(TransactionsActions.getSuccessfulTransactionsSuccess(successfulTransactionDates))
  } catch (e) {
    yield put(TransactionsActions.getSuccessfulTransactionsFailure(getErrorMessage(e)))
  }
}

// Watchers
function * getTransactionsWatcher () {
  yield takeLatest(TransactionsTypes.GET_COMPANY_TRANSACTIONS, getTransactions)
}

function * getSuccessfulTransactionsWatcher () {
  yield takeLatest(TransactionsTypes.GET_SUCCESSFUL_TRANSACTIONS, getSuccessfulTransactions)
}

export default function * root () {
  yield fork(getTransactionsWatcher)
  yield fork(getSuccessfulTransactionsWatcher)
}
