import React, { useEffect, useReducer } from 'react'
import classNames from 'classnames'
import { path, mergeAll } from 'ramda'
import { Trans } from 'react-i18next'
import PropTypes from 'prop-types'
import i18n from 'i18n'
// Material UI
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import Paper from '@material-ui/core/Paper'
import TablePagination from '@material-ui/core/TablePagination'
import Typography from '@material-ui/core/Typography'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
// Icons
import SearchIcon from '@material-ui/icons/Search'
import ViewIcon from '@material-ui/icons/ViewWeek'
// Project deps
import CustomFilter from 'components/reusable/CustomFilter'
import CustomPopover from 'components/reusable/CustomPopover'
import LoadingText from 'components/reusable/LoadingText'
import DelayedSearchBox from 'components/reusable/DelayedSearchBox'
import RemoveIconButton from 'components/reusable/RemoveIconButton'
import { stableSort, getSorting } from 'utils/sort'
import { makeUnique } from 'utils/list'
// Local deps
import EnhancedTableHead from './TableHead'
import EnhancedTableRow from './TableRow'
import TotalRow from './TotalRow'
import { escape } from 'utils/regex'

const styles = theme => ({
  ...theme.global,
  noDataContainer: {
    padding: theme.spacing(1),
    textAlign: 'center',
  },
  toolbar: {
    display: 'flex',
    padding: theme.spacing(1),
    // padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(0.5)}px`,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  paper: {
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableRow: {
    '& .editIcon': {
      opacity: 0,
      transition: '.35s ease',
    },
    '&&:hover .editIcon': {
      opacity: 1,
    },
    height: 43,
  },
  tableRowSelected: {
    backgroundColor: '#f2f2f2 !important',
  },
  expanded: {
    transform: 'rotate(90deg)',
  },
  loadingContainer: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchBoxFull: {
    width: 300,
    transition: 'width .3s ease',
  },
  searchBoxShort: {
    width: 40,
  },
  removeUnderline: {
    '&&:before': {
      transition: 'border-bottom-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 100ms',
      borderBottom: '1px solid transparent !important',
    },
  },
})

const findPageForOpenedItem = (items, expandedDefault, perPage, page) => {
  const foundItemIndex = expandedDefault && items.findIndex(item => item.id === expandedDefault)
  if (foundItemIndex < 0) {
    return page
  }
  return Math.floor(foundItemIndex / perPage)
}

const tableReducer = (state, action) => {
  const { type, ...args } = action
  const id = args.id
  switch (type) {
    case 'REMOVE_SELECTED_ROW_ID':
      return {
        ...state,
        selected: state.selected.filter(id_ => id_ !== id),
      }
    case 'ADD_SELECTED_ROW_ID':
      return {
        ...state,
        selected: [...state.selected, id],
      }
    case 'SET_DISPLAY_ALL':
      return {
        ...state,
        display: action.display,
      }
    case 'SET_DISPLAY':
      return {
        ...state,
        display: {
          ...state.display,
          [action.index]: action.checked,
        },
      }
    case 'TOGGLE_DISPLAY_SEARCH':
      return {
        ...state,
        displaySearchField: !state.displaySearchField,
      }
    case 'SET_TRANSFORMED_COLUMNS':
      const { columns, filters } = args
      const transformedColumns = columns.reduce((accum, column, index) => {
        const options = { ...column.options }
        const columnFilterOptions = path(['options', 'filterOptions', 'data'], columns[index]) ||
          path(['options', 'filterOptions'], columns[index]) ||
          []
        const columnFilterList = filters[index]
        return [
          ...accum,
          {
            id: column.id,
            name: column.label,
            options: {
              ...options,
              filterOptions: columnFilterOptions,
              filterList: columnFilterList,
            },
          },
        ]
      }, [])
      return {
        ...state,
        transformedColumns,
      }
    case 'SET_SORT':
      return {
        ...state,
        ...args,
      }
    case 'SET_SEARCH':
      const { searchText } = args
      const transformedSearch = escape(searchText)
      const regexSearch = new RegExp(transformedSearch, 'i')
      return {
        ...state,
        searchText,
        regexSearch,
      }
    case 'SET_FILTERS':
    case 'SET_PAGE':
    case 'SET_ROWS_PER_PAGE':
    case 'SET_SELECTED':
      return mergeAll([state, args])
    default: return state
  }
}

const CustomTable = props => {
  const {
    classes,
    title,
    description,
    options = {},
    expandedRows = [],
    disablePagination = false,
    paperProps = {},
    tableWrapperProps = {},
    columns,
    data,
    noData = i18n.t('customTable.default.empty'),
    loadingLabel = i18n.t('customTable.default.loading'),
    isLoading = false,
    onExpandRow,
    onExpandCellClick,
    onRowClick,
    tableLayout = 'auto',
    total,
    totalPosition = 'end',
    shouldRenderExpandableRow,
    expandableRowRender,
    header,
    scrollable = false,
    height,
    scrollTo,
  } = props
  const {
    rowsPerPage: rowsPerPageFromOptions = 25,
    page: pageFromOptions = 0,
    orderBy: orderByFromOptions,
    order: orderFromOptions = 'desc',

    customToolbar,
    showToolbarAlways = false,
    filter,
    search = false,
    view = false,
    enableRowSelection = false,
    customSelectionToolbar,
    setRowProps = () => ({}),
    tableId = 'table',
  } = options
  const [state, dispatch] = useReducer(tableReducer, {
    sortedData: [],
    order: orderFromOptions,
    orderBy: orderByFromOptions,
    page: pageFromOptions,
    rowsPerPage: rowsPerPageFromOptions,
    rowsPerPageOptions: makeUnique([rowsPerPageFromOptions, 25, 50, 100]).sort((a, b) => a - b),
    selected: [],
    expandedDefault: props.expandedDefault || null,
    display: columns.map(column => {
      const { options = {} } = column
      const { display = true } = options
      return display
    }),
    filters: Array(columns.length).fill([]),
    displaySearchField: true,
    searchText: '',
    regexSearch: '',
    transformedColumns: [],
  })
  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    rowsPerPageOptions,
    selected,
    expandedDefault,
    display,
    filters,
    displaySearchField,
    searchText,
    // sortedData,
    transformedColumns,
    regexSearch,
  } = state

  const sortedData = orderBy ? stableSort(data, getSorting(order, orderBy)) : data
  /*
  useEffect(() => {
    const itemsThatStillInData = selected.filter(item => Boolean(findById(item, data)))
    if (!equals(prevState.selected, itemsThatStillInData)) {
      this.setState({ selected: itemsThatStillInData })
    }
  })
  */

  const waitUntilReadyToScroll = scrollTo => {
    setTimeout(() => {
      const element = document.getElementById(`${tableId}-row-${scrollTo}`)
      if (element) {
        element.scrollIntoView()
      } else {
        waitUntilReadyToScroll(scrollTo)
      }
    }, 250)
  }
  useEffect(() => {
    if (columns.length > 0 || filters.length > 0) {
      dispatch({ type: 'SET_TRANSFORMED_COLUMNS', columns, filters })
    }
  }, [columns.length, filters.length])
  useEffect(() => {
    if (data.length > 0) {
      dispatch({ type: 'SET_SORT', order, orderBy, data, expandedDefault, page: findPageForOpenedItem(sortedData, expandedDefault, rowsPerPage, page) })
    }
  }, [expandedDefault])
  useEffect(() => {
    if (scrollTo) {
      waitUntilReadyToScroll(scrollTo.toString())
    }
  }, [scrollTo])
  useEffect(() => {
    dispatch({ type: 'SET_DISPLAY_ALL',
      display: columns.map(column => {
        const { options = {} } = column
        const { display = true } = options
        return display
      }) })
  }, [columns.length])
  const handleRequestSort = (event, property) => {
    const orderBy = property
    let order = 'desc'

    if (state.orderBy === property && state.order === 'desc') {
      order = 'asc'
    }
    dispatch({ type: 'SET_SORT', order, orderBy, data, expandedDefault })
  }

  const handleChangePage = (event, page) => dispatch({ type: 'SET_PAGE', page })
  const handleChangeRowsPerPage = event => dispatch({ type: 'SET_ROWS_PER_PAGE', rowsPerPage: event.target.value })

  const handleChangeCheck = id => event => {
    if (event.target.checked) {
      dispatch({ type: 'ADD_SELECTED_ROW_ID', id })
    } else {
      dispatch({ type: 'REMOVE_SELECTED_ROW_ID', id })
    }
  }

  const toggleDisplaySearchField = () => dispatch({ type: 'TOGGLE_DISPLAY_SEARCH' })
  const onFilterChange = filters => dispatch({ type: 'SET_FILTERS', filters })
  const handleSearchChange = value => dispatch({ type: 'SET_SEARCH', searchText: value })
  const handleClearSearch = e => dispatch({ type: 'SET_SEARCH', searchText: '' })
  const handleCheckAll = data => () => dispatch({ type: 'SET_SELECTED', selected: data.map(({ id }) => id) })
  const handleUnCheckAll = () => dispatch({ type: 'SET_SELECTED', selected: [] })
  const isSelectedRow = id => expandedRows.indexOf(id) >= 0
  const isCheckedRow = id => selected.indexOf(id) >= 0
  const handleChangeDisplay = index => event => {
    dispatch({ type: 'SET_DISPLAY', index, checked: event.target.checked })
  }
  const onExpandCellClickAction = id => e => {
    e.stopPropagation()
    e.preventDefault()
    if (typeof onExpandCellClick === 'function') {
      onExpandCellClick(id)(e)
    }
  }

  const isOnExpandCellClickFunctionExist = typeof onExpandCellClick === 'function'
  const shouldFilterByFilters = !filters.every(filter => typeof filter === 'object' && filter.length <= 0)
  const shouldFilterBySearch = Boolean(displaySearchField && searchText)
  const columnsToSearchBy = columns.filter(({ search = true }) => search)
  const filteredData = (shouldFilterBySearch || shouldFilterByFilters)
    ? sortedData
      .filter(item => {
        let keep = true
        if (shouldFilterBySearch) {
          keep = Boolean(columnsToSearchBy.find(column => (item[column.id] || {}).toString().match(regexSearch)))
          if (!keep) return false
        }
        if (shouldFilterByFilters) {
          keep = transformedColumns.some((column, index) => {
            const { id: columnId, options: { filter = false } } = column
            const filterList = filters[index]
            const itemField = item[columnId]
            return !filter
              ? true
              : Array.isArray(filterList)
                ? filterList.some(filterItem => itemField === filterItem)
                : itemField === filterList
          })
          if (!keep) return false
        }
        return keep
      })
    : sortedData
  const finalData = (disablePagination
    ? filteredData
    : filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  )
  const noItems = finalData.length <= 0
  return (
    (noData && data.length <= 0 && !isLoading && !showToolbarAlways)
      ? <Paper className={classes.noDataContainer}>{noData}</Paper>
      : <Paper className={classes.paper} {...paperProps}>
        { (search || description || title || filter || typeof customToolbar === 'function') ? selected.length <= 0 ? (
          <Grid container className={classes.toolbar}>
            {title && <Typography variant='h6' style={{ flex: 1 }}>{title}</Typography>}
            {description && <Typography style={{ flex: 1 }}>{description}</Typography>}
            { search && (
              <DelayedSearchBox
                value={searchText}
                onChange={handleSearchChange}
                autoFocus
                onClick={e => {}}
                onKeyDown={e => {
                  if (e.keyCode === 27) {
                    toggleDisplaySearchField()
                  }
                }}
                InputProps={{
                  className: classNames(classes.searchBoxFull, !displaySearchField && classes.searchBoxShort),
                  classes: {
                    underline: !displaySearchField && classes.removeUnderline,
                  },
                  // disableUnderline: !displaySearchField,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <IconButton onClick={toggleDisplaySearchField}>
                        <SearchIcon/>
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: displaySearchField && (
                    <InputAdornment position='end'>
                      <RemoveIconButton onClick={handleClearSearch}/>
                    </InputAdornment>
                  ),
                }}
              />
            )
            }
            { view && (
              <CustomPopover component={
                <Tooltip title={<Trans i18nKey='customTable.show.tooltip' />}>
                  <IconButton>
                    <ViewIcon/>
                  </IconButton>
                </Tooltip>
              }>
                <Paper style={{ display: 'flex', flexDirection: 'column', padding: 16 }}>
                  <Typography><Trans i18nKey='customTable.show.title' /></Typography>
                  {
                    columns.map((column, index) => {
                      const { options } = column
                      const { view = true } = options
                      return view && (
                        <FormControlLabel
                          key={`checkbox-${index}`}
                          control={
                            <Checkbox
                              checked={display[index]}
                              onChange={handleChangeDisplay(index)}
                            />
                          }
                          label={column.label || column.id || i18n.t('customTable.emptyColumn')}
                        />
                      )
                    })
                  }
                </Paper>
              </CustomPopover>
            )
            }
            { filter && (
              <CustomFilter
                columns={transformedColumns}
                filters={filters}
                onFilterChange={onFilterChange}
              />
            )}
            { typeof customToolbar === 'function' && customToolbar() }
          </Grid>
        )
          : <Paper style={{ display: 'flex', alignItems: 'center', padding: 8 }}>
            <Trans i18nKey='customTable.selected' values={{ selected: selected.length }}/>
            <div style={{ marginLeft: 'auto' }}>
              { typeof customSelectionToolbar === 'function' && customSelectionToolbar(selected) }
            </div>
          </Paper>
          : null
        }
        { isLoading
          ? <div className={classes.loadingContainer}>
            <LoadingText title={loadingLabel}/>
          </div>
          : noItems
            ? <Typography style={{ padding: 8 }} align='center'>
              <Trans i18nKey='customTable.noItemsFound' values={{ text: noData }}/>
            </Typography>
            : <div className={classes.tableWrapper} {...tableWrapperProps}>
              <TableContainer style={{ maxHeight: height || '100%' }}>
                <Table
                  aria-labelledby='tableTitle'
                  stickyHeader={scrollable}
                  style={{ tableLayout }}
                  size='small'>
                  { noItems
                    ? null
                    : <EnhancedTableHead
                      enableRowSelection={enableRowSelection}
                      numChecked={selected.length}
                      onCheckAll={handleCheckAll(filteredData)}
                      onUnCheckAll={handleUnCheckAll}
                      key='table-head'
                      numSelected={expandedRows.length}
                      order={order}
                      orderBy={orderBy}
                      // onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={data.length}
                      // shouldRenderExpandableRow={this.props.shouldRenderExpandableRow}
                      expandableRowRender={expandableRowRender}
                      display={display}
                      cols={columns}
                      header={header}
                    />
                  }
                  <TableBody>
                    {total && totalPosition === 'start' && (
                      <TotalRow columns={columns} total={total} display={display}/>
                    )}
                    {
                      finalData.map((row, index) => {
                        const { id, isDeleting } = row
                        const shouldRenderExpandRow = typeof shouldRenderExpandableRow === 'function'
                          ? shouldRenderExpandableRow(row)
                          : expandableRowRender
                        const isSelected = shouldRenderExpandRow ? isSelectedRow(id) : false
                        const isChecked = isCheckedRow(id)
                        const rowProps = typeof setRowProps === 'function' ? (setRowProps(row) || {}) : {}
                        return (
                          isDeleting
                            ? <TableRow key={id} {...rowProps}>
                              <TableCell colSpan={columns.length}>
                                <LoadingText title={<Trans i18nKey='customTable.deleting'/>}/>
                              </TableCell>
                            </TableRow>
                            : <EnhancedTableRow
                              index={index}
                              key={id}
                              rowId={`${tableId}-row-${id}`}
                              onChangeCheck={handleChangeCheck}
                              onExpandCellClick={isOnExpandCellClickFunctionExist ? onExpandCellClickAction : () => {}}
                              onClick={
                                typeof onExpandCellClick === 'function'
                                  ? typeof onRowClick === 'function'
                                    ? e => onRowClick(row, e)
                                    : () => {}
                                  : shouldRenderExpandRow
                                    ? typeof onExpandRow === 'function'
                                      ? onExpandRow(id, row)
                                      : () => {}
                                    : typeof onRowClick === 'function'
                                      ? e => onRowClick(row, e)
                                      : () => {}
                              }
                              options={options}
                              enableRowSelection={enableRowSelection}
                              display={display}
                              columns={columns}
                              rowData={row}
                              checked={isChecked}
                              selected={isSelected}
                              shouldRenderExpandableRow={shouldRenderExpandRow}
                              expandableRowRender={expandableRowRender}
                              scrollExpandableRowOnMount={expandedDefault && (id === expandedDefault)}
                              classes={classes}
                            />
                        )
                      })
                    }
                    {total && totalPosition === 'end' && (
                      <TotalRow columns={columns} total={total} display={display}/>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
        }
        { (!disablePagination && !isLoading && data.length > 0) && (
          <TablePagination
            style={{ marginTop: 'auto' }}
            rowsPerPageOptions={rowsPerPageOptions}
            component='div'
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{ 'aria-label': i18n.t('customTable.previousPage') }}
            nextIconButtonProps={{ 'aria-label': i18n.t('customTable.nextPage') }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )
        }
      </Paper>
  )
}

CustomTable.propTypes = {
  noData: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  height: PropTypes.number,
  scrollTo: PropTypes.string,
  tableLayout: PropTypes.string,
  totalPosition: PropTypes.string,
  isLoading: PropTypes.bool,
  scrollable: PropTypes.bool,
  disablePagination: PropTypes.bool,
  total: PropTypes.object,
  classes: PropTypes.object,
  options: PropTypes.object,
  paperProps: PropTypes.object,
  tableWrapperProps: PropTypes.object,
  expandedDefault: PropTypes.object,
  data: PropTypes.array,
  header: PropTypes.array,
  columns: PropTypes.array,
  expandedRows: PropTypes.array,
  title: PropTypes.node,
  description: PropTypes.node,
  loadingLabel: PropTypes.node,
  onRowClick: PropTypes.func,
  onExpandRow: PropTypes.func,
  onExpandCellClick: PropTypes.func,
  expandableRowRender: PropTypes.func,
  shouldRenderExpandableRow: PropTypes.func,
}

CustomTable.defaultProps = {
  expandedRows: [],
}

export default withStyles(styles)(CustomTable)
