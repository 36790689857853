import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  getRMA: ['search', 'pageSize', 'page', 'orderBy', 'order', 'filterString'],
  getRMALoading: [],
  getRMASuccess: ['rmas', 'pagination'],
  getRMAFailure: [],

  getAllRMA: null,
  getAllRMALoading: [],
  getAllRMASuccess: ['rmas'],
  getAllRMAFailure: [],

  selectRMA: ['rmaId'],
  selectRMALoading: [],
  selectRMASuccess: ['rma'],
  selectRMAFailure: [],

  deselectRMA: [],
})

export const RMATypes = Types
export default Creators
