import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// Material UI
import { DialogActions, DialogContent, List, MenuItem, Checkbox, Button } from '@material-ui/core'
// Project deps
import Tag from 'components/reusable/Tag'
import ClosableDialog from 'components/reusable/StyledClosableDialog'
import DialogTitle from 'components/reusable/StyledDialogTitle'
import LoadingButton from 'components/reusable/LoadingButton'
import { getTagColor } from 'utils/tags'

const TagsEditDialog = props => {
  const { open, onClose, tags = [], allTags, onSubmit, isLoading } = props
  const [editState, setEditState] = useState({ search: '', tags: [] })
  const { tags: selectedTags } = editState

  useEffect(() => {
    if (open) {
      setEditState({ search: [], tags })
    }
  }, [open])
  const onRemoveTag = tagName => e => {
    e.stopPropagation()
    setEditState(prevState => ({
      ...prevState,
      tags: prevState.tags.filter(tag => tag !== tagName),
    }))
  }
  const onAddTag = tagName => e => {
    e.stopPropagation()
    setEditState(prevState => ({
      ...prevState,
      tags: [
        tagName,
        ...prevState.tags,
      ],
    }))
  }
  const onSubmitEditTags = e => {
    e.stopPropagation()
    onSubmit(editState.tags)
  }
  return (
    <ClosableDialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle>Edit tags</DialogTitle>
      <DialogContent>
        <div style={{ minHeight: 22 }}>
          {selectedTags.length > 0
            ? selectedTags.map(tagName => (
              <Tag key={tagName} name={tagName} onDelete={onRemoveTag(tagName)} style={{
                marginRight: 4,
              }}/>
            ))
            : 'No tags selected'
          }
        </div>
        <List style={{ maxHeight: 200, overflowY: 'auto' }}>
          {allTags.map(option => {
            const isSelected = selectedTags.includes(option)
            return (
              <MenuItem
                key={option}
                style={{ paddingRight: 0, paddingLeft: 0 }}
                value={option}
                onClick={e => {
                  if (isSelected) {
                    onRemoveTag(option)(e)
                  } else {
                    onAddTag(option)(e)
                  }
                }}>
                <Checkbox checked={isSelected} />
                <span style={{
                  marginRight: 4,
                  width: 10,
                  height: 10,
                  borderRadius: 4,
                  background: getTagColor(option),
                }}
                />
                {option}
              </MenuItem>
            )
          },
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton isLoading={isLoading} color='primary' onClick={onSubmitEditTags}>Edit tags</LoadingButton>
      </DialogActions>
    </ClosableDialog>
  )
}

TagsEditDialog.propTypes = {
  open: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  tags: PropTypes.array,
  allTags: PropTypes.array,
}

export default TagsEditDialog
