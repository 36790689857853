import React from 'react'
import i18next from 'i18n'
import Badge from '@material-ui/core/Badge'
import { isBayerEnvironmentCompany } from 'utils/env'
import { routeCompanyProjects, routeUserProjects } from 'utils/routing'
import { Route } from './route'
import { getCompanyId } from 'utils/company'
import { getAllowedSoftwareUpdates } from 'utils/userUpdates'

export const UserTabs = {
  GENERAL: 'general',
  SUBSCRIPTION: 'subscription',
  SYSTEMS: 'systems',
}

export const CompanyTabs = {
  GENERAL: 'general',
  SUBSCRIPTION: 'subscription',
  USERS: 'users',
  SYSTEMS: 'systems',
  CALIBRATIONS: 'calibrations',
  PROJECTS: 'projects',
  ROVERS: 'rovers',
  UPDATES: 'updates',
  SUB_COMPANIES: 'sub_companies',
}

export const PaymentsTabs = {
  SUBSCRIPTION: 'subscription',
  TRANSACTIONS: 'transactions',
}

export const tabMap = {
  [Route.USERS]: [
    {
      label: i18next.t('tabs.users.general'),
      tabName: UserTabs.GENERAL,
      path: id => `/users/${id}`,
    },
    {
      label: i18next.t('tabs.users.projects'),
      tabName: CompanyTabs.PROJECTS,
      path: (id, props) => {
        const { activeUser } = props
        const company = activeUser && activeUser.company
        return routeUserProjects(id, isBayerEnvironmentCompany(company))
      },
      redirect: true,
    },
    {
      label: i18next.t('tabs.users.subscription'),
      tabName: UserTabs.SUBSCRIPTION,
      path: id => `/users/${id}/${UserTabs.SUBSCRIPTION}`,
      display: ({ isActiveUserCustomer, isUserCustomer }) => !isUserCustomer && !isActiveUserCustomer,
    },
    {
      label: i18next.t('tabs.users.systems'),
      tabName: UserTabs.SYSTEMS,
      path: id => `/users/${id}/${UserTabs.SYSTEMS}`,
      display: ({ isActiveUserCustomer }) => !isActiveUserCustomer,
    },
    {
      label: i18next.t('tabs.companies.calibrations'),
      tabName: CompanyTabs.CALIBRATIONS,
      path: id => `/users/${id}/${CompanyTabs.CALIBRATIONS}`,
    },
    {
      label: i18next.t('tabs.companies.rovers'),
      tabName: CompanyTabs.ROVERS,
      path: id => `/users/${id}/${CompanyTabs.ROVERS}`,
    },
    {
      label: ({ releases = [], rovers = [], loggedUser, activeCompanyId }) => {
        if (getCompanyId(loggedUser) === activeCompanyId) {
          const allowedReleases = getAllowedSoftwareUpdates(releases, rovers)
          return <Badge badgeContent={allowedReleases.reduce((all, release) => all + release.releases.length, 0)} color="primary">{i18next.t('tabs.companies.updates')}</Badge>
        }
        return i18next.t('tabs.companies.updates')
      },
      tabName: CompanyTabs.UPDATES,
      path: id => `/users/${id}/${CompanyTabs.UPDATES}`,
    },
  ],
  [Route.PAYMENTS]: [
    {
      label: i18next.t('tabs.payments.subscription'),
      tabName: PaymentsTabs.SUBSCRIPTION,
      path: `/payments/${PaymentsTabs.SUBSCRIPTION}`,
    },
    {
      label: i18next.t('tabs.payments.transactions'),
      tabName: PaymentsTabs.TRANSACTIONS,
      path: `/payments/${PaymentsTabs.TRANSACTIONS}`,
    },
  ],
  [Route.COMPANY]: [
    {
      label: i18next.t('tabs.companies.users'),
      tabName: CompanyTabs.USERS,
      path: id => `/company/${id}/${CompanyTabs.USERS}`,
      display: ({ isUserCustomer }) => !isUserCustomer,
    },
    {
      label: i18next.t('tabs.companies.general'),
      tabName: CompanyTabs.GENERAL,
      path: id => `/company/${id}/${CompanyTabs.GENERAL}`,
    },
    {
      label: i18next.t('tabs.companies.projects'),
      tabName: CompanyTabs.PROJECTS,
      path: (id, props) => {
        const { activeCompany } = props
        return routeCompanyProjects(id, isBayerEnvironmentCompany(activeCompany))
      },
      redirect: true,
    },
    {
      label: i18next.t('tabs.companies.subscription'),
      tabName: CompanyTabs.SUBSCRIPTION,
      path: id => `/company/${id}/${CompanyTabs.SUBSCRIPTION}`,
      display: ({ isUserCustomer }) => !isUserCustomer,
    },
    {
      label: i18next.t('tabs.companies.systems'),
      tabName: CompanyTabs.SYSTEMS,
      path: id => `/company/${id}/${CompanyTabs.SYSTEMS}`,
      display: ({ isUserCustomer }) => !isUserCustomer,
    },
    {
      label: i18next.t('tabs.companies.calibrations'),
      tabName: CompanyTabs.CALIBRATIONS,
      path: id => `/company/${id}/${CompanyTabs.CALIBRATIONS}`,
    },
    {
      label: i18next.t('tabs.companies.rovers'),
      tabName: CompanyTabs.ROVERS,
      path: id => `/company/${id}/${CompanyTabs.ROVERS}`,
    },
    {
      label: i18next.t('tabs.companies.sub_companies'),
      tabName: CompanyTabs.SUB_COMPANIES,
      display: props => props.childCompanies && props.childCompanies.length > 0,
      path: id => `/company/${id}/${CompanyTabs.SUB_COMPANIES}`,
    },
    {
      label: ({ releases = [], rovers = [], loggedUser, activeCompanyId }) => {
        if (getCompanyId(loggedUser) === activeCompanyId) {
          const allowedReleases = getAllowedSoftwareUpdates(releases, rovers)
          return <Badge badgeContent={allowedReleases.reduce((all, release) => all + release.releases.length, 0)} color="primary">{i18next.t('tabs.companies.updates')}</Badge>
        }
        return i18next.t('tabs.companies.updates')
      },
      tabName: CompanyTabs.UPDATES,
      path: id => `/company/${id}/${CompanyTabs.UPDATES}`,
    },
  ],
}

export const getTabPath = (path, param, props) => {
  return typeof path === 'function' ? path(param, props) : path
}

export function getTabName (match, basicUrl) {
  const tabs = tabMap[basicUrl]
  const index = tabs && tabs.findIndex(tab => tab.tabName === match.params.tab)
  const tabIndex = (!index || index < 0) ? 0 : index
  return tabs[tabIndex].tabName || ''
}

export function getTabValue (match, basicUrl, tabName, extraProps) {
  const tabs = tabMap[basicUrl]
  const index = tabs && tabs.findIndex(tab => tab.tabName === tabName)
  const tabIndex = (!index || index < 0) ? 0 : index
  return tabIndex
}
