import { createReducer } from 'reduxsauce'
// Project deps
import { mapById, removeById } from 'utils/list'
// Local deps
import { INITIAL_STATE } from './initialState'
import { CalibrationTypes } from './actions'

export const getCalibrationsLoading = (state, { companyId }) => state.merge({
  calibrationsIsLoading: {
    ...state.get('calibrationsIsLoading'),
    [companyId]: true,
  },
})
export const getCalibrationsSuccess = (state, { companyId, calibrations }) => {
  return state.merge({
    calibrations: {
      ...state.get('calibrations'),
      [companyId]: calibrations,
    },
    calibrationsIsLoading: {
      ...state.get('calibrationsIsLoading'),
      [companyId]: false,
    },
  })
}
export const getCalibrationsFailure = (state, { companyId }) => state.merge({
  calibrationsIsLoading: {
    ...state.get('calibrationsIsLoading'),
    [companyId]: false,
  },
})

export const updateCalibrationLoading = state => state.merge({})
export const updateCalibrationSuccess = (state, { companyId, calibrationId, calibration }) => {
  const calibrations = state.get('calibrations')
  return state.merge({
    calibrations: {
      ...calibrations,
      [companyId]: mapById(calibrationId, calibrations[companyId] || [], c => ({ ...c, ...calibration })),
    },
  })
}
export const updateCalibrationFailure = state => state.merge({})

export const deleteCalibrationLoading = state => state.merge({})
export const deleteCalibrationSuccess = (state, { companyId, calibrationId }) => {
  const calibrations = state.get('calibrations')
  return state.merge({
    calibrations: {
      ...calibrations,
      [companyId]: removeById(calibrationId, calibrations[companyId] || []),
    },
  })
}
export const deleteCalibrationFailure = state => state.merge({})

const addCalibrationLoading = state => state.merge({ addCalibrationLoading: true })
const addCalibrationSuccess = (state, { companyId, calibration }) => {
  const calibrations = state.get('calibrations')
  return state.merge({
    calibrations: {
      ...calibrations,
      [companyId]: [calibration, ...(calibrations[companyId] || [])],
    },
    addCalibrationLoading: false,
  })
}
const addCalibrationFailure = state => state.merge({ addCalibrationLoading: false })

export const reducer = createReducer(INITIAL_STATE, {
  [CalibrationTypes.GET_COMPANY_CALIBRATIONS_LOADING]: getCalibrationsLoading,
  [CalibrationTypes.GET_COMPANY_CALIBRATIONS_SUCCESS]: getCalibrationsSuccess,
  [CalibrationTypes.GET_COMPANY_CALIBRATIONS_FAILURE]: getCalibrationsFailure,

  [CalibrationTypes.UPDATE_CALIBRATION_LOADING]: updateCalibrationLoading,
  [CalibrationTypes.UPDATE_CALIBRATION_SUCCESS]: updateCalibrationSuccess,
  [CalibrationTypes.UPDATE_CALIBRATION_FAILURE]: updateCalibrationFailure,

  [CalibrationTypes.DELETE_CALIBRATION_LOADING]: deleteCalibrationLoading,
  [CalibrationTypes.DELETE_CALIBRATION_SUCCESS]: deleteCalibrationSuccess,
  [CalibrationTypes.DELETE_CALIBRATION_FAILURE]: deleteCalibrationFailure,

  [CalibrationTypes.ADD_CALIBRATION_LOADING]: addCalibrationLoading,
  [CalibrationTypes.ADD_CALIBRATION_SUCCESS]: addCalibrationSuccess,
  [CalibrationTypes.ADD_CALIBRATION_FAILURE]: addCalibrationFailure,
})
