import { Map } from 'immutable'

export const INITIAL_STATE = Map({
  getRefundsIsLoading: false,
  refunds: [],

  allCustomers: [],
  activeCustomers: [],
  canceledCustomers: [],
  customersStatisticIsLoading: false,
  subscriptionsStatisticIsLoading: false,

  downloadCSVSubscriptionsIsLoading: false,

  sessions: {},
  sessionsPreferences: {
    ignoreTime: 120,
    countUnknown: false,
    splitBy: 'week',
  },
  sessionsIsLoading: {},
})
