import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  addKey: ['companyId', 'subscriptionId', 'data', 'onSuccess'],
  addKeyLoading: null,
  addKeySuccess: ['companyId', 'subscriptionId', 'key'],
  addKeyFailure: ['errorMessage'],

  editKey: ['subscriptionId', 'keyId', 'data'],
  editKeyLoading: null,
  editKeySuccess: ['subscriptionId', 'keyId', 'data', 'licenseKey'],
  editKeyFailure: null,

  downloadRoverLicense: ['subscriptionId', 'keyId'],
  downloadRoverLicenseSuccess: ['subscriptionId', 'keyId', 'data'],
})

export const KeyTypes = Types
export default Creators
