import React from 'react'
import PropTypes from 'prop-types'
// Material UI
import Typography from '@material-ui/core/Typography'

const ImageOption = options => {
  const {
    option,
  } = options
  const { src, name, width, height, positionX, positionY } = option
  return (
    <div style={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <Typography style={{ color: 'rgba(0, 0, 0, 0.54)' }}>{name}</Typography>
      <div style={{
        paddingLeft: 16,
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
      }}>
        <div style={{
          backgroundImage: `url(${src})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPositionX: positionX || 'left',
          backgroundPositionY: positionY || 'bottom',
          width: width || '100%',
          height: height || 100,
        }}></div>

      </div>
    </div>

  )
}

ImageOption.propTypes = {
  option: PropTypes.object,
}

export default ImageOption
