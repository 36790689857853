/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import { equals } from 'ramda'
// Material UI
import TextField from '@material-ui/core/TextField'
import { getValue } from 'utils/templatedForm'
import { Button, Grid, IconButton, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Close'
import AddNewIcon from '@material-ui/icons/Add'
// import { getFormFieldErrorMessage } from '../utils'

class RenderMultiStringOption extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      value: this.getValue(props),
      hasChanged: false,
    }
    this.timeout = null
  }

  getValue = props => {
    const rawValue = getValue(props)
    return Array.isArray(rawValue) ? rawValue : []
  }

  onChange = (index, fieldName) => event => {
    const { option } = this.props
    const { idField } = option
    const { value } = event.target
    this.setState(prevState => {
      const newState = { ...prevState }
      newState.value[index][fieldName] = fieldName === idField ? parseInt(value, 10) : value
      newState.hasChanged = true
      return newState
    })
  }
  onDelete = index => e => {
    const {
      option,
      name,
      setValue,
    } = this.props
    const {
      idField,
    } = option
    this.setState(prevState => {
      const newState = {
        ...prevState,
        value: prevState.value.filter((_, i) => i !== index),
      }
      if (idField) {
        newState.value = newState.value.map((val, index) => ({ ...val, [idField]: index }))
      }
      setValue(name, newState.value, option)
      return newState
    })
  }
  onAddNew = index => e => {
    const {
      option,
      name,
      setValue,
    } = this.props
    const {
      options = [],
      idField,
    } = option
    const newValue = options.reduce((all, option) => ({ ...all, [option.id]: option.initialValue }), {})
    if (idField) {
      newValue[idField] = index
    }
    this.setState(prevState => {
      const newState = {
        ...prevState,
        value: [...prevState.value, newValue],
      }
      setValue(name, newState.value, option)
      return newState
    })
  }

  render () {
    const {
      // state,
      // extra,
      option,
      // values,
      name,
      setValue,
      // formTemplate,
    } = this.props
    const {
      // placeholder = '',
      getValue: getValueFromOption,
      onChange,
      onChangeOn = 'blur',
      variant = 'standard',
      options = [],
      idField,
      addText = '',
      min = 0,
    } = option
    // const placeHolder = typeof placeholder === 'function' ? placeholder(state, values, extra, this.props) : placeholder
    const isGetValueFunction = typeof getValueFromOption === 'function'
    const value = isGetValueFunction
      ? getValue(this.props)
      : this.state.value
    // const errorMessage = getFormFieldErrorMessage(value, option, values, state, extra, formTemplate, this.props)
    // const error = Boolean(errorMessage)
    // const label = typeof option.name === 'function' ? option.name(value, values) : option.name
    const onBlur = () => {
      if (this.state.hasChanged && onChangeOn === 'blur') {
        setValue(name, value, option)
        if (typeof onChange === 'function') {
          onChange(name, value, this.props)
        }
      }
    }
    const lastAddedValue = value[value.length - 1]
    return (
      <div>
        {value.length > 0 && <Typography align='center'>{option.name}</Typography>}
        {value.length > 0 && (
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              <Grid container spacing={1} style={{ paddingLeft: 0, paddingBottom: 0 }}>
                {options.map(option => {
                  const { placeholder, label, optional = true, id: fieldId, alwaysShowPlaceholder = false, grid, display = true } = option
                  if (!display) {
                    return null
                  }
                  const fieldLabel = (alwaysShowPlaceholder ? placeholder : label) || fieldId
                  return (
                    <Grid key={fieldId} item xs={grid}>
                      <Typography noWrap>{fieldLabel}</Typography>
                    </Grid>
                  )
                })}
              </Grid>
            </div>
            <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center', width: 40, height: 40 }}>
            </div>
          </div>
        )}
        {value.map((val, index) => {
          return (
            <div key={index} style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>
                <Grid container spacing={1} style={{ paddingLeft: 0, paddingTop: 0 }}>
                  {options.map((option, columnIndex) => {
                    const { editable = true, min, type, placeholder, label, optional = true, id: fieldId, render, grid, display = true } = option
                    if (!display) {
                      return null
                    }
                    const fieldValue = val[fieldId]
                    const error = !optional && !fieldValue.toString()
                    const text = typeof render === 'function' ? render(fieldValue) : fieldValue
                    return (
                      <Grid key={fieldId} item xs={grid}>
                        <TextField
                          disabled={!editable}
                          fullWidth
                          multiline={type === 'textarea'}
                          maxRows={5}
                          type={type}
                          variant={variant}
                          value={text}
                          required={!optional}
                          error={error}
                          // style={{ padding: 8 }}
                          inputProps={{ style: { padding: type === 'textarea' ? 0 : 12 }, min }}
                          InputProps={{ style: { padding: type === 'textarea' ? 12 : 0 } }}
                          onChange={this.onChange(index, fieldId)}
                          onBlur={onBlur}
                          helperText={error ? 'Must be filled' : ''}
                        />
                        {columnIndex === 0 && val.added_by && <Typography noWrap variant='body2'><b>added by: {val.added_by}</b></Typography>}
                      </Grid>
                    )
                  })}
                </Grid>
              </div>
              <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center', width: 40, height: 40 }}>
                {!val.id && (index - min >= 0) && <IconButton onClick={this.onDelete(index)}><DeleteIcon/></IconButton> }
              </div>
            </div>
          )
        })}
        <Button
          variant='contained'
          color='primary'
          fullWidth
          startIcon={<AddNewIcon/>}
          onClick={this.onAddNew(lastAddedValue ? +lastAddedValue[idField] + 1 : 0)}>
          {addText}
        </Button>
      </div>
    )
  }
}

RenderMultiStringOption.propTypes = {
  extra: PropTypes.any,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  state: PropTypes.object,
  values: PropTypes.object,
  option: PropTypes.object,
  formTemplate: PropTypes.object,
  onWheel: PropTypes.func,
  setValue: PropTypes.func,
}

export default RenderMultiStringOption
