import React from 'react'
import PropTypes from 'prop-types'
// Material UI
import { Grid, Select, InputLabel, FormControl, Input, Checkbox, MenuItem } from '@material-ui/core'
// import MenuItem from '../MenuItem'
// Icons
// import FilterIcon from '@material-ui/icons/FilterList'

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: `calc(100vh-250px)`,
    },
  },
}

const FilterRender = props => {
  const {
    classes,
    filters,
    column: { name, options },
    index,
    actions: {
      handleChange,
    },
  } = props
  const list = filters[index]
  const key = `filter-${name}-index`
  if (options.filter) {
    switch (options.filterType) {
      case 'multiselect':
        return (
          <Grid item xs={6} className={classes.item} key={key}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor='select-state'>
                {name}
              </InputLabel>
              <Select
                multiple
                value={list}
                onChange={handleChange(index)}
                input={<Input id={`select-multiple-${name}`} />}
                renderValue={selected => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {
                  options.filterOptions.map((option, index) => {
                    const isObject = typeof option === 'object'
                    const label = isObject ? option.label : option
                    const value = isObject ? option.value : option
                    return (
                      <MenuItem key={`${name}--${value}--${index}`} value={value}>
                        <Checkbox checked={list.indexOf(value) > -1} />
                        {label}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>
          </Grid>
        )
      default:
        return (
          <Grid item xs={6} className={classes.item} key={key}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor={`select-${name}`}>{name}</InputLabel>
              <Select
                value={list}
                onChange={handleChange(index)}
                input={<Input id={`select-${name}`} />}
                MenuProps={MenuProps}
              >
                {options.filterOptions.map((option, index) => {
                  const isObject = typeof option === 'object'
                  const label = isObject ? option.label : option
                  const value = isObject ? option.value : option
                  return (
                    <MenuItem key={`${name}--${value}--${index}`} value={value}>
                      {label.toString()}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
        )
    }
  }
  return null
}

FilterRender.propTypes = {
  index: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  classes: PropTypes.object,
  filters: PropTypes.array,
  column: PropTypes.object,
  actions: PropTypes.object,
}

export default FilterRender
