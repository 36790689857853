import React, { useState } from 'react'
import PropTypes from 'prop-types'
// Material UI
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Checkbox,
  DialogActions,
  Button,
  MenuItem,
  TextField,
} from '@material-ui/core'
// Icons
import CheckAllIcon from '@material-ui/icons/CheckBox'
import UncheckAllIcon from '@material-ui/icons/IndeterminateCheckBox'
// Project deps
import VirtualizedList from 'components/reusable/VirtualizedList'
import StyledChip from 'components/reusable/Chip'
import { findById } from 'utils/list'
import { escape } from 'utils/regex'

const Filter = props => {
  const { open, options, filtered, onClose, onSubmit, id, type = 'default' } = props
  const [filters, setFilters] = useState(filtered)
  const [search, setSearch] = useState('')

  const onToggleFilterOption = option => e => {
    setFilters(prevState => {
      const options = prevState
      const isSelected = findById(option.id, options)
      if (isSelected) {
        return options.filter(option_ => option_.id !== option.id)
      } else {
        return [...options, option]
      }
    })
  }
  const onDelete = id => e => {
    setFilters(prevState => prevState.filter(prevState => prevState.id !== id))
  }
  const onChangeSearch = e => {
    const { value } = e.target
    setSearch(value)
  }

  const onSubmitClick = () => {
    onSubmit(filters)
  }

  const onCheckAll = () => {
    setFilters(options)
  }
  const onUncheckAll = () => {
    setFilters([])
  }

  const transformedSearch = escape(search)
  const regex = new RegExp(transformedSearch, 'i')

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Filter by {id}</DialogTitle>
      <DialogContent>
        {type === 'search' &&
          <TextField
            value={search}
            label='Search'
            onChange={onChangeSearch}
            fullWidth
            variant='outlined'
            type='search'
          />
        }
        {type === 'search' && filters.length > 0 && (
          <div>
            {filters.map(option => (
              <StyledChip
                style={{ marginLeft: 4, marginTop: 4 }}
                onDelete={onDelete(option.id)}
                label={(option.label || '').slice(0, 25)}
                key={option.id}
              />
            ))}
          </div>
        )}
        <div style={{ height: 200, overflow: 'auto', minWidth: 420 }}>
          <VirtualizedList
            rowHeight={40}
            autoSizeHeight={false}
            listHeight={200}
            list={search ? options.filter(option => option.label.toString().match(regex)) : options}
            renderItem={(option, options) => {
              const isSelected = findById(option.id, filters)
              return (
                <MenuItem
                  key={options.key}
                  style={{ ...options.style, paddingLeft: 0, paddingRight: 0 }}
                  onClick={onToggleFilterOption(option)}>
                  <Checkbox checked={Boolean(isSelected)}/>
                  {option.label}
                </MenuItem>
              )
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <div style={{ marginRight: 'auto' }}>
          <Button disabled={filters.length === options.length} onClick={onCheckAll} startIcon={<CheckAllIcon/>}>Check all</Button>
          <Button disabled={filters.length === 0} onClick={onUncheckAll} startIcon={<UncheckAllIcon/>}>Uncheck all</Button>
        </div>
        <div>
          <Button onClick={onClose}>Cancel</Button>
          <Button color='primary' onClick={onSubmitClick}>Apply</Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

Filter.propTypes = {
  open: PropTypes.bool,
  id: PropTypes.string,
  type: PropTypes.oneOf(['default', 'search']),
  options: PropTypes.array,
  filtered: PropTypes.array,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default Filter
