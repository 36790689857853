import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import Draggable from 'react-draggable'

function DraggablePaper (props) {
  const { id = 'draggable-dialog-title' } = props
  return (
    <Draggable
      handle={`#${id}`}
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}

DraggablePaper.propTypes = {
  id: PropTypes.string,
}

export default DraggablePaper
