import React from 'react'
import _ from 'lodash'
import { omit } from 'ramda'
import { PropTypes } from 'prop-types'
import { withRouter } from 'react-router-dom'
// Material UI
import { withStyles } from '@material-ui/core/styles'
import {
  ClickAwayListener,
  Popper,
  Paper,
  AppBar,
  Tabs,
  Tab,
  Typography,
} from '@material-ui/core'
// Project deps
import {
  routeCompanySubscriptionLicenseKey,
  routeCompanySubscriptionGrant,
  routeCompany,
  routeUser,
  routeCompanySubscription,
  routeRovers,
  routeRoverEvent,
} from 'utils/routing'
import LoadingText from 'components/reusable/LoadingText'
// Local deps
import SearchBlock from './SearchBlock'
import moment from 'moment'
import config from 'config'

function createMarkup (snippet) {
  return { __html: snippet }
}

const styles = theme => ({
  scrollParent: {
    maxHeight: 'calc(100vh - 250px)',
    overflowX: 'auto',
    overflowY: 'auto',
    maxWidth: 900,
    minWidth: 700,
    textOverflow: 'ellipsis',
  },
  loader: {
    marginLeft: theme.spacing(1),
  },
  lastMenuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'default',
    height: '48px',
    padding: 0,
    '&&:hover': {
      background: 'inherit',
    },
  },
  appBar: {
    minWidth: 150,
    maxWidth: 150,
    background: '#eee',
    color: '#000',
    flex: 1,
  },
  tabsIndicator: {
    background: theme.palette.primary.main,
  },
  tab: {
    '&&:hover': {
      color: '#000',
    },
  },
  tabDisabled: {
    opacity: '0.25 !important',
  },
})

const typesMap = {
  'user': {
    title: 'Users',
    getUrl: id => routeUser(id),
    itemRenderer: {
      primary: result => <React.Fragment>
        { result.full_name.snippet &&
          <Typography
            noWrap
            variant='body1'
            dangerouslySetInnerHTML={createMarkup(result.full_name.snippet || '')}
          />
        }
        { result.email.snippet &&
          <Typography
            noWrap
            variant='body1'
            dangerouslySetInnerHTML={createMarkup(result.email.snippet || '')}
          />
        }
      </React.Fragment>,
    },
  },
  'company': {
    title: 'Companies',
    getUrl: id => routeCompany(id),
    itemRenderer: {
      primary: result => <Typography
        noWrap
        variant='body1'
        dangerouslySetInnerHTML={createMarkup(result.name.snippet || '')}
      />,
    },
  },
  'subscription': {
    title: 'Subscriptions',
    getUrl: (id, props) => routeCompanySubscription(undefined, props.subscription_id),
    itemRenderer: {
      primary: result => <React.Fragment>
        { result.subscription_name.snippet &&
          <Typography
            noWrap
            variant='body1'
            dangerouslySetInnerHTML={createMarkup(result.subscription_name.snippet || '')}
          />
        }
        { result.path &&
          <Typography
            noWrap
            variant='body1'
            dangerouslySetInnerHTML={createMarkup(result.path || '')}
          />
        }
      </React.Fragment>,
    },
  },
  'license_key': {
    title: 'License keys',
    fieldsToCheck: [
      { id: 'license_key_id', label: 'ID' },
      { id: 'comment', label: 'Comment' },
      { id: 'hostname', label: 'Hostname' },
      { id: 'machine_id', label: 'Machine ID' },
      { id: 'rover_serial', label: 'Rover serial' },
    ],
    rowHeight: item => {
      const nonEmptyFields = typesMap.license_key.fieldsToCheck.filter(({ id }) => item[id].snippet)
      return nonEmptyFields.length <= 2 ? 50 : (25 * nonEmptyFields.length - 2) + 50
    },
    getUrl: (licenseKeyId, props) => {
      return routeCompanySubscriptionLicenseKey(undefined, props.subscription_id, licenseKeyId)
    },
    itemRenderer: {
      primary: result => <React.Fragment>
        {typesMap.license_key.fieldsToCheck.map(({ id, label }) => (
          result[id].snippet ? <Typography
            key={id}
            noWrap
            variant='body1'
            dangerouslySetInnerHTML={createMarkup(`${label}: ${result[id].snippet}`)}
          /> : null
        ))}
        {result.path &&
          <Typography
            noWrap
            variant='body1'
            dangerouslySetInnerHTML={createMarkup(result.path || '')}
          />
        }
      </React.Fragment>,
    },
  },
  'grant': {
    title: 'Grants',
    getUrl: (id, props) => routeCompanySubscriptionGrant(undefined, props.subscription_id, props.grant_id),
    itemRenderer: {
      primary: result => {
        return (
          <React.Fragment>
            <Typography
              noWrap
              variant='body1'
              dangerouslySetInnerHTML={createMarkup(`${result.type_grant_id.snippet}: ${result.value.snippet}` || '')}
            />
            { result.path &&
              <Typography
                noWrap
                variant='body1'
                dangerouslySetInnerHTML={createMarkup(result.path || '')}
              />
            }
          </React.Fragment>
        )
      },
    },
  },
  'Rover': {
    title: 'Rover',
    getUrl: (id, props) => routeRovers(id),
    itemRenderer: {
      primary: result => {
        return (
          <React.Fragment>
            <Typography
              noWrap
              variant='body1'
              dangerouslySetInnerHTML={createMarkup(`${result.rover_serial.snippet}` || '')}
            />
          </React.Fragment>
        )
      },
    },
  },
  'events': {
    title: 'Rover events',
    getUrl: (id, props) => routeRoverEvent(props.rover_serial.value, props.parent_id, props.event_id),
    itemRenderer: {
      primary: result => {
        const nonMainFields = omit(['rover_serial', 'type', 'id', 'event_id'], result)
        return (
          <React.Fragment>
            <Typography
              noWrap
              variant='body1'
              dangerouslySetInnerHTML={createMarkup(`${result.rover_serial.snippet}: ${result.type}` || '')}
            />
            <Typography
              noWrap
              variant='body2'
              dangerouslySetInnerHTML={
                createMarkup(`${
                  Object
                    .values(nonMainFields)
                    .map(field => field ? field.snippet : null)
                    .filter(Boolean)
                    .sort((a, b) => a.includes('<b>') ? -1 : 1)
                    .join(', ')
                }`)
              }
            />
          </React.Fragment>
        )
      },
    },
  },
  'report': {
    getUrl: (id, props) => routeRoverEvent(props.p_host_name.replace('al', ''), props.id),
    title: 'Reports',
    itemRenderer: {
      primary: item => (
        <Typography
          noWrap
          variant='body1'
          dangerouslySetInnerHTML={createMarkup(`${item.p_host_name} ${item.highlighted ? `(${item.highlighted})` : ''}`)}
        />
      ),
      secondary: item => (
        <Typography
          noWrap
          variant='body1'
          dangerouslySetInnerHTML={createMarkup(`${item.reporter} ${item.ip}`)}
        />
      ),
    },
  },
  'component_property': {
    getUrl: (id, props) => routeRoverEvent(props.p_host_name.replace('al', ''), props.report_id, props.report_component_id),
    title: 'Component property',
    itemRenderer: {
      primary: item => (
        <Typography
          noWrap
          variant='body1'
          dangerouslySetInnerHTML={createMarkup(`${item.p_host_name} ${item.highlighted ? `(${item.highlighted})` : ''}`)}
        />
      ),
      secondary: item => (
        <Typography
          noWrap
          variant='body1'
          dangerouslySetInnerHTML={createMarkup(`<div>${item.report_components_count || 0} components, ${moment(item.report_updated).format(config.DATETIME_FORMAT)}</div>${item.property_type}: ${item.value}`)}
        />
      ),
    },
  },
}

class SearchList extends React.Component {
  scrollOffset = 50
  listenerSet = false
  state = {
    tabsValue: 0,
  }

  componentDidMount () {
    if (this.props.data && this.props.search) {
      const [firstDataItem] = this.props.data
      if (firstDataItem) {
        const firstDataItemType = firstDataItem.type in typesMap ? firstDataItem.type : 'events'
        this.setState({ tabsValue: Object.keys(typesMap).findIndex(key => key === firstDataItemType) })
      }
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.search !== this.props.search && this.listenerSet) {
      this.unsetListener()
      this.setListener()
      this.listenerSet = true
    }
    if (prevProps.isLoading && !this.props.isLoading) {
      const [firstDataItem] = this.props.data
      if (firstDataItem) {
        const firstDataItemType = firstDataItem.type in typesMap ? firstDataItem.type : 'events'
        this.setState({ tabsValue: Object.keys(typesMap).findIndex(key => key === firstDataItemType) })
      }
    }
    if (prevProps.isLoading && !this.props.isLoading && this.scrollParentRef) {
      this.scrollParentRef.scrollTo(0, 0)
    }
  }

  componentWillUnmount () {
    this.unsetListener()
  }

  setListener = () => {
    if (this.scrollParentRef) {
      this.scrollParentRef.addEventListener('scroll', _.throttle(this.onScroll, 275), false)
    }
  }

  unsetListener = () => {
    if (this.scrollParentRef) {
      this.scrollParentRef.removeEventListener('scroll', this.onScroll, false)
    }
  }

  onScroll = () => {
    const { isLoading } = this.props
    const distanceFromTop = this.scrollParentRef.clientHeight + this.scrollParentRef.scrollTop
    const distanceToNextLoad = this.scrollParentRef.scrollHeight - this.scrollOffset
    if (distanceFromTop >= distanceToNextLoad && this.props.data.length && !isLoading) {
      this.props.loadNext(distanceFromTop)
    }
  }

  isScrollExists = () => {
    if (this.scrollParentRef) {
      if (this.scrollParentRef.scrollTop === 0) {
        return false
      }
      return true
    }
    return false
  }

  handleClose = event => {
    this.props.handleClose(event)
  }

  onTabsChange = (event, tabsValue) => {
    event.preventDefault()
    this.setState({ tabsValue })
  }

  render () {
    const { tabsValue } = this.state
    const { data, open, anchorEl, isLoading, hasMore, classes, total, history, location } = this.props
    const dataByType = data.reduce((result, dataItem) => {
      const dataItemType = dataItem.type in typesMap ? dataItem.type : 'events'
      return {
        ...result,
        [dataItemType]: [
          ...(result[dataItemType] || []),
          dataItem,
        ],
      }
    }, Object.keys(typesMap).reduce((accum, key) => ({ ...accum, [key]: [] }), {}))

    const tabs = Object.keys(dataByType).reduce((allTabs, type) => {
      const _data = dataByType[type]
      const typeData = typesMap[type]
      return typeData
        ? [
          ...allTabs,
          {
            ...typeData,
            type: type,
            data: _data,
          }]
        : allTabs
    }, [])
    const selectedTab = tabs[tabsValue]

    if (open && !this.listenerSet) {
      this.setListener()
      this.listenerSet = true
    }
    if (!open && this.listenerSet) {
      this.unsetListener()
      this.listenerSet = false
    }

    return (
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement='bottom-end'
        style={{
          zIndex: 99999,
          marginTop: 2,
          border: `1px solid #3d4151`,
          overflow: isLoading ? 'hidden' : 'auto',
        }}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
          },
        }}
      >
        <ClickAwayListener onClickAway={this.handleClose}>
          <div
            className={classes.scrollParent}
            key='popper--scroll-list'
            ref={ref => {
              this.scrollParentRef = ref
              if (ref) {
                this.setListener()
                this.listenerSet = true
              }
            }}
          >
            <Paper style={{ display: 'flex' }}>
              <AppBar position='static' elevation={1} className={classes.appBar}>
                <Tabs
                  value={tabsValue}
                  onChange={this.onTabsChange}
                  variant='scrollable'
                  orientation='vertical'
                  classes={{
                    indicator: classes.tabsIndicator,
                  }}
                >
                  {
                    tabs.map(tab => (
                      <Tab
                        key={tab.type}
                        label={tab.title}
                        className={classes.tab}
                        classes={{ disabled: classes.tabDisabled }}
                        disabled={(dataByType[tab.type] || []).length <= 0}
                      />
                    ))
                  }
                </Tabs>
              </AppBar>
              { (isLoading || (hasMore && total > 0))
                ? <div className={classes.lastMenuItem}>
                  <LoadingText title='Getting results'/>
                </div>
                : selectedTab && (
                  <SearchBlock
                    history={history}
                    location={location}
                    key={selectedTab.type}
                    data={selectedTab.data}
                    onClose={this.handleClose}
                    {...selectedTab}
                  />
                )
              }
            </Paper>
          </div>
        </ClickAwayListener>
      </Popper>
    )
  }
}

SearchList.propTypes = {
  search: PropTypes.string,
  isLoading: PropTypes.bool,
  open: PropTypes.bool,
  data: PropTypes.array,
  loadNext: PropTypes.func,
  loadAll: PropTypes.func,
  handleClose: PropTypes.func,
  anchorEl: PropTypes.object,
  hasMore: PropTypes.bool,
  classes: PropTypes.object,
  total: PropTypes.number,
  location: PropTypes.object,
  history: PropTypes.object,
}

export default withRouter(withStyles(styles)(SearchList))
