import config from 'config'
import { RoverEventType } from './roverEvents'
import { getUTCDate } from 'utils/dateTime'
import { convertRawAttachment } from './attachments'

export const convertRawRecall = raw => {
  const created = raw.created_at || raw.created
  const updated = raw.updated_at || raw.updated
  const timestamp = raw.timestamp
  const attachments = raw.attachments || []
  return {
    ...raw,
    type: RoverEventType.RECALL,
    created: created && getUTCDate(created).format(config.DATETIME_FORMAT),
    updated: updated && getUTCDate(updated).format(config.DATETIME_FORMAT),
    timestamp: timestamp && getUTCDate(timestamp).format(config.DATETIME_FORMAT),
    attachments: attachments.map(convertRawAttachment),
  }
}

export const convertRawRecallEvent = raw => {
  const created = raw.created_at || raw.created
  const updated = raw.updated_at || raw.updated
  const closed = raw.closed_at || raw.closed
  const timestamp = raw.timestamp
  return {
    ...raw,
    type: RoverEventType.RECALL_EVENT,
    created: created && getUTCDate(created).format(config.DATETIME_FORMAT),
    updated: updated && getUTCDate(updated).format(config.DATETIME_FORMAT),
    timestamp: timestamp && getUTCDate(timestamp).format(config.DATETIME_FORMAT),
    comment: raw.comment || '',
    closed: closed && getUTCDate(closed).format(config.DATETIME_FORMAT),
  }
}
