import React, { useState } from 'react'
import PropTypes from 'prop-types'
// import { DatePicker } from 'material-ui-pickers'
// Material UI
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import ListSubheader from '@material-ui/core/ListSubheader'
// Icons
// Project deps
import MenuItem from 'components/reusable/MenuItem'
// import StyledTooltip from 'components/reusable/StyledTooltip'
import StyledTooltipMenu from 'components/reusable/StyledTooltipMenu'
import { getValue } from 'utils/templatedForm'
import { getFormFieldErrorMessage } from '../utils'

/**
 * Renders a selection input field.
 * @param name The name of the field.
 * @param option The field definition.
 * @return A component for selecting an option.
 */
export const SelectionOption = propOptions => {
  const {
    state,
    extra,
    name,
    option,
    disabled,
    values: formValues,
    setValue,
    formTemplate,
    extraProps,
  } = propOptions
  const {
    onChange: onChangeHandle = () => {},
    tooltip,
    tooltipProps = {},
    editable = true,
    variant = 'standard',
    type = 'default',
    disableOptionLabel = '',
    description,
    icon,
    iconPosition = 'end',
    none = false,
    showNone = true,
    noneLabel = 'N/A',
    multiple = false,
    max = multiple ? 100 : 1,
    additionalContent,
    renderValue,
  } = option
  const amountToSelect = typeof max === 'function' ? max(state, formValues) : max
  const [isFocused, setIsFocused] = useState(false)
  const [isOpened, setIsOpened] = useState(false)
  const value = getValue(propOptions) || (multiple ? [] : '')
  const cantSelectMore = multiple && value.length >= amountToSelect
  const onChange = (event, index) => {
    const value = event.target.value
    if (event.currentTarget && event.currentTarget.getAttribute('name') === 'sub-header') {
      return
    }
    if (typeof onChangeHandle === 'function') onChangeHandle(name, value, propOptions)
    setValue(name, value, option)
  }
  const errorMessage = getFormFieldErrorMessage(value, option, formValues, state, extra, formTemplate, propOptions)
  const { options, disabledOptions, disableText } = option
  // const onFocus = () => onSetContext(name)
  const optionName = typeof option.name === 'function'
    ? option.name(state, formValues, extra, extraProps)
    : option.name
  let createdOptions
  const disabledOptionsList = typeof disabledOptions === 'function' ? disabledOptions(state, formValues, extra, extraProps) : disabledOptions || []
  // It is possible to specify the options for the select field as an array of strings as well as
  // a function returning the array of strings.
  // If the `options` property was a function, it needs to be evaluated...
  if (typeof options === 'function') {
    createdOptions = options(state, formValues, extra, propOptions)
  } else {
    // ... otherwise the property can be used directly.
    createdOptions = options || []
  }

  const disableLabel = typeof disableOptionLabel === 'function' ? disableOptionLabel(state, formValues, extra, extraProps) : disableOptionLabel

  const renderSelectableOptions = (options, category = false) => (
    // Here, `createOptions` contains an array of strings which can be used to render the menu items.
    options.map((selectOption, index) => {
      const isOptionDisabled = type === 'complex'
        ? disabledOptionsList.includes(selectOption.id)
        : disabledOptionsList.includes(selectOption)
      const dLabel = isOptionDisabled
        ? disableLabel[selectOption.id] || (disableText ? typeof disableText === 'function' ? disableText(selectOption, formValues, state) : disableText : '')
        : ''
      const dLabelComponent = isOptionDisabled && dLabel && (
        <MenuItem disabled style={{ padding: 0, opacity: 1, paddingLeft: 24 }}>
          <span style={{ color: 'orange', fontStyle: 'italic', fontSize: `0.875em` }}>{dLabel}</span>
        </MenuItem>
      )
      const descriptionLabelComponent = typeof description === 'function'
        ? <div style={{ fontStyle: 'italic', fontSize: `0.875em`, whiteSpace: 'normal', paddingLeft: 14 }}>{description(selectOption)}</div>
        : null
      const isSelected = type === 'complex'
        ? multiple
          ? value.some(val => selectOption.value === val)
          : selectOption.value === value
        : selectOption === value
      const iconComponent = typeof icon === 'function'
        ? icon(selectOption, isSelected)
        : null
      const styles = descriptionLabelComponent ? {
        flexDirection: 'column',
        alignItems: 'flex-start',
      } : {}
      return type === 'complex'
        ? [
          <MenuItem key={selectOption.id + index} style={{ paddingLeft: category ? 32 : 16, ...styles }} value={selectOption.value} disabled={isOptionDisabled || (cantSelectMore && !isSelected)}>
            <div style={{ display: 'flex', alignItems: 'center' }}>{iconPosition === 'start' ? iconComponent : null}{selectOption.label}{iconPosition === 'end' ? iconComponent : null}</div>
            {descriptionLabelComponent}
          </MenuItem>,
          dLabelComponent,
        ].filter(Boolean)
        : [
          <MenuItem key={selectOption.id + index} style={{ paddingLeft: category ? 32 : 16, ...styles }} value={selectOption} disabled={isOptionDisabled || (cantSelectMore && !isSelected)}>
            <div style={{ display: 'flex', alignItems: 'center' }}>{selectOption} {iconComponent}</div>
            {descriptionLabelComponent}
          </MenuItem>,
          dLabelComponent,
        ].filter(Boolean)
    })
  )
  const allCreatedOptions = Array.isArray(createdOptions)
    ? createdOptions
    : Object.keys(createdOptions).reduce((all, key) => [...all, ...(createdOptions[key] || [])], [])
  const selectLabel = errorMessage || optionName
  const placeholder = optionName ? <span style={{ color: '#a1a1a1' }}>{optionName}</span> : ''
  return (
    <div style={{ ...(additionalContent && { display: 'flex', flexDirection: 'row' }) }}>
      {typeof additionalContent === 'function' ? additionalContent(state, formValues, option) : null}
      <StyledTooltipMenu {...(additionalContent && { open: isFocused && isOpened })} placement='top-start' {...tooltipProps} title={(typeof tooltip === 'function' ? tooltip(state, formValues, option) : tooltip) || ''}>
        {!editable
          ? <TextField variant={variant} value={value || ''} fullWidth label={optionName}/>
          : <FormControl style={{ width: '100%' }} variant={variant}>
            <InputLabel error={errorMessage} id={optionName}>{selectLabel}</InputLabel>
            <Select
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              onClose={() => setIsOpened(false)}
              onOpen={() => setIsOpened(true)}
              labelId={optionName}
              id={optionName + '-select'}
              value={value || (showNone ? noneLabel : multiple ? [] : '')}
              error={Boolean(errorMessage)}
              onChange={editable ? onChange : () => {}}
              {...additionalContent && { open: isOpened, native: false }}
              multiple={multiple}
              renderValue={value => {
                const func = value => {
                  if (typeof renderValue === 'function' && value) {
                    return renderValue(value)
                  }
                  return type === 'complex'
                    ? typeof value !== 'object'
                      ? (allCreatedOptions.find(option => `${option.id}` === `${value}`) || {}).label || placeholder
                      : value
                        ? value.label || value.name || placeholder
                        : placeholder
                    : value || placeholder
                }
                if (multiple) {
                  return <div>{value.map(func)}</div>
                }
                return func(value)
              }}
              disabled={disabled}
              className={additionalContent ? 'remove-bottom-line' : ''}
              inputProps={{ id: optionName }}
              fullWidth
              label={selectLabel}
              style={{ padding: 0, ...(additionalContent && { height: '100%' }) }}
              placeholder={optionName}
            >
              {none &&
                <MenuItem style={{ paddingLeft: 16 }} key='none' value=''>
                  <em>None</em>
                </MenuItem>
              }
              { Array.isArray(createdOptions)
                ? renderSelectableOptions(createdOptions)
                : (
                  Object.keys(createdOptions).map(key => (
                    [
                      <ListSubheader key={key} name='sub-header'>{key}</ListSubheader>,
                      ...renderSelectableOptions(createdOptions[key], true),
                    ]
                  ))
                )
              }
            </Select>
          </FormControl>
        }
      </StyledTooltipMenu>
    </div>
  )
}

SelectionOption.propTypes = {
  state: PropTypes.object,
  extra: PropTypes.any,
  name: PropTypes.string,
  option: PropTypes.object,
  disabled: PropTypes.bool,
  classes: PropTypes.object,
  values: PropTypes.object,
  setValue: PropTypes.func,
  formTemplate: PropTypes.object,
}

export default SelectionOption
