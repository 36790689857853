import { call, put, fork, takeEvery, takeLatest } from 'redux-saga/effects'
import { createQueryString, createQueryStringMaximumEntries, getPaginationFromResponse, getErrorMessage } from 'utils/api'
import axios from 'utils/axios'
// Local deps
import RMAActions, { RMATypes } from './actions'

// Sagas
function * getRMA ({ search = '', pageSize = 50, page = 1, orderBy = 'timestamp', order = 'desc', filterString = '' }) {
  yield put(RMAActions.getRMALoading())
  try {
    //, pagination
    const { data: { data: rma, pagination } } = yield call(
      axios.get,
      `/rmas${createQueryString({ search, pageSize, page, order, orderBy, additional: `parent_only=true${filterString ? `&${filterString}` : ''}` })}`,
    )
    yield put(RMAActions.getRMASuccess(
      rma,
      getPaginationFromResponse(pagination.total, page, pageSize, order, orderBy),
    ))
  } catch (e) {
    yield put(RMAActions.getRMAFailure(getErrorMessage(e)))
  }
}

function * getAllRMA () {
  yield put(RMAActions.getRMALoading())
  try {
    const { data: { data: rma } } = yield call(
      axios.get,
      `/rmas${createQueryStringMaximumEntries({ orderBy: 'timestamp', order: 'desc', additional: `parent_only=true` })}`,
    )
    yield put(RMAActions.getAllRMASuccess(rma))
  } catch (e) {
    yield put(RMAActions.getRMAFailure(getErrorMessage(e)))
  }
}

function * selectRMA ({ rmaId }) {
  yield put(RMAActions.selectRMALoading())
  try {
    const { data: { data: rma } } = yield call(axios.get, `/rmas/${rmaId}`)
    yield put(RMAActions.selectRMASuccess(rma))
  } catch (e) {
    yield put(RMAActions.selectRMAFailure(e.message || 'Error ocurred'))
  }
}

// Watchers
function * getRMAWatcher () {
  yield takeEvery(RMATypes.GET_RMA, getRMA)
}
function * getAllRMAWatcher () {
  yield takeLatest(RMATypes.GET_ALL_RMA, getAllRMA)
}
function * selectRMAWatcher () {
  yield takeEvery(RMATypes.SELECT_RMA, selectRMA)
}

export default function * root () {
  yield fork(getRMAWatcher)
  yield fork(getAllRMAWatcher)
  yield fork(selectRMAWatcher)
}
