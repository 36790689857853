import { Map } from 'immutable'

export const INITIAL_STATE = Map({
  transactions: [],
  getTransactionsIsLoading: false,
  getTransactionsErrorMessage: null,
  successfulTransactions: [],
  getSuccessfulTransactionsIsLoading: false,
  getSuccessfulTransactionsErrorMessage: null,
})
