import { createReducer } from 'reduxsauce'
import _ from 'lodash'
import { path } from 'ramda'
import { RoverTypes } from './actions'
import { INITIAL_STATE } from './initialState'
import { convertRawRover } from 'types/rovers'
import { convertRawRoverEvent, RoverEventType } from 'types/roverEvents'
import { makeUniqueById, mapById, removeById } from 'utils/list'
import { convertRawLog } from 'types/logs'
import { updatePagination } from 'modules/utils'

export const convertRawComponent = (component, index) => {
  const { component_properties: properties = {}, component_type = '' } = component
  return {
    ...component,
    id: 'id' + index,
    searchString: properties.reduce((string, prop) => string + ' ' + prop.property_type + ' ' + prop.value, component_type),
  }
}

export const getRoversLoading = state => {
  return state.merge({ getRoversIsLoading: true })
}
/*, filteredCustomers = [] */
export const getRoversSuccess = (state, { rovers, pagination }) => {
  return state.merge({
    getRoversIsLoading: false,
    rovers: rovers.map(convertRawRover),
    roversPagination: pagination,
    roversMap: new Map(rovers.map(rover => [rover.serial, true])),
    // filteredCustomers: filteredCustomers.map(convertRawCustomer),
  })
}
export const getRoversFailure = state => {
  return state.merge({ getRoversIsLoading: false })
}

export const getAllRoversLoading = state => {
  return state.merge({ getRoversIsLoading: true })
}
/*, filteredCustomers = [] */
export const getAllRoversSuccess = (state, { rovers }) => {
  return state.merge({
    getRoversIsLoading: false,
    allRovers: rovers.map(convertRawRover),
    roversMap: new Map(rovers.map(rover => [rover.serial, true])),
    // filteredCustomers: filteredCustomers.map(convertRawCustomer),
  })
}
export const getAllRoversFailure = state => {
  return state.merge({ getRoversIsLoading: false })
}

export const getCustomerRoversLoading = state => {
  return state.merge({ getCustomerRoversIsLoading: true })
}
/*, filteredCustomers = [] */
export const getCustomerRoversSuccess = (state, { customerId, rovers }) => {
  return state.merge({
    getCustomerRoversIsLoading: false,
    customerRovers: {
      ...state.get('customerRovers'),
      [customerId]: rovers.map(convertRawRover),
    },
    // filteredCustomers: filteredCustomers.map(convertRawCustomer),
  })
}
export const getCustomerRoversFailure = state => {
  return state.merge({ getCustomerRoversIsLoading: false })
}

export const addRoverSuccess = (state, { rover }) => {
  return state.merge({
    addRoverIsLoading: false,
    rovers: [
      convertRawRover(rover),
      ...state.get('rovers'),
    ],
    roversPagination: updatePagination(state.get('roversPagination'), 'add'),
  })
}
export const addRoverLoading = state => {
  return state.merge({ addRoverIsLoading: true })
}
export const addRoverFailure = state => {
  return state.merge({ addRoverIsLoading: false })
}

export const enableDownloadRoverSSHLoading = (state, { serial }) => state.merge({
})
export const enableDownloadRoverSSHSuccess = (state, { serial, rover }) => {
  return state.merge({
    rovers: state.get('rovers').map(r => r.serial === serial ? convertRawRover(rover) : r),
  })
}
export const enableDownloadRoverSSHFailure = (state, { serial }) => state.merge({
})

export const editRoverLoading = (state, { serial }) => {
  return state.merge({
    editRoverIsLoading: {
      ...state.get('editRoverIsLoading'),
      [serial]: true,
    },
  })
}
export const editRoverSuccess = (state, { serial, rover }) => {
  const rovers = state.get('rovers')
  return state.merge({
    rovers: rover ? rovers.map(r => r.serial === serial ? convertRawRover(rover) : r) : rovers,
    editRoverIsLoading: {
      ...state.get('editRoverIsLoading'),
      [serial]: false,
    },
  })
}
export const editRoverTagsSuccess = (state, { serial, tags }) => {
  return state.merge({
    rovers: state.get('rovers').map(r => r.serial === serial ? convertRawRover({ ...r, rover_tags: tags }) : r),
  })
}
export const editRoverFailure = (state, { serial }) => {
  return state.merge({
    editRoverIsLoading: {
      ...state.get('editRoverIsLoading'),
      [serial]: false,
    },
  })
}

export const getRoverCalibrationsLoading = (state, { serial }) => {
  return state.merge({
    gerRoverCalibrationsIsLoading: {
      ...state.get('gerRoverCalibrationsIsLoading'),
      [serial]: true,
    },
  })
}
export const getRoverCalibrationsSuccess = (state, { serial, calibrations }) => {
  const roverEvents = state.get('roverEvents')
  const roverEventsForRover = roverEvents[serial] || []
  const newRoverEvents = [
    ...roverEventsForRover,
    ...calibrations.map(calibration => convertRawRoverEvent(calibration, RoverEventType.CALIBRATION)),
  ]
  return state.merge({
    roverEvents: {
      ...roverEvents,
      [serial]: _.orderBy(newRoverEvents, ['timestamp'], ['desc']),
    },
    gerRoverCalibrationsIsLoading: {
      ...state.get('gerRoverCalibrationsIsLoading'),
      [serial]: false,
    },
  })
}
export const getRoverCalibrationsFailure = (state, { serial }) => {
  return state.merge({
    gerRoverCalibrationsIsLoading: {
      ...state.get('gerRoverCalibrationsIsLoading'),
      [serial]: false,
    },
  })
}

export const getRoverCustomSettingsLoading = (state, { serial }) => {
  return state.merge({
    getRoverCustomSettingsIsLoading: {
      ...state.get('getRoverCustomSettingsIsLoading'),
      [serial]: true,
    },
  })
}
export const getRoverCustomSettingsSuccess = (state, { serial, settings }) => {
  const roverEvents = state.get('roverEvents')
  const roverEventsForRover = roverEvents[serial] || []
  const newRoverEvents = [
    ...roverEventsForRover,
    ...settings.map(customSetting => convertRawRoverEvent(customSetting, RoverEventType.CUSTOM_SETTING)),
  ]
  return state.merge({
    roverEvents: {
      ...roverEvents,
      [serial]: _.orderBy(newRoverEvents, ['timestamp'], ['desc']),
    },
    getRoverCustomSettingsIsLoading: {
      ...state.get('getRoverCustomSettingsIsLoading'),
      [serial]: false,
    },
  })
}
export const getRoverCustomSettingsFailure = (state, { serial }) => {
  return state.merge({
    getRoverCustomSettingsIsLoading: {
      ...state.get('getRoverCustomSettingsIsLoading'),
      [serial]: false,
    },
  })
}

export const addRoverCustomSettingLoading = (state, { serial }) => {
  return state.merge({})
}
export const addRoverCustomSettingSuccess = (state, { serial, setting }) => {
  const roverEvents = state.get('roverEvents')
  const roverEventsForRover = roverEvents[serial] || []
  const transformedEvent = convertRawRoverEvent(setting, RoverEventType.CUSTOM_SETTING)
  return state.merge({
    selectedEvent: transformedEvent,
    roverEvents: {
      ...roverEvents,
      [serial]: [
        transformedEvent,
        ...roverEventsForRover,
      ],
    },
  })
}
export const addRoverCustomSettingFailure = (state, { serial }) => {
  return state.merge({})
}

export const editRoverCalibrationLoading = (state, { serial, slug }) => {
  return state.merge({
    editRoverCalibrationIsLoading: {
      ...state.get('editRoverIsLoading'),
      [slug]: true,
    },
  })
}
export const editRoverCalibrationSuccess = (state, { serial, slug, formData }) => {
  const roverCalibrations = state.get('roverCalibrations')
  return state.merge({
    roverCalibrations: {
      ...roverCalibrations,
      [serial]: (roverCalibrations[serial] || []).map(rc => rc.slug === slug ? { ...rc, comment: formData.comment } : rc),
    },
    editRoverCalibrationIsLoading: {
      ...state.get('editRoverIsLoading'),
      [slug]: false,
    },
  })
}
export const editRoverCalibrationFailure = (state, { serial, slug }) => {
  return state.merge({
    editRoverCalibrationIsLoading: {
      ...state.get('editRoverIsLoading'),
      [slug]: false,
    },
  })
}

export const deleteRoverCalibrationLoading = (state, { serial, slug }) => {
  return state.merge({})
}
export const deleteRoverCalibrationSuccess = (state, { serial, slug }) => {
  const roverCalibrations = state.get('roverCalibrations')
  return state.merge({
    roverCalibrations: {
      ...roverCalibrations,
      [serial]: (roverCalibrations[serial] || []).filter(rc => rc.slug !== slug),
    },
  })
}
export const deleteRoverCalibrationFailure = (state, { serial, slug }) => {
  return state.merge({})
}

export const setExpandedCustomSetting = (state, { id }) => {
  return state.merge({ expandRoverSetting: id })
}

export const getRoverEventsLoading = (state, { serial }) => state.merge({
  getRoverEventsIsLoading: {
    ...state.get('getRoverEventsIsLoading'),
    [serial]: true,
  },
})
export const getRoverEventsSuccess = (state, { serial, events }) => {
  return state.merge({
    getRoverEventsIsLoading: {
      ...state.get('getRoverEventsIsLoading'),
      [serial]: false,
    },
    roverEvents: {
      ...state.get('roverEvents'),
      [serial]: _.orderBy(events, ['timestamp'], ['desc']),
    },
  })
}
export const getRoverEventsFailure = (state, { serial }) => state.merge({
  getRoverEventsIsLoading: {
    ...state.get('getRoverEventsIsLoading'),
    [serial]: false,
  },
})

export const addRoverEventLoading = (state, { serial }) => state.merge({
  addRoverEventIsLoading: {
    ...state.get('addRoverEventIsLoading'),
    [serial]: true,
  },
})
export const addRoverEventSuccess = (state, { serial, eventType, event }) => {
  const roverEvents = state.get('roverEvents')
  const roverEventsForRover = roverEvents[serial] || []
  return state.merge({
    addRoverEventIsLoading: {
      ...state.get('addRoverEventIsLoading'),
      [serial]: false,
    },
    roverEvents: {
      ...roverEvents,
      [serial]: [
        event,
        ...roverEventsForRover,
      ],
    },
  })
}
export const addRoverEventFailure = (state, { serial }) => state.merge({
  addRoverEventIsLoading: {
    ...state.get('addRoverEventIsLoading'),
    [serial]: false,
  },
})

// System reports
export const getSystemReportsLoading = (state, { serial }) => {
  return state.merge({
    getSystemReportsIsLoading: {
      ...state.get('getSystemReportsIsLoading'),
      [serial]: true,
    },
  })
}
export const getSystemReportsSuccess = (state, { serial, reports, pagination }) => {
  return state.merge({
    reports: {
      ...state.get('reports'),
      [serial]: reports.map(report => convertRawRoverEvent(report, RoverEventType.COMPONENTINFO)),
    },
    reportsPagination: {
      ...state.get('reportsPagination'),
      [serial]: pagination,
    },
    getSystemReportsIsLoading: {
      ...state.get('getSystemReportsIsLoading'),
      [serial]: false,
    },
  })
}

export const getSystemReportsFailure = (state, { serial }) => {
  return state.merge({
    getSystemReportsIsLoading: {
      ...state.get('getSystemReportsIsLoading'),
      [serial]: false,
    },
  })
}
export const getReportComponentsLoading = (state, { reportId }) => {
  return state.merge({
    getReportComponentsIsLoading: {
      ...state.get('getReportComponentsIsLoading'),
      [reportId]: true,
    },
  })
}

export const getReportComponentsSuccess = (state, { components, reportId }) => {
  return state.merge({
    components: {
      ...state.get('components'),
      [reportId]: components.map(convertRawComponent),
    },
    getReportComponentsIsLoading: {
      ...state.get('getReportComponentsIsLoading'),
      [reportId]: false,
    },
  })
}

export const getReportComponentsFailure = (state, { reportId }) => {
  return state.merge({
    getReportComponentsIsLoading: {
      ...state.get('getReportComponentsIsLoading'),
      [reportId]: false,
    },
  })
}

export const updateRoverSuccess = (state, { serial, data }) => {
  const rovers = state.get('rovers')
  return state.merge({
    rovers: serial ? rovers.map(r => r.serial === serial ? ({ ...r, ...data }) : r) : rovers,
  })
}

const updateEventProperties = (parentEventId, childEventType, parentEvent, childEvent) => {
  return parentEvent && parentEvent.id === parentEventId ? {
    ...parentEvent,
    ...(childEventType === RoverEventType.SHIPPING && {
      shipping_events: [
        childEvent.id,
        ...(parentEvent.shipping_events || []),
      ],
    }),
    ...(childEventType === RoverEventType.RMA && {
      children: [
        { id: childEvent.id },
        ...(parentEvent.children || []),
      ],
    }),
  } : parentEvent
}

export const addChildEventLoading = (state, { serial }) => state.merge({
  addRoverEventIsLoading: {
    ...state.get('addRoverEventIsLoading'),
    [serial]: true,
  },
})
export const addChildEventSuccess = (state, { serial, parentEventId, parentEventType, eventType, event, parentEvent }) => {
  const roverEvents = state.get('roverEvents')
  const selectedEvent = state.get('selectedEvent')
  const roverEventsForRover = roverEvents[serial] || []
  return state.merge({
    selectedEvent: updateEventProperties(parentEventId, eventType, selectedEvent, event),
    addRoverEventIsLoading: {
      ...state.get('addRoverEventIsLoading'),
      [serial]: false,
    },
    roverEvents: {
      ...roverEvents,
      [serial]: [
        event,
        ...roverEventsForRover.map(roverEvent => {
          if (roverEvent.id === parentEventId) {
            if (parentEvent) {
              return convertRawRoverEvent(parentEvent, parentEventType)
            } else {
              return updateEventProperties(
                parentEventId,
                eventType,
                roverEvent,
                event,
              )
            }
          }
          return roverEvent
        }),
      ],
    },
  })
}
export const addChildEventFailure = (state, { serial }) => state.merge({
  addRoverEventIsLoading: {
    ...state.get('addRoverEventIsLoading'),
    [serial]: false,
  },
})
export const selectEvent = (state, { event }) => state.merge({
  selectedEvent: event,
})
export const deselectEvent = (state, { event }) => state.merge({
  selectedEvent: null,
})

export const approveEventSuccess = (state, { serial, eventId, event, eventType }) => {
  const roverEvents = state.get('roverEvents')
  const roverEventsForRover = roverEvents[serial] || []
  const newEvents = mapById(eventId, roverEventsForRover, () => convertRawRoverEvent(event, eventType))
  return state.merge({
    approveEventLoading: false,
    roverEvents: {
      ...roverEvents,
      [serial]: newEvents,
    },
  })
}
export const approveEventLoading = (state, { event }) => state.merge({
  approveEventLoading: true,
})
export const approveEventFailure = (state, { event }) => state.merge({
  approveEventLoading: false,
})

export const disapproveEventSuccess = (state, { serial, eventId, event, eventType }) => {
  const roverEvents = state.get('roverEvents')
  const roverEventsForRover = roverEvents[serial] || []
  const newEvents = mapById(eventId, roverEventsForRover, () => convertRawRoverEvent(event, eventType))
  return state.merge({
    disapproveEventLoading: false,
    roverEvents: {
      ...roverEvents,
      [serial]: newEvents,
    },
  })
}
export const disapproveEventLoading = (state, { event }) => state.merge({
  disapproveEventLoading: true,
})
export const disapproveEventFailure = (state, { event }) => state.merge({
  disapproveEventLoading: false,
})

export const rejectEventSuccess = (state, { serial, eventId, event, eventType }) => {
  const roverEvents = state.get('roverEvents')
  const roverEventsForRover = roverEvents[serial] || []
  const newEvents = mapById(eventId, roverEventsForRover, () => convertRawRoverEvent(event, eventType))
  return state.merge({
    rejectEventLoading: false,
    roverEvents: {
      ...roverEvents,
      [serial]: newEvents,
    },
  })
}
export const rejectEventLoading = (state, { event }) => state.merge({
  rejectEventLoading: true,
})
export const rejectEventFailure = (state, { event }) => state.merge({
  rejectEventLoading: false,
})
export const setShowRMADialog = (state, { show, rmaId }) => state.merge({
  showRMADialog: { show, rmaId: rmaId },
})
export const setRegisterSystemDialog = (state, { show }) => state.merge({
  showRegisterSystemDialog: { show },
})

export const registerSystemSuccess = (state, { companyId, event }) => {
  const transformedEvent = convertRawRoverEvent(event, RoverEventType.REGISTER)
  const roverSerial = event.rover.serial
  const roverEvents = state.get('roverEvents')
  const roverEventsForRover = roverEvents[roverSerial] || []
  return state.merge({
    registerSystemLoading: false,
    roverEvents: {
      ...roverEvents,
      [roverSerial]: [
        transformedEvent,
        ...roverEventsForRover,
      ],
    },
  })
}
export const registerSystemLoading = (state, { event }) => state.merge({
  registerSystemLoading: true,
})
export const registerSystemFailure = (state, { event }) => state.merge({
  registerSystemLoading: false,
})

export const closeEventSuccess = (state, { serial, eventId, event, eventType }) => {
  const roverEvents = state.get('roverEvents')
  const roverEventsForRover = roverEvents[serial] || []
  return state.merge({
    closeEventLoading: false,
    roverEvents: {
      ...roverEvents,
      [serial]: mapById(eventId, roverEventsForRover, () => convertRawRoverEvent(event, eventType)),
    },
  })
}
export const closeEventLoading = (state, { event }) => state.merge({
  closeEventLoading: true,
})
export const closeEventFailure = (state, { event }) => state.merge({
  closeEventLoading: false,
})

export const editEventSuccess = (state, { serial, eventId, eventType, event }) => {
  const roverEvents = state.get('roverEvents')
  const roverEventsForRover = roverEvents[serial] || []
  return state.merge({
    editEventLoading: false,
    roverEvents: {
      ...roverEvents,
      [serial]: mapById(eventId, roverEventsForRover, () => event),
    },
  })
}
export const editEventLoading = (state, { event }) => state.merge({
  editEventLoading: true,
})
export const editEventFailure = (state, { event }) => state.merge({
  editEventLoading: false,
})

export const deleteEventSuccess = (state, { serial, eventId }) => {
  const roverEvents = state.get('roverEvents')
  const roverEventsForRover = roverEvents[serial] || []
  return state.merge({
    deleteEventLoading: false,
    roverEvents: {
      ...roverEvents,
      [serial]: removeById(eventId, roverEventsForRover),
    },
  })
}
export const deleteEventLoading = (state, { event }) => state.merge({
  deleteEventLoading: true,
})
export const deleteEventFailure = (state, { event }) => state.merge({
  deleteEventLoading: false,
})

export const getEventDBLogsSuccess = (state, { eventId, eventType, logs, pagination }) => {
  return state.merge({
    getEventDBLogsLoading: false,
    eventDBLogsPagination: {
      ...state.get('eventDBLogsPagination'),
      [eventId]: pagination,
    },
    eventDBLogs: {
      ...state.get('eventDBLogs'),
      [eventId]: logs.map(convertRawLog),
    },
  })
}
export const getEventDBLogsLoading = (state, { event }) => state.merge({
  getEventDBLogsLoading: true,
})
export const getEventDBLogsFailure = (state, { event }) => state.merge({
  getEventDBLogsLoading: false,
})

export const getCheckListStepDBLogsSuccess = (state, { eventType, stepId, logs, pagination }) => {
  return state.merge({
    getCheckListStepDBLogsLoading: false,
    checkListStepDBLogsPagination: {
      ...state.get('checkListStepDBLogsPagination'),
      [stepId]: pagination,
    },
    checkListStepDBLogs: {
      ...state.get('checkListStepDBLogs'),
      [stepId]: logs.map(convertRawLog),
    },
  })
}
export const getCheckListStepDBLogsLoading = (state, { event }) => state.merge({
  getCheckListStepDBLogsLoading: true,
})
export const getCheckListStepDBLogsFailure = (state, { event }) => state.merge({
  getCheckListStepDBLogsLoading: false,
})

export const addEventAttachmentsSuccess = (state, { serial, eventId, eventType, attachments }) => {
  const roverEvents = state.get('roverEvents')
  const roverEventsForRover = roverEvents[serial] || []
  return state.merge({
    addAttachmentsIsLoading: false,
    roverEvents: {
      ...roverEvents,
      [serial]: mapById(
        eventId,
        roverEventsForRover,
        event => ({
          ...event,
          attachments: [
            ...attachments,
            ...(event.attachments || []),
          ],
        })),
    },
  })
}
export const addEventAttachmentsLoading = state => state.merge({
  addAttachmentsIsLoading: true,
})
export const addEventAttachmentsFailure = state => state.merge({
  addAttachmentsIsLoading: false,
})

export const deleteEventAttachmentsSuccess = (state, { serial, eventId, eventType, attachmentIds }) => {
  const roverEvents = state.get('roverEvents')
  const roverEventsForRover = roverEvents[serial] || []

  return state.merge({
    deleteAttachmentIsLoading: false,
    roverEvents: {
      ...roverEvents,
      [serial]: mapById(
        eventId,
        roverEventsForRover,
        event => ({
          ...event,
          attachments: (event.attachments || []).filter(attachment => !attachmentIds.includes(`${attachment.id}`)),
        })),
    },
  })
}
export const deleteEventAttachmentsLoading = state => state.merge({
  deleteAttachmentIsLoading: true,
})
export const deleteEventAttachmentsFailure = state => state.merge({
  deleteAttachmentIsLoading: false,
})

export const getRoverProjectsSuccess = (state, { serial, projects, companyProjects }) => {
  return state.merge({
    getRoverProjectsIsLoading: { ...state.get('getRoverProjectsIsLoading'), [serial]: false },
    roverProjects: {
      ...(state.get('roverProjects') || {}),
      [serial]: projects,
    },
    roverCompanyProjects: {
      ...(state.get('roverCompanyProjects') || {}),
      [serial]: makeUniqueById([
        ...projects,
        ...companyProjects,
      ]),
    },
  })
}
export const getRoverProjectsLoading = (state, { serial }) => state.merge({
  getRoverProjectsIsLoading: { ...state.get('getRoverProjectsIsLoading'), [serial]: true },
})
export const getRoverProjectsFailure = (state, { serial }) => state.merge({
  getRoverProjectsIsLoading: { ...state.get('getRoverProjectsIsLoading'), [serial]: false },
})

export const addEventStatusLoading = state => state.merge({ editEventLoading: true })
export const addEventStatusSuccess = (state, { serial, eventId, eventType, event }) => {
  const roverEvents = state.get('roverEvents')
  const roverEventsForRover = roverEvents[serial] || []
  const newEvents = mapById(eventId, roverEventsForRover, () => convertRawRoverEvent(event, eventType))
  return state.merge({
    editEventLoading: false,
    roverEvents: {
      ...roverEvents,
      [serial]: newEvents,
    },
  })
}
export const addEventStatusFailure = state => state.merge({ editEventLoading: false })

export const getRoversCSVLoading = state => state.merge({ getRoversCSVLoading: true })
export const getRoversCSVSuccess = (state, { csv }) => {
  return state.merge({
    getRoversCSVLoading: false,
    roversCSV: csv,
  })
}
export const getRoversCSVFailure = state => state.merge({ getRoversCSVLoading: false })

export const getRoverUpdatesLoading = (state, { serial }) =>
  state.merge({
    getRoverUpdatesLoading: {
      ...state.get('getRoverUpdatesLoading'),
      [serial]: true,
    },
  })
export const getRoverUpdatesSuccess = (state, { serial, updates }) => {
  return state.merge({
    getRoverUpdatesLoading: {
      ...state.get('getRoverUpdatesLoading'),
      [serial]: false,
    },
    roverUpdates: {
      ...state.get('roverUpdates'),
      [serial]: updates.map(update => ({
        ...update,
        version: path(['release', 'version'], update),
        date: path(['release', 'date'], update),
      })),
    },
  })
}
export const getRoverUpdatesFailure = (state, { serial }) =>
  state.merge({
    getRoverUpdatesLoading: {
      ...state.get('getRoverUpdatesLoading'),
      [serial]: false,
    },
  })

export const getRoverVersionLoading = (state, { serial }) =>
  state.merge({
    getRoverVersionLoading: {
      ...state.get('getRoverVersionLoading'),
      [serial]: true,
    },
  })
export const getRoverVersionSuccess = (state, { serial, firmware_version }) => {
  return state.merge({
    getRoverVersionLoading: {
      ...state.get('getRoverVersionLoading'),
      [serial]: false,
    },
    roverVersion: {
      ...state.get('roverVersion'),
      [serial]: firmware_version,
    },
  })
}
export const getRoverVersionFailure = (state, { serial }) =>
  state.merge({
    getRoverVersionLoading: {
      ...state.get('getRoverVersionLoading'),
      [serial]: false,
    },
  })

export const reducer = createReducer(INITIAL_STATE, {
  [RoverTypes.GET_ROVERS_LOADING]: getRoversLoading,
  [RoverTypes.GET_ROVERS_SUCCESS]: getRoversSuccess,
  [RoverTypes.GET_ROVERS_FAILURE]: getRoversFailure,

  [RoverTypes.GET_ALL_ROVERS_LOADING]: getAllRoversLoading,
  [RoverTypes.GET_ALL_ROVERS_SUCCESS]: getAllRoversSuccess,
  [RoverTypes.GET_ALL_ROVERS_FAILURE]: getAllRoversFailure,

  [RoverTypes.GET_ROVER_CALIBRATIONS_LOADING]: getRoverCalibrationsLoading,
  [RoverTypes.GET_ROVER_CALIBRATIONS_SUCCESS]: getRoverCalibrationsSuccess,
  [RoverTypes.GET_ROVER_CALIBRATIONS_FAILURE]: getRoverCalibrationsFailure,

  [RoverTypes.GET_ROVER_CUSTOM_SETTINGS_LOADING]: getRoverCustomSettingsLoading,
  [RoverTypes.GET_ROVER_CUSTOM_SETTINGS_SUCCESS]: getRoverCustomSettingsSuccess,
  [RoverTypes.GET_ROVER_CUSTOM_SETTINGS_FAILURE]: getRoverCustomSettingsFailure,

  [RoverTypes.ADD_ROVER_CUSTOM_SETTING_LOADING]: addRoverCustomSettingLoading,
  [RoverTypes.ADD_ROVER_CUSTOM_SETTING_SUCCESS]: addRoverCustomSettingSuccess,
  [RoverTypes.ADD_ROVER_CUSTOM_SETTING_FAILURE]: addRoverCustomSettingFailure,

  [RoverTypes.SET_EXPANDED_CUSTOM_SETTING]: setExpandedCustomSetting,

  [RoverTypes.GET_CUSTOMER_ROVERS_LOADING]: getCustomerRoversLoading,
  [RoverTypes.GET_CUSTOMER_ROVERS_SUCCESS]: getCustomerRoversSuccess,
  [RoverTypes.GET_CUSTOMER_ROVERS_FAILURE]: getCustomerRoversFailure,

  [RoverTypes.ADD_ROVER_LOADING]: addRoverLoading,
  [RoverTypes.ADD_ROVER_SUCCESS]: addRoverSuccess,
  [RoverTypes.ADD_ROVER_FAILURE]: addRoverFailure,

  [RoverTypes.EDIT_ROVER_LOADING]: editRoverLoading,
  [RoverTypes.EDIT_ROVER_SUCCESS]: editRoverSuccess,
  [RoverTypes.EDIT_ROVER_TAGS_SUCCESS]: editRoverTagsSuccess,
  [RoverTypes.EDIT_ROVER_FAILURE]: editRoverFailure,

  [RoverTypes.EDIT_ROVER_CALIBRATION_LOADING]: editRoverCalibrationLoading,
  [RoverTypes.EDIT_ROVER_CALIBRATION_SUCCESS]: editRoverCalibrationSuccess,
  [RoverTypes.EDIT_ROVER_CALIBRATION_FAILURE]: editRoverCalibrationFailure,

  [RoverTypes.DELETE_ROVER_CALIBRATION_LOADING]: deleteRoverCalibrationLoading,
  [RoverTypes.DELETE_ROVER_CALIBRATION_SUCCESS]: deleteRoverCalibrationSuccess,
  [RoverTypes.DELETE_ROVER_CALIBRATION_FAILURE]: deleteRoverCalibrationFailure,

  [RoverTypes.ENABLE_DOWNLOAD_ROVER_SSH_LOADING]: enableDownloadRoverSSHLoading,
  [RoverTypes.ENABLE_DOWNLOAD_ROVER_SSH_SUCCESS]: enableDownloadRoverSSHSuccess,
  [RoverTypes.ENABLE_DOWNLOAD_ROVER_SSH_FAILURE]: enableDownloadRoverSSHFailure,

  [RoverTypes.GET_ROVER_EVENTS_LOADING]: getRoverEventsLoading,
  [RoverTypes.GET_ROVER_EVENTS_SUCCESS]: getRoverEventsSuccess,
  [RoverTypes.GET_ROVER_EVENTS_FAILURE]: getRoverEventsFailure,

  [RoverTypes.ADD_ROVER_EVENT_LOADING]: addRoverEventLoading,
  [RoverTypes.ADD_ROVER_EVENT_SUCCESS]: addRoverEventSuccess,
  [RoverTypes.ADD_ROVER_EVENT_FAILURE]: addRoverEventFailure,

  [RoverTypes.ADD_CHILD_EVENT_LOADING]: addChildEventLoading,
  [RoverTypes.ADD_CHILD_EVENT_SUCCESS]: addChildEventSuccess,
  [RoverTypes.ADD_CHILD_EVENT_FAILURE]: addChildEventFailure,

  [RoverTypes.GET_SYSTEM_REPORTS_LOADING]: getSystemReportsLoading,
  [RoverTypes.GET_SYSTEM_REPORTS_SUCCESS]: getSystemReportsSuccess,
  [RoverTypes.GET_SYSTEM_REPORTS_FAILURE]: getSystemReportsFailure,

  [RoverTypes.GET_REPORT_COMPONENTS_LOADING]: getReportComponentsLoading,
  [RoverTypes.GET_REPORT_COMPONENTS_SUCCESS]: getReportComponentsSuccess,
  [RoverTypes.GET_REPORT_COMPONENTS_FAILURE]: getReportComponentsFailure,

  [RoverTypes.APPROVE_EVENT_LOADING]: approveEventLoading,
  [RoverTypes.APPROVE_EVENT_SUCCESS]: approveEventSuccess,
  [RoverTypes.APPROVE_EVENT_FAILURE]: approveEventFailure,

  [RoverTypes.DISAPPROVE_EVENT_LOADING]: disapproveEventLoading,
  [RoverTypes.DISAPPROVE_EVENT_SUCCESS]: disapproveEventSuccess,
  [RoverTypes.DISAPPROVE_EVENT_FAILURE]: disapproveEventFailure,

  [RoverTypes.REJECT_EVENT_LOADING]: rejectEventLoading,
  [RoverTypes.REJECT_EVENT_SUCCESS]: rejectEventSuccess,
  [RoverTypes.REJECT_EVENT_FAILURE]: rejectEventFailure,

  [RoverTypes.CLOSE_EVENT_LOADING]: closeEventLoading,
  [RoverTypes.CLOSE_EVENT_SUCCESS]: closeEventSuccess,
  [RoverTypes.CLOSE_EVENT_FAILURE]: closeEventFailure,

  [RoverTypes.EDIT_EVENT_LOADING]: editEventLoading,
  [RoverTypes.EDIT_EVENT_SUCCESS]: editEventSuccess,
  [RoverTypes.EDIT_EVENT_FAILURE]: editEventFailure,

  [RoverTypes.DELETE_EVENT_LOADING]: deleteEventLoading,
  [RoverTypes.DELETE_EVENT_SUCCESS]: deleteEventSuccess,
  [RoverTypes.DELETE_EVENT_FAILURE]: deleteEventFailure,

  [RoverTypes.REGISTER_SYSTEM_LOADING]: registerSystemLoading,
  [RoverTypes.REGISTER_SYSTEM_SUCCESS]: registerSystemSuccess,
  [RoverTypes.REGISTER_SYSTEM_FAILURE]: registerSystemFailure,

  [RoverTypes.ADD_EVENT_ATTACHMENTS_LOADING]: addEventAttachmentsLoading,
  [RoverTypes.ADD_EVENT_ATTACHMENTS_SUCCESS]: addEventAttachmentsSuccess,
  [RoverTypes.ADD_EVENT_ATTACHMENTS_FAILURE]: addEventAttachmentsFailure,

  [RoverTypes.DELETE_EVENT_ATTACHMENTS_LOADING]: deleteEventAttachmentsLoading,
  [RoverTypes.DELETE_EVENT_ATTACHMENTS_SUCCESS]: deleteEventAttachmentsSuccess,
  [RoverTypes.DELETE_EVENT_ATTACHMENTS_FAILURE]: deleteEventAttachmentsFailure,

  [RoverTypes.GET_EVENT_DB_LOGS_LOADING]: getEventDBLogsLoading,
  [RoverTypes.GET_EVENT_DB_LOGS_SUCCESS]: getEventDBLogsSuccess,
  [RoverTypes.GET_EVENT_DB_LOGS_FAILURE]: getEventDBLogsFailure,

  [RoverTypes.GET_CHECK_LIST_STEP_DB_LOGS_LOADING]: getCheckListStepDBLogsLoading,
  [RoverTypes.GET_CHECK_LIST_STEP_DB_LOGS_SUCCESS]: getCheckListStepDBLogsSuccess,
  [RoverTypes.GET_CHECK_LIST_STEP_DB_LOGS_FAILURE]: getCheckListStepDBLogsFailure,

  [RoverTypes.GET_ROVER_PROJECTS_LOADING]: getRoverProjectsLoading,
  [RoverTypes.GET_ROVER_PROJECTS_SUCCESS]: getRoverProjectsSuccess,
  [RoverTypes.GET_ROVER_PROJECTS_FAILURE]: getRoverProjectsFailure,

  [RoverTypes.ADD_EVENT_STATUS_LOADING]: addEventStatusLoading,
  [RoverTypes.ADD_EVENT_STATUS_SUCCESS]: addEventStatusSuccess,
  [RoverTypes.ADD_EVENT_STATUS_FAILURE]: addEventStatusFailure,

  [RoverTypes.GET_ROVERS_CSV_LOADING]: getRoversCSVLoading,
  [RoverTypes.GET_ROVERS_CSV_SUCCESS]: getRoversCSVSuccess,
  [RoverTypes.GET_ROVERS_CSV_FAILURE]: getRoversCSVFailure,

  [RoverTypes.GET_ROVER_UPDATES_LOADING]: getRoverUpdatesLoading,
  [RoverTypes.GET_ROVER_UPDATES_SUCCESS]: getRoverUpdatesSuccess,
  [RoverTypes.GET_ROVER_UPDATES_FAILURE]: getRoverUpdatesFailure,

  [RoverTypes.GET_ROVER_VERSION_LOADING]: getRoverVersionLoading,
  [RoverTypes.GET_ROVER_VERSION_SUCCESS]: getRoverVersionSuccess,
  [RoverTypes.GET_ROVER_VERSION_FAILURE]: getRoverVersionFailure,

  [RoverTypes.UPDATE_ROVER_SUCCESS]: updateRoverSuccess,
  [RoverTypes.SELECT_EVENT]: selectEvent,
  [RoverTypes.DESELECT_EVENT]: deselectEvent,
  [RoverTypes.SET_SHOW_RMA_DIALOG]: setShowRMADialog,
  [RoverTypes.SET_REGISTER_SYSTEM_DIALOG]: setRegisterSystemDialog,
})
